

import { PageResult } from "@framework/utils";
import { QueryModel, QueryState } from "@framework/models";

import { BrandApi } from "@api";

interface BrandState extends QueryState<BrandApi.Brand> {
}

export class BrandModel extends QueryModel<BrandState> {

    constructor() {
        super();
        this.state = {};
    }

    public saveOrUpdate = async (params: BrandApi.Brand) => {
        const finalParams: any = {
            name: params.name as string,
            sort: params.sort as number,
            image: params.image as string,
            logo: params.logo as string,
            info: params.info as string,
            promoteSort: params.promoteSort
        };
        if (this.state.editingItem?.id == null) {
            finalParams.status = BrandApi.BrandStatus.enabled;
            await BrandApi.add(finalParams);
        } else {
            await BrandApi.update(this.state.editingItem.id, finalParams);
        }
    }

    public changeStatus = (index: number, status: BrandApi.BrandStatus) => {
        const item = (this.state.data as BrandApi.Brand[])[index]
        BrandApi.changeStatus(item.id as number, status).then(() => {
            if (this.state.data != null) {
                this.state.data[index] = {...item, status};
                this.updateState({ data: [...this.state.data] })
            }
        });
    }

    public queryOverride(params?: any): Promise<BrandApi.Brand[] | PageResult<BrandApi.Brand>> {
        return BrandApi.list(params?.limit, params?.skip, { name: params?.name, status: params?.status });
    }
}