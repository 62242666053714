

import { FileUploadResult } from "@framework/component/quill-editor";

import { fetch } from "@root/app";
import { CACHE, HD } from "@root/constants";

export namespace FileUploadApi {
    /**
     * 
     * @param file 上传的图片文件。
     * @returns 上传成功后返回图片的url地址。
     */
    export async function uploadImage(file: File) {
        let formData: FormData = new FormData();
        formData.append("image", file, file.name);
        return fetch.postFormData<string>("/api/user/upload/image", formData);
    }

    export async function uploadIcon(file: File) {
        let formData: FormData = new FormData();
        formData.append("image", file, file.name)
        return fetch.postFormData<string>("/api/user/upload/icon", formData);
    }

    export async function upload(file: File) {
        let formData: FormData = new FormData();
        formData.append("image", file, file.name);
        const url = await fetch.postFormData<string>("/api/user/upload/image", formData);
        const result: FileUploadResult = {
            url: url,
            thumbUrl: `${CACHE}${url}`,
            originUrl: `${HD}${url}`,
        }
        return result;
    }

    export function requestForUploader(options: any) {
        const { onProgress, onError, onSuccess, file } = options;
        onProgress != null && onProgress({percent: 99});
        uploadImage(file).then(link => {
            onSuccess != null && onSuccess(link);
        }).catch(reason => {
            onError != null && onError({ name: "requestForUploader", message: reason})
        })
    }

    export function requestForIconUploader(options: any) {
        const { onProgress, onError, onSuccess, file } = options;
        onProgress != null && onProgress({percent: 99});
        uploadIcon(file).then(link => {
            onSuccess != null && onSuccess(link);
        }).catch(reason => {
            onError != null && onError({ name: "requestForIconUploader", message: reason})
        })
    }
}