

import { Converter, refrenceEquals } from "@framework/utils";

import moment from "moment";
import { Form, FormInstance } from "antd";
import { NamePath } from "antd/lib/form/interface";
import React, { Component, createRef, ReactElement, ReactNode, useEffect, useMemo, useState } from "react";

import { Decorator, DecoratorFormProps, PropsWithSignal } from "./types";

import "@framework/styles/decorator-form.global.less";

const convertInitialValue = (initialValue?: any, converter?: Converter<any, any>, values?: any) => {
    if (converter?.convert != null) {
        initialValue = (converter.convert) ? converter.convert(initialValue, values) : initialValue;
    }
    return initialValue;
}

const convertFieldValue = (name: string, values: any, target: any, converter?: Converter<any, any>) => {
    const value = values[name];
    if (converter?.convertBack != null) {
        const result = converter.convertBack(value, values, target);
        if (result == target || result == values) {
            return target[name];
        } else {
            return result;
        }
    }
    return value;
}

function DecoratorFormItem(props: Decorator): ReactElement {
    const [ shoudlValidate, setShouldValidate ] = useState(false);
    const [decorator] = useState<Decorator>({...props, newProps: {...props.newProps}});
    const [initValue, setInitValue] = useState<any>();
    const [converter, setConverter] = useState<Converter<any, any> | undefined>();
    const [decorateChangeFlag, setDecoratorChangeFlag] = useState({});

    const { updateProps } = decorator;

    if (updateProps != null && updateProps instanceof Function && decorator.dependencies != null) {
        const dependencies: any[] = [];
        const form = decorator.formRef?.current == null ? undefined : decorator.formRef.current;
        if (decorator.dependencies instanceof Array) {
            for (let dependency of decorator.dependencies) {
                dependencies.push(Form.useWatch(dependency, form));
            }
        } else {
            dependencies.push(Form.useWatch(decorator.dependencies, form));
        }

        useEffect(() => {
            let newProps = updateProps(decorator, dependencies);
            if (!refrenceEquals(newProps, decorator.newProps)) {
                Object.keys(decorator).forEach(key => delete decorator[key as keyof Decorator]);
                Object.assign(decorator, props, newProps, { newProps: newProps });
                if (decorator.converter != converter || decorator.initialValue != initValue) {
                    const form = decorator.formRef?.current;
                    let values = form?.getFieldsValue();
                    values = values == null ? {} : values;
                    let value = values[decorator.name];
                    if (decorator.initialValue != initValue) {
                        value = convertInitialValue(decorator.initialValue, decorator.converter, values);
                    } else {
                        value = convertFieldValue(decorator.name, values, value, converter);
                        value = convertInitialValue(value, decorator.converter, values);
                    }
                    form?.setFieldValue(decorator.name, value);
                    setInitValue(decorator.initialValue);
                    setConverter(decorator.converter);
                }
                setShouldValidate(true);
                setDecoratorChangeFlag({});
            }
        }, dependencies);
    }

    useEffect(() => {
        const newProps = decorator.newProps;
        Object.keys(decorator).forEach(key => delete decorator[key as keyof Decorator]);
        Object.assign(decorator, props, newProps, { newProps: newProps });
        const { initialValue: newInitValue, converter: newConverter } = decorator;
        if (initValue != newInitValue || converter != newConverter) {
            const form = decorator.formRef?.current;
            const values = form?.getFieldsValue();
            const finalValue = convertInitialValue(newInitValue, newConverter, values);
            setInitValue(newInitValue);
            setConverter(newConverter);
            form?.setFieldValue(decorator.name, finalValue);
        }
        setDecoratorChangeFlag({});
    }, [props]);

    useEffect(() => {
        if (shoudlValidate) {
            const form = decorator.formRef?.current;
            form?.validateFields([decorator.name]);
            setShouldValidate(false);
        }
    }, [decorator.rules]);


    const View = useMemo(() => {
        const { updateProps, formRef, element, className, converter, newProps, extraProps, initialValue, ...restProps} = decorator;
        let child: ReactNode = undefined;
        if (element instanceof Function) {
            child = element(decorator);
        } else if (element != null) {
            child = React.cloneElement(element, extraProps);
        }
        const style = decorator.hidden ? { display: "none" } : undefined;
        return (
            <Form.Item style={style} {...restProps} className={className}>
                {child}
            </Form.Item>
        );
    }, [decorateChangeFlag]);
    
    return View;
}

export class DecoratorForm extends Component<DecoratorFormProps> {

    formRef: React.RefObject<FormInstance<any>>;

    decorators?: Decorator[];

    #time = moment().valueOf().toString(16);

    constructor(props: DecoratorFormProps) {
        super(props);
        this.formRef = createRef();
    }

    getChildren(): JSX.Element[] {
        this.decorators = this.props.decorators;
        if (this.decorators == null && this.props.getDecorators != null) {
            this.decorators = this.props.getDecorators();
        }
        if (this.decorators != null) {
            return this.decorators.map((decorator) => {
                const key = decorator.name;
                decorator.formRef = this.formRef;
                decorator.newProps = {};
                return <DecoratorFormItem key={key} { ...decorator} />
            });
        }
        return [];
    }

    private handleSubmit = (values: any) => {
        let finalValues = { ...values };
        if (this.decorators != null) {
            for (const decorator of this.decorators) {
                const converter = decorator.newProps?.converter == null ? decorator.converter : decorator.newProps.converter;
                finalValues[decorator.name] = convertFieldValue(decorator.name, values, finalValues, converter);
            }
        }
        const fireSubmit = this.props.onSubmit == null ? this.props.onFinish : this.props.onSubmit;

        return fireSubmit == null ? finalValues : fireSubmit(finalValues);
    }

    public submit() {
        if (this.formRef.current != null) {
            return this.formRef.current.validateFields().then(this.handleSubmit);
        } else {
            return new Promise((_, reject) => reject(new Error("未找到 form 实例！")))
        }
    }

    public resetFields(fields?: NamePath[]) {
        this.formRef.current?.resetFields(fields);
    }

    render = () => {
        const { decorators, getDecorators, onSubmit, children, autoComplete, ...otherProps } = this.props;
        let autoCompleteSetting = autoComplete == null ? "on" : autoComplete;
        return (
            <Form
                {...otherProps}
                name={this.#time}
                ref={this.formRef}
                onFinish={this.handleSubmit}
                autoComplete={autoCompleteSetting}
            >
                {[...this.getChildren(), children as ReactNode]}
            </Form>
        )
    }
}

export function FormElement<T>({ children, inputElementKey, model, ...props }: PropsWithSignal<T>) {
    let count = React.Children.count(children);

    let finalChildren = React.Children.map(children, child => {
        if (count == 1) {
            return React.cloneElement(child, props);
        } else if (child.key == inputElementKey) {
            return React.cloneElement(child, props);
        } else {
            return child;
        }
    });

    return (<>{finalChildren}</>);
}