import { fetch } from "@root/app";
import { PageResult } from "@root/framework/utils";

export namespace AdvertisingApi {

    export enum AdvertisingStatus {
        Enabled = 1,
        Disabled = 2
    }

    export enum AdvertisingType {
        product = 1
    }

    export interface AdvertisingRequest {
        /**
         * 显示条数
         */
        limit?: number,

        skip?: number,

        title?: string,

        status?: AdvertisingStatus,

        type?: AdvertisingType

    }

    export interface Advertising {
        id?: string,

        title?: string,

        image?: string,

        type?: AdvertisingType,

        relevancyId?: string,

        status?: AdvertisingStatus,

        sort?: number,

        startDate?: number,

        endDate?: number,

        startTime?: number,

        endTime?: number,

        repeatNotice?: boolean,

        createTime?: number,

        lastUpdateTime?: number,

        createUserName?: string,

        lastUpdateUserName?: string
    }

    export function addAdvertising(title?: string, image?: string, type?: AdvertisingType, relevancyId?: string, sort?: number, status?: AdvertisingStatus, startDate?: number, endDate?: number, repeatNotice?: boolean, startTime?: number, endTime?: number) {
        return fetch.post("/api/notice", {title, image, type, relevancyId, sort, status, startDate, endDate, repeatNotice, startTime, endTime})
    }

    export function deleteAdvertising(id?: string){
        return fetch.delete(`/api/notice/${id}`)
    }

    export function changeAdvertising(id?: string, title?: string, image?: string, type?: AdvertisingType, relevancyId?: string, sort?: number, status?: AdvertisingStatus, startDate?: number, endDate?: number, repeatNotice?: boolean, startTime?: number, endTime?: number){
        return fetch.put(`/api/notice/${id}`, {title, image, type, relevancyId, sort, status, startDate, endDate, repeatNotice, startTime, endTime})
    }

    export function changeAdvertisingStatus(id?: string, status?: AdvertisingStatus){
        return fetch.put(`/api/notice/status/${id}`, {status})
    }

    export function getAdvertisingList(title?: string, status?: AdvertisingStatus, type?: AdvertisingType){
        return fetch.get<PageResult<Advertising>>("/api/notice", {title, status, type})
    }
}