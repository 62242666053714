

import { PageResult } from "@framework/utils";

import { fetch } from "@root/app";

import { ProductApi } from "./product";
import { VoucherApi } from "./voucher";

export namespace ActivityApi {
    /**
     * Activity
     */
    export interface Activity {
        /**
         * 富文本内容
         */
        content?: string;
        /**
         * 展示图
         */
        cover?: string;
        createTime?: number;
        createUserName?: string;
        /**
         * 结束时间
         */
        endTime?: number;
        id?: string;
        lastUpdateTime?: number;
        lastUpdateUserName?: string;
        /**
         * 备注
         */
        remark?: string;
        /**
         * 排序（正序）
         */
        sort?: number;
        /**
         * 开始时间
         */
        startTime?: number;
        /**
         * 状态
         */
        status?: number;
        /**
         * 标题
         */
        title?: string;
        /**
         * 活动关联的优惠券列表。
         */
        couponBatchList?: Array<VoucherApi.VoucherBatch>
    }

    export enum Status {
        /**
         * 新建
         */
        New = 1,
        /**
         * 已开始
         */
        Available = 2,
        /**
         * 已暂停
         */
        Paused = 3,
        /**
         * 已结束
         */
        Finished = 4
    }

    /**
     * 新建活动。
     * 
     * @param title 活动标题
     * @param startTime 开始时间
     * @param endTime 结束时间
     * @param cover 活动封面
     * @param content 活动介绍
     * @param sort 排序序号
     * @param remark 备注信息
     */
    export async function add(title?: string, startTime?: number, endTime?: number, cover?: string, content?: string, sort?: number, remark?: string) {
        return fetch.post<Activity>("/api/activity", { title, startTime, endTime, cover, content, sort, remark });
    }
    
    /**
     * 活动详情。
     * @param id 活动编号
     * @returns 活动信息（包含 content 的富文本内容）。
     */
    export async function detail(id?: string) {
        return fetch.get<Activity>(`/api/activity/${id}`);
    }

    /**
     * 停止活动
     * @param id 活动编号
     */
    export async function finish(id?: string) {
        return fetch.put<void>(`/api/activity/status/${id}`, { status: Status.Finished });
    }

    /**
     * 查询活动列表。
     * 
     * @param limit 分页大小
     * @param skip 分页位置
     * @param title 活动标题
     * @param status 活动状态
     * @returns 分页的活动列表。
     */
    export async function list(limit?: number, skip?: number, title?: string, status?: Status) {
        return fetch.get<PageResult<Activity>>("/api/activity", { limit, skip, title, status });
    }

    /**
     * 获取指定活动可用的优惠券批次列表。
     * 
     * @param limit 分页大小
     * @param skip 分页位置
     * @param activityId 活动编号
     * @param couponBatchName 优惠券名称
     */
    export async function listAvailableBatch(id?: string, limit?: number, skip?: number, couponBatchName?: string) {
        return fetch.get<PageResult<VoucherApi.VoucherBatch>>("/api/activity/coupon-batch/available", {
            limit, skip, id, couponBatchName
        });
    }

    export async function listProduct(activityId?: string, limit?: number, skip?: number, storeName?: string) {
        return fetch.get<ProductApi.Product>("/api/activity/product", { activityId, limit, skip,})
    }

    /**
     * 暂停活动
     * @param id 活动编号
     */
    export async function pause(id?: string) {
        return fetch.put<void>(`/api/activity/status/${id}`, { status: Status.Paused });
    }
    
    /**
     * 设置活动关联的优惠批次。
     * 
     * @param activityId 活动id
     * @param batchIds 批次id（多个id 使用 ',' 分隔）
     */
    export async function setBatchList(activityId?: string, batchIds?: string) {
        return fetch.put<void>("/api/activity/coupon-batch/relevancy", { activityId, couponBatchIds: batchIds });
    }

    /**
     * 开始活动
     * @param id 活动编号
     */
    export async function start(id?: string) {
        return fetch.put<void>(`/api/activity/status/${id}`, { status: Status.Available });
    }

    /**
     * 修改活动信息。
     * 
     * @param id 活动编号
     * @param title 活动标题
     * @param startTime 开始时间
     * @param endTime 结束时间
     * @param cover 活动封面
     * @param content 活动介绍
     * @param sort 排序序号
     * @param remark 备注信息
     */
    export async function update(id?: string, title?: string, startTime?: number, endTime?: number, cover?: string, content?: string, sort?: number, remark?: string) {
        return fetch.put<void>(`/api/activity/${id}`, { title, startTime, endTime, cover, content, sort, remark });
    }
}