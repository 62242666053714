

import { Converter } from "@framework/utils";

import { UploadFile } from "antd";

import { HD, CACHE } from "@root/constants";

type PickStatusType<T> = T extends { status?: infer P } ? P : never;
type StatusType = PickStatusType<UploadFile<any>>; 

export class FileConverter implements Converter<string | UploadFile<any>, UploadFile<any>> {
    convertBackType: "url" | "file" = "url";
    defaultStatus: StatusType = "uploading";

    constructor(defaultStatus: StatusType = "uploading", convertBackType: "url" | "file" = "url") {
        this.defaultStatus = defaultStatus;
        this.convertBackType = convertBackType;
    }

    convert(value?: string | UploadFile<any> | undefined, values?: Record<string, any> | undefined): UploadFile<any> | null {
        if (value == null) {
            return null;
        }
        let result: UploadFile<any> = { status: "done", name: 'temp.jpg', uid: "" }
        if (typeof value == 'string') {
            let lastIndex = value.lastIndexOf('/');
            result.name = value.substring(lastIndex + 1);
            result.uid = result.name;
            result.thumbUrl = `${CACHE}${value}`;
            result.url = value;
            result.preview = `${HD}${value}`;
        } else if (value.originFileObj instanceof File) {
            result.uid = value.uid;
            result.name = value.name;
            result.originFileObj = value.originFileObj;
            result.type = value.type;
            if (value.thumbUrl == null) {
                result.thumbUrl = URL.createObjectURL(value.originFileObj);
                result.url = result.thumbUrl;
                result.status = this.defaultStatus;
            } else {
                result.url = value.thumbUrl;
                result.thumbUrl = value.thumbUrl;
            }
        } else {
            result = value;
        }
        return result;
    }
    convertBack(value?: UploadFile<any> | undefined, values?: Record<string, any> | undefined, target?: Record<string, any> | undefined): string | UploadFile<any> | null {
        if (value == null) {
            return null;
        }
        if (this.convertBackType == "url") {
            return value.url == null || value.url == "" ? null : value.url;
        } else {
            return value;
        }
    }
    
}

const converter = new FileConverter();
export default converter;