

import { refrenceEquals, PageResult } from "@framework/utils";

import { BaseModel } from ".";

export interface QueryState<TData> {
    loading?: boolean
    showEditor?: boolean
    editingItem?: TData
    data?: Array<TData>,
    params?: Partial<PickElementType<TData>> & { [key: string]: any }
    limit?: number
    skip?: number
    total?: number
}

type PickElementType<T> = T extends Array<infer P> ? P : T

type PickDataType<T extends QueryState<any> | Array<any>> = T extends QueryState<infer P> ? P : T;

export abstract class QueryModel<TState extends QueryState<any>> extends BaseModel<TState> {

    get queryParams() {
        return this.state.params;
    }

    set queryParams(params: any) {
        if (!refrenceEquals(this.state.params, params)) {
            this.updateState({ params: params } as Partial<TState>);
        }
    }

    public query(params?: any) {
        this.updateState({ loading: true } as Partial<TState>);
        this.queryOverride(params).then(result => {
            let update: Partial<TState> = {};
            update.params = params;
            if (result instanceof Array) {
                update.data = result;
            } else {
                update.data = result.info;
                update.limit = result.limit;
                update.skip = result.skip;
                update.total = result.total;
            }
            this.updateState(update);
        }).finally(() => {
            this.updateState({ loading: false } as Partial<TState>);
        });
    }

    public abstract queryOverride(params?: any): Promise<Array<PickDataType<TState>> | PageResult<PickDataType<TState>>>;

    public refresh = () => {
        this.query(this.state.params);
    }
}