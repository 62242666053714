import { QueryModel, QueryState } from "@root/framework/models";
import { PageResult, Subtotal, SummaryItem } from "@root/framework/utils";
import { ReportApi } from "@root/services/report";



interface PayRecordState extends QueryState<ReportApi.PayRecord> {
}

export class PayRecordModel extends QueryModel<PayRecordState> {

    constructor() {
        super();
        this.state = {};
    }

    public queryOverride(params?: any): Promise<ReportApi.PayRecord[] | PageResult<ReportApi.PayRecord>> {
        return ReportApi.listPayment(
            params?.startDate, params?.endDate, params?.limit, params?.skip,
            params?.merchantName, params?.merchantId, params?.orderId, params?.payTypes, params?.status, params?.platformIds
        );
    }

}

declare type PaySummary = SummaryItem<ReportApi.PaySummary>;
interface PaySummaryState extends QueryState<PaySummary> {
}

export class PaySummaryModel extends QueryModel<PaySummaryState> {

    constructor() {
        super();
        this.state = {};
    }

    public async queryOverride(params?: any): Promise<PaySummary[] | PageResult<PaySummary>> {
        const result = await ReportApi.listPaymentSummary(params?.startDate, params?.endDate, params?.merchantName, params?.payTypes, params?.status, params?.platformIds);
        const subtotal = new Subtotal(result, (a, b) => {
            if (a.amount == null) {
                a.amount = b.amount;
            } else {
                a.amount += b.amount;
            }
            return a as ReportApi.PaySummary;
        });
        const subtotalResult = subtotal.collect([{
            keyProp: "platformId",
            valueProp: "platformName"
        },{
            keyProp: "merchantId",
            valueProp: "merchantName"
        }, {
            keyProp: "payeeId",
            valueProp: "payeeName"
        }, {
            keyProp: "payType",
            valueProp: "payType"
        }, {
            keyProp: "status",
            valueProp: "status"
        }]);
        subtotalResult.forEach((item, index) => item.id = index);
        return subtotalResult;
    }


}