import { fetch } from "@root/app"
import { PageResult } from "@root/framework/utils"

export namespace AddressApi {

    export interface Address {
        id?: string
        name?: string
        phone?: string
        location?: string
        default?: boolean
        isDefault?: boolean
        createUserName?: string
        lastUpdateUserName?: string
    }

    /**
     * 商户地址列表
     * 
     * @param limit 分页大小
     * @param skip 分页位置
     * @param merchantName 商户名称
     * @param location 地址
     * @param name 收货人姓名
     * @param phone 联系电话
     * @returns 
     */
    export function list(limit?: number, skip?: number, merchantName?: string, location?: string, name?: string, phone?: string) {
        return fetch.get<PageResult<Address>>("/api/address", { limit, skip, merchantName, location, name, phone});
    }

    /**
     * 添加收货地址
     * 
     * @param name 收货人姓名
     * @param location 收货地址
     * @param phone 联系电话
     */
    export function add(isDefault?: boolean, name?: string, location?: string, phone?: string) {
        return fetch.post<void>("/api/address", { isDefault, name, phone, location });
    }

    /**
     * 修改收货地址
     * 
     * @param id 收货地址编号
     * @param name 收货人姓名
     * @param location 收货地址
     * @param phone 联系电话
     */
    export function modify(id?: string, isDefault?: boolean, name?: string, location?: string, phone?: string) {
        return fetch.put<void>(`/api/address/${id}`, {isDefault, name, location, phone});
    }

    /**
     * 删除指定的收货地址
     * 
     * @param id 收货地址编号
     */
    export function remove(id?: string) {
        return fetch.delete<void>(`/api/address/${id}`);
    }
}