

/**
 * 分页查询结果
 */
 export interface PageResult<T extends any> {
    limit: number
    skip: number
    total: number
    info: Array<T>
}


export const toPaginate = (pageResult: PageResult<any>) => {
    const pageSize = pageResult.limit;
    const total = pageResult.total;
    const current = pageResult.skip == null ? 1 : Math.floor(pageResult.skip / pageSize) + 1;
    return {
        pageSize,
        current,
        total
    }
}