

import { PageResult } from "@framework/utils";

import { UserApi } from "./user";
import { fetch } from "@root/app";


export namespace RoleApi {

    export interface Role {
        id?: string
        allowEdit?: boolean
        level?: number
        name?: string
        no?: number
        description?: string
        pid?: string
        permissionList?: Permission[]
        permissions?: number[]
        lastUpdateTime?: number
        lastUpdateUserName?: string
        createTime?: number
        createUserName?: string
        children?: Array<Role>
    }

    export interface Permission {
        id: number
        code: number
        checked: boolean
        name: string
        description?: string
        allowCheck: boolean
        createTime: number
    }

    /**
     * 添加新的用户角色。
     * 
     * @param name 角色名称
     * @param description 角色简介
     * @param permissions 角色权限
     * @param pid 上级角色
     * @returns 创建的角色信息。
     */
    export function add(name?: string, description?: string, permissions?: number[], pid?: string) {
        return fetch.post<Role>("/api/role", {
            pid,
            name,
            description,
            permissions: permissions == null ? undefined : permissions.join(",")
        });
    }

    /**
     * 删除指定角色。
     * 
     * @param ids 逗号分隔的角色id
     */
    export function remove(ids?: string) {
        return fetch.delete<void>("/api/role", { ids });
    }

    /**
     * 查询角色详情
     * @param id 角色编号
     */
    export function detail(id: string) {
        return fetch.get<Role>(`/api/role/${id}`);
    }

    export function grant(userId?: string, roleIds?: string) {
        return fetch.put("/api/role/grant", { id: userId, roleIds: roleIds });
    }
    /**
     * 查询所有角色。
     * 
     * @returns 所有角色列表。
     */
    export function list() {
        return fetch.get<Array<Role>>("/api/role");
    }
    /**
     * 查询当前用户可授予的角色列表。
     * @returns 角色列表。
     */
    export function listAllowGrant() {
        return fetch.get<Array<Role>>("/api/role/allowGrant");
    }

    /**
     * 查询权限列表，并使用 {@link Permission["allowCheck"]} 标记是否允许用户使用该权限。
     * @returns 
     */
    export function listAvailablePermissions(roleId: string) {
        return fetch.get<Array<Permission>>("/api/role/permissions/available", { roleId });
    }

    /**
     * 查询权限列表。
     * 
     * @param name 权限名称
     * @returns 权限列表
     */
    export function listPermissions(name?: string) {
        return fetch.get<Array<Permission>>("/api/role/permissions", { name });
    }
    
    /**
     * 查询拥有指定角色的用户列表。
     * @param roleId 角色id
     * @param limit 分页大小
     * @param skip  分页位置
     */
    export function listUsers(roleId?: string, limit?: number, skip?: number) {
        return fetch.get<PageResult<UserApi.User>>(`/api/role/user/${roleId}`, { limit, skip });
    }

    /**
     * 修改角色信息。
     * 
     * @param roleId      角色编号
     * @param permissions 角色权限 
     * @param name        角色名称
     * @param description 角色介绍。
     */
    export function update(roleId?: string, permissions?: number[], name?: string, description?: string) {
        return fetch.put<void>(`/api/role/${roleId}`, { 
            name,
            description,
            permissions: permissions == null ? undefined : permissions.join(",")
        });
    }
}