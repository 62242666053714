

import { QueryModel, QueryState } from "@framework/models";
import { PageResult, TimestampConverter } from "@framework/utils";
import { TableColumn, Decorator, FormElement, PermissionButton } from "@framework/component";

import moment from "moment";
import { DatePicker, Input, InputNumber, Select } from "antd";

import { VoucherApi } from "@api";
import { BatchStatusMap, VoucherTypeMap } from ".";
import { LinkColumn, TagColumn, TimestampColumn } from "@root/component";

import DiscountRule = VoucherApi.Rule;
import VoucherType = VoucherApi.VoucherType;
import VoucherStatus = VoucherApi.VoucherStatus;
import MakeMode = VoucherApi.MakeMode;
import BatchStatus = VoucherApi.BatchStatus;
import ExpireType = VoucherApi.ExpireType;
import { PermissionCode } from "@root/permission";
import { sprintf } from "sprintf-js";

type VoucherBatch = VoucherApi.VoucherBatch;

interface VoucherBatchState extends QueryState<VoucherApi.VoucherBatch> {

}

export class VoucherBatchModel extends QueryModel<VoucherBatchState> {

    constructor() {
        super();
        this.setState({});
    }

    #showEditor = (item?: VoucherBatch) => {
        this.updateState({ showEditor: true, editingItem: item });
    }

    getQueryDecorators(): Decorator[] {
        const labelCol = { span: 5 };
        return [{
            name:"name",
            label:"名称",
            className:"query-form-item",
            element:<Input placeholder="按批次名称查询" />
        }, { 
            name: "discountRule",
            label: "优惠规则",
            className: "query-form-item",
            element: (
                <Select placeholder="按优惠规则查询" allowClear={true}>
                    <Select.Option value={DiscountRule.MXMY}>满减</Select.Option>
                    <Select.Option value={DiscountRule.PMXMY}>阶梯满减</Select.Option>
                    <Select.Option value={DiscountRule.MXDY}>满额折扣</Select.Option>
                </Select>
            ),
            labelCol: labelCol
        }, {
            name: "type",
            label: "优惠劵获取方式",
            className: "query-form-item",
            element: (
                <Select placeholder="按优惠劵获取方式查询" allowClear={true}>
                    <Select.Option value={VoucherType.Instant}>立减</Select.Option>
                    <Select.Option value={VoucherType.Coupon}>用券</Select.Option>
                </Select>
            ),
            labelCol: labelCol
        }, {
            name: "status",
            label: "状态",
            className: "query-form-item",
            element: (
                <Select placeholder="按当前状态查询" allowClear={true}>
                    <Select.Option value={BatchStatus.New}>待提交</Select.Option>
                    <Select.Option value={BatchStatus.Pending}>待审核</Select.Option>
                    <Select.Option value={BatchStatus.Effected}>已审核</Select.Option>
                    <Select.Option value={BatchStatus.Stoped}>已停用</Select.Option>
                    <Select.Option value={BatchStatus.Rejected}>已驳回</Select.Option>
                </Select>
            ),
            labelCol: labelCol
        }];
    }

    getEditorDecorators(): Decorator[] {
        const { editingItem } = this.state;
        return [{
            name: "name",
            label: "名   称",
            className: "editor-form-item-2",
            element: <Input placeholder="请输入优惠券名称" />,
            rules: [{ required: true, message: "优惠券名称不能为空！" }],
            initialValue: editingItem?.name
        }, { 
            name: "type",
            label: "优惠劵获取方式",
            className: "editor-form-item-2",
            element: (
                <Select>
                    <Select.Option value={VoucherType.Instant}>立减</Select.Option>
                    <Select.Option value={VoucherType.Coupon}>用券</Select.Option>
                </Select>
            ),
            initialValue: editingItem?.type == null ? VoucherType.Coupon : editingItem.type
        }, {
            label: "制券方式",
            name: "makeCouponMode",
            dependencies: ["type"],
            className: "editor-form-item-2",
            element: (
                <Select>
                    <Select.Option value={MakeMode.Auto}>自动生成</Select.Option>
                    <Select.Option value={MakeMode.Import}>手动导入</Select.Option>
                </Select>
            ),
            updateProps: (_, [type]) => {
                const hidden = type == VoucherType.Instant;
                const initialValue = editingItem?.makeCouponMode == null ? MakeMode.Auto : editingItem.makeCouponMode;
                return {hidden: hidden, initialValue: hidden ? MakeMode.Auto : initialValue}
            },
            initialValue: editingItem?.makeCouponMode == null ? MakeMode.Auto : editingItem.makeCouponMode
        }, { 
            name: "discountRule",
            label: "优惠劵类型",
            className: "editor-form-item-2",
            element: (
                <Select placeholder="请选择优惠劵类型">
                    <Select.Option value={DiscountRule.MXMY}>满减</Select.Option>
                    <Select.Option value={DiscountRule.PMXMY}>阶梯满减</Select.Option>
                    <Select.Option value={DiscountRule.MXDY}>满额折扣</Select.Option>
                </Select>
            ),
            initialValue: editingItem?.discountRule == null ? DiscountRule.MXMY : editingItem.discountRule
        }, { 
            name: "restrictPrice",
            label: "最低消费",
            className: "editor-form-item-2",
            element: (<InputNumber min={0} addonAfter="元" addonBefore="￥" />),
            initialValue: editingItem?.restrictPrice == null ? 0 : editingItem.restrictPrice
        }, { 
            name: "discount",
            label: "优惠金额",
            className: "editor-form-item-2",
            dependencies: ["discountRule", "restrictPrice"],
            updateProps: (old, values) => {
                const restrictPrice = values[1] == null ? 0 : values[1];
                if (values[0] == DiscountRule.MXDY) {
                    return {
                        element: (
                            <InputNumber style={{width: "90%"}} min={0} max={100} step={10} addonAfter="%" placeholder="请输入折扣率" />
                        ),
                        rules: [{required: true, type: "number", min: 0, max: 100, message: "请填写折扣率"}],
                        converter: {
                            convert(value?: number){
                                return value == null ? undefined : (value > 1 ? 100 : value * 100);
                            },
                            convertBack(value?: number) {
                                return value == null ? undefined : value / 100;
                            }
                        }
                    };
                } else {
                    return { 
                        element: <InputNumber min={0} addonAfter="元" addonBefore="￥" max={restrictPrice} placeholder="请输入优惠金额" />, 
                        rules: [{ required: true, type: "number", min:0, max: restrictPrice, message: `折扣金额必须小于最低消费金额！`}],
                        converter: undefined 
                    };
                }
            },
            initialValue: editingItem?.discount
        }, { 
            name: "restrictQty",
            label: "单人限制",
            className: "editor-form-item-2",
            element: (<InputNumber placeholder="单个用户做多可使用的优惠次数" addonAfter="次" min={0} />),
            rules: [{ required: true, message: "请填写单个用户做多可使用的优惠次数" }],
            initialValue: editingItem?.restrictQty
        }, { 
            name: "startTime",
            label: "开始日期",
            className: "editor-form-item-2",
            element: (<DatePicker />),
            initialValue: editingItem?.startTime,
            converter: new TimestampConverter(moment())
        }, { 
            name: "endTime",
            label: "结束日期",
            className: "editor-form-item-2",
            element: (<DatePicker />),
            dependencies: ["type", "startTime"],
            updateProps: (old, values) => {
                if (values[0] == VoucherType.Instant) {
                    return { label: "结束日期" };
                } else {
                    return { label: "截止日期" }
                }
            },
            initialValue: editingItem?.endTime,
            converter: new TimestampConverter(moment().endOf('day'))
        }, { 
            name: "expireType",
            label: "过期方式",
            className: "editor-form-item-2",
            dependencies: ["type"],
            updateProps: (old, values) => {
                if (values[0] == VoucherType.Instant) {
                    return { hidden: true, rules: undefined, element: undefined };
                } else {
                    return { 
                        hidden: false,
                        element: (
                            <Select placeholder="选择优惠券过期方式">
                                <Select.Option value={ExpireType.Delay}>顺延</Select.Option>
                                <Select.Option value={ExpireType.Fixed}>固定期限</Select.Option>
                            </Select>
                        ),
                        rules: [{required: true, message: "请选择优惠券过期设置！"}]
                    }
                }
            },
            initialValue: editingItem?.expireType == null ? ExpireType.Delay : editingItem.expireType
        }, { 
            name: "expireTime",
            label: "顺延天数",
            className: "editor-form-item-2",
            dependencies: ["type", "expireType"],
            updateProps: (old, [type, expireType]) => {
                if (type == VoucherType.Instant) {
                    return {hidden: true, rules: undefined};
                }
                const init = editingItem?.expireType == expireType ? editingItem?.expireTime : null;
                if (expireType == ExpireType.Delay) {
                    return {
                        hidden: false,
                        element: (
                            <InputNumber style={{width: "90%"}} min={1} addonAfter="天" step={1} placeholder="请输入顺延天数" />
                        ),
                        rules: [{ required: true, message: `请输入顺延天数！`}],
                        converter: undefined,
                        initialValue: init == null ? 15 : init
                    };
                } else {
                    return { 
                        hidden: false,
                        label: "过期时间",
                        element: <DatePicker placeholder="请输入过期时间" />, 
                        rules: [{ required: true, message: `请输入优惠券过期时间！`}],
                        converter: new TimestampConverter(),
                        initialValue: init == null ? new Date().getTime() : init
                    };
                }
            }
        }, { 
            name: "maxGrantQty",
            label: "优惠劵总数",
            className: "editor-form-item-2",
            element: <InputNumber placeholder="请输入所有用户自行领取时的累计优惠劵总数" />,
            rules: [{ required: true, message: "请填写所有用户自行领取时的累计优惠劵总数，管理员发放操作不受此限制影响！" }],
            initialValue: editingItem?.maxGrantQty
        }];
    }

    getRuleName = (value: any, batch: VoucherBatch, index: number) => {
        if (batch == null) {
            return "--";
        }
        let discountStr = "";
        if (batch.discountRule == DiscountRule.MXDY) {
            let discountStr = batch.discount == null ? "-" : (batch.discount * 100).toFixed(0);
            if (discountStr.endsWith("0")) {
                discountStr = discountStr.substring(0, discountStr.length - 1);
            }
            return `满${batch.restrictPrice?.toFixed(0)} ${discountStr}折`;
        } else {
            discountStr = batch.discount == null ? "0" : sprintf("%.1f", batch.discount)
        }
        const prefix = batch.discountRule == DiscountRule.PMXMY ? "每满" : "满";

        return `${prefix}${batch.restrictPrice?.toFixed(1)}减${discountStr}元`;
    }

    getExpireTime = (value: any, batch: VoucherBatch, index: number) => {
        if (batch == null) {
            return "--";
        }
        const format = "YYYY-MM-DD";
        if (batch.type == VoucherType.Instant) {
            return batch.endTime == null ? "--" : moment(batch.endTime).format(format);
        } else {
            if (batch.expireType == ExpireType.Delay) {
                return `自发放日起，顺延${batch.expireTime}天`;
            } else {
                return batch.expireTime == null ? "--" : moment(batch.expireTime).format(format);
            }
        }
    }

    getTableColumns(): TableColumn<VoucherBatch>[] {
        return [
            { title: "ID", dataIndex: "id"},
            new LinkColumn("名      称", `/app/activity/voucher/%(id)s`, "name", { width: 100}),
            new TagColumn(VoucherTypeMap, "优惠劵获取方式", "type"),
            { title: "优惠劵类型", render: this.getRuleName},
            { title: "单人数量/次数限制", dataType: "number", dataIndex: "restrictQty", stringFormat: "%d", width: 100},
            new TimestampColumn("开始日期", "startTime", { width: 130, stringFormat: "YYYY/MM/DD" }),
            new TimestampColumn("结束/截止日期", "endTime", { width: 130, stringFormat: "YYYY/MM/DD" }),
            { title: "过期设置", render: this.getExpireTime, width: 130},
            { title: "优惠劵总数", dataIndex: "maxGrantQty", dataType: "number", width: 60 },
            new TimestampColumn("最后修改时间", "lastUpdateTime", { width: 120 }),
            { dataIndex: "lastUpdateUserName", title: "最后修改人" },
            new TagColumn(BatchStatusMap, "状态", "status"),
            {
                title: "操作",
                render: (value, record, index) => {
                    return (
                        <PermissionButton
                            type="link"
                            size="small"
                            hideWhenNoPermission={true}
                            onClick={() => this.#showEditor(record)}
                            permissionCode={PermissionCode.COUPON_BATCH_MANAGE}
                        >
                            修改
                        </PermissionButton>
                    )
                }
            }
        ];
    }
    
    public queryOverride(params?: any): Promise<VoucherBatch[] | PageResult<VoucherBatch>> {
        return VoucherApi.listBatch(params?.limit, params?.skip, params?.discountRule, params?.type, params?.status,params?.name);
    }

    public async saveOrUpdate(batch: VoucherBatch) {
        const id = this.state.editingItem?.id;
        if (id != null) {
            await VoucherApi.updateBatch(id, batch.name, batch.activityId, batch.type, batch.makeCouponMode,
                batch.discountRule, batch.discount, batch.startTime, batch.endTime, batch.expireType,
                batch.expireTime, batch.restrictPrice, batch.restrictQty, batch.maxGrantQty);
            batch = Object.assign({}, this.state.editingItem, batch);
            
        } else {
            batch = await VoucherApi.addBatch(batch.name, batch.activityId, batch.type, batch.makeCouponMode,
                batch.discountRule, batch.discount, batch.startTime, batch.endTime, batch.expireType,
                batch.expireTime, batch.restrictPrice, batch.restrictQty, batch.maxGrantQty);
        }
        return batch;
    }

    public async updateRestrict(restrictMerchantIds?: string, restrictCateCodes?: string, restrictProductIds?: string) {
        await VoucherApi.updateRestrict(this.state.editingItem?.id, restrictMerchantIds, restrictCateCodes, restrictProductIds);
    }
}