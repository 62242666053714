

import { BaseModel, QueryModel, QueryState } from "@framework/models";
import { DEFAULT_IDS_CONVERTER, Delayer, PageResult, TimestampConverter } from "@framework/utils";
import { Decorator, DecoratorForm, FormElement, TreeQuerySelect, QuerySelect, TableColumn, PermissionButton } from "@framework/component";

import { Input, Modal, Image, DatePicker, Select, Button, Tag, Divider } from "antd";
import Icon, { LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";

import app, { ResetPwdDecorators } from "@root/app";
import { MerchantApi, UserApi, RoleApi } from "@api";

import { UserStatusMap } from "./tag-map";
import VerifyCodeSvg from "@svg/verify-code.svg";
import IconResetPwd from "@svg/icon-reset-password.svg";
import { TagColumn, TimestampColumn } from "@root/component";
import { PermissionCode } from "@root/permission";

type User = UserApi.User;
type SignInfo = UserApi.SignInfo;

export interface UserState extends QueryState<User> {
    selectedUser: User[],
    editorType?: "normal" | "role-grant"
}


export class UserModel extends QueryModel<UserState> {
    #checkLoginName = Delayer.debounce(UserApi.check, 1000);
    constructor() {
        super();
    }

    public batchDisable = () => {
        const { selectedUser } = this.state;
        if (!selectedUser?.length) {
            return;
        }
        Modal.confirm({
            title: "确认",
            type: "info",
            content: `正在禁用选定的${selectedUser.length}个用户，是否继续？`,
            onOk: () => this.#doDisable(selectedUser)
        });
    }

    public batchEnable = () => {
        const { selectedUser } = this.state;
        if (!selectedUser?.length) {
            return;
        }
        Modal.confirm({
            title: "确认",
            type: "info",
            content: `正在启用选定的${selectedUser.length}个用户，是否继续？`,
            onOk: () => this.#doEnable(selectedUser)
        });
    }

    public getEditorDecorators(editingItem?: UserApi.User): Decorator<any>[] {
        const { editorType } = this.state;
        if (editorType == "role-grant") {
            return [{
                name: "id",
                hidden: true,
                element: <Input />,
                initialValue: editingItem?.id
            }, {
                name: "roleIds",
                label: "角色列表",
                className: "editor-form-item",
                element: (
                    <TreeQuerySelect
                        keyOfValue="id"
                        multiple={true}
                        dataSorted={true}
                        keyOfLabel="name"
                        searchMode="filter"
                        keyOfParentId="pid"
                        keyOfChildren="children"
                        fetchItems={RoleApi.list}
                        treeDefaultExpandAll={true}
                        showCheckedStrategy="SHOW_PARENT"
                    />
                ),
                initialValue: editingItem?.roles == null ? undefined : editingItem.roles.map(role => role.id).join(","),
                converter: DEFAULT_IDS_CONVERTER
            }]
        }
        return [{
            name: "loginName",
            label: "登录名",
            hidden: editingItem?.id != null,
            className: "editor-form-item",
            element: <Input placeholder="请输入用户登录名" allowClear={true} />,
            rules: editingItem?.id != null ? undefined : [{
                type: "string", required: true, whitespace: true, validator: (_, value, callback) => {
                    if (value == null || value == "") {
                        return Promise.reject("请输入登录名！");
                    }
                    return this.#checkLoginName(value);
                }
            }],
            initialValue: editingItem?.loginName
        }, {
            name: "name",
            label: "用 户 名",
            className: "editor-form-item",
            element: <Input placeholder="请输入用户显示名称！" allowClear={true} />,
            rules: [{ type: "string", required: true, message: "请输入用户显示名称！" }],
            initialValue: editingItem?.name
        }, {
            name: "phone",
            label: "联系电话",
            className: "editor-form-item",
            element: <Input placeholder="请输入联系电话！" allowClear={true} />,
            rules: [{ type: "string", pattern: /(1[3-9]\d{9})|(((\d{3}-\d{8})|(\d{3}-\d{7}))(-\d{1,5})?)/, message: "请输入正确的联系电话，有效格式为13000000000或022-66666666[-930]。" }],
            initialValue: editingItem?.phone
        }, {
            name: "password",
            label: "登录密码",
            className: "editor-form-item",
            hidden: editingItem?.id != null,
            element: <Input.Password placeholder="请输入登录密码" allowClear={true} />,
            rules: editingItem?.id == null ? [{ type: "string", required: true, min: 8, max: 24, message: "请输入8-24位的登录密码" }] : undefined,
        }, {
            label: "确认密码",
            name: "confirmPassword",
            dependencies: ["password"],
            className: "editor-form-item",
            hidden: editingItem?.id != null,
            element: <Input.Password placeholder="请输入确认密码" allowClear={true} />,
            updateProps: (decorator, values) => {
                const password = values[0];
                return {
                    rules: decorator.hidden ? undefined : [{
                        type: "string",
                        required: true,
                        validator: (_rule, value) => {
                            if (value !== password) {
                                return Promise.reject("两次输入的密码不一致！");
                            } else {
                                return Promise.resolve();
                            }
                        }
                    }]
                }
            }
        }, {
            label: "所属商户",
            name: "merchantId",
            className: "editor-form-item",
            hidden: app.currentUser.merchantId != -1,
            element: (
                <QuerySelect
                    keyOfValue="id"
                    keyOfLabel="name"
                    allowClear={true}
                    placeholder="请选择用户所属商户"
                    fetchItems={this.#fetchMerchant}
                    disabled={editingItem?.id != null}
                />
            ),
            initialValue: editingItem?.merchantId
        }];
    }

    public getQueryDecorators(): Decorator<any>[] {
        const labelCol = { span: 6 };
        return [{
            name: "loginName",
            label: "登录名",
            labelCol: labelCol,
            className: "query-form-item",
            element: <Input placeholder="按登录名查询" allowClear />
        }, {
            name: "name",
            label: "用 户 名",
            labelCol: labelCol,
            className: "query-form-item",
            element: <Input placeholder="按用户名查询" allowClear />
        }, {
            name: "phone",
            label: "联系电话",
            labelCol: labelCol,
            className: "query-form-item",
            element: <Input placeholder="按联系电话查询" allowClear />
        }, {
            name: "loginTime",
            label: "登录时间",
            labelCol: labelCol,
            className: "query-form-item",
            element: <DatePicker showTime={false} placeholder="按用户最后登录时间查询" allowClear />,
            converter: new TimestampConverter()
        }, {
            name: "enabled",
            label: "状态",
            labelCol: labelCol,
            className: "query-form-item",
            element: (
                <Select placeholder="按用户当前状态查询" allowClear>
                    <Select.Option value={true}>启用</Select.Option>
                    <Select.Option value={false}>禁用</Select.Option>
                </Select>
            )
        }];
    }

    public getTableColumns(): TableColumn<UserApi.User>[] {
        return [
            { title: "登录名", dataIndex: "loginName" },
            { title: "用户名", dataIndex: "displayName" },
            { title: "联系电话", dataIndex: "phone", fallback: "--" },
            { title: "店铺名称", dataIndex: "merchantName" },
            { title: "最后登录IP", dataIndex: "lastLoginIp", fallback: "尚未登录" },
            new TimestampColumn<UserApi.User>("最后登录时间", "lastLoginTime"),
            new TagColumn(UserStatusMap, "状态", "enabled"),
            {
                title: "用户角色", dataIndex: "roles", render(value, _record) {
                    return value == null ? "--" : value.map((role: RoleApi.Role) => <Tag key={role.id} color="blue">{role.name}</Tag>)
                }
            },
            {
                title: "", render: (value, record, index) => {
                    const result: React.ReactNode[] = [
                        <PermissionButton
                            type="link"
                            key="update"
                            hideWhenNoPermission={true}
                            onClick={() => this.showEditor(record)}
                            permissionCode={PermissionCode.USER_MANAGE}
                        >
                            修改
                        </PermissionButton>
                    ];
                    if (record.enabled) {
                        result.push(
                            <Divider key="1" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="disable"
                                hideWhenNoPermission={true}
                                onClick={() => this.#doDisable([record])}
                                disabled={record.id == app.currentUser.id}
                                permissionCode={PermissionCode.USER_MANAGE}
                            >
                                禁用
                            </PermissionButton>
                        );
                    }else if(!record.enabled){
                        result.push(
                            <Divider key="0" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="enable"
                                hideWhenNoPermission={true}
                                onClick={() => this.#doEnable([record])}
                                disabled={record.id == app.currentUser.id}
                                permissionCode={PermissionCode.USER_MANAGE}
                            >
                                启用
                            </PermissionButton>
                        );
                    }
                    result.push(
                        <Divider key="2" type="vertical" />,
                        <PermissionButton
                            type="link"
                            key="grant"
                            hideWhenNoPermission={true}
                            permissionCode={PermissionCode.USER_ROLE_MANAGE}
                            onClick={() => this.showEditor(record, "role-grant")}
                        >
                            授权
                        </PermissionButton>,
                        <Divider key="3" type="vertical" />,
                        <PermissionButton
                            type="link"
                            key="reset"
                            hideWhenNoPermission={true}
                            onClick={() => this.resetPassword(record)}
                            disabled={record.id == app.currentUser.id}
                            permissionCode={PermissionCode.USER_MANAGE}
                        >
                            重置密码
                        </PermissionButton>
                    );
                    return result;
                }
            }
        ];
    }

    public grant(values: any) {
        return RoleApi.grant(values.id, values.roleIds);
    }

    public queryOverride(params?: any): Promise<PageResult<UserApi.User> | UserApi.User[]> {
        return UserApi.list(params?.limit, params?.skip, params?.name, params?.email,
            params?.roleId, params?.phone, params?.loginName, params?.enabled, params?.loginTime);
    }

    public resetPassword(user: UserApi.User) {
        const updater = Modal.confirm({
            width: 500,
            title: "重置密码",
            icon: <Icon component={IconResetPwd} />,
            okButtonProps: { style: { display: "none" } },
            cancelButtonProps: { style: { display: "none" } },
            content: (
                <DecoratorForm
                    className="editor-form"
                    decorators={ResetPwdDecorators}
                    style={{ marginTop: 20 }}
                    onSubmit={ async (values) => {
                        await UserApi.resetPassword(user.id as string, values.password);
                        updater.destroy();
                    }}
                >
                    <div className="operation">
                        <Button type="primary" htmlType="submit">确定</Button>
                        <Button type="default" onClick={() => updater.destroy()}>取消</Button>
                    </div>
                </DecoratorForm>
            )

        });
    }

    public async saveOrUpdate(user?: Partial<UserApi.User> & { password?: string, }) {
        const { editingItem } = this.state;
        if (editingItem?.id == null) {
            return await UserApi.create(user?.loginName, user?.password, undefined, user?.merchantId, user?.name,
                user?.nickName, user?.email, user?.gender, user?.province, user?.city, user?.county, user?.street, user?.phone);
        } else {
            await UserApi.modify(editingItem.id, editingItem?.loginName, user?.name, user?.nickName,
                user?.email, user?.gender, user?.province, user?.city, user?.county, user?.street, user?.phone);
            return Object.assign({}, editingItem, user);
        }
    }

    public showEditor(editingItem?: UserApi.User, editorType?: "normal" | "role-grant") {
        this.updateState({ editingItem, showEditor: true, editorType: editorType == null ? "normal" : editorType });
    }

    #doDisable(list: UserApi.User[]) {
        const ids = list.map(user => user.id as string).join(",");
        UserApi.disable(ids).then(() => {
            list.forEach(user => user.enabled = false);
            const { data } = this.state;
            this.updateState({ data: data == null ? undefined : [...data] })
        });
    }

    #doEnable(list: UserApi.User[]) {
        const ids = list.map(user => user.id as string).join(",");
        UserApi.enable(ids).then(() => {
            list.forEach(user => user.enabled = true);
            const { data } = this.state;
            this.updateState({ data: data == null ? undefined : [...data] })
        });
    }

    #fetchMerchant = (name?: string, limit?: number, skip?: number) => {
        return MerchantApi.listMerchant(limit, skip, name);
    }
}

export interface SignInState extends SignInfo {
    loginName?: string
    password?: string
    verifyCode?: string
}

export class SignInModel extends BaseModel<SignInState> {

    constructor() {
        super();
        this.state = app.currentUser;
    }

    getDecorators() {
        const { verifyCode } = this.state;
        return [{
            colon: false,
            name: "loginName",
            element: <Input prefix={<UserOutlined />} placeholder="用户名" />,
            rule: [{ required: true, type: "string", message: "请填写用户名" }]
        }, {
            name: "password",
            element: <Input type="password" prefix={<LockOutlined />} placeholder="登录密码" />,
            rule: [{ required: true, min: 6, max: 24, message: "请输入登录密码" }]
        }, {
            name: "verifyCode",
            element: (_decorator: Decorator) => {
                return (
                    <FormElement key="verifyCode" inputElementKey="main">
                        <Input key="main" prefix={<VerifyCodeSvg width={16} height={16} />} placeholder="请输入验证码" style={{ width: '65%', marginRight: '10px' }} />
                        {verifyCode ? (
                            <Image preview={false} width="30%" height={32} src={verifyCode} onClick={this.getVerifyCode} />
                        ) : (
                            <span className="verify-code" onClick={this.getVerifyCode}>点击重试 <LoadingOutlined /></span>
                        )
                        }
                    </FormElement>
                )
            },

        }];
    }

    getVerifyCode = () => {
        UserApi.getImgVerifyCode().then((data) => {
            this.updateState({ verifyCode: data?.data });
        });
    }
}