

import { Tabs, TabsProps } from "antd";
import { EditableTableProps } from "./editable-table";

import { PropsType } from "./base-component";
import { PaginationTableView } from "./pagination-table-view";
import { TabQueryModel, QueryState, TabQueryState, QueryModel } from "@framework/models";

import styles from "@framework/styles/tab-query-view.less";


export abstract class TabQueryView<T extends TabQueryModel<TabQueryState<any>>> extends PaginationTableView<T> {
    
    constructor(props: PropsType<T>) {
        super(props);
    }

    componentDidMount(): void {
        const activeKey = this.queryParams?.get("tab");
        this.props.model.activeKey = activeKey == null ? undefined : activeKey;
        super.componentDidMount();
    }
    /**
     * 根据 tabState 决定是否重新查询数据，若需要重新查询，返回 true, 否则返回 false。
     * 
     * @param {*} tabState 当前 tab 页对应的 state
     */
    needQuery(tabState: T) {
        return !(tabState?.state?.loaded);
    }

    #onTabChange = (key: string | undefined) => {
        const { model } = this.props;
        model.activeKey = key;
        const params = Object.assign({}, this.state.params, { tab: key, skip: 0 });
        this.query(params);
    }
    
    renderTable(props: EditableTableProps<T extends QueryModel<infer P extends QueryState<any>> ? P extends QueryState<infer Q> ? Q : P : T>): JSX.Element {
        return (
            <>
                {this.#renderTabs()}
                {super.renderTable(props)}
            </>
        )
    }

    renderTabs(props: TabsProps) {
        return (
            <Tabs {...props} />
        );
    }

    #renderTabs = () => {
        const { model } = this.props;
        const props: TabsProps = { type: "card", onChange: this.#onTabChange, activeKey: model.activeKey, className: styles.cardTabs };
        props.children = model.tabItems?.map((tabItem) => <Tabs.TabPane tab={tabItem.name} key={tabItem.key} />);
        return this.renderTabs(props);
    }
}