

import { PageResult, toTree } from "@framework/utils";
import { QueryState, QueryModel } from "@framework/models";

import { ProductApi } from "@api";


interface ProductCateState extends QueryState<ProductApi.ProductCate> {
    
}

type Status = ProductApi.ProductCateStatus;

export class ProductCateModel extends QueryModel<ProductCateState> {

    constructor() {
        super();
        this.state = {};
    }

    public async saveOrUpdate(name: string, sort: number, image?: string, parentCode?: string, taxCode?: string, taxRate?: number) {
        if (this.state.editingItem?.code == null) {
            await ProductApi.addCate(name, sort, image, parentCode, taxCode, taxRate);
        } else {
            await ProductApi.updateCate(this.state.editingItem.code, name, image, sort, taxCode, taxRate);
        }
    }

    public changeStatus = (item: ProductApi.ProductCate, status: ProductApi.ProductCateStatus) => {
        const { data } = this.state;
        ProductApi.changeCateStatus(item.code as string, status).then(() => {
            if (data != null) {
                item.status = status;
                this.updateState({ data: [...data] })
            }
        });
    }

    public queryOverride(params?: any): Promise<ProductApi.ProductCate[] | PageResult<ProductApi.ProductCate>> {
        return ProductApi.listCate(params?.name, params?.status).then(cates => {
            return toTree(cates, "code", "parentCode", "children")
        });
    }
}