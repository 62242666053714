

import { QueryModel, QueryState } from "./query-model";


export interface TabQueryState<TData> extends QueryState<TData> {
    loaded: boolean
    key?: string
    name?: string
    tabParams?: Record<string, string | number>
}

type PickDataType<T extends TabQueryState<any> | Array<any>> = T extends QueryState<infer P> ? P : T;

export abstract class TabQueryModel<TState extends TabQueryState<any>> extends QueryModel<TState> {
    #tabItems: TState[] | undefined;
    #activeKey: string | undefined;

    get tabItems() {
        return this.#tabItems;
    }

    set tabItems(items: TState[] | undefined) {
        let tabItem = this.getTabItem(items, this.#activeKey);
        this.#tabItems = items;
        if (items == null) {
            this.activeKey = undefined;
            return;
        } 
        else if (tabItem == null) {
            this.activeKey = items[0].key;
            return;
        } else {
            if (this.state?.key !== tabItem.key) {
                this.updateState(tabItem);
            }
        }
    }

    get activeKey() {
        return this.#activeKey;
    }

    set activeKey(key: string | undefined) {
        let tabItem = this.getTabItem(this.#tabItems, key);
        this.#activeKey = key;
        if (tabItem != null && this.state?.key !== tabItem.key) {
            this.updateState(tabItem);
        }
    }

    constructor() {
        super();
    }

    public query(params?: any): void {
        this.tabItems = this.#tabItems;
        const finalParams = Object.assign({}, params, this.state.tabParams);
        
        this.queryOverride(finalParams).then(result => {
            let update: Partial<TState> = {};
            update.params = params;
            if (result instanceof Array) {
                update.data = result;
            } else {
                update.data = result.info;
                update.limit = result.limit;
                update.skip = result.skip;
                update.total = result.total;
            }
            update.loaded = true;
            this.updateState(update);
        });
    }

    getTabItem(items: TState[] | undefined, key: string | number | undefined) {
        if (items != null && key != null) {
            for (let item of items) {
                if (item.key === key) {
                    return item;
                }
            }
        }
        return undefined;
    }
    
    public updateState(paramsToMerge: Partial<TState>, desc?: string | undefined): void {
        super.updateState(paramsToMerge, desc);
        if (this.#tabItems != null) {
            for (let index = 0; index < this.#tabItems.length; index++) {
                const tabItem = this.#tabItems[index];
                if (tabItem.key === this.#activeKey) {
                    this.#tabItems[index] = this.state;
                    break;
                }
            }
        }
    }
}