

import { CellProps, TableColumn } from "@framework/component";
import { Converter, TimestampConverter } from "@framework/utils";

import { sprintf } from "sprintf-js";
import { Image, Modal, Tag } from "antd";
import { NavLink } from "react-router-dom";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { ChangeEvent, MouseEvent, MutableRefObject, useRef, useState } from "react";

import { TagItem } from "@root/models";
import { CACHE, HD } from "@root/constants";
import { FileUploadApi } from "@root/services";
import FallbackImage from "@root/assets/img/fallback-image.png";
import { ColumnTitle } from "antd/lib/table/interface";
import moment from "moment";


export class TagColumn<RecordType> implements TableColumn<RecordType> {
    #propertyPath?: Array<string | number>
    #map: Record<string | number, TagItem>;

    constructor(tagMap: Record<string | number, TagItem>, title: string);
    constructor(tagMap: Record<string | number, TagItem>, title: string, dataIndex: keyof RecordType);
    constructor(tagMap: Record<string | number, TagItem>, title: string, params: TableColumn<RecordType>);
    constructor(tagMap: Record<string | number, TagItem>, title: string, dataIndex: keyof RecordType, propertyPath: string | number | Array<string | number>);
    constructor(tagMap: Record<string | number, TagItem>, title: string, dataIndex: keyof RecordType, params: TableColumn<RecordType>);
    constructor(tagMap: Record<string | number, TagItem>, title: string, dataIndex: keyof RecordType, propertyPath: string | number | Array<string | number>, params: TableColumn<RecordType>);
    constructor(tagMap: Record<string | number, TagItem>, title: string, dataIndex?: keyof RecordType | TableColumn<RecordType>, propertyPath?: string | number | Array<string | number> | TableColumn<RecordType>, params?: TableColumn<RecordType>) {
        this.#map = tagMap;
        const self: TableColumn<RecordType> = this;
        self.title = title;
        if (dataIndex != null) {
            if (typeof dataIndex === "string") {
                self.dataIndex = dataIndex;
            } else {
                Object.assign(this, propertyPath);
            }
        }
        if (propertyPath != null) {
            if (propertyPath instanceof Array) {
                this.#propertyPath = propertyPath;
            } else if (propertyPath instanceof Object) {
                Object.assign(this, propertyPath);
            } else {
                this.#propertyPath = [propertyPath];
            }
        }
        if (params != null) {
            Object.assign(this, propertyPath);
        }
    }

    render = (data: string | number, record: RecordType, index: number) => {
        let value: any = data;
        if (this.#propertyPath != null) {
            value = data == null ? record : data;
            for (let path of this.#propertyPath) {
                if (value == null) {
                    break;
                }
                value = value[path];
            }
        }
        let item = value == null ? null : this.#map[value];
        if (item == null) {
            return <Tag>未定义</Tag>
        }
        return <Tag color={item.color}>{item.name}</Tag>
    }
}

const TimestampConv = new TimestampConverter();

export class TimestampColumn<RecordType> implements TableColumn<RecordType> {
    converter: Converter<any, any> = TimestampConv;
    fallback: string = "--";
    stringFormat: string = "yyyy-MM-DD HH:mm";
    dataType: "string" | "image" | "number" | "date" | "time" | "datetime" = "datetime";

    constructor(title: string, dataIndex: keyof RecordType);
    constructor(title: string, dataIndex: keyof RecordType, stringFormat: string);
    constructor(title: string, dataIndex: keyof RecordType, params: TableColumn<RecordType>);
    constructor(title: string, dataIndex: keyof RecordType, stringFormat: string, params: TableColumn<RecordType>);

    constructor(title: string, dataIndex: keyof RecordType, stringFormat?: string | TableColumn<RecordType>, params?: TableColumn<RecordType>) {
        const self: TableColumn<RecordType> = this;
        self.title = title;
        self.dataIndex = dataIndex;
        if (typeof stringFormat === "string") {
            self.stringFormat = stringFormat;
        } else {
            Object.assign(self, stringFormat);
            return;
        }
        Object.assign(self, params);
    }

}

export class TimestampRangeColumn<RecordType> implements Omit<TableColumn<RecordType>, "dataIndex"> {
    title?: ColumnTitle<RecordType>;
    readonly?: boolean | ((value: any, record: RecordType) => boolean);
    startField: keyof RecordType;
    endField: keyof RecordType;
    separator: string = "~";
    fallback: string = "--";
    converter: Converter<any, any> = TimestampConv;
    stringFormat: string = "yyyy-MM-DD HH:mm";
    endFallback: string = "--";
    startFallback: string = "--";
    dataType: "string" | "image" | "number" | "date" | "time" | "datetime" = "string";

    constructor(title: string, startField: keyof RecordType, endField: keyof RecordType);
    constructor(title: string, startField: keyof RecordType, endField: keyof RecordType, stringFormat: string);
    constructor(title: string, startField: keyof RecordType, endField: keyof RecordType, params: Partial<TimestampRangeColumn<RecordType>>);
    constructor(title: string, startField: keyof RecordType, endField: keyof RecordType, stringFormat: string, params: Partial<TimestampRangeColumn<RecordType>>);

    constructor(title: string, startField: keyof RecordType, endField: keyof RecordType, stringFormat?: string | Partial<TimestampRangeColumn<RecordType>>, params?: Partial<TimestampRangeColumn<RecordType>>) {
        const self: TimestampRangeColumn<RecordType> = this;
        self.title = title;
        this.startField = startField;
        this.endField = endField;
        self.readonly = true;
        if (typeof stringFormat === "string") {
            self.stringFormat = stringFormat;
        } else if (typeof stringFormat !== "undefined") {
            Object.assign(self, stringFormat);
            return;
        }
        Object.assign(self, params);
        self.stringFormat = self.stringFormat == null ? "yyyy-MM-DD HH:mm" : self.stringFormat;
    }

    render = (value: any, data: RecordType, index: number) => {
        const start = Number(data[this.startField]);
        const end = Number(data[this.endField]);
        if (Number.isNaN(start) && Number.isNaN(end)) {
            return this.fallback;
        } else if (Number.isNaN(start)) {
            return `${this.startFallback}${this.separator}${moment(end).format(this.stringFormat)}`;
        } else if (Number.isNaN(end)) {
            return `${moment(start).format(this.stringFormat)}${this.separator}${this.endFallback}`;
        } else {
            return `${moment(start).format(this.stringFormat)}${this.separator}${moment(end).format(this.stringFormat)}`;
        }
    }
}

export class MinutesColumn<RecordType> implements TableColumn<RecordType> {

    constructor(title: string, dataIndex: keyof RecordType, option?: TableColumn<RecordType>) {
        const self: TableColumn<RecordType> = this;
        self.title = title;
        self.dataIndex = dataIndex
        Object.assign(self, option);
    }

    render = (data: number | number, record: RecordType, index: number) => {
        let hour = Math.floor(data / 60);
        let minute = data % 60;
        let hourStr = hour < 10 ? "0" + hour : hour;
        let minuteStr = minute < 10 ? "0" + minute : minute;
        return data ? hourStr + ":" + minuteStr : '--';
    }
}

export class MinuteRangeColumn<RecordType> implements Omit<TableColumn<RecordType>, "dataIndex"> {
    title?: ColumnTitle<RecordType>;
    startField: keyof RecordType;
    endField: keyof RecordType;

    constructor(title: string, startField: keyof RecordType, endField: keyof RecordType, option?: Partial<MinutesColumn<RecordType>>) {
        this.title = title;
        this.startField = startField;
        this.endField = endField;
        Object.assign(this, option);
    }

    minuteToTime = (minute: number) => {
        let h = Math.floor(minute / 60);
        let m = minute % 60;
        return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;
    }

    render = (data: number, record: RecordType, index: number) => {
        let startMinute = Number(record[this.startField]);
        let endMinute = Number(record[this.endField]);
        return `${this.minuteToTime(startMinute)}~${this.minuteToTime(endMinute)}`
    }
}

export class ImageColumn<RecordType> implements TableColumn<RecordType> {

    constructor(title: string);
    constructor(title: string, dataIndex: keyof RecordType);
    constructor(title: string, params: TableColumn<RecordType>);
    constructor(title: string, dataIndex: keyof RecordType, imageSize: string | number);
    constructor(title: string, dataIndex: keyof RecordType, params: TableColumn<RecordType>);
    constructor(title: string, dataIndex: keyof RecordType, imageSize: string | number, readonly: boolean);
    constructor(title: string, dataIndex: keyof RecordType, imageSize: string | number, params: TableColumn<RecordType>);
    constructor(title: string, dataIndex: keyof RecordType, imageSize: string | number, readonly: boolean, params: TableColumn<RecordType>);

    constructor(title: string, dataIndex?: keyof RecordType | TableColumn<RecordType>, imageSize?: string | number | TableColumn<RecordType>, readonly?: boolean | TableColumn<RecordType>, params?: TableColumn<RecordType>) {
        const self: TableColumn<RecordType> = this;
        self.dataType = "image";
        let size: string | number = 64;

        self.title = title;
        if (dataIndex == null || typeof dataIndex === "string") {
            self.dataIndex = dataIndex;
        } else {
            Object.assign(self, dataIndex);
            return;
        }
        if (imageSize == null || typeof imageSize === "string" || typeof imageSize === "number") {
            size = imageSize == null ? size : imageSize;
        } else {
            Object.assign(self, imageSize);
            return;
        }
        self.onComponentWillMount = (value: any, props: any, editing: boolean) => {
            props.width = size;
            props.height = size;
            props.src = `${CACHE}${value}`
            props.preview = { src: `${HD}${value}` };
            return props;
        }
        if (readonly == null || typeof readonly === "boolean") {
            self.readonly = readonly;
        } else {
            Object.assign(self, readonly);
            return;
        }
        if (params != null) {
            Object.assign(self, params);
            return;
        }

        if (self.readonly == null || self.readonly) {
            self.viewComponent = Image as any;
        } else {
            self.readonly = true;
            self.viewComponent = EditableImage;
        }
    }
}

export class SvgColumn<RecordType> implements TableColumn<RecordType> {

    constructor(title: string);
    constructor(title: string, dataIndex: keyof RecordType);
    constructor(title: string, params: TableColumn<RecordType>);
    constructor(title: string, dataIndex: keyof RecordType, imageSize: string | number);
    constructor(title: string, dataIndex: keyof RecordType, params: TableColumn<RecordType>);
    constructor(title: string, dataIndex: keyof RecordType, imageSize: string | number, readonly: boolean);
    constructor(title: string, dataIndex: keyof RecordType, imageSize: string | number, params: TableColumn<RecordType>);
    constructor(title: string, dataIndex: keyof RecordType, imageSize: string | number, readonly: boolean, params: TableColumn<RecordType>);

    constructor(title: string, dataIndex?: keyof RecordType | TableColumn<RecordType>, imageSize?: string | number | TableColumn<RecordType>, readonly?: boolean | TableColumn<RecordType>, params?: TableColumn<RecordType>) {
        const self: TableColumn<RecordType> = this;
        self.dataType = "image";
        let size: string | number = 64;

        self.title = title;
        if (dataIndex == null || typeof dataIndex === "string") {
            self.dataIndex = dataIndex;
        } else {
            Object.assign(self, dataIndex);
            return;
        }
        if (imageSize == null || typeof imageSize === "string" || typeof imageSize === "number") {
            size = imageSize == null ? size : imageSize;
        } else {
            Object.assign(self, imageSize);
            return;
        }
        if (readonly == null || typeof readonly === "boolean") {
            self.readonly = readonly;
        } else {
            Object.assign(self, readonly);
            return;
        }
        if (params != null) {
            Object.assign(self, params);
            return;
        }
        self.render = (value, record, index) => <img src={value} width={size} height={size} />
        self.readonly = true;
    }
}

export class LinkColumn<RecordType> implements TableColumn<RecordType> {

    constructor(title: string, toFormat: string);
    constructor(title: string, toFormat: string, dataIndex: keyof RecordType);
    constructor(title: string, toFormat: string, params: TableColumn<RecordType>);
    constructor(title: string, toFormat: string, params: TableColumn<RecordType>);
    constructor(title: string, toFormat: string, dataIndex: keyof RecordType, stringFormat: string);
    constructor(title: string, toFormat: string, dataIndex: keyof RecordType, params: TableColumn<RecordType>);
    constructor(title: string, toFormat: string, dataIndex: keyof RecordType, stringFormat: string, params: TableColumn<RecordType>);

    constructor(title: string, to: string, dataIndex?: keyof RecordType | TableColumn<RecordType>, stringFormat?: string | TableColumn<RecordType>, params?: TableColumn<RecordType>) {
        const self: TableColumn<RecordType> = this;
        if (dataIndex != null && typeof dataIndex === "string") {
            self.dataIndex = dataIndex;
        } else if (dataIndex != null) {
            Object.assign(this, dataIndex);
        }
        if (stringFormat != null && typeof stringFormat === "string") {
            self.stringFormat = stringFormat;
        } else if (stringFormat != null) {
            Object.assign(this, stringFormat);
        }
        Object.assign(this, params);
        self.title = title;
        self.readonly = true;
        self.viewComponent = Link as any;
        self.onComponentWillMount = (value, props, editing) => {
            (props as any).to = sprintf(to, props.record);
            (props as any).replace = false;
            return props;
        }
    }
}

function EditableImage<RecordType>({ onChange, column, record, value, stringFormat, onBlur, onFocus, preview, ...props }: CellProps<RecordType>) {

    const inputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
    const hiddenRef = useRef<HTMLSpanElement>() as MutableRefObject<HTMLSpanElement>;
    const [src, setSrc] = useState(value);
    const [previewSrc, setPreviewSrc] = useState(preview?.src);

    const onFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files == null) {
            return;
        }
        const file = event.target.files[0];
        const url = URL.createObjectURL(file);
        setSrc(url);
        FileUploadApi.uploadImage(file).then(res => {
            setSrc(`${CACHE}${res}`);
            setPreviewSrc(`${HD}${res}`);
            fireOnChange(res);
        }).finally(() => {
            event.target.value = "";
            fireBlur();
        });
    }

    const fireOnChange = (value: string) => {
        if (onChange != null) {
            onChange(value);
        }
    }

    const fireFocus = () => {
        onFocus != null && onFocus();
    }

    const fireBlur = () => {
        onBlur != null && onBlur();
    }

    const onPreview = (event: MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        Modal.success({
            mask: true,
            open: true,
            maskClosable: true,
            icon: undefined,
            centered: true,
            width: "auto",
            okButtonProps: { style: { display: "none" } },
            closable: true,
            content: <img src={previewSrc} width="auto" height="auto" />
        })
    }

    const onEdit = (event: MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        inputRef.current.click();
    }

    return (
        <>
            <Image
                src={src}
                {...props}
                onClick={onEdit}
                fallback={FallbackImage}
                preview={{ mask: <div style={{ opacity: 0.8 }}><EyeOutlined onClick={onPreview} /><EditOutlined ref={hiddenRef} style={{ marginLeft: 5 }} onClick={onEdit} /></div> }}
            />
            <input type="file" style={{ display: "none" }} ref={inputRef} onChange={onFileChanged} />
        </>
    )
}

function Link<RecordType>({ onChange, column, record, value, format, onBlur, onFocus, to, ...props }: CellProps<RecordType> & { to: string }) {
    if (value == column.fallback) {
        return value;
    }
    return <NavLink to={to} state={value} {...props}>{sprintf(format, value)}</NavLink>
}