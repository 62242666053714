

import { Decorator, TreeQuerySelect, TableColumn, PermissionButton } from "@framework/component";
import { toTree } from "@framework/utils";
import { QueryModel, QueryState } from "@framework/models";

import { Button, Divider, Input, InputNumber, Select } from "antd";

import { MerchantCateApi } from "@api";
import { TagColumn, TimestampColumn } from "@root/component";
import { getTagItem, MerchantCateStatusMap } from "./tag-map";
import { PermissionCode } from "@root/permission";

type MerchantCate = MerchantCateApi.MerchantCate;

export class MerchantCateModel extends QueryModel<QueryState<MerchantCate>> {
    constructor() {
        super();
    }

    public changeStatus = (cate: MerchantCate, status: MerchantCateApi.CateStatus) => {
        let array = this.state.data as MerchantCate[];
        MerchantCateApi.updateMerchantCateStatus(cate.code as string, status).then(() => {
            cate.status = status;
            this.updateState({ data: array });
        });
    }

    getEditorDecorators(editingItem?: MerchantCate | undefined): Decorator[] {
        return [{
            name: "name",
            label: "分类名称",
            className: "editor-form-item",
            element: <Input placeholder="输入分类名称" />,
            rules: [{ type: "string", required: true, min: 2, max: 20, message: "请填写分类名称（2-20字）" }],
            initialValue: editingItem?.name,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
        }, {
            name: "sort",
            label: "序号",
            className: "editor-form-item",
            element: <InputNumber placeholder="输入排序序号" min={1} max={999} step={1} />,
            rules: [{ type: "number", required: true, min: 1, max: 999 }],
            initialValue: editingItem?.sort? 1 : editingItem?.sort,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
        }, {
            name: "parentCode",
            label: "上级分类",
            className: "editor-form-item",
            element: (
                <TreeQuerySelect
                    keyOfValue="code"
                    allowClear={true}
                    keyOfLabel="name"
                    keyOfChildren="children"
                    placeholder="请选择上级分类"
                    keyOfParentId="parentCode"
                    fetchItems={(value?: string) => this.queryOverride({ name: value })}
                />
            ),
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            initialValue: editingItem?.parentCode
        }];
    }
    
    getQueryDecorators(): Decorator[] {
        return [{
            name: "name",
            label: "分类名称",
            className: "query-form-item",
            element: <Input placeholder="分类名称" />
        }, {
            name: "status",
            label: "状态",
            className: "query-form-item",
            element: (
                <Select placeholder="状态" allowClear={true}>
                    {Object.keys(MerchantCateStatusMap).map(key => (
                        <Select.Option key={key} value={key}>{getTagItem(key, MerchantCateStatusMap).name}</Select.Option>
                    ))}
                </Select>
            )
        }];
    }
    
    getTableColumns(): TableColumn<MerchantCate>[] {
        return [
            { dataIndex: "name", title: "分类名称" },
            { dataIndex: "sort", title: "排序" },
            new TimestampColumn("创建时间", "createTime"),
            { dataIndex: "createUserName", title: "创建人" },
            new TimestampColumn("最后修改时间", "lastUpdateTime"),
            { dataIndex: "lastUpdateUserName", title: "最后修改人" },
            new TagColumn(MerchantCateStatusMap, "状态", "status"),
            {
                title: "操作",
                render: (value: undefined, record: MerchantCate, index: number) => {
                    let nextStatus = record.status == 2 ? 1 : 2;
                    let nextItem = getTagItem(nextStatus, MerchantCateStatusMap);
                    return (
                        <>
                            <PermissionButton
                                type="link"
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.MERCHANT_CATE_MANAGE}
                                onClick={() => this.#showEditor({ parentCode: record.code })}>新增</PermissionButton>
                            <Divider type="vertical" />
                            <PermissionButton
                                type="link"
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.MERCHANT_CATE_MANAGE}
                                onClick={() => this.#showEditor(record)}>修改</PermissionButton>
                            <Divider type="vertical" />
                            <PermissionButton
                                type="link"
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.MERCHANT_CATE_MANAGE}
                                onClick={() => this.changeStatus(record, nextStatus)}>{nextItem.name}</PermissionButton>
                        </>
                    )
                }
            }
        ]
    }

    #showEditor = (cate?: MerchantCate) => {
        this.updateState({ showEditor: true, editingItem: cate });
    }

    public saveOrUpdate = async (values: MerchantCate) => {
        const { editingItem } = this.state;
        if (editingItem?.code == null) {
            await MerchantCateApi.addMerchantCate(values.name, values.sort, undefined, values.parentCode);
        } else {
            await MerchantCateApi.updateMerchantCate(editingItem.code, values.name, values.sort);
        }
    }

    public async queryOverride(params?: any): Promise<MerchantCate[]> {
        return MerchantCateApi.listMerchantCate(params?.name, params?.status).then(value => {
            return toTree(value, "code", "parentCode", "children");
        });
    }

}