

import { TabQueryModel, TabQueryState } from "@framework/models";
import { DEFAULT_IDS_CONVERTER, PageResult } from "@framework/utils";
import { Decorator, DecoratorForm, FileUploader, QuerySelect, TreeQuerySelect, TableColumn, PermissionButton } from "@framework/component";

import { ReactNode, RefObject } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Input, InputNumber, Modal, Select, Switch } from "antd";

import { FilesConverter } from "@root/converter";
import { BrandApi, FileUploadApi, MerchantApi, ProductApi } from "@api";
import { ImageColumn, LinkColumn, TagColumn, TimestampColumn } from "@root/component";

import { getTagItem, InvoicePartyMap, ProductStatusMap } from "./tag-map";
import { PermissionCode } from "@root/permission";

import PriceType = ProductApi.PriceType;
import PickUpType = ProductApi.PickUpType;
import app from "@root/app";

interface ProductState extends TabQueryState<ProductApi.Product> {

}


export class ProductModel extends TabQueryModel<ProductState> {

    constructor() {
        super();
        this.tabItems = [{
            key: "all",
            name: "全部",
            loaded: false,
            tabParams: undefined
        }, {
            key: "0",
            name: "待提交",
            loaded: false,
            tabParams: { status: ProductApi.ProductStatus.New }
        }, {
            key: "1",
            name: "待审核",
            loaded: false,
            tabParams: { status: ProductApi.ProductStatus.UnderReview }
        }, {
            key: "2",
            name: "已驳回",
            loaded: false,
            tabParams: { status: ProductApi.ProductStatus.Rejected }
        }, {
            key: "3",
            name: "未上架",
            loaded: false,
            tabParams: { status: ProductApi.ProductStatus.Passed }
        }, {
            key: "4",
            name: "已上架",
            loaded: false,
            tabParams: { status: ProductApi.ProductStatus.Available }
        }, {
            key: "5",
            name: "已禁用",
            loaded: false,
            tabParams: { status: ProductApi.ProductStatus.Disabled }
        }, {
            key: "6",
            name: "已删除",
            loaded: false,
            tabParams: { status: ProductApi.ProductStatus.Deleted }
        }];
    }

    public async changeStatus(product?: ProductApi.Product, status?: ProductApi.ProductStatus, reason?: string) {
        const { data } = this.state;
        if (data == null || product == null) {
            return;
        }
        switch (status) {
            case ProductApi.ProductStatus.UnderReview:
                await ProductApi.submit(product.id);
                break;
            case ProductApi.ProductStatus.Passed:
                await ProductApi.audit(product.id);
                break;
            case ProductApi.ProductStatus.Rejected:
                await ProductApi.reject(product.id, reason);
                break;
            case ProductApi.ProductStatus.Deleted:
                await ProductApi.markAsDelete(product.id);
                break;
            case ProductApi.ProductStatus.Removed:
                await ProductApi.offShelves(product.id);
                break;
            case ProductApi.ProductStatus.Available:
                await ProductApi.onSale(product.id);
                break;
        }
        this.refresh();
    }

    getEditorDecorators(editingItem?: ProductApi.Product | undefined): Decorator<any>[] {
        const currentMerchant = editingItem?.merchantId == null ? undefined : { id: editingItem.merchantId, name: editingItem.merchantName };
        const options = Object.entries(InvoicePartyMap).map(entry => ({ label: entry[1].name, value: Number(entry[0]) }));
        options.unshift({ label: "使用商户设定", value: null as any });
        return [{
            label: "商品名称",
            name: "storeName",
            className: "editor-form-item-2",
            element: <Input placeholder="输入商品名称" />,
            rules: [{ required: true, message: "商品名称不能为空" }],
            initialValue: editingItem?.storeName
        }, {
            name: "itemNo",
            label: "商品货号",
            className: "editor-form-item-2",
            element: <Input placeholder="输入商品货号" />,
            initialValue: editingItem?.itemNo
        }, {
            label: "商品分类",
            name: "cateCode",
            className: "editor-form-item-2",
            element: (
                <TreeQuerySelect
                    keyOfValue="code"
                    keyOfLabel="name"
                    keyOfChildren="children"
                    placeholder="请选择商品分类"
                    keyOfParentId="parentCode"
                    showSearch={true}
                    searchMode="filter"
                    treeDefaultExpandAll={true}
                    fetchItems={ProductApi.listCate}
                    disabled={editingItem?.allowEdit == false}
                    allowSelect={(item) => item.parentCode != null && item.parentCode.length == 8}
                />
            ),
            rules: [{ required: true }],
            initialValue: editingItem?.cateCode
        }, {
            label: "组合商品",
            name: "combinationGood",
            className: "editor-form-item-2",
            valuePropName: "checked",
            element: (<Switch disabled={editingItem?.id != null} unCheckedChildren="否" checkedChildren="是" />),
            initialValue: editingItem?.combinationGood == null ? false : editingItem.combinationGood
        }, {
            label: "库存控制",
            name: "stockType",
            className: "editor-form-item-2",
            dependencies: ["combinationGood"],
            element: (
                <Select disabled={editingItem?.id != null}>
                    <Select.Option value={0}>按SKU（单规格库存）</Select.Option>
                    <Select.Option value={1}>按商品（所有规格共用库存）</Select.Option>
                </Select>
            ),
            updateProps: (_, [combinationGood]) => {
                return combinationGood == true ? { initialValue: 0, extraProps: { disabled: true } } : undefined;
            },
            rules: [{required: true, message: "请选择库存控制方式"}],
            initialValue: editingItem?.stockType
        }, {
            label: "核销控制",
            name: "singleWriteOff",
            className: "editor-form-item-2",
            dependencies: ["combinationGood"],
            element: (
                <Select>
                    <Select.Option value={true}>由合作方核销</Select.Option>
                    <Select.Option value={false}>正常核销</Select.Option>
                </Select>
            ),
            updateProps: (_, [combinationGood]) => {
                return combinationGood == true ? { initialValue: true, extraProps: { disabled: true } } : undefined;
            },
            rules: [{required: true, message: "请选择核销方式"}],
            initialValue: editingItem?.singleWriteOff
        }, {
            label: "收货方式",
            name: "pickUpType",
            className: "editor-form-item-2",
            dependencies: ["combinationGood"],
            element: (
                <Select>
                    <Select.Option value={PickUpType.SelfPickUp}>自取</Select.Option>
                    <Select.Option value={PickUpType.SendByPost}>邮递</Select.Option>
                    <Select.Option value={PickUpType.Free}>用户下单时选择</Select.Option>
                </Select>
            ),
            updateProps: (_, [combinationGood]) => {
                return combinationGood == true ? { initialValue: PickUpType.SelfPickUp, extraProps: { disabled: true } } : undefined;
            },
            rules: [{ required: true, message: "请选择收货方式" }],
            initialValue: editingItem?.pickUpType
        }, {
            name: "brandId",
            label: "商品品牌",
            className: "editor-form-item-2",
            element: (
                <QuerySelect
                    keyOfValue="id"
                    keyOfLabel="name"
                    placeholder="请选择商品品牌"
                    fetchItems={this.listBrand}
                />
            ),
            initialValue: editingItem?.brandId
        }, {
            name: "cover",
            label: "封面图片",
            className: "editor-form-item-2",
            element: (
                <FileUploader
                    maxCount={1}
                    accept="image/*"
                    listType="picture"
                    converter={FilesConverter}
                    customRequest={FileUploadApi.requestForUploader}
                >
                    <p className="ant-upload-drag-icon" style={{ display: "inline" }}>
                        <InboxOutlined height="100%" style={{ fontSize: 32 }} />
                        <span className="ant-upload-text" style={{ display: "inline-block" }}>点击此区域上传图片</span>
                        <span className="ant-upload-hint" style={{ display: "inline-block" }}>建议使用正方形图片，大小不超过200k</span>
                    </p>
                </FileUploader>
            ),
            converter: DEFAULT_IDS_CONVERTER,
            rules: [{ required: true, type: "array", min: 1, max: 1, message: "请上传商品封面图片！" }],
            initialValue: editingItem?.cover
        }, {
            label: "轮播图",
            name: "sliderImage",
            className: "editor-form-item-2",
            element: (
                <FileUploader
                    maxCount={5}
                    accept="image/*"
                    listType="picture-card"
                    converter={FilesConverter}
                    customRequest={FileUploadApi.requestForUploader}
                >
                    <p className="ant-upload-drag-icon" style={{ display: "inline" }}>
                        <InboxOutlined height="100%" style={{ fontSize: 32 }} />
                        <span className="ant-upload-text" style={{ display: "inline-block" }}>点击此区域上传图片</span>
                        <span className="ant-upload-hint" style={{ display: "inline-block" }}>建议宽高比3:4图片，大小不超过500k</span>
                    </p>
                </FileUploader>
            ),
            converter: DEFAULT_IDS_CONVERTER,
            rules: [{ required: true, type: "array", min: 1, max: 5, message: "至少添加一张轮播图，最多5张！" }],
            initialValue: editingItem?.sliderImage
        }, {
            label: "计量单位",
            name: "unitName",
            className: "editor-form-item-2",
            element: <Input min={0} placeholder="输入计量单位" />,
            initialValue: editingItem?.unitName
        }, { 
            label: "商户编号",
            name: "merchantId",
            className: "editor-form-item-2",
            hidden: app.currentUser.merchantId != -1,
            element: (
                <QuerySelect
                    keyOfValue="id"
                    keyOfLabel="name"
                    current={currentMerchant}
                    placeholder="请选择归属商户"
                    fetchItems={this.listMerchant}
                    disabled={editingItem?.id != null}
                />
            ),
            rules: app.currentUser.merchantId != -1 ? undefined : [{required: true, message: "平台用户新增商品时必须选择商品所属商户"}],
            initialValue: editingItem?.merchantId
        }, {
            name: "sort",
            label: "排序序号",
            className: "editor-form-item-2",
            element: <InputNumber min={0} placeholder="输入排序序号" />,
            initialValue: editingItem?.sort
        }, {
            name: "type",
            label: "购买方式",
            className: "editor-form-item-2",
            element: (
                <Select>
                    <Select.Option value={0}>App内购买</Select.Option>
                    <Select.Option value={1}>三方App购买</Select.Option>
                </Select>
            ),
            initialValue: editingItem?.type ? editingItem.type : 0,
            rules: [{ required: true, message: "请选择购买方式" }],
        }, {
            label: "appId",
            name: "wechatAppId",
            className: "editor-form-item-2",
            element: (<Input placeholder="请输入三方小程序 AppId" />),
            dependencies: ["type"],
            updateProps: (_, [type]) => {
                return { hidden: type == "0", rules: [{ required: type == "1", message: "请填写第三方小程序的appId" }] };
            },
            initialValue: editingItem?.wechatAppId
        }, {
            label: "页面路径",
            name: "wechatPageLocation",
            className: "editor-form-item-2",
            element: (<Input placeholder="请输入三方小程序页面路径" />),
            dependencies: ["type"],
            updateProps: (_, [type]) => {
                return { hidden: type == "0", rules: [{ required: type == "1", message: "请填写要跳转到的第三方小程序页面路径" }] };
            },
            initialValue: editingItem?.wechatPageLocation
        }, {
            label: "开票方",
            name: "invoiceParty",
            className: "editor-form-item-2",
            element: (
                <Select placeholder="选择开票方" options={options} />
            ),
            initialValue: editingItem?.invoiceParty == null ? null : editingItem.invoiceParty
        }, {
            label: "推荐序号",
            name: "promoteSort",
            className: "editor-form-item-2",
            element: <InputNumber min={0} placeholder="输入推荐排序序号" />,
            initialValue: editingItem?.promoteSort
        }, {
            label: "价格类型",
            name: "priceType",
            className: "editor-form-item-2",
            dependencies: ["combinationGood"],
            element: (
                <Select disabled={editingItem?.id != null}>
                    <Select.Option value={PriceType.Flat}>统一价格</Select.Option>
                    <Select.Option value={PriceType.Calendar}>日历价格</Select.Option>
                    <Select.Option value={PriceType.TimeSharing}>分时价格</Select.Option>
                </Select>
            ),
            updateProps: (_, [combinationGood]) => {
                return combinationGood == true ? { initialValue: PriceType.Flat, extraProps: { disabled: true } } : undefined;
            },
            rules: [{ required: true, message: "请选择价格类型" }],
            initialValue: editingItem?.priceType
        }, {
            hidden: true,
            name: "buyModel",
            label: "日期选择方式",
            dependencies: ["priceType"],
            className: "editor-form-item-2",
            updateProps: (_, [priceType]) => ({
                hidden: priceType != 1,
                element: priceType != 1 ? undefined : (
                    <Select disabled={editingItem?.id != null}>
                        <Select.Option value={0}>单日</Select.Option>
                        <Select.Option value={1}>区间</Select.Option>
                    </Select>
                ),
                rules: priceType != 1 ? undefined : [{ required: true, message: "请选择日期选择方式" }]
            }),
            initialValue: editingItem?.buyModel
        }, {
            label: "实名购买",
            name: "verified",
            valuePropName: "checked",
            className: "editor-form-item-2",
            dependencies: ["combinationGood"],
            element: <Switch checkedChildren="是" unCheckedChildren="否" />,
            rules: [{ required: true, message: "请选择当前商品是否需实名购买" }],
            updateProps: (_, [combinationGood]) => {
                return combinationGood == true ? { extraProps: { disabled: true } } : undefined;
            },
            initialValue: editingItem?.verified == null ? false : editingItem.verified
        }];
    }

    getOperations(record: ProductApi.Product) {
        const allowModify = (record.status != ProductApi.ProductStatus.UnderReview
            && record.status != ProductApi.ProductStatus.Available && record.status != ProductApi.ProductStatus.Deleted);
        const allowDelete = record.status != ProductApi.ProductStatus.Deleted;

        let key = 1;
        const operations = [];
        if (allowModify) {
            operations.push(
                <PermissionButton
                    type="link"
                    key={key++}
                    hideWhenNoPermission={true}
                    onClick={() => this.showEditor(record)}
                    permissionCode={PermissionCode.PRODUCT_EDIT}
                >
                    修改
                </PermissionButton>
            );
        }

        if (record.status == ProductApi.ProductStatus.New) {
            operations.push(
                <PermissionButton
                    type="link"
                    key={key++}
                    hideWhenNoPermission={true}
                    permissionCode={PermissionCode.PRODUCT_SUBMIT}
                    onClick={() => this.onChangeProductStatus(record, ProductApi.ProductStatus.UnderReview)}
                >
                    提交
                </PermissionButton>
            );
        } else if (record.status == ProductApi.ProductStatus.UnderReview) {
            operations.push(
                <PermissionButton
                    type="link"
                    key={key++}
                    hideWhenNoPermission={true}
                    permissionCode={PermissionCode.PRODUCT_AUDIT}
                    onClick={() => this.onChangeProductStatus(record, ProductApi.ProductStatus.Passed)}
                >
                    审核
                </PermissionButton>,
                <PermissionButton
                    type="link"
                    key={key++}
                    hideWhenNoPermission={true}
                    permissionCode={PermissionCode.PRODUCT_AUDIT}
                    onClick={() => this.onChangeProductStatus(record, ProductApi.ProductStatus.Rejected)}
                >
                    驳回
                </PermissionButton>
            );
        } else if (record.status == ProductApi.ProductStatus.Passed || record.status == ProductApi.ProductStatus.Removed) {
            operations.push(
                <PermissionButton
                    type="link"
                    key={key++}
                    hideWhenNoPermission={true}
                    permissionCode={PermissionCode.PRODUCT_ON_SALE}
                    onClick={() => this.onChangeProductStatus(record, ProductApi.ProductStatus.Available)}
                >
                    上架
                </PermissionButton>
            );
        } else if (record.status == ProductApi.ProductStatus.Available || record.status == ProductApi.ProductStatus.Disabled) {
            operations.push(
                <PermissionButton
                    type="link"
                    key={key++}
                    hideWhenNoPermission={true}
                    permissionCode={PermissionCode.PRODUCT_ON_SALE}
                    onClick={() => this.onChangeProductStatus(record, ProductApi.ProductStatus.Removed)}
                >
                    下架
                </PermissionButton>
            );
        }
        if (allowDelete) {
            operations.push(
                <PermissionButton
                    type="link"
                    key={key++}
                    hideWhenNoPermission={true}
                    onClick={() => this.onDeleteProduct(record)}
                    permissionCode={PermissionCode.PRODUCT_ON_SALE}
                >
                    删除
                </PermissionButton>
            );
        }
        return operations;
    }

    getQueryDecorators(): Decorator<any>[] {
        return [
            { name: "name", label: "名称", className: "query-form-item", element: <Input placeholder="按商品名称查询" /> },
            { name: "itemNo", label: "货号", className: "query-form-item", element: <Input placeholder="按商品货号查询" /> },
            { name: "merchantName", label: "商户名称", className: "query-form-item", element: <Input placeholder="按商户名称查询" /> },
            {
                name: "cateCode", label: "品类", className: "query-form-item", element:
                    <TreeQuerySelect
                        keyOfValue="code"
                        keyOfLabel="name"
                        keyOfChildren="children"
                        placeholder="按品类查询"
                        keyOfParentId="parentCode"
                        showSearch={true}
                        searchMode="filter"
                        treeDefaultExpandAll={true}
                        fetchItems={ProductApi.listCate}
                        allowSelect={(item) => item.parentCode != null && item.parentCode.length == 8}
                    />
            },

            // { name: "status", label: "状态", className: "query-form-item", element: (
            //     <Select placeholder="按状态查询" allowClear={true}>
            //         {Object.keys(ProductStatusMap).map(key => {
            //             return <Select.Option key={key} value={key}>{getTagItem(key, ProductStatusMap).name}</Select.Option>
            //         })}
            //     </Select>
            // )},
        ];
    }

    getTableColumns(): TableColumn<ProductApi.Product>[] {
        let result: TableColumn<ProductApi.Product>[] = [
            { title: "ID", dataIndex: "id", dataType: "string", fallback: "--" },
            new LinkColumn("商品名称", "/app/product/%(id)s", "", "%(storeName)s"),
            new ImageColumn("封面图片", "cover"),
            { title: "货    号", dataIndex: "itemNo", dataType: "string", fallback: "--" },
            { dataIndex: "cateName", title: "商品分类", dataType: "string", fallback: "--" },
            new LinkColumn("所属商户", "/app/merchant/%(merchantId)s", "", "%(merchantName)s"),
            new TimestampColumn("最后修改时间", "lastUpdateTime"),
            { dataIndex: "lastUpdateUserName", title: "最后修改人", dataType: "string", fallback: "--" },
            { dataIndex: "sort", title: "排序序号", fallback: "--" },
            new TagColumn(ProductStatusMap, "状态", "status"),
            {
                title: "操作",
                render: (value: undefined, record: ProductApi.Product, index: number) => this.getOperations(record)
            }
        ];
        return result;
    }

    listMerchant = (name?: string, limit?: number, skip?: number) => {
        return MerchantApi.listMerchant(limit, skip, name);
    };

    listBrand = (name?: string, limit?: number, skip?: number) => {
        return BrandApi.list(limit, skip, { name });
    };

    onDeleteProduct = (product?: ProductApi.Product) => {
        const { data } = this.state;
        if (data == null || product == null) {
            return;
        }

        let modalUpdate = Modal.confirm({
            title: "确认",
            visible: true,
            content: `正在删除商品《${product.storeName}》，确认删除后商品将无法恢复，是否继续？`,
            okButtonProps: { danger: true },
            onOk: () => {
                this.changeStatus(product, ProductApi.ProductStatus.Deleted);
                modalUpdate.destroy();
            },
            onCancel: () => modalUpdate.destroy()
        });
    };

    onChangeProductStatus = (product?: ProductApi.Product, status?: ProductApi.ProductStatus) => {
        const { data } = this.state;
        if (data == null || product == null) {
            return;
        }
        let content: ReactNode | undefined = undefined;
        const formRef: RefObject<DecoratorForm> = { current: null };
        let submitPromise: Promise<void> | undefined = undefined;

        const promiseResult: Record<"resolve" | "reject", any> = { resolve: null, reject: null };
        let onRejectOk = () => {
            submitPromise = new Promise((resolve, reject) => {
                formRef.current?.submit();
                promiseResult.resolve = () => {
                    this.refresh();
                    resolve();
                };
                promiseResult.reject = reject;
            });
            return submitPromise;
        };

        switch (status) {
            case ProductApi.ProductStatus.UnderReview:
                content = `提交审核后，在审核通过或驳回前将无法继续修改，是否继续？`;
                break;
            case ProductApi.ProductStatus.Passed:
                content = `确认通过当前商品的审核吗？`;
                break;
            case ProductApi.ProductStatus.Rejected:
                content = (
                    <DecoratorForm
                        ref={formRef}
                        className={"editor-form"}
                        onFinishFailed={() => promiseResult.reject("")}
                        onSubmit={(values) => this.onRejectSubmit(promiseResult.resolve, promiseResult.reject, values)}
                        decorators={[
                            { name: "product", hidden: true, element: <Input />, initialValue: product },
                            { name: "reason", label: "驳回原因", className: "editor-form-item", element: <Input placeholder="请填写驳回原因" />, rules: [{ required: true, message: "驳回原因不能为空" }] },
                        ]}
                    />
                );
                break;
            case ProductApi.ProductStatus.Removed:
                content = `下架后当前商品将无法在商城中购买，是否继续？`;
                break;
        }
        if (content == null) {
            this.changeStatus(product, status);
        } else {
            let modalUpdate = Modal.confirm({
                title: "确认",
                visible: true,
                content: content,
                onOk: status == ProductApi.ProductStatus.Rejected ? onRejectOk : () => this.changeStatus(product, status),
                onCancel: () => modalUpdate.destroy()
            });
        }

    };

    onRejectSubmit = (resolve: () => void, reject: (reason: any) => void, values: any) => {
        this.changeStatus(values.product, ProductApi.ProductStatus.Rejected, values.reason).then(resolve).catch(reject);
    };

    public async saveOrUpdate(product: ProductApi.Product) {
        const { editingItem } = this.state;
        let item = product as Required<ProductApi.Product>;
        let result: ProductApi.Product | null = null;
        if (editingItem?.id == null) {
            if (product.status == null) {
                product.status = ProductApi.ProductStatus.UnderReview;
            }
            result = await ProductApi.add(
                item.merchantId, item.storeName, item.cover, item.sliderImage, item.cateCode,
                item.unitName, item.itemNo, item.sort, item.brandId, item.promoteSort,
                item.type, item.wechatAppId, item.wechatPageLocation, item.pickUpType, item.invoiceParty,
                item.priceType, item.verified, item.buyModel, item.stockType, item.combinationGood, item.singleWriteOff
            );
        } else {
            result = editingItem;
            await ProductApi.update(
                editingItem.id,
                item.merchantId, item.storeName, item.cover, item.sliderImage, item.cateCode,
                item.unitName, item.itemNo, item.sort, item.brandId, item.promoteSort,
                item.type, item.wechatAppId, item.wechatPageLocation, item.pickUpType, item.invoiceParty,
                item.priceType, item.verified, item.buyModel, item.stockType, item.combinationGood, item.singleWriteOff
            );
        }
        return result;
    }

    protected showEditor(editingItem?: ProductApi.Product | undefined) {
        this.updateState({ showEditor: true, editingItem: editingItem });
    }

    public queryOverride(params?: any): Promise<ProductApi.Product[] | PageResult<ProductApi.Product>> {
        return ProductApi.list(params?.limit, params?.skip, params?.name, params?.status, params?.itemNo, params?.merchantName, params?.cateCode);
    }

    public async detail(id: string) {
        return await ProductApi.detail(id);
    }
}