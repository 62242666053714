
export namespace PermissionCode{
    /*
    * 允许操作用户管理	用户管理
    */
    export const USER_MANAGE = 1
    /*
    * 允许创建用户	允许创建用户
    */
    export const CREATE_USER = 2
    /*
    * 允许操作用户角色管理	角色管理
    */
    export const ROLE_MANAGE = 3
    /*
    * 允许操作角色管理	角色管理操作
    */
    export const USER_ROLE_MANAGE = 4
    /*
    * 允许用户查看系统设置	系统设置
    */
    export const SYSTEM_MANAGE = 5
    /*
    * 允许用户对优惠券批次：查看	优惠券批次访问
    */
    export const COUPON_BATCH_VIEW = 6
    /*
    * 允许用户对优惠券批次：创建、修改、提交审核、停用、延期	优惠券批次管理
    */
    export const COUPON_BATCH_MANAGE = 7
    /*
    * 允许用户对优惠券批次：审核	优惠券批次审核
    */
    export const COUPON_BATCH_AUDIT = 8
    /*
    * 允许用户对优惠券：查看	优惠券访问
    */
    export const COUPON_VIEW = 9
    /*
    * 允许用户对优惠券：发放、注销	优惠券管理
    */
    export const COUPON_MANAGE = 10
    /*
    * 允许用户对品类：查看	品类访问
    */
    export const PRODUCT_CATE_VIEW = 11
    /*
    * 允许用户对品类：创建、修改、禁用、启用	品类管理
    */
    export const PRODUCT_CATE_MANAGE = 12
    /*
    * 允许用户对品牌：查看	品牌访问
    */
    export const BRAND_VIEW = 13
    /*
    * 允许用户对品牌：创建、修改、禁用、启用	品牌管理
    */
    export const BRAND_MANAGE = 14
    /*
    * 允许用户对商户分类：查看	商户分类访问
    */
    export const MERCHANT_CATE_VIEW = 15
    /*
    * 允许用户对商户分类：创建、修改、禁用、启用	商户分类管理
    */
    export const MERCHANT_CATE_MANAGE = 16
    /*
    * 允许用户对商户：查看	商户访问
    */
    export const MERCHANT_VIEW = 17
    /*
    * 允许用户对商户：创建、修改商户基本信息、禁用	商户管理
    */
    export const MERCHANT_MANAGE = 18
    /*
    * 允许用户对商户：修改商户详情、休息、营业	商户信息管理
    */
    export const MERCHANT_DETAIL_MANAGE = 19
    /*
    * 允许用户对Banner：查看	Banner访问
    */
    export const BANNER_VIEW = 20
    /*
    * 允许用户对Banner：创建、修改、删除、禁用、启用	Banner管理
    */
    export const BANNER_MANAGE = 21
    /*
    * 允许用户对平台订单：查看	平台订单访问
    */
    export const ORDER_PLATFORM_VIEW = 22
    /*
    * 允许用户对商户订单：查看、确认取货	商户订单访问
    */
    export const ORDER_MERCHANT_VIEW = 23
    /*
    * 允许用户创建退订单	创建退订单
    */
    export const ORDER_REFUND_CREATE = 24
    /*
    * 允许用户对退订单：查看、同意退订单、驳回退订单	退订单管理
    */
    export const ORDER_REFUND_VIEW = 25
    /*
    * 允许用户对退订单：退款	退款
    */
    export const ORDER_REFUND_PRICE = 26
    /*
    * 允许用户对活动：查看	活动访问
    */
    export const ACTIVITY_VIEW = 27
    /*
    * 允许用户对活动：创建、修改、暂停、开始、结束	活动管理
    */
    export const ACTIVITY_MANAGE = 28
    /*
    * 允许用户对商品：查看	商品访问
    */
    export const PRODUCT_VIEW = 29
    /*
    * 允许用户对商品：创建、修改	商品创建修改
    */
    export const PRODUCT_EDIT = 30
    /*
    * 允许用户对商品：审核	商品审核
    */
    export const PRODUCT_AUDIT = 31
    /*
    * 允许操作商品：上架、下架、删除	商品上架
    */
    export const PRODUCT_ON_SALE = 32
    /*
    * 允许操作商品：提交审核	商品提交审核
    */
    export const PRODUCT_SUBMIT = 33
    /*
    * 允许操作商品：推荐、取消推荐	商品推荐
    */
    export const PRODUCT_COMMEND = 34
    /*
    * 允许操作文章分类：查看	文章分类查看
    */
    export const ARTICLE_CATE_VIEW = 35
    /*
    * 允许操作文章分类：创建、修改、禁用、启用	文章分类管理
    */
    export const ARTICLE_CATE_MANAGE = 36
    /*
    * 允许操作文章：查看	文章查看
    */
    export const ARTICLE_VIEW = 37
    /*
    * 允许操作文章：创建、修改、删除	文章管理
    */
    export const ARTICLE_MANAGE = 38
    /*
    * 允许用户对报表管理：查看	报表管理
    */
    export const REPORT_MANAGE = 39
    /*
    * 允许用户对导览地点分类：查看、创建、修改、修改状态、删除	导览地点分类管理
    */
    export const LOCALE_CATE_MANAGE = 40
    /*
    * 允许用户对导览地点：查看	导览地点查看
    */
    export const LOCALE_VIEW = 41
    /*
    * 允许用户对导览地点：创建、修改、修改状态、删除	导览地点管理
    */
    export const LOCALE_MANAGE = 42
    /*
    * 允许用户对导览路线：查看、创建、修改、修改状态、删除	导览路线管理
    */
    export const LOCALE_ROUTE_MANAGE = 43
    /**
     * 商户地址管理
     */
    export const ADDRESS_MANAGE = 44
    /**
     * 查看商户地址
     */
    export const LIST_MERCHANT_ADDRESS = 45
    /*
    * 允许用户查看商户对账报表
    */
    export const MERCHANT_REPORT_VIEW = 46
}