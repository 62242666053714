import { fetch } from "@root/app"


export namespace ExpressDeliveryApi {

    export interface DeliveryCompany {
        code?: string
        name?: string
    }

    /**
     * 查询快递公司编号
     * 
     * @param name 快递公司名称
     */
    export function list(name?: string) {
        return fetch.get<DeliveryCompany[]>("/api/order/express", { name });
    }

    /**
     * 查询物流信息。
     * 
     * @param expressCode 快递公司编号
     * @param expressNumber 快递单号
     */
    export function getLogisticsInfo(expressCode?: string, expressNumber?: string) {
        return fetch.get<any>("/api/order/product/express", {expressCode, expressNumber});
    }
}