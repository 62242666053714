import { BaseModel } from "@framework/models";
import { isJsonStringUnsafe } from "@framework/utils";

import { ReactNode } from "react";
import { message } from "antd";

import { ProductApi } from "@api";

import { ProductModel } from "./product-model";


interface ProductDetailState {
    editingSpecIndex?: number

    refreshSkuFlag: Object

    refreshSpecFlag: Object

    selectedSkuIds?: string[]

    [key: string]: any

    showCalendarModalFlag: boolean
    calendarModalType: string

    currentSku: ProductApi.ProductSku
}

export class ProductDetailModel extends BaseModel<ProductDetailState> {
    #product: ProductApi.Product;
    #productModel: ProductModel;
    #arrayOfSpecMap: Record<string, ProductApi.SpecValue>[]

    constructor(product: string | ProductApi.Product) {
        super();
        if (typeof product === "string") {
            this.#product = { id: product.substring(0, 11), allowEdit: true, price: 0, unitName: "--", sort: 1, sales: 0, status: ProductApi.ProductStatus.New, browse: 0 };
        } else {
            this.#product = product;
        }
        this.#arrayOfSpecMap = [];
        this.state = { refreshSkuFlag: {}, refreshSpecFlag: {}, showCalendarModalFlag: false, calendarModalType: "", currentSku: {} }
        this.#productModel = new ProductModel();
    }

    get product() {
        return this.#product;
    }

    public async batchDisableSku(keys: React.Key[]) {
        if (keys == null || keys.length < 0) {
            return;
        }
        ProductApi.changeSkuStatus(keys.join(","), ProductApi.SkuStatus.Disabled)
        this.#onChangeSkuStatusComplete(keys, ProductApi.SkuStatus.Disabled);
    }

    public async batchEnableSku(keys: React.Key[]) {
        if (keys == null || keys.length < 0) {
            return;
        }
        await ProductApi.changeSkuStatus(keys.join(","), ProductApi.SkuStatus.Enabled);
        this.#onChangeSkuStatusComplete(keys, ProductApi.SkuStatus.Enabled)
    }

    public async changeSkuStatus(index: number, status: ProductApi.SkuStatus) {
        if (this.#product.skuList == null || this.#product.skuList.length < index) {
            return;
        }
        const sku = this.#product.skuList[index];
        if (sku.no == null) {
            return;
        }
        await ProductApi.changeSkuStatus(sku.no as string, status);

        sku.status = status;
        this.updateState({ refreshSkuFlag: {} });
    }

    /**
     * 查询当前商品详情。
     */
    public async detail() {
        const detail = await ProductApi.detail(this.#product.id as string);
        Object.assign(this.#product, detail);
        if (this.#product.specs != null && isJsonStringUnsafe(this.#product.specs)) {
            this.#product.specArray = JSON.parse(this.#product.specs as string);
        } else {
            this.#product.specArray = [];
        }
        this.#product.specArray?.forEach((spec) => {
            spec.value?.forEach((specValue, index) => {
                specValue.index = index;
                specValue.key = parseInt(specValue.no as string);
                specValue.localNo = specValue.no;
            });
        });
        this.setSpecStr(this.#product.specArray);
        if (this.#product.skuList != null) {
            for (let sku of this.#product.skuList) {
                sku.skuNo = sku.no;
            }
        }
    }

    public getProductDecorators() {
        return this.#productModel.getEditorDecorators(this.product);
    }

    public async deleteCalendarPrice(CalendarPrice: ProductApi.CalendarPrice) {
        if (CalendarPrice?.id == null) {
            return;
        }
        await ProductApi.deleteCalendarPrice(CalendarPrice?.id);
    }

    public async changeCalendarStockStatus(calendarStock: ProductApi.CalendarStock, status: ProductApi.CalendarStockStatus) {
        if (calendarStock?.id == null) {
            return;
        }
        await ProductApi.updateCalendarStockStatus(calendarStock?.id, status);
    }

    /**
     * sku 状态变更完成后通知界面刷新 sku 列表。
     * @param keys 变更状态的 {@link ProductApi["ProductSku"]["skuNo"]} 列表。
     * @param status 新的 sku 状态。
     */
    #onChangeSkuStatusComplete(keys: React.Key[], status: ProductApi.SkuStatus) {
        const map = keys.reduce((prev, current) => {
            prev[current] = current;
            return prev;
        }, {} as Record<React.Key, React.Key>);
        if (this.#product.skuList != null) {
            for (let sku of this.#product.skuList) {
                if (map[sku.no as string] != null) {
                    sku.status = status;
                }
            }
        }
        this.updateState({ refreshSkuFlag: {} });
    }
    /**
     * 商品规格变化时，更新 sku 关联的规格信息。
     */
    #refreshInfos = () => {
        if (this.#product.skuList != null && this.#arrayOfSpecMap.length > 0) {
            this.#product.skuList.forEach((sku, index) => {
                if (sku.no == null || sku.specs != null) {
                    return;
                }
                sku.specs = [];
                let specIndex = 0;
                let specs = sku.no.substring(11);
                let allowEdit = false;
                for (let i = 0; i < specs.length; i += 2) {
                    if (specIndex >= this.#arrayOfSpecMap.length) {
                        return;
                    }
                    let specNo = specs.substring(i, i + 2);
                    let specValue = this.#arrayOfSpecMap[specIndex][specNo];
                    if (specValue != null) {
                        sku.specs.push(specValue);
                        allowEdit = allowEdit || specValue?.status != ProductApi.SpecStatus.Sealed;
                    }
                    specIndex++;
                }
                sku.editable = allowEdit;
            });
        }
        this.#product.specArray?.forEach(current => {
            current.editable = (current.value == null ? true : current.value.reduce((pre, cur) => {
                return pre && cur.status != ProductApi.SpecStatus.Sealed;
            }, true));
        });

        this.updateState({ refreshSkuFlag: {}, refreshSpecFlag: {} });
    }

    renderSkuSpecs = (specs?: ProductApi.SkuSpec[]) => {
        const result: ReactNode[] = [];
        if (specs != null && specs instanceof Array) {
            let key = 1;
            for (let spec of specs) {
                result.push(<span key={key++} style={{ marginRight: 10 }}>{spec.name}：{spec.value?.name}</span>)
            }
        }
        return <div>{result}</div>;
    }

    /**
     * 保存商品详情。
     * @param detail 商品详情的富文本数据。
     */
    public async saveDetail(detail?: string) {
        return ProductApi.saveDetail(this.#product.id as string, detail).then(() => {
            this.#product.storeDetail = detail;
        });
    }

    /**
     * 保存商品规格及关联 sku。
     */
    public async saveSpecAndSku() {
        const specs: ProductApi.ProductSpec[] = this.#product.specArray == null ? [] : this.#product.specArray.map(spec => {
            return {
                name: spec.name, 
                value: spec.value == null ? [] : spec.value.map(specValue => ({
                    no: specValue.no,
                    name: specValue.name,
                    nameId: specValue.nameId,
                    status: specValue.status == ProductApi.SpecStatus.Sealed ? specValue.status : undefined
                }))
            }
        });

        const skuList: ProductApi.ProductSku[] = this.#product.skuList == null ? [] : this.#product.skuList.map(sku => ({
            no: sku.no,
            image: sku.image,
            price: sku.price,
            productId: sku.productId,
            index: sku.index,
            stock: sku.stock,
            cost: sku.cost,
            tagPrice: sku.tagPrice
        }));
        await ProductApi.saveSpecAndSku(this.#product.id as string, JSON.stringify(specs), JSON.stringify(skuList));
        message.success('保存成功')
        this.detail();
    }

    public setSpecStr(productSpecs?: Array<ProductApi.ProductSpec>) {
        this.#arrayOfSpecMap = productSpecs == null ? [] : productSpecs.map((specs) => {
            if (specs.value == null) {
                return {};
            } else {
                return specs.value.reduce((prev, current) => {
                    prev[current.localNo as string] = { name: specs.name, value: current };
                    return prev;
                }, {} as Record<string, ProductApi.SkuSpec>);
            }
        });
        this.#product.specArray = productSpecs;
        this.#refreshInfos();
    }

    public async update(product: ProductApi.Product) {
        const item = product as Required<ProductApi.Product>
        return ProductApi.update(
            this.#product.id as string, item.merchantId, item.storeName, item.cover, item.sliderImage,
            item.cateCode, item.unitName, item.itemNo, item.sort, item.brandId, item.promoteSort, item.type,
            item.wechatAppId, item.wechatPageLocation, item.pickUpType, item.invoiceParty, item.priceType, item.verified,
            item.buyModel, item.stockType, item.combinationGood, item.singleWriteOff
        ).then(() => {
            return Object.assign(this.#product, product);
        });
    }
}