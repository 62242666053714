

import ReactDOM from 'react-dom/client';
import { ConfigProvider } from "antd";
import zh_CN from "antd/es/locale/zh_CN"
import { BrowserRouter, RouteObject, useRoutes } from "react-router-dom";

import { routers } from "./router";
import reportWebVitals from './reportWebVitals';

import "./global.less";
import { RouteWithPermission } from "./framework/route-with-permission";


function Routes(props: { routers: RouteWithPermission[] }) {
  return useRoutes(props.routers as any);
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render((
  <ConfigProvider locale={zh_CN}>
      <BrowserRouter>
          <Routes routers={routers} />
      </BrowserRouter>
  </ConfigProvider>
));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
