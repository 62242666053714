

import { Converter } from "@framework/utils";
import { SelectionTableValue } from "@framework/component";

import React from "react";

export class SelectionTableValueConverter implements Converter<React.Key[], SelectionTableValue> {
    convert(value?: React.Key[] | undefined, values?: Record<string, any> | undefined): SelectionTableValue | null {
        if (value == null) {
            return { selectedRowKeys: [] };
        }
        return { selectedRowKeys: value }
    }
    convertBack(value?: SelectionTableValue | undefined, values?: Record<string, any> | undefined, target?: Record<string, any> | undefined): React.Key[] | null {
        return value?.selectedRowKeys == null ? null : value.selectedRowKeys;
    }

}