

import { BaseModel, StateListener } from "@framework/models/base-model";

import { Component } from "react";
import { NavigateFunction, To, useLocation, useNavigate, Location, useParams, useSearchParams, Params } from "react-router-dom";


export declare type SnapshotState = Record<number | string | symbol, any>;

export type PropsType<T extends BaseModel<any>> = { model: T }

type PickStateType<T extends PropsType<any>> = T extends PropsType<infer P> ? (P extends BaseModel<infer R> ? R : P) : T;

/**
 * 向 {@link ["attach"]} 中注入 {@link Location}, {@link NavigateFunction}。
 * 
 * @param param0 需向注入 {@link Location} 及 {@link NavigateFunction} 的组件。
 */
export function UseRouter({attach, ...props}: { attach: BaseComponent<any>, [key: string] : any }) {
    attach.navigate = useNavigate();
    attach.location = useLocation();
    const [ queryParams ] = useSearchParams(new URLSearchParams());
    attach.queryParams = queryParams;
    attach.pathParams = useParams();
    attach.attached = true;
    return <></>;
};

/**
 * 基础组件。
 */
export class BaseComponent<TProps extends PropsType<BaseModel<any>>, TState = PickStateType<TProps>> extends Component<TProps, Partial<TState>, SnapshotState> {
    protected listener?: StateListener;

    attached: boolean;
    
    #navigate?: NavigateFunction;

    #location?: Location;

    #queryParams: URLSearchParams | null;

    #pathParams?: Readonly<Params<string>>;


    constructor(props: TProps) {
        super(props);
        this.attached = false;
        this.state = props.model.state;
        this.#queryParams = null;
    }

    protected navigateTo(to: To, replace?: boolean, state?: any) {
        if (this.#navigate == null) {
            throw new Error(`使用此方法前，需要在render方法中增加节点 <UseRouter attach={this} /> 。`);
        }
        this.#navigate(to, replace == null && state == null ? undefined : { replace, state });
    }

    set navigate(navigate: NavigateFunction) {
        this.#navigate = navigate;
    }

    get location(): Location {
        if (this.#location == null) {
            console.warn("使用 location 属性，需要在render方法中增加节点 <UseRouter attach={this} /> 。");
            return {} as Location;
        }
        return this.#location as Location;
    }

    set location(location: Location) {
        this.#location = location;
    }

    get pathParams(): Readonly<Params<string>> {
        if (this.#pathParams == null) {
            console.warn("使用 pathParams 属性，需要在render方法中增加节点 <UseRouter attach={this} /> 。");
            return {};
        }
         return this.#pathParams;
    }

    set pathParams(pathParams: Readonly<Params<string>>) {
        this.#pathParams = pathParams;
    }

    get queryParams() : URLSearchParams {
        if (this.#queryParams === null) {
            console.warn("使用 queryParams 属性，需要在render方法中增加节点 <UseRouter attach={this} /> 。");
            return new URLSearchParams();
        } else {
            return this.#queryParams;
        }
    }

    set queryParams(queryParams: URLSearchParams) {
        this.#queryParams = queryParams;
    }

    componentDidMount(): void {
        this.listener = this.props.model.addStateListener(this.#onModelStateChanged);
    }

    override componentWillUnmount(): void {
        this.listener?.remove();
    }

    #onModelStateChanged = (source: BaseModel<any>, params: any, originState: any) => {
        this.onModelStateChanged(source, params, originState);
    }

    onModelStateChanged(source: BaseModel<any>, params: any, originState: any) {
    }
}
