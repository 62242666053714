import { MerchantApi, ReportApi } from "@api";
import app from "@root/app";
import { DecoratorForm } from "@root/framework/component";
import { QueryModel, QueryState } from "@root/framework/models";
import { PageResult, Subtotal, SummaryGroup, SummaryItem, TimestampConverter } from "@root/framework/utils";
import { Button, DatePicker, message } from "antd";
import moment from "moment";

declare type IncomeSummary<T extends ReportApi.IncomeViewBase> = SummaryItem<T>;
interface IncomeSummaryState<T extends ReportApi.IncomeViewBase> extends QueryState<IncomeSummary<T>> {
}

const add = (a: number | undefined, b: number | undefined) => {
    a = a == null ? 0 : a;
    b = b == null ? 0 : b;
    return (a + b);
};

abstract class IncomeSummaryModel<T extends ReportApi.BusinessIncome> extends QueryModel<IncomeSummaryState<T>> {

    constructor() {
        super();
        this.state = {};
    }

    protected abstract doQuery(params?: any): Promise<T[]>;

    public async queryOverride(params?: any): Promise<IncomeSummary<T>[] | PageResult<IncomeSummary<T>>> {
        const result = await this.doQuery(params);
        
        result.forEach(item => {
            if (item.settleType == MerchantApi.InvoiceParty.Platform) {
                item.settleAmount = item.actualAmount;
                item.actualAmount = 0;
            } else {
                item.settleAmount = 0;
            }
        });
        const subtotal = new Subtotal(result, (a, b) => {
            a.qty = add(a.qty, Number.isNaN(Number(b.qty)) ? 0 : Math.abs(b.qty));
            a.cost = add(a.cost, b.cost);
            a.amount = add(a.amount, b.amount);
            a.totalAmount = add(a.totalAmount, b.totalAmount);
            a.settleAmount = add(a.settleAmount, b.settleAmount);
            a.actualAmount = add(a.actualAmount, b.actualAmount);
            a.invoiceAmount = add(a.invoiceAmount, b.invoiceAmount);
            a.merchantDiscount = add(a.merchantDiscount, b.merchantDiscount);
            a.platformDiscount = add(a.platformDiscount, b.platformDiscount);
            return a as IncomeSummary<T>;
        });

        const subtotalResult = subtotal.collect([{
            keyProp: "merchantId",
            valueProp: "merchantName"
        }, {
            keyProp: "productCate",
            valueProp: "productCateName"
        }, {
            keyProp: "incomeType",
            valueProp: "incomeType"
        }]);
        subtotalResult.forEach((item, index) => item.id = index);
        return subtotalResult;
    }
}

export class BusinessIncomeModel extends IncomeSummaryModel<ReportApi.BusinessIncome> {

    constructor() {
        super();
        this.state = {};
    }

    public generate() {
        const close = app.showModal({
            footer: null,
            title: "生成报表",
            maskClosable: true,
            destroyOnClose: true,
            children: (
                <DecoratorForm
                    labelCol={ { span: 6 } }
                    className="editor-form"
                    decorators={ [{
                        name: "date",
                        label: "报表日期",
                        className: "editor-form-item",
                        converter: new TimestampConverter(moment()),
                        element: <DatePicker />
                    }] }
                    onSubmit={ async (values) => {
                        await ReportApi.generate(values.date);
                        close();
                        message.success("生成报表任务已提交，请在5秒后进行查询");
                    } }
                >
                    <div className="operation">
                        <Button type="primary" htmlType="submit">提 交</Button>
                    </div>
                </DecoratorForm>
            )
        });
    }

    protected doQuery(params?: any): Promise<ReportApi.BusinessIncome[]> {
        return ReportApi.listBusiness(params?.startDate, params?.endDate, params?.merchantName, params?.cateName, params?.incomeTypes, params?.settleType);
    }
}

export class PrepaidIncomeModel extends IncomeSummaryModel<ReportApi.PrepaidIncome> {
    constructor() {
        super();
        this.state = {};
    }

    protected doQuery(params?: any): Promise<ReportApi.PrepaidIncome[]> {
        return ReportApi.listPrepaid(params?.startDate, params?.endDate, params?.merchantName, params?.cateName, params?.incomeTypes, params?.settleType);
    }
}


export class MerchantIncomeModel extends QueryModel<IncomeSummaryState<ReportApi.MerchantIncome>> {

    public async queryOverride(params?: any): Promise<IncomeSummary<ReportApi.MerchantIncome>[] | PageResult<IncomeSummary<ReportApi.MerchantIncome>>> {
        const result = await ReportApi.listMerchantBusiness(params?.startDate, params?.endDate, params?.cateName, params?.incomeTypes, params?.settleType);
        const subtotal = new Subtotal(result, (a, b) => {
            a.qty = add(a.qty, Number.isNaN(Number(b.qty)) ? 0 : Math.abs(b.qty));
            a.amount = add(a.amount, b.amount);
            a.totalAmount = add(a.totalAmount, b.totalAmount);
            a.actualAmount = add(a.actualAmount, b.actualAmount);
            a.merchantDiscount = add(a.merchantDiscount, b.merchantDiscount);
            a.platformDiscount = add(a.platformDiscount, b.platformDiscount);
            return a as IncomeSummary<ReportApi.MerchantIncome>;
        });

        const groups: SummaryGroup<ReportApi.MerchantIncome>[] = [
            { keyProp: "productCate", valueProp: "productCateName" },
            { keyProp: "incomeType", valueProp: "incomeType" }
        ];
        if (app.currentUser.merchantId == -1) {
            groups.unshift({ keyProp: "merchantId", valueProp: "merchantName" });
        }

        const subtotalResult = subtotal.collect(groups);
        subtotalResult.forEach((item, index) => item.id = index);
        return subtotalResult;
    }

    constructor() {
        super();
        this.state = {};
    }


}