

import { mergeProps } from "@framework/utils";

export type StateChangedListener = (source: BaseModel<any>, params: any, originState: any) => void;


/**
 * 在 {@link BaseModel} 中执行 {@link BaseModel["addStateListener"]} 后的返回对象。
 */
export class StateListener {
    private listener: StateChangedListener;
    private container: BaseModel<any>;
    constructor(listener: StateChangedListener, container: BaseModel<any>) {
        this.listener = listener;
        this.container = container;
    }

    remove = () => {
        this.container.removeStateListener(this.listener);
    }
}

/**
 * 封装对数据操作的方法。
 * 
 * @template TState 挂载到 redux store 中的state类型。
 */
export class BaseModel<TState extends {}> {
    private parent: BaseModel<any> | null = null;
    private children: Record<string, BaseModel<any>> | null = null;
    private listeners: StateChangedListener[] | null = null;
    public state: TState = {} as TState;

    protected constructor() {
    }

    public addChildModel<TCM extends BaseModel<any>>(key: string, child: TCM) {
        if (this.children == null) {
            this.children = {};
        }
        if (child.parent != null && child.parent != this) {
            throw new Error(`此数据模型已作为其它模型的子级，参数：child！`);
        }
        if (this.children[key] != null) {
            throw new Error(`已存在名为${key}的子数据模型！`);
        }
        child.parent = this;
        this.children[key] = child;
        child.notifyAttached(this);
    }

    /**
     * 添加一个 {@link BaseModel["state"]} 的更改监听程序。
     * 
     * @param listener 监听 {@link BaseModel["state"]} 更改的监听程序 {@link StateChangedListener}。
     * @returns StateListener
     */
    public addStateListener(listener: StateChangedListener): StateListener {
        if (this.listeners == null) {
            this.listeners = [];
        }
        this.listeners.push(listener);
        return new StateListener(listener, this);
    }

    public get<T extends BaseModel<any>>(key: string) : T | null {
        return this.children == null ? null : (this.children[key] == null ? null : this.children[key] as T);
    }

    protected notifyAttached(parent: BaseModel<any>): void {}

    protected notifyDetached(parent: BaseModel<any>): void {}

    private notifyStateChanged(params: any, originState: any, source?: BaseModel<any>) {
        if (this.listeners == null) {
            return;
        }
        for (let listener of this.listeners) {
            listener(source == null ? this : source, params, originState);
        }
        if (this.parent != null) {
            this.parent.notifyStateChanged(params, originState, this);
        }
    }

    public orElseGet<T extends BaseModel<any>>(key: string, ctor?: { new():T }, creator?: () => T) : T {
        let child = this.get(key);
        if (child == null) {
            if (ctor == null && creator == null) {
                throw new Error("未找到key对应的Model，且未指定任何创建方法！")
            } else if (ctor != null) {
                child = new ctor();
            } else if (creator != null) {
                child = creator();
            }
            this.addChildModel(key, child as T);
        }
        return child as T;
    }
    
    public removeStateListener(listener: StateChangedListener) {
        if (this.listeners == null || this.listeners.length == 0) {
            return;
        }
        for (let index = 0, item = this.listeners[index]; index < this.listeners.length; index++, item = this.listeners[index]) {
            if (item === listener) {
                this.listeners.splice(index, 1);
                break;
            }
        }
    }

    /**
     * 重置store中的state为{@link state}。
     * 
     * @param state 新的{@template TState}对象。
     */
    public setState(state: TState) {
        let originState = this.state;
        this.state = {...state};
        this.notifyStateChanged(state, originState);
    }

    public removeChildModel<T extends BaseModel<any>>(key: string) : T | null {
        if (this.children != null && this.children[key] != null) {
            let child = this.children[key];
            child.parent = null;
            delete this.children[key];
            child.notifyDetached(this);
            return child as T;
        }
        return null;
    }

    /**
     * 更新state。
     * 
     * @param paramsToMerge 需合并的属性。
     * @param desc 操作说明。
     */
     public updateState(paramsToMerge: Partial<TState>, desc?: string) {
        let originState = this.state;
        this.state = mergeProps(originState, paramsToMerge) as TState;
        this.notifyStateChanged(paramsToMerge, originState);
    }
}
