export default {
    "tianjin": [
        { "code": "120101", "name": "和平", "level": 2, "parentCode": "1201", "firstLetterSpell": "hp", "fullSpell": "heping", "extCode": "120101000000", "extName": "和平区" },
        { "code": "120101001", "name": "劝业场", "level": 3, "parentCode": "120101", "firstLetterSpell": "qyc", "fullSpell": "quanyechang", "extCode": "120101001000", "extName": "劝业场街道" },
        { "code": "120101002", "name": "小白楼", "level": 3, "parentCode": "120101", "firstLetterSpell": "xbl", "fullSpell": "xiaobailou", "extCode": "120101002000", "extName": "小白楼街道" },
        { "code": "120101003", "name": "五大道", "level": 3, "parentCode": "120101", "firstLetterSpell": "wdd", "fullSpell": "wudadao", "extCode": "120101003000", "extName": "五大道街道" },
        { "code": "120101004", "name": "新兴", "level": 3, "parentCode": "120101", "firstLetterSpell": "xx", "fullSpell": "xinxing", "extCode": "120101004000", "extName": "新兴街道" },
        { "code": "120101005", "name": "南营门", "level": 3, "parentCode": "120101", "firstLetterSpell": "nym", "fullSpell": "nanyingmen", "extCode": "120101005000", "extName": "南营门街道" },
        { "code": "120101006", "name": "南市", "level": 3, "parentCode": "120101", "firstLetterSpell": "ns", "fullSpell": "nanshi", "extCode": "120101006000", "extName": "南市街道" },
        { "code": "120102", "name": "河东", "level": 2, "parentCode": "1201", "firstLetterSpell": "hd", "fullSpell": "hedong", "extCode": "120102000000", "extName": "河东区" },
        { "code": "120102001", "name": "大王庄", "level": 3, "parentCode": "120102", "firstLetterSpell": "dwz", "fullSpell": "daiwangzhuang", "extCode": "120102001000", "extName": "大王庄街道" },
        { "code": "120102002", "name": "大直沽", "level": 3, "parentCode": "120102", "firstLetterSpell": "dzg", "fullSpell": "dazhigu", "extCode": "120102002000", "extName": "大直沽街道" },
        { "code": "120102003", "name": "中山门", "level": 3, "parentCode": "120102", "firstLetterSpell": "zsm", "fullSpell": "zhongshanmen", "extCode": "120102003000", "extName": "中山门街道" },
        { "code": "120102004", "name": "富民路", "level": 3, "parentCode": "120102", "firstLetterSpell": "fml", "fullSpell": "fuminlu", "extCode": "120102004000", "extName": "富民路街道" },
        { "code": "120102005", "name": "二号桥", "level": 3, "parentCode": "120102", "firstLetterSpell": "ehq", "fullSpell": "erhaoqiao", "extCode": "120102005000", "extName": "二号桥街道" },
        { "code": "120102006", "name": "春华", "level": 3, "parentCode": "120102", "firstLetterSpell": "ch", "fullSpell": "chunhua", "extCode": "120102006000", "extName": "春华街道" },
        { "code": "120102007", "name": "唐家口", "level": 3, "parentCode": "120102", "firstLetterSpell": "tjk", "fullSpell": "tangjiakou", "extCode": "120102007000", "extName": "唐家口街道" },
        { "code": "120102008", "name": "向阳楼", "level": 3, "parentCode": "120102", "firstLetterSpell": "xyl", "fullSpell": "xiangyanglou", "extCode": "120102008000", "extName": "向阳楼街道" },
        { "code": "120102009", "name": "常州道", "level": 3, "parentCode": "120102", "firstLetterSpell": "czd", "fullSpell": "changzhoudao", "extCode": "120102009000", "extName": "常州道街道" },
        { "code": "120102010", "name": "上杭路", "level": 3, "parentCode": "120102", "firstLetterSpell": "shl", "fullSpell": "shanghanglu", "extCode": "120102010000", "extName": "上杭路街道" },
        { "code": "120102011", "name": "东新", "level": 3, "parentCode": "120102", "firstLetterSpell": "dx", "fullSpell": "dongxin", "extCode": "120102011000", "extName": "东新街道" },
        { "code": "120102012", "name": "鲁山道", "level": 3, "parentCode": "120102", "firstLetterSpell": "lsd", "fullSpell": "lushandao", "extCode": "120102012000", "extName": "鲁山道街道" },
        { "code": "120103", "name": "河西", "level": 2, "parentCode": "1201", "firstLetterSpell": "hx", "fullSpell": "hexi", "extCode": "120103000000", "extName": "河西区" },
        { "code": "120103001", "name": "大营门", "level": 3, "parentCode": "120103", "firstLetterSpell": "dym", "fullSpell": "dayingmen", "extCode": "120103001000", "extName": "大营门街道" },
        { "code": "120103002", "name": "下瓦房", "level": 3, "parentCode": "120103", "firstLetterSpell": "xwf", "fullSpell": "xiawafang", "extCode": "120103002000", "extName": "下瓦房街道" },
        { "code": "120103003", "name": "桃园", "level": 3, "parentCode": "120103", "firstLetterSpell": "ty", "fullSpell": "taoyuan", "extCode": "120103003000", "extName": "桃园街道" },
        { "code": "120103004", "name": "挂甲寺", "level": 3, "parentCode": "120103", "firstLetterSpell": "gjs", "fullSpell": "guajiasi", "extCode": "120103004000", "extName": "挂甲寺街道" },
        { "code": "120103005", "name": "马场", "level": 3, "parentCode": "120103", "firstLetterSpell": "mc", "fullSpell": "machang", "extCode": "120103005000", "extName": "马场街道" },
        { "code": "120103006", "name": "越秀路", "level": 3, "parentCode": "120103", "firstLetterSpell": "yxl", "fullSpell": "yuexiulu", "extCode": "120103006000", "extName": "越秀路街道" },
        { "code": "120103007", "name": "友谊路", "level": 3, "parentCode": "120103", "firstLetterSpell": "yyl", "fullSpell": "youyilu", "extCode": "120103007000", "extName": "友谊路街道" },
        { "code": "120103008", "name": "天塔", "level": 3, "parentCode": "120103", "firstLetterSpell": "tt", "fullSpell": "tianta", "extCode": "120103008000", "extName": "天塔街道" },
        { "code": "120103009", "name": "尖山", "level": 3, "parentCode": "120103", "firstLetterSpell": "js", "fullSpell": "jianshan", "extCode": "120103009000", "extName": "尖山街道" },
        { "code": "120103010", "name": "陈塘庄", "level": 3, "parentCode": "120103", "firstLetterSpell": "ctz", "fullSpell": "chentangzhuang", "extCode": "120103010000", "extName": "陈塘庄街道" },
        { "code": "120103011", "name": "柳林", "level": 3, "parentCode": "120103", "firstLetterSpell": "ll", "fullSpell": "liulin", "extCode": "120103011000", "extName": "柳林街道" },
        { "code": "120103012", "name": "东海", "level": 3, "parentCode": "120103", "firstLetterSpell": "dh", "fullSpell": "donghai", "extCode": "120103012000", "extName": "东海街道" },
        { "code": "120103013", "name": "梅江", "level": 3, "parentCode": "120103", "firstLetterSpell": "mj", "fullSpell": "meijiang", "extCode": "120103013000", "extName": "梅江街道" },
        { "code": "120104", "name": "南开", "level": 2, "parentCode": "1201", "firstLetterSpell": "nk", "fullSpell": "nankai", "extCode": "120104000000", "extName": "南开区" },
        { "code": "120104001", "name": "长虹", "level": 3, "parentCode": "120104", "firstLetterSpell": "ch", "fullSpell": "changhong", "extCode": "120104001000", "extName": "长虹街道" },
        { "code": "120104002", "name": "鼓楼", "level": 3, "parentCode": "120104", "firstLetterSpell": "gl", "fullSpell": "gulou", "extCode": "120104002000", "extName": "鼓楼街道" },
        { "code": "120104003", "name": "兴南", "level": 3, "parentCode": "120104", "firstLetterSpell": "xn", "fullSpell": "xingnan", "extCode": "120104003000", "extName": "兴南街道" },
        { "code": "120104004", "name": "广开", "level": 3, "parentCode": "120104", "firstLetterSpell": "gk", "fullSpell": "guangkai", "extCode": "120104004000", "extName": "广开街道" },
        { "code": "120104005", "name": "万兴", "level": 3, "parentCode": "120104", "firstLetterSpell": "wx", "fullSpell": "wanxing", "extCode": "120104005000", "extName": "万兴街道" },
        { "code": "120104006", "name": "学府", "level": 3, "parentCode": "120104", "firstLetterSpell": "xf", "fullSpell": "xuefu", "extCode": "120104006000", "extName": "学府街道" },
        { "code": "120104007", "name": "向阳路", "level": 3, "parentCode": "120104", "firstLetterSpell": "xyl", "fullSpell": "xiangyanglu", "extCode": "120104007000", "extName": "向阳路街道" },
        { "code": "120104008", "name": "嘉陵道", "level": 3, "parentCode": "120104", "firstLetterSpell": "jld", "fullSpell": "jialingdao", "extCode": "120104008000", "extName": "嘉陵道街道" },
        { "code": "120104009", "name": "王顶堤", "level": 3, "parentCode": "120104", "firstLetterSpell": "wdd", "fullSpell": "wangdingdi", "extCode": "120104009000", "extName": "王顶堤街道" },
        { "code": "120104010", "name": "水上公园", "level": 3, "parentCode": "120104", "firstLetterSpell": "ssgy", "fullSpell": "shuishanggongyuan", "extCode": "120104010000", "extName": "水上公园街道" },
        { "code": "120104011", "name": "体育中心", "level": 3, "parentCode": "120104", "firstLetterSpell": "tyzx", "fullSpell": "tiyuzhongxin", "extCode": "120104011000", "extName": "体育中心街道" },
        { "code": "120104012", "name": "华苑", "level": 3, "parentCode": "120104", "firstLetterSpell": "hy", "fullSpell": "huayuan", "extCode": "120104012000", "extName": "华苑街道" },
        { "code": "120105", "name": "河北", "level": 2, "parentCode": "1201", "firstLetterSpell": "hb", "fullSpell": "hebei", "extCode": "120105000000", "extName": "河北区" },
        { "code": "120105001", "name": "光复道", "level": 3, "parentCode": "120105", "firstLetterSpell": "gfd", "fullSpell": "guangfudao", "extCode": "120105001000", "extName": "光复道街道" },
        { "code": "120105002", "name": "望海楼", "level": 3, "parentCode": "120105", "firstLetterSpell": "whl", "fullSpell": "wanghailou", "extCode": "120105002000", "extName": "望海楼街道" },
        { "code": "120105003", "name": "鸿顺里", "level": 3, "parentCode": "120105", "firstLetterSpell": "hsl", "fullSpell": "hongshunli", "extCode": "120105003000", "extName": "鸿顺里街道" },
        { "code": "120105004", "name": "新开河", "level": 3, "parentCode": "120105", "firstLetterSpell": "xkh", "fullSpell": "xinkaihe", "extCode": "120105004000", "extName": "新开河街道" },
        { "code": "120105005", "name": "铁东路", "level": 3, "parentCode": "120105", "firstLetterSpell": "tdl", "fullSpell": "tiedonglu", "extCode": "120105005000", "extName": "铁东路街道" },
        { "code": "120105006", "name": "建昌道", "level": 3, "parentCode": "120105", "firstLetterSpell": "jcd", "fullSpell": "jianchangdao", "extCode": "120105006000", "extName": "建昌道街道" },
        { "code": "120105007", "name": "宁园", "level": 3, "parentCode": "120105", "firstLetterSpell": "ny", "fullSpell": "ningyuan", "extCode": "120105007000", "extName": "宁园街道" },
        { "code": "120105008", "name": "王串场", "level": 3, "parentCode": "120105", "firstLetterSpell": "wcc", "fullSpell": "wangchuanchang", "extCode": "120105008000", "extName": "王串场街道" },
        { "code": "120105009", "name": "江都路", "level": 3, "parentCode": "120105", "firstLetterSpell": "jdl", "fullSpell": "jiangdulu", "extCode": "120105009000", "extName": "江都路街道" },
        { "code": "120105010", "name": "月牙河", "level": 3, "parentCode": "120105", "firstLetterSpell": "yyh", "fullSpell": "yueyahe", "extCode": "120105010000", "extName": "月牙河街道" },
        { "code": "120106", "name": "红桥", "level": 2, "parentCode": "1201", "firstLetterSpell": "hq", "fullSpell": "hongqiao", "extCode": "120106000000", "extName": "红桥区" },
        { "code": "120106001", "name": "西于庄", "level": 3, "parentCode": "120106", "firstLetterSpell": "xyz", "fullSpell": "xiyuzhuang", "extCode": "120106001000", "extName": "西于庄街道" },
        { "code": "120106003", "name": "咸阳北路", "level": 3, "parentCode": "120106", "firstLetterSpell": "xybl", "fullSpell": "xianyangbeilu", "extCode": "120106003000", "extName": "咸阳北路街道" },
        { "code": "120106004", "name": "丁字沽", "level": 3, "parentCode": "120106", "firstLetterSpell": "dzg", "fullSpell": "dingzigu", "extCode": "120106004000", "extName": "丁字沽街道" },
        { "code": "120106005", "name": "西沽", "level": 3, "parentCode": "120106", "firstLetterSpell": "xg", "fullSpell": "xigu", "extCode": "120106005000", "extName": "西沽街道" },
        { "code": "120106006", "name": "三条石", "level": 3, "parentCode": "120106", "firstLetterSpell": "sts", "fullSpell": "santiaoshi", "extCode": "120106006000", "extName": "三条石街道" },
        { "code": "120106008", "name": "邵公庄", "level": 3, "parentCode": "120106", "firstLetterSpell": "sgz", "fullSpell": "shaogongzhuang", "extCode": "120106008000", "extName": "邵公庄街道" },
        { "code": "120106009", "name": "芥园", "level": 3, "parentCode": "120106", "firstLetterSpell": "jy", "fullSpell": "jieyuan", "extCode": "120106009000", "extName": "芥园街道" },
        { "code": "120106010", "name": "铃铛阁", "level": 3, "parentCode": "120106", "firstLetterSpell": "ldg", "fullSpell": "lingdangge", "extCode": "120106010000", "extName": "铃铛阁街道" },
        { "code": "120106011", "name": "大胡同", "level": 3, "parentCode": "120106", "firstLetterSpell": "dht", "fullSpell": "dahutong", "extCode": "120106011000", "extName": "大胡同街道" },
        { "code": "120110", "name": "东丽", "level": 2, "parentCode": "1201", "firstLetterSpell": "dl", "fullSpell": "dongli", "extCode": "120110000000", "extName": "东丽区" },
        { "code": "120110001", "name": "张贵庄", "level": 3, "parentCode": "120110", "firstLetterSpell": "zgz", "fullSpell": "zhangguizhuang", "extCode": "120110001000", "extName": "张贵庄街道" },
        { "code": "120110002", "name": "丰年村", "level": 3, "parentCode": "120110", "firstLetterSpell": "fnc", "fullSpell": "fengniancun", "extCode": "120110002000", "extName": "丰年村街道" },
        { "code": "120110003", "name": "万新", "level": 3, "parentCode": "120110", "firstLetterSpell": "wx", "fullSpell": "wanxin", "extCode": "120110003000", "extName": "万新街道" },
        { "code": "120110004", "name": "无瑕", "level": 3, "parentCode": "120110", "firstLetterSpell": "wx", "fullSpell": "wuxia", "extCode": "120110004000", "extName": "无瑕街道" },
        { "code": "120110005", "name": "新立", "level": 3, "parentCode": "120110", "firstLetterSpell": "xl", "fullSpell": "xinli", "extCode": "120110005000", "extName": "新立街道" },
        { "code": "120110006", "name": "华明", "level": 3, "parentCode": "120110", "firstLetterSpell": "hm", "fullSpell": "huaming", "extCode": "120110006000", "extName": "华明街道" },
        { "code": "120110007", "name": "金钟", "level": 3, "parentCode": "120110", "firstLetterSpell": "jz", "fullSpell": "jinzhong", "extCode": "120110007000", "extName": "金钟街道" },
        { "code": "120110008", "name": "军粮城", "level": 3, "parentCode": "120110", "firstLetterSpell": "jlc", "fullSpell": "junliangcheng", "extCode": "120110008000", "extName": "军粮城街道" },
        { "code": "120110009", "name": "金桥", "level": 3, "parentCode": "120110", "firstLetterSpell": "jq", "fullSpell": "jinqiao", "extCode": "120110009000", "extName": "金桥街道" },
        { "code": "120110010", "name": "东丽湖", "level": 3, "parentCode": "120110", "firstLetterSpell": "dlh", "fullSpell": "donglihu", "extCode": "120110010000", "extName": "东丽湖街道" },
        { "code": "120110400", "name": "天津开发区西区", "level": 3, "parentCode": "120110", "firstLetterSpell": "tjkfqxq", "fullSpell": "tianjinkaifaquxiqu", "extCode": "120110400000", "extName": "天津开发区西区" },
        { "code": "120110401", "name": "天津航空物流区", "level": 3, "parentCode": "120110", "firstLetterSpell": "tjhkwlq", "fullSpell": "tianjinhangkongwuliuqu", "extCode": "120110401000", "extName": "天津航空物流区" },
        { "code": "120110402", "name": "天津空港经济区", "level": 3, "parentCode": "120110", "firstLetterSpell": "tjkgjjq", "fullSpell": "tianjinkonggangjingjiqu", "extCode": "120110402000", "extName": "天津空港经济区" },
        { "code": "120110403", "name": "东丽区开发区", "level": 3, "parentCode": "120110", "firstLetterSpell": "dlqkfq", "fullSpell": "dongliqukaifaqu", "extCode": "120110403000", "extName": "东丽区开发区" },
        { "code": "120110405", "name": "综合保税区", "level": 3, "parentCode": "120110", "firstLetterSpell": "zhbsq", "fullSpell": "zonghebaoshuiqu", "extCode": "120110405000", "extName": "综合保税区" },
        { "code": "120110407", "name": "滨海新区建设管理委员会", "level": 3, "parentCode": "120110", "firstLetterSpell": "bhxqjsglwyh", "fullSpell": "binhaixinqujiansheguanliweiyuanhui", "extCode": "120110407000", "extName": "滨海新区建设管理委员会" },
        { "code": "120111", "name": "西青", "level": 2, "parentCode": "1201", "firstLetterSpell": "xq", "fullSpell": "xiqing", "extCode": "120111000000", "extName": "西青区" },
        { "code": "120111001", "name": "西营门", "level": 3, "parentCode": "120111", "firstLetterSpell": "xym", "fullSpell": "xiyingmen", "extCode": "120111001000", "extName": "西营门街道" },
        { "code": "120111002", "name": "李七庄", "level": 3, "parentCode": "120111", "firstLetterSpell": "lqz", "fullSpell": "liqizhuang", "extCode": "120111002000", "extName": "李七庄街道" },
        { "code": "120111005", "name": "津门湖", "level": 3, "parentCode": "120111", "firstLetterSpell": "jmh", "fullSpell": "jinmenhu", "extCode": "120111005000", "extName": "津门湖街道" },
        { "code": "120111100", "name": "中北", "level": 3, "parentCode": "120111", "firstLetterSpell": "zb", "fullSpell": "zhongbei", "extCode": "120111100000", "extName": "中北镇" },
        { "code": "120111101", "name": "杨柳青", "level": 3, "parentCode": "120111", "firstLetterSpell": "ylq", "fullSpell": "yangliuqing", "extCode": "120111101000", "extName": "杨柳青镇" },
        { "code": "120111102", "name": "辛口", "level": 3, "parentCode": "120111", "firstLetterSpell": "xk", "fullSpell": "xinkou", "extCode": "120111102000", "extName": "辛口镇" },
        { "code": "120111103", "name": "张家窝", "level": 3, "parentCode": "120111", "firstLetterSpell": "zjw", "fullSpell": "zhangjiawo", "extCode": "120111103000", "extName": "张家窝镇" },
        { "code": "120111104", "name": "精武", "level": 3, "parentCode": "120111", "firstLetterSpell": "jw", "fullSpell": "jingwu", "extCode": "120111104000", "extName": "精武镇" },
        { "code": "120111105", "name": "大寺", "level": 3, "parentCode": "120111", "firstLetterSpell": "ds", "fullSpell": "dasi", "extCode": "120111105000", "extName": "大寺镇" },
        { "code": "120111106", "name": "王稳庄", "level": 3, "parentCode": "120111", "firstLetterSpell": "wwz", "fullSpell": "wangwenzhuang", "extCode": "120111106000", "extName": "王稳庄镇" },
        { "code": "120111400", "name": "开发区西青微电子小区", "level": 3, "parentCode": "120111", "firstLetterSpell": "kfqxqwdzxq", "fullSpell": "kaifaquxiqingweidianzixiaoqu", "extCode": "120111400000", "extName": "开发区西青微电子小区" },
        { "code": "120111401", "name": "新技术产业园区", "level": 3, "parentCode": "120111", "firstLetterSpell": "xjscyyq", "fullSpell": "xinjishuchanyeyuanqu", "extCode": "120111401000", "extName": "新技术产业园区" },
        { "code": "120111450", "name": "西青区开发区", "level": 3, "parentCode": "120111", "firstLetterSpell": "xqqkfq", "fullSpell": "xiqingqukaifaqu", "extCode": "120111450000", "extName": "西青区开发区" },
        { "code": "120112", "name": "津南", "level": 2, "parentCode": "1201", "firstLetterSpell": "jn", "fullSpell": "jinnan", "extCode": "120112000000", "extName": "津南区" },
        { "code": "120112002", "name": "双林", "level": 3, "parentCode": "120112", "firstLetterSpell": "sl", "fullSpell": "shuanglin", "extCode": "120112002000", "extName": "双林街道" },
        { "code": "120112100", "name": "咸水沽", "level": 3, "parentCode": "120112", "firstLetterSpell": "xsg", "fullSpell": "xianshuigu", "extCode": "120112100000", "extName": "咸水沽镇" },
        { "code": "120112101", "name": "葛沽", "level": 3, "parentCode": "120112", "firstLetterSpell": "gg", "fullSpell": "gegu", "extCode": "120112101000", "extName": "葛沽镇" },
        { "code": "120112102", "name": "小站", "level": 3, "parentCode": "120112", "firstLetterSpell": "xz", "fullSpell": "xiaozhan", "extCode": "120112102000", "extName": "小站镇" },
        { "code": "120112103", "name": "双港", "level": 3, "parentCode": "120112", "firstLetterSpell": "sg", "fullSpell": "shuanggang", "extCode": "120112103000", "extName": "双港镇" },
        { "code": "120112104", "name": "辛庄", "level": 3, "parentCode": "120112", "firstLetterSpell": "xz", "fullSpell": "xinzhuang", "extCode": "120112104000", "extName": "辛庄镇" },
        { "code": "120112106", "name": "双桥河", "level": 3, "parentCode": "120112", "firstLetterSpell": "sqh", "fullSpell": "shuangqiaohe", "extCode": "120112106000", "extName": "双桥河镇" },
        { "code": "120112107", "name": "八里台", "level": 3, "parentCode": "120112", "firstLetterSpell": "blt", "fullSpell": "balitai", "extCode": "120112107000", "extName": "八里台镇" },
        { "code": "120112108", "name": "北闸口", "level": 3, "parentCode": "120112", "firstLetterSpell": "bzk", "fullSpell": "beizhakou", "extCode": "120112108000", "extName": "北闸口镇" },
        { "code": "120112401", "name": "铁路十八局", "level": 3, "parentCode": "120112", "firstLetterSpell": "tlsbj", "fullSpell": "tielushibaju", "extCode": "120112401000", "extName": "铁路十八局" },
        { "code": "120112404", "name": "津南开发区东区", "level": 3, "parentCode": "120112", "firstLetterSpell": "jnkfqdq", "fullSpell": "jinnankaifaqudongqu", "extCode": "120112404000", "extName": "津南开发区东区" },
        { "code": "120112405", "name": "津南开发区西区", "level": 3, "parentCode": "120112", "firstLetterSpell": "jnkfqxq", "fullSpell": "jinnankaifaquxiqu", "extCode": "120112405000", "extName": "津南开发区西区" },
        { "code": "120112407", "name": "海河教育园区", "level": 3, "parentCode": "120112", "firstLetterSpell": "hhjyyq", "fullSpell": "haihejiaoyuyuanqu", "extCode": "120112407000", "extName": "海河教育园区" },
        { "code": "120113", "name": "北辰", "level": 2, "parentCode": "1201", "firstLetterSpell": "bc", "fullSpell": "beichen", "extCode": "120113000000", "extName": "北辰区" },
        { "code": "120113001", "name": "果园新村", "level": 3, "parentCode": "120113", "firstLetterSpell": "gyxc", "fullSpell": "guoyuanxincun", "extCode": "120113001000", "extName": "果园新村街道" },
        { "code": "120113002", "name": "集贤里", "level": 3, "parentCode": "120113", "firstLetterSpell": "jxl", "fullSpell": "jixianli", "extCode": "120113002000", "extName": "集贤里街道" },
        { "code": "120113003", "name": "普东", "level": 3, "parentCode": "120113", "firstLetterSpell": "pd", "fullSpell": "pudong", "extCode": "120113003000", "extName": "普东街道" },
        { "code": "120113004", "name": "瑞景", "level": 3, "parentCode": "120113", "firstLetterSpell": "rj", "fullSpell": "ruijing", "extCode": "120113004000", "extName": "瑞景街道" },
        { "code": "120113005", "name": "佳荣里", "level": 3, "parentCode": "120113", "firstLetterSpell": "jrl", "fullSpell": "jiarongli", "extCode": "120113005000", "extName": "佳荣里街道" },
        { "code": "120113100", "name": "天穆", "level": 3, "parentCode": "120113", "firstLetterSpell": "tm", "fullSpell": "tianmu", "extCode": "120113100000", "extName": "天穆镇" },
        { "code": "120113101", "name": "北仓", "level": 3, "parentCode": "120113", "firstLetterSpell": "bc", "fullSpell": "beicang", "extCode": "120113101000", "extName": "北仓镇" },
        { "code": "120113102", "name": "双街", "level": 3, "parentCode": "120113", "firstLetterSpell": "sj", "fullSpell": "shuangjie", "extCode": "120113102000", "extName": "双街镇" },
        { "code": "120113103", "name": "双口", "level": 3, "parentCode": "120113", "firstLetterSpell": "sk", "fullSpell": "shuangkou", "extCode": "120113103000", "extName": "双口镇" },
        { "code": "120113104", "name": "青光", "level": 3, "parentCode": "120113", "firstLetterSpell": "qg", "fullSpell": "qingguang", "extCode": "120113104000", "extName": "青光镇" },
        { "code": "120113106", "name": "宜兴埠", "level": 3, "parentCode": "120113", "firstLetterSpell": "yxb", "fullSpell": "yixingbu", "extCode": "120113106000", "extName": "宜兴埠镇" },
        { "code": "120113107", "name": "小淀", "level": 3, "parentCode": "120113", "firstLetterSpell": "xd", "fullSpell": "xiaodian", "extCode": "120113107000", "extName": "小淀镇" },
        { "code": "120113108", "name": "大张庄", "level": 3, "parentCode": "120113", "firstLetterSpell": "dzz", "fullSpell": "dazhangzhuang", "extCode": "120113108000", "extName": "大张庄镇" },
        { "code": "120113111", "name": "西堤头", "level": 3, "parentCode": "120113", "firstLetterSpell": "xdt", "fullSpell": "xiditou", "extCode": "120113111000", "extName": "西堤头镇" },
        { "code": "120113400", "name": "科技园区北区", "level": 3, "parentCode": "120113", "firstLetterSpell": "kjyqbq", "fullSpell": "kejiyuanqubeiqu", "extCode": "120113400000", "extName": "科技园区北区" },
        { "code": "120113401", "name": "科技园区南区", "level": 3, "parentCode": "120113", "firstLetterSpell": "kjyqnq", "fullSpell": "kejiyuanqunanqu", "extCode": "120113401000", "extName": "科技园区南区" },
        { "code": "120113402", "name": "天津医药医疗器械工业园", "level": 3, "parentCode": "120113", "firstLetterSpell": "tjyyylqxgyy", "fullSpell": "tianjinyiyaoyiliaoqixiegongyeyuan", "extCode": "120113402000", "extName": "天津医药医疗器械工业园" },
        { "code": "120113403", "name": "天津陆路港物流装备产业园", "level": 3, "parentCode": "120113", "firstLetterSpell": "tjllgwlzbcyy", "fullSpell": "tianjinlulugangwuliuzhuangbeichanyeyuan", "extCode": "120113403000", "extName": "天津陆路港物流装备产业园" },
        { "code": "120113404", "name": "天津风电产业园", "level": 3, "parentCode": "120113", "firstLetterSpell": "tjfdcyy", "fullSpell": "tianjinfengdianchanyeyuan", "extCode": "120113404000", "extName": "天津风电产业园" },
        { "code": "120113501", "name": "曙光农场", "level": 3, "parentCode": "120113", "firstLetterSpell": "sgnc", "fullSpell": "shuguangnongchang", "extCode": "120113501000", "extName": "曙光农场" },
        { "code": "120114", "name": "武清", "level": 2, "parentCode": "1201", "firstLetterSpell": "wq", "fullSpell": "wuqing", "extCode": "120114000000", "extName": "武清区" },
        { "code": "120114001", "name": "杨村", "level": 3, "parentCode": "120114", "firstLetterSpell": "yc", "fullSpell": "yangcun", "extCode": "120114001000", "extName": "杨村街道" },
        { "code": "120114002", "name": "下朱庄", "level": 3, "parentCode": "120114", "firstLetterSpell": "xzz", "fullSpell": "xiazhuzhuang", "extCode": "120114002000", "extName": "下朱庄街道" },
        { "code": "120114003", "name": "东蒲洼", "level": 3, "parentCode": "120114", "firstLetterSpell": "dpw", "fullSpell": "dongpuwa", "extCode": "120114003000", "extName": "东蒲洼街道" },
        { "code": "120114004", "name": "黄庄", "level": 3, "parentCode": "120114", "firstLetterSpell": "hz", "fullSpell": "huangzhuang", "extCode": "120114004000", "extName": "黄庄街道" },
        { "code": "120114005", "name": "徐官屯", "level": 3, "parentCode": "120114", "firstLetterSpell": "xgt", "fullSpell": "xuguantun", "extCode": "120114005000", "extName": "徐官屯街道" },
        { "code": "120114006", "name": "运河西", "level": 3, "parentCode": "120114", "firstLetterSpell": "yhx", "fullSpell": "yunhexi", "extCode": "120114006000", "extName": "运河西街道" },
        { "code": "120114101", "name": "梅厂", "level": 3, "parentCode": "120114", "firstLetterSpell": "mc", "fullSpell": "meichang", "extCode": "120114101000", "extName": "梅厂镇" },
        { "code": "120114102", "name": "大碱厂", "level": 3, "parentCode": "120114", "firstLetterSpell": "djc", "fullSpell": "dajianchang", "extCode": "120114102000", "extName": "大碱厂镇" },
        { "code": "120114103", "name": "崔黄口", "level": 3, "parentCode": "120114", "firstLetterSpell": "chk", "fullSpell": "cuihuangkou", "extCode": "120114103000", "extName": "崔黄口镇" },
        { "code": "120114104", "name": "大良", "level": 3, "parentCode": "120114", "firstLetterSpell": "dl", "fullSpell": "daliang", "extCode": "120114104000", "extName": "大良镇" },
        { "code": "120114105", "name": "下伍旗", "level": 3, "parentCode": "120114", "firstLetterSpell": "xwq", "fullSpell": "xiawuqi", "extCode": "120114105000", "extName": "下伍旗镇" },
        { "code": "120114106", "name": "南蔡村", "level": 3, "parentCode": "120114", "firstLetterSpell": "ncc", "fullSpell": "nancaicun", "extCode": "120114106000", "extName": "南蔡村镇" },
        { "code": "120114107", "name": "大孟庄", "level": 3, "parentCode": "120114", "firstLetterSpell": "dmz", "fullSpell": "damengzhuang", "extCode": "120114107000", "extName": "大孟庄镇" },
        { "code": "120114108", "name": "泗村店", "level": 3, "parentCode": "120114", "firstLetterSpell": "scd", "fullSpell": "sicundian", "extCode": "120114108000", "extName": "泗村店镇" },
        { "code": "120114109", "name": "河西务", "level": 3, "parentCode": "120114", "firstLetterSpell": "hxw", "fullSpell": "hexiwu", "extCode": "120114109000", "extName": "河西务镇" },
        { "code": "120114110", "name": "城关", "level": 3, "parentCode": "120114", "firstLetterSpell": "cg", "fullSpell": "chengguan", "extCode": "120114110000", "extName": "城关镇" },
        { "code": "120114111", "name": "东马圈", "level": 3, "parentCode": "120114", "firstLetterSpell": "dmj", "fullSpell": "dongmajuan", "extCode": "120114111000", "extName": "东马圈镇" },
        { "code": "120114112", "name": "黄花店", "level": 3, "parentCode": "120114", "firstLetterSpell": "hhd", "fullSpell": "huanghuadian", "extCode": "120114112000", "extName": "黄花店镇" },
        { "code": "120114113", "name": "石各庄", "level": 3, "parentCode": "120114", "firstLetterSpell": "sgz", "fullSpell": "shigezhuang", "extCode": "120114113000", "extName": "石各庄镇" },
        { "code": "120114114", "name": "王庆坨", "level": 3, "parentCode": "120114", "firstLetterSpell": "wqt", "fullSpell": "wangqingtuo", "extCode": "120114114000", "extName": "王庆坨镇" },
        { "code": "120114115", "name": "汊沽港", "level": 3, "parentCode": "120114", "firstLetterSpell": "cgg", "fullSpell": "chagugang", "extCode": "120114115000", "extName": "汊沽港镇" },
        { "code": "120114116", "name": "河北屯", "level": 3, "parentCode": "120114", "firstLetterSpell": "hbt", "fullSpell": "hebeitun", "extCode": "120114116000", "extName": "河北屯镇" },
        { "code": "120114117", "name": "上马台", "level": 3, "parentCode": "120114", "firstLetterSpell": "smt", "fullSpell": "shangmatai", "extCode": "120114117000", "extName": "上马台镇" },
        { "code": "120114118", "name": "大王古庄", "level": 3, "parentCode": "120114", "firstLetterSpell": "dwgz", "fullSpell": "daiwangguzhuang", "extCode": "120114118000", "extName": "大王古庄镇" },
        { "code": "120114119", "name": "陈咀", "level": 3, "parentCode": "120114", "firstLetterSpell": "cj", "fullSpell": "chenju", "extCode": "120114119000", "extName": "陈咀镇" },
        { "code": "120114120", "name": "白古屯", "level": 3, "parentCode": "120114", "firstLetterSpell": "bgt", "fullSpell": "baigutun", "extCode": "120114120000", "extName": "白古屯镇" },
        { "code": "120114121", "name": "曹子里", "level": 3, "parentCode": "120114", "firstLetterSpell": "czl", "fullSpell": "caozili", "extCode": "120114121000", "extName": "曹子里镇" },
        { "code": "120114122", "name": "大黄堡", "level": 3, "parentCode": "120114", "firstLetterSpell": "dhb", "fullSpell": "dahuangbao", "extCode": "120114122000", "extName": "大黄堡镇" },
        { "code": "120114123", "name": "豆张庄", "level": 3, "parentCode": "120114", "firstLetterSpell": "dzz", "fullSpell": "douzhangzhuang", "extCode": "120114123000", "extName": "豆张庄镇" },
        { "code": "120114124", "name": "高村", "level": 3, "parentCode": "120114", "firstLetterSpell": "gc", "fullSpell": "gaocun", "extCode": "120114124000", "extName": "高村镇" },
        { "code": "120114400", "name": "天津开发区逸仙科学工业园", "level": 3, "parentCode": "120114", "firstLetterSpell": "tjkfqyxkxgyy", "fullSpell": "tianjinkaifaquyixiankexuegongyeyuan", "extCode": "120114400000", "extName": "天津开发区逸仙科学工业园" },
        { "code": "120114401", "name": "武清区开发区", "level": 3, "parentCode": "120114", "firstLetterSpell": "wqqkfq", "fullSpell": "wuqingqukaifaqu", "extCode": "120114401000", "extName": "武清区开发区" },
        { "code": "120114402", "name": "武清商务区", "level": 3, "parentCode": "120114", "firstLetterSpell": "wqswq", "fullSpell": "wuqingshangwuqu", "extCode": "120114402000", "extName": "武清商务区" },
        { "code": "120114403", "name": "武清京滨园", "level": 3, "parentCode": "120114", "firstLetterSpell": "wqjby", "fullSpell": "wuqingjingbinyuan", "extCode": "120114403000", "extName": "武清京滨园" },
        { "code": "120114405", "name": "武清汽车园", "level": 3, "parentCode": "120114", "firstLetterSpell": "wqqcy", "fullSpell": "wuqingqicheyuan", "extCode": "120114405000", "extName": "武清汽车园" },
        { "code": "120114406", "name": "武清科技谷", "level": 3, "parentCode": "120114", "firstLetterSpell": "wqkjg", "fullSpell": "wuqingkejigu", "extCode": "120114406000", "extName": "武清科技谷" },
        { "code": "120114500", "name": "武清农场", "level": 3, "parentCode": "120114", "firstLetterSpell": "wqnc", "fullSpell": "wuqingnongchang", "extCode": "120114500000", "extName": "武清农场" },
        { "code": "120115", "name": "宝坻", "level": 2, "parentCode": "1201", "firstLetterSpell": "bd", "fullSpell": "baodi", "extCode": "120115000000", "extName": "宝坻区" },
        { "code": "120115001", "name": "海滨", "level": 3, "parentCode": "120115", "firstLetterSpell": "hb", "fullSpell": "haibin", "extCode": "120115001000", "extName": "海滨街道" },
        { "code": "120115002", "name": "宝平", "level": 3, "parentCode": "120115", "firstLetterSpell": "bp", "fullSpell": "baoping", "extCode": "120115002000", "extName": "宝平街道" },
        { "code": "120115003", "name": "钰华", "level": 3, "parentCode": "120115", "firstLetterSpell": "yh", "fullSpell": "yuhua", "extCode": "120115003000", "extName": "钰华街道" },
        { "code": "120115004", "name": "周良", "level": 3, "parentCode": "120115", "firstLetterSpell": "zl", "fullSpell": "zhouliang", "extCode": "120115004000", "extName": "周良街道" },
        { "code": "120115007", "name": "朝霞", "level": 3, "parentCode": "120115", "firstLetterSpell": "zx", "fullSpell": "zhaoxia", "extCode": "120115007000", "extName": "朝霞街道" },
        { "code": "120115008", "name": "潮阳", "level": 3, "parentCode": "120115", "firstLetterSpell": "cy", "fullSpell": "chaoyang", "extCode": "120115008000", "extName": "潮阳街道" },
        { "code": "120115101", "name": "大口屯", "level": 3, "parentCode": "120115", "firstLetterSpell": "dkt", "fullSpell": "dakoutun", "extCode": "120115101000", "extName": "大口屯镇" },
        { "code": "120115103", "name": "王卜庄", "level": 3, "parentCode": "120115", "firstLetterSpell": "wbz", "fullSpell": "wangbuzhuang", "extCode": "120115103000", "extName": "王卜庄镇" },
        { "code": "120115104", "name": "方家庄", "level": 3, "parentCode": "120115", "firstLetterSpell": "fjz", "fullSpell": "fangjiazhuang", "extCode": "120115104000", "extName": "方家庄镇" },
        { "code": "120115105", "name": "林亭口", "level": 3, "parentCode": "120115", "firstLetterSpell": "ltk", "fullSpell": "lintingkou", "extCode": "120115105000", "extName": "林亭口镇" },
        { "code": "120115106", "name": "八门城", "level": 3, "parentCode": "120115", "firstLetterSpell": "bmc", "fullSpell": "bamencheng", "extCode": "120115106000", "extName": "八门城镇" },
        { "code": "120115107", "name": "大钟庄", "level": 3, "parentCode": "120115", "firstLetterSpell": "dzz", "fullSpell": "dazhongzhuang", "extCode": "120115107000", "extName": "大钟庄镇" },
        { "code": "120115108", "name": "新安", "level": 3, "parentCode": "120115", "firstLetterSpell": "xa", "fullSpell": "xinan", "extCode": "120115108000", "extName": "新安镇" },
        { "code": "120115111", "name": "霍各庄", "level": 3, "parentCode": "120115", "firstLetterSpell": "hgz", "fullSpell": "huogezhuang", "extCode": "120115111000", "extName": "霍各庄镇" },
        { "code": "120115113", "name": "新开口", "level": 3, "parentCode": "120115", "firstLetterSpell": "xkk", "fullSpell": "xinkaikou", "extCode": "120115113000", "extName": "新开口镇" },
        { "code": "120115115", "name": "大唐庄", "level": 3, "parentCode": "120115", "firstLetterSpell": "dtz", "fullSpell": "datangzhuang", "extCode": "120115115000", "extName": "大唐庄镇" },
        { "code": "120115119", "name": "牛道口", "level": 3, "parentCode": "120115", "firstLetterSpell": "ndk", "fullSpell": "niudaokou", "extCode": "120115119000", "extName": "牛道口镇" },
        { "code": "120115120", "name": "史各庄", "level": 3, "parentCode": "120115", "firstLetterSpell": "sgz", "fullSpell": "shigezhuang", "extCode": "120115120000", "extName": "史各庄镇" },
        { "code": "120115121", "name": "郝各庄", "level": 3, "parentCode": "120115", "firstLetterSpell": "hgz", "fullSpell": "haogezhuang", "extCode": "120115121000", "extName": "郝各庄镇" },
        { "code": "120115123", "name": "牛家牌", "level": 3, "parentCode": "120115", "firstLetterSpell": "njp", "fullSpell": "niujiapai", "extCode": "120115123000", "extName": "牛家牌镇" },
        { "code": "120115124", "name": "尔王庄", "level": 3, "parentCode": "120115", "firstLetterSpell": "ewz", "fullSpell": "erwangzhuang", "extCode": "120115124000", "extName": "尔王庄镇" },
        { "code": "120115125", "name": "黄庄", "level": 3, "parentCode": "120115", "firstLetterSpell": "hz", "fullSpell": "huangzhuang", "extCode": "120115125000", "extName": "黄庄镇" },
        { "code": "120115126", "name": "口东", "level": 3, "parentCode": "120115", "firstLetterSpell": "kd", "fullSpell": "koudong", "extCode": "120115126000", "extName": "口东镇" },
        { "code": "120115127", "name": "大白庄", "level": 3, "parentCode": "120115", "firstLetterSpell": "dbz", "fullSpell": "dabaizhuang", "extCode": "120115127000", "extName": "大白庄镇" },
        { "code": "120115453", "name": "天宝工业园区", "level": 3, "parentCode": "120115", "firstLetterSpell": "tbgyyq", "fullSpell": "tianbaogongyeyuanqu", "extCode": "120115453000", "extName": "天宝工业园区" },
        { "code": "120115454", "name": "九园工业园区", "level": 3, "parentCode": "120115", "firstLetterSpell": "jygyyq", "fullSpell": "jiuyuangongyeyuanqu", "extCode": "120115454000", "extName": "九园工业园区" },
        { "code": "120115455", "name": "京津新城", "level": 3, "parentCode": "120115", "firstLetterSpell": "jjxc", "fullSpell": "jingjinxincheng", "extCode": "120115455000", "extName": "京津新城" },
        { "code": "120115500", "name": "里自沽农场", "level": 3, "parentCode": "120115", "firstLetterSpell": "lzgnc", "fullSpell": "lizigunongchang", "extCode": "120115500000", "extName": "里自沽农场" },
        { "code": "120115502", "name": "大钟农场", "level": 3, "parentCode": "120115", "firstLetterSpell": "dznc", "fullSpell": "dazhongnongchang", "extCode": "120115502000", "extName": "大钟农场" },
        { "code": "120116", "name": "滨海新区", "level": 2, "parentCode": "1201", "firstLetterSpell": "bhxq", "fullSpell": "binhaixinqu", "extCode": "120116000000", "extName": "滨海新区" },
        { "code": "120116005", "name": "杭州道", "level": 3, "parentCode": "120116", "firstLetterSpell": "hzd", "fullSpell": "hangzhoudao", "extCode": "120116005000", "extName": "杭州道街道" },
        { "code": "120116006", "name": "新河", "level": 3, "parentCode": "120116", "firstLetterSpell": "xh", "fullSpell": "xinhe", "extCode": "120116006000", "extName": "新河街道" },
        { "code": "120116007", "name": "大沽", "level": 3, "parentCode": "120116", "firstLetterSpell": "dg", "fullSpell": "dagu", "extCode": "120116007000", "extName": "大沽街道" },
        { "code": "120116008", "name": "新北", "level": 3, "parentCode": "120116", "firstLetterSpell": "xb", "fullSpell": "xinbei", "extCode": "120116008000", "extName": "新北街道" },
        { "code": "120116009", "name": "北塘", "level": 3, "parentCode": "120116", "firstLetterSpell": "bt", "fullSpell": "beitang", "extCode": "120116009000", "extName": "北塘街道" },
        { "code": "120116011", "name": "胡家园", "level": 3, "parentCode": "120116", "firstLetterSpell": "hjy", "fullSpell": "hujiayuan", "extCode": "120116011000", "extName": "胡家园街道" },
        { "code": "120116012", "name": "泰达", "level": 3, "parentCode": "120116", "firstLetterSpell": "td", "fullSpell": "taida", "extCode": "120116012000", "extName": "泰达街道" },
        { "code": "120116021", "name": "汉沽", "level": 3, "parentCode": "120116", "firstLetterSpell": "hg", "fullSpell": "hangu", "extCode": "120116021000", "extName": "汉沽街道" },
        { "code": "120116022", "name": "寨上", "level": 3, "parentCode": "120116", "firstLetterSpell": "zs", "fullSpell": "zhaishang", "extCode": "120116022000", "extName": "寨上街道" },
        { "code": "120116033", "name": "古林", "level": 3, "parentCode": "120116", "firstLetterSpell": "gl", "fullSpell": "gulin", "extCode": "120116033000", "extName": "古林街道" },
        { "code": "120116034", "name": "海滨", "level": 3, "parentCode": "120116", "firstLetterSpell": "hb", "fullSpell": "haibin", "extCode": "120116034000", "extName": "海滨街道" },
        { "code": "120116036", "name": "塘沽", "level": 3, "parentCode": "120116", "firstLetterSpell": "tg", "fullSpell": "tanggu", "extCode": "120116036000", "extName": "塘沽街道" },
        { "code": "120116037", "name": "茶淀", "level": 3, "parentCode": "120116", "firstLetterSpell": "cd", "fullSpell": "chadian", "extCode": "120116037000", "extName": "茶淀街道" },
        { "code": "120116038", "name": "大港", "level": 3, "parentCode": "120116", "firstLetterSpell": "dg", "fullSpell": "dagang", "extCode": "120116038000", "extName": "大港街道" },
        { "code": "120116100", "name": "新城", "level": 3, "parentCode": "120116", "firstLetterSpell": "xc", "fullSpell": "xincheng", "extCode": "120116100000", "extName": "新城镇" },
        { "code": "120116102", "name": "杨家泊", "level": 3, "parentCode": "120116", "firstLetterSpell": "yjb", "fullSpell": "yangjiabo", "extCode": "120116102000", "extName": "杨家泊镇" },
        { "code": "120116105", "name": "太平", "level": 3, "parentCode": "120116", "firstLetterSpell": "tp", "fullSpell": "taiping", "extCode": "120116105000", "extName": "太平镇" },
        { "code": "120116106", "name": "小王庄", "level": 3, "parentCode": "120116", "firstLetterSpell": "xwz", "fullSpell": "xiaowangzhuang", "extCode": "120116106000", "extName": "小王庄镇" },
        { "code": "120116107", "name": "中塘", "level": 3, "parentCode": "120116", "firstLetterSpell": "zt", "fullSpell": "zhongtang", "extCode": "120116107000", "extName": "中塘镇" },
        { "code": "120116401", "name": "保税区", "level": 3, "parentCode": "120116", "firstLetterSpell": "bsq", "fullSpell": "baoshuiqu", "extCode": "120116401000", "extName": "保税区" },
        { "code": "120116402", "name": "天津滨海新区高新技术产业开发区", "level": 3, "parentCode": "120116", "firstLetterSpell": "tjbhxqgxjscykfq", "fullSpell": "tianjinbinhaixinqugaoxinjishuchanyekaifaqu", "extCode": "120116402000", "extName": "天津滨海新区高新技术产业开发区" },
        { "code": "120116403", "name": "东疆保税港区", "level": 3, "parentCode": "120116", "firstLetterSpell": "djbsgq", "fullSpell": "dongjiangbaoshuigangqu", "extCode": "120116403000", "extName": "东疆保税港区" },
        { "code": "120116405", "name": "临港经济区", "level": 3, "parentCode": "120116", "firstLetterSpell": "lgjjq", "fullSpell": "lingangjingjiqu", "extCode": "120116405000", "extName": "临港经济区" },
        { "code": "120116406", "name": "中新天津生态城", "level": 3, "parentCode": "120116", "firstLetterSpell": "zxtjstc", "fullSpell": "zhongxintianjinshengtaicheng", "extCode": "120116406000", "extName": "中新天津生态城" },
        { "code": "120116408", "name": "南港工业区", "level": 3, "parentCode": "120116", "firstLetterSpell": "nggyq", "fullSpell": "nanganggongyequ", "extCode": "120116408000", "extName": "南港工业区" },
        { "code": "120117", "name": "宁河", "level": 2, "parentCode": "1201", "firstLetterSpell": "nh", "fullSpell": "ninghe", "extCode": "120117000000", "extName": "宁河区" },
        { "code": "120117001", "name": "芦台", "level": 3, "parentCode": "120117", "firstLetterSpell": "lt", "fullSpell": "lutai", "extCode": "120117001000", "extName": "芦台街道" },
        { "code": "120117002", "name": "桥北", "level": 3, "parentCode": "120117", "firstLetterSpell": "qb", "fullSpell": "qiaobei", "extCode": "120117002000", "extName": "桥北街道" },
        { "code": "120117101", "name": "宁河镇", "level": 3, "parentCode": "120117", "firstLetterSpell": "nhz", "fullSpell": "ninghezhen", "extCode": "120117101000", "extName": "宁河镇" },
        { "code": "120117102", "name": "苗庄", "level": 3, "parentCode": "120117", "firstLetterSpell": "mz", "fullSpell": "miaozhuang", "extCode": "120117102000", "extName": "苗庄镇" },
        { "code": "120117103", "name": "丰台", "level": 3, "parentCode": "120117", "firstLetterSpell": "ft", "fullSpell": "fengtai", "extCode": "120117103000", "extName": "丰台镇" },
        { "code": "120117104", "name": "岳龙", "level": 3, "parentCode": "120117", "firstLetterSpell": "yl", "fullSpell": "yuelong", "extCode": "120117104000", "extName": "岳龙镇" },
        { "code": "120117105", "name": "板桥", "level": 3, "parentCode": "120117", "firstLetterSpell": "bq", "fullSpell": "banqiao", "extCode": "120117105000", "extName": "板桥镇" },
        { "code": "120117106", "name": "潘庄", "level": 3, "parentCode": "120117", "firstLetterSpell": "pz", "fullSpell": "panzhuang", "extCode": "120117106000", "extName": "潘庄镇" },
        { "code": "120117107", "name": "造甲城", "level": 3, "parentCode": "120117", "firstLetterSpell": "zjc", "fullSpell": "zaojiacheng", "extCode": "120117107000", "extName": "造甲城镇" },
        { "code": "120117108", "name": "七里海", "level": 3, "parentCode": "120117", "firstLetterSpell": "qlh", "fullSpell": "qilihai", "extCode": "120117108000", "extName": "七里海镇" },
        { "code": "120117109", "name": "大北涧沽", "level": 3, "parentCode": "120117", "firstLetterSpell": "dbjg", "fullSpell": "dabeijiangu", "extCode": "120117109000", "extName": "大北涧沽镇" },
        { "code": "120117110", "name": "东棘坨", "level": 3, "parentCode": "120117", "firstLetterSpell": "djt", "fullSpell": "dongjituo", "extCode": "120117110000", "extName": "东棘坨镇" },
        { "code": "120117111", "name": "俵口", "level": 3, "parentCode": "120117", "firstLetterSpell": "bk", "fullSpell": "biaokou", "extCode": "120117111000", "extName": "俵口镇" },
        { "code": "120117112", "name": "廉庄", "level": 3, "parentCode": "120117", "firstLetterSpell": "lz", "fullSpell": "lianzhuang", "extCode": "120117112000", "extName": "廉庄镇" },
        { "code": "120117113", "name": "北淮淀", "level": 3, "parentCode": "120117", "firstLetterSpell": "bhd", "fullSpell": "beihuaidian", "extCode": "120117113000", "extName": "北淮淀镇" },
        { "code": "120117400", "name": "宁河区贸易开发区", "level": 3, "parentCode": "120117", "firstLetterSpell": "nhqmykfq", "fullSpell": "ninghequmaoyikaifaqu", "extCode": "120117400000", "extName": "宁河区贸易开发区" },
        { "code": "120117401", "name": "宁河区经济开发区", "level": 3, "parentCode": "120117", "firstLetterSpell": "nhqjjkfq", "fullSpell": "ninghequjingjikaifaqu", "extCode": "120117401000", "extName": "宁河区经济开发区" },
        { "code": "120117451", "name": "宁河县现代产业园区", "level": 3, "parentCode": "120117", "firstLetterSpell": "nhxxdcyyq", "fullSpell": "ninghexianxiandaichanyeyuanqu", "extCode": "120117451000", "extName": "宁河县现代产业园区" },
        { "code": "120117452", "name": "天津潘庄工业区", "level": 3, "parentCode": "120117", "firstLetterSpell": "tjpzgyq", "fullSpell": "tianjinpanzhuanggongyequ", "extCode": "120117452000", "extName": "天津潘庄工业区" },
        { "code": "120117500", "name": "潘庄农场", "level": 3, "parentCode": "120117", "firstLetterSpell": "pznc", "fullSpell": "panzhuangnongchang", "extCode": "120117500000", "extName": "潘庄农场" },
        { "code": "120118", "name": "静海", "level": 2, "parentCode": "1201", "firstLetterSpell": "jh", "fullSpell": "jinghai", "extCode": "120118000000", "extName": "静海区" },
        { "code": "120118100", "name": "静海镇", "level": 3, "parentCode": "120118", "firstLetterSpell": "jhz", "fullSpell": "jinghaizhen", "extCode": "120118100000", "extName": "静海镇" },
        { "code": "120118101", "name": "唐官屯", "level": 3, "parentCode": "120118", "firstLetterSpell": "tgt", "fullSpell": "tangguantun", "extCode": "120118101000", "extName": "唐官屯镇" },
        { "code": "120118102", "name": "独流", "level": 3, "parentCode": "120118", "firstLetterSpell": "dl", "fullSpell": "duliu", "extCode": "120118102000", "extName": "独流镇" },
        { "code": "120118103", "name": "王口", "level": 3, "parentCode": "120118", "firstLetterSpell": "wk", "fullSpell": "wangkou", "extCode": "120118103000", "extName": "王口镇" },
        { "code": "120118104", "name": "台头", "level": 3, "parentCode": "120118", "firstLetterSpell": "tt", "fullSpell": "taitou", "extCode": "120118104000", "extName": "台头镇" },
        { "code": "120118105", "name": "子牙", "level": 3, "parentCode": "120118", "firstLetterSpell": "zy", "fullSpell": "ziya", "extCode": "120118105000", "extName": "子牙镇" },
        { "code": "120118106", "name": "陈官屯", "level": 3, "parentCode": "120118", "firstLetterSpell": "cgt", "fullSpell": "chenguantun", "extCode": "120118106000", "extName": "陈官屯镇" },
        { "code": "120118107", "name": "中旺", "level": 3, "parentCode": "120118", "firstLetterSpell": "zw", "fullSpell": "zhongwang", "extCode": "120118107000", "extName": "中旺镇" },
        { "code": "120118108", "name": "大邱庄", "level": 3, "parentCode": "120118", "firstLetterSpell": "dqz", "fullSpell": "daqiuzhuang", "extCode": "120118108000", "extName": "大邱庄镇" },
        { "code": "120118109", "name": "蔡公庄", "level": 3, "parentCode": "120118", "firstLetterSpell": "cgz", "fullSpell": "caigongzhuang", "extCode": "120118109000", "extName": "蔡公庄镇" },
        { "code": "120118110", "name": "梁头", "level": 3, "parentCode": "120118", "firstLetterSpell": "lt", "fullSpell": "liangtou", "extCode": "120118110000", "extName": "梁头镇" },
        { "code": "120118111", "name": "团泊", "level": 3, "parentCode": "120118", "firstLetterSpell": "tb", "fullSpell": "tuanbo", "extCode": "120118111000", "extName": "团泊镇" },
        { "code": "120118112", "name": "双塘", "level": 3, "parentCode": "120118", "firstLetterSpell": "st", "fullSpell": "shuangtang", "extCode": "120118112000", "extName": "双塘镇" },
        { "code": "120118113", "name": "大丰堆", "level": 3, "parentCode": "120118", "firstLetterSpell": "dfd", "fullSpell": "dafengdui", "extCode": "120118113000", "extName": "大丰堆镇" },
        { "code": "120118114", "name": "沿庄", "level": 3, "parentCode": "120118", "firstLetterSpell": "yz", "fullSpell": "yanzhuang", "extCode": "120118114000", "extName": "沿庄镇" },
        { "code": "120118115", "name": "西翟庄", "level": 3, "parentCode": "120118", "firstLetterSpell": "xdz", "fullSpell": "xidizhuang", "extCode": "120118115000", "extName": "西翟庄镇" },
        { "code": "120118200", "name": "良王庄", "level": 3, "parentCode": "120118", "firstLetterSpell": "lwz", "fullSpell": "liangwangzhuang", "extCode": "120118200000", "extName": "良王庄乡" },
        { "code": "120118201", "name": "杨成庄", "level": 3, "parentCode": "120118", "firstLetterSpell": "ycz", "fullSpell": "yangchengzhuang", "extCode": "120118201000", "extName": "杨成庄乡" },
        { "code": "120118400", "name": "天津静海经济开发区", "level": 3, "parentCode": "120118", "firstLetterSpell": "tjjhjjkfq", "fullSpell": "tianjinjinghaijingjikaifaqu", "extCode": "120118400000", "extName": "天津静海经济开发区" },
        { "code": "120118402", "name": "天津子牙工业园区", "level": 3, "parentCode": "120118", "firstLetterSpell": "tjzygyyq", "fullSpell": "tianjinziyagongyeyuanqu", "extCode": "120118402000", "extName": "天津子牙工业园区" },
        { "code": "120118403", "name": "团泊新城", "level": 3, "parentCode": "120118", "firstLetterSpell": "tbxc", "fullSpell": "tuanboxincheng", "extCode": "120118403000", "extName": "团泊新城" },
        { "code": "120119", "name": "蓟州", "level": 2, "parentCode": "1201", "firstLetterSpell": "jz", "fullSpell": "jizhou", "extCode": "120119000000", "extName": "蓟州区" },
        { "code": "120119001", "name": "文昌", "level": 3, "parentCode": "120119", "firstLetterSpell": "wc", "fullSpell": "wenchang", "extCode": "120119001000", "extName": "文昌街道" },
        { "code": "120119100", "name": "渔阳", "level": 3, "parentCode": "120119", "firstLetterSpell": "yy", "fullSpell": "yuyang", "extCode": "120119100000", "extName": "渔阳镇" },
        { "code": "120119101", "name": "洇溜", "level": 3, "parentCode": "120119", "firstLetterSpell": "yl", "fullSpell": "yinliu", "extCode": "120119101000", "extName": "洇溜镇" },
        { "code": "120119102", "name": "官庄", "level": 3, "parentCode": "120119", "firstLetterSpell": "gz", "fullSpell": "guanzhuang", "extCode": "120119102000", "extName": "官庄镇" },
        { "code": "120119103", "name": "马伸桥", "level": 3, "parentCode": "120119", "firstLetterSpell": "msq", "fullSpell": "mashenqiao", "extCode": "120119103000", "extName": "马伸桥镇" },
        { "code": "120119104", "name": "下营", "level": 3, "parentCode": "120119", "firstLetterSpell": "xy", "fullSpell": "xiaying", "extCode": "120119104000", "extName": "下营镇" },
        { "code": "120119105", "name": "邦均", "level": 3, "parentCode": "120119", "firstLetterSpell": "bj", "fullSpell": "bangjun", "extCode": "120119105000", "extName": "邦均镇" },
        { "code": "120119106", "name": "别山", "level": 3, "parentCode": "120119", "firstLetterSpell": "bs", "fullSpell": "bieshan", "extCode": "120119106000", "extName": "别山镇" },
        { "code": "120119108", "name": "尤古庄", "level": 3, "parentCode": "120119", "firstLetterSpell": "ygz", "fullSpell": "youguzhuang", "extCode": "120119108000", "extName": "尤古庄镇" },
        { "code": "120119109", "name": "上仓", "level": 3, "parentCode": "120119", "firstLetterSpell": "sc", "fullSpell": "shangcang", "extCode": "120119109000", "extName": "上仓镇" },
        { "code": "120119111", "name": "下仓", "level": 3, "parentCode": "120119", "firstLetterSpell": "xc", "fullSpell": "xiacang", "extCode": "120119111000", "extName": "下仓镇" },
        { "code": "120119112", "name": "罗庄子", "level": 3, "parentCode": "120119", "firstLetterSpell": "lzz", "fullSpell": "luozhuangzi", "extCode": "120119112000", "extName": "罗庄子镇" },
        { "code": "120119113", "name": "白涧", "level": 3, "parentCode": "120119", "firstLetterSpell": "bj", "fullSpell": "baijian", "extCode": "120119113000", "extName": "白涧镇" },
        { "code": "120119115", "name": "侯家营", "level": 3, "parentCode": "120119", "firstLetterSpell": "hjy", "fullSpell": "houjiaying", "extCode": "120119115000", "extName": "侯家营镇" },
        { "code": "120119116", "name": "桑梓", "level": 3, "parentCode": "120119", "firstLetterSpell": "sz", "fullSpell": "sangzi", "extCode": "120119116000", "extName": "桑梓镇" },
        { "code": "120119117", "name": "东施古", "level": 3, "parentCode": "120119", "firstLetterSpell": "dsg", "fullSpell": "dongshigu", "extCode": "120119117000", "extName": "东施古镇" },
        { "code": "120119118", "name": "下窝头", "level": 3, "parentCode": "120119", "firstLetterSpell": "xwt", "fullSpell": "xiawotou", "extCode": "120119118000", "extName": "下窝头镇" },
        { "code": "120119119", "name": "杨津庄", "level": 3, "parentCode": "120119", "firstLetterSpell": "yjz", "fullSpell": "yangjinzhuang", "extCode": "120119119000", "extName": "杨津庄镇" },
        { "code": "120119120", "name": "出头岭", "level": 3, "parentCode": "120119", "firstLetterSpell": "ctl", "fullSpell": "chutouling", "extCode": "120119120000", "extName": "出头岭镇" },
        { "code": "120119121", "name": "西龙虎峪", "level": 3, "parentCode": "120119", "firstLetterSpell": "xlhy", "fullSpell": "xilonghuyu", "extCode": "120119121000", "extName": "西龙虎峪镇" },
        { "code": "120119122", "name": "穿芳峪", "level": 3, "parentCode": "120119", "firstLetterSpell": "cfy", "fullSpell": "chuanfangyu", "extCode": "120119122000", "extName": "穿芳峪镇" },
        { "code": "120119123", "name": "东二营", "level": 3, "parentCode": "120119", "firstLetterSpell": "dey", "fullSpell": "dongerying", "extCode": "120119123000", "extName": "东二营镇" },
        { "code": "120119124", "name": "许家台", "level": 3, "parentCode": "120119", "firstLetterSpell": "xjt", "fullSpell": "xujiatai", "extCode": "120119124000", "extName": "许家台镇" },
        { "code": "120119125", "name": "礼明庄", "level": 3, "parentCode": "120119", "firstLetterSpell": "lmz", "fullSpell": "limingzhuang", "extCode": "120119125000", "extName": "礼明庄镇" },
        { "code": "120119126", "name": "东赵各庄", "level": 3, "parentCode": "120119", "firstLetterSpell": "dzgz", "fullSpell": "dongzhaogezhuang", "extCode": "120119126000", "extName": "东赵各庄镇" },
        { "code": "120119127", "name": "州河湾", "level": 3, "parentCode": "120119", "firstLetterSpell": "zhw", "fullSpell": "zhouhewan", "extCode": "120119127000", "extName": "州河湾镇" },
        { "code": "120119203", "name": "孙各庄", "level": 3, "parentCode": "120119", "firstLetterSpell": "sgz", "fullSpell": "sungezhuang", "extCode": "120119203000", "extName": "孙各庄满族乡" }
    ]
}