

import { PageResult } from "@framework/utils";

import { fetch } from "@root/app";
import { MerchantApi, ProductApi } from "@api";


export namespace OrderApi {

    export interface OrderBase {
        id?: string
        userId?: string
        /**
         * 收货人
         */
        deliveryName?: string;
        /**
         * 收货电话
         */
        deliveryPhone?: string;
        /**
         * 收货地址
         */
        deliveryAddress?: string;
        /**
         * 订单金额
         */
        orderPrice?: number;
        /**
         * 平台订单折扣金额 
         */
        platformDiscountPrice?: number
        /**
         * 商户订单折扣金额
         */
        merchantDiscountPrice?: number
        /**
         * 总折扣金额
         */
        discountPrice?: number
        paymentRecordList?: PaymentRecord[]
        refundRecordList?: PaymentRecord[]
    }

    export interface PlatformOrder extends OrderBase {
        /**
         * 关闭时间
         */
        closeOrderTime?: string;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 过期时间
         */
        expireTime?: string;
        /**
         * 支付金额
         */
        paidPrice?: number;
        /**
         * 支付时间
         */
        payTime?: string;
        /**
         * 退款金额
         */
        refundPrice?: number;
        /**
         * 订单状态，1-新建   2-支付成功  3-支付失败   4-撤销    5-交易完成    6-交易关闭  7-正在支付
         */
        status?: OrderStatus;
        merchantOrderList?: MerchantOrder[]
    }

    interface OrderExtra extends OrderBase {
        merchantId?: number
        merchantName?: string
        merchantCover?: string
        contactPhone?: string
        platformOrderId?: string
        merchantOrderId?: string
    }

    /**
     * 商户订单
     */
    export interface MerchantOrder extends OrderExtra {
        /**
         * 关闭订单时间
         */
        closeOrderTime?: string;
        /**
         * 优惠券批次id
         */
        couponBatchId?: string;
        /**
         * 优惠券券号
         */
        couponNo?: string;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 过期时间
         */
        expireTime?: string;
        /**
         * 订单金额
         */
        orderPrice?: number;
        /**
         * 
         */
        paidPrice?: number;
        /**
         * 支付时间
         */
        payTime?: string;
        /**
         * 状态
         */
        /**
         * 退款金额
         */
        refundPrice?: number;
        status?: OrderStatus;
        refundStatus?: RefundOrderStatus
        orderProductList?: OrderProduct[]
    }

    /**
     * 退订单
     */
    export interface RefundOrder extends OrderExtra {
        /**
         * 实际退款金额
         */
        actualRefundPrice?: number;
        /**
         * 订单商品id
         */
        orderProductId?: string;
        merchantOrderId?: string;
        /**
         * 数量
         */
        qty?: number;
        /**
         * 退款金额
         */
        refundPrice?: number;
        /**
         * 退款时间
         */
        refundTime?: string;
        /**
         * 购买人
         */
        refundUserName?: string;
        /**
         * 购买人联系电话
         */
        refundUserPhone?: string
        /**
         * 购买人收货地址
         */
        refundUserAddress?: string
        /**
         * 退款状态
         */
        status?: RefundOrderStatus;
        /**
         * 申请人
         */
        userId?: string;
        /**
         * 新增
         */
        reason?: string
        /**
         * 新增
         */
        image?: string
        rejectReason?: string
        /**
         * 退货商品列表
         */
        refundOrderList?: OrderProduct[]
    }

    /**
     * 订单商品
     */
    export interface OrderProduct {
        /**
         * 已退数量
         */
        refundProductQty?: number;
        /**
         * 可退数量
         */
        refundableQty?: number;
        /**
         * 标识
         */
        id?: string;
        /**
         * 商户优惠金额
         */
        merchantDiscountPrice?: string;
        /**
         * 商户id
         */
        merchantId?: number;
        /**
         * 商户订单号
         */
        merchantOrderId?: string;
        /**
         * 支付价格
         */
        payPrice?: number;
        /**
         * 平台优惠金额
         */
        platformDiscountPrice?: number;
        /**
         * 平台订单号
         */
        platformOrderId?: string;
        /**
         * 价格
         */
        orderPrice?: number;
        /**
         * 商品id
         */
        productId?: string;
        /**
         * 商品图片
         */
        productImage?: string;
        /**
         * 商品名称
         */
        productStoreName?: string;
        /**
         * 数量
         */
        qty?: number;
        /**
         * 退订单号
         */
        refundOrderId?: string;
        /**
         * 退货备注
         */
        remarks?: string;
        /**
         * 规格图片
         */
        skuImage?: string;
        /**
         * 商品规格id
         */
        skuNo?: string;
        /**
         * 规格
         */
        skuValue?: string;
        /**
         * 收货方式
         * @type {ProductApi.PickUpType}
         */
        pickUpType?: any;

        /**
         * 取货时间
         */
        pickedUpTime?: number;

        /**
         * 使用日期
         */
        creditedDate?: number;
        /**
         * 物流公司编号
         */
        expressCode?: string;
        /**
         * 物流单号
         */
        expressNumber?: string;
        /**
         * 状态
         */
        status?: number;
    }

    /**
     * 支付记录
     */
    export interface PaymentRecord {
        /**
         * 支付金额
         */
        amount?: number;
        /**
         * 交易时间
         */
        createTime?: number;
        /**
         * 描述
         */
        description?: number;
        /**
         * 标识
         */
        id?: string;
        /**
         * 订单号
         */
        orderId?: string;
        /**
         * 原交易记录
         */
        originPayId?: string;
        /**
         * 收款人id
         */
        payeeId?: string;
        /**
         * 收款人
         */
        payeeName?: string;
        /**
         * 交易号
         */
        payId?: string
        /**
         * 付款人id
         */
        payerId?: string;
        /**
         * 付款人
         */
        payerName?: string;
        /**
         * 支付信息
         */
        payInfo?: string;
        /**
         * 已退款金额
         */
        refundedAmount?: number;
        payType?: number
        /**
         * 状态，0-成功 1-正在支付 2-支付失败 3-等待撤销 4-等待退款 5-已撤销 6-已退款
         */
        status?: PaymentStatus;
        /**
         * 交易单号
         */
        transactionId?: string;
    }

    export enum OrderStatus {
        /**
         * 新建
         */
        New = 1,
        /**
         * 正在支付。
         */
        Paying = 2,
        /**
         * 已付款
         */
        Paid = 3,
        /**
         * 交易完成
         */
        Finished = 4,
        /**
         * 已关闭
         */
        Closed = 5
    }

    export enum RefundOrderStatus {
        /**
         * 等待商家确认
         */
        Pending = 1,
        /**
         * 待退款。
         */
        Refunding = 2,
        /**
         * 已退款
         */
        Refunded = 3,
        /**
         *  已撤销
         */
        Closed = 4,
        /**
         * 拒绝
         */
        Rejected = 5
    }

    export enum PaymentStatus {
        /**
         * 支付成功
         */
        Success = 0,
        /**
         * 正在支付
         */
        Paying = 1,
        /**
         * 支付失败
         */
        Failed = 2,
        /**
         * 等待撤销
         */
        WaitRevoke = 3,
        /**
         * 等待退款
         */
        WaitRefund = 4,
        /**
         * 已撤销
         */
        Revoked = 5,
        /**
         * 退款中
         */
        Refunding = 6,
        /**
         * 已退款
         */
        Refunded = 7
    }

    export enum ProductStatus {
        /**
         * 待付款（订单未撤销）/未支付（订单撤销后）|退订单：待确认
         */
        New = 1,
        /**
         * 已付款/待发货|退订单：等待用户发货
         */
        Paid = 2,
        /**
         * 已发货|退订单：用户已发货
         */
        Shipped = 3,
        /**
         * 交易完成（商品状态为‘已付款’（2），且交易状态为完成时）|退订单：商户已收货/已收货
         */
        Complete = 4,
        /**
         * 已撤销
         */
        Revoked = 5,
    }

    export function refund(platformOrderId?: string, orderProductId?: string, qty?: number, reason?: string) {
        let refundProductJson = JSON.stringify([{orderProductId, qty, reason: "管理人员发起退款"}])
        return fetch.post<RefundOrder>("/api/order/refund", { platformOrderId, refundProductJson });
    }

    export function getPlatformOrderDetail(orderId?: string) {
        return fetch.get<PlatformOrder>(`/api/order/platform/${orderId}`);
    }

    export function getMerchantOrderDetail(orderId?: string) {
        return fetch.get<MerchantOrder>(`/api/order/merchant/${orderId}`);
    }

    export function getRefundOrderDetail(orderId?: string) {
        return fetch.get<RefundOrder>(`/api/order/refund/${orderId}`);
    }

    /**
     * 商户同意退款。
     * 
     * @param refundOrderId 退订单号。 
     */
    export function agree(refundOrderId?: string, addressId?: string) {
        return fetch.patch<void>(`/api/order/refund/agree/${refundOrderId}`, {addressId});
    }

    /**
     * 商户拒绝退款。
     * 
     * @param refundOrderId 退订单号。 
     * @param rejectReason  拒绝原因
     */
    export function reject(refundOrderId?: string, rejectReason?: string) {
        return fetch.patch<void>(`/api/order/refund/refuse/${refundOrderId}`, { rejectReason });
    }

    /**
     * 确认退款。
     * @param refundOrderId 退订单号
     */
    export function confirmRefund(refundOrderId?: string, refundPrice?: number) {
        return fetch.post<void>(`/api/order/refund/${refundOrderId}`, {refundPrice});
    }

    /**
     * 查询退款记录状态。
     * @param paymentId 支付记录编号
     */
    export function queryRefundRecordStatus(paymentId?: string) {
        return fetch.post<void>(`/api/order/refund/query/${paymentId}`);
    }

    /**
     * 查询支付记录状态。
     * @param paymentId 支付记录编号
     */
    export function queryPaymentRecordStatus(paymentId?: string) {
        return fetch.post<void>(`/api/order/pay/query/${paymentId}`);
    }

    /**
     * 分页查询平台订单列表。
     * 
     * @param limit      分页大小
     * @param skip       分页位置
     * @param orderId    平台订单号
     * @param userPhone  用户电话
     * @param userName   用户姓名
     * @param createTime 下单时间
     * @param status     订单状态
     * @returns 商户订单列表。
     */
    export function listPlatformOrder(limit?: number, skip?: number, orderId?: string, userPhone?: string, userName?: string, createTime?: number, status?: OrderStatus) {
        return fetch.get<PageResult<PlatformOrder>>("/api/order/platform", {
            limit, skip, orderId, userPhone, userName, createTime, status
        });
    }

    /**
     * 分页查询商户订单列表。
     * 
     * @param limit      分页大小
     * @param skip       分页位置
     * @param orderId    商户订单号
     * @param createTime 下单时间
     * @param status     订单状态
     * @returns 商户订单列表。
     */
    export function listMerchantOrder(limit?: number, skip?: number,
            orderId?: string, platformOrderId?: string, createTime?: number, status?: OrderStatus) {
        return fetch.get<PageResult<MerchantOrder>>("/api/order/merchant", {
            limit, skip, orderId, platformOrderId, createTime, status
        });
    }


    /**
     * 分页查询商户订单列表。
     * 
     * @param limit      分页大小
     * @param skip       分页位置
     * @param orderId    商户订单号
     * @param refundDate 发起时间
     * @param status     订单状态
     * @returns 商户订单列表。
     */
    export function listRefundOrder(limit?: number, skip?: number, orderId?: string,
            platformOrderId?: string, merchantOrderId?: string, refundDate?: number, status?: RefundOrderStatus) {
        return fetch.get<PageResult<RefundOrder>>("/api/order/refund", { 
            limit, skip, orderId, platformOrderId, merchantOrderId, refundDate, status 
        });
    }

    /**
     * 商户确认取货
     */
    export function confirmPickUp(merchantOrderId?: string, orderProductIds?: string){
        return fetch.patch("/api/order/merchant/picked-up", {merchantOrderId, orderProductIds});
    }

    /**
     * 商户发货
     * 
     * @param orderProductIds 订单商品id（多个逗号分割）
     * @param expressCode 快递编号
     * @param expressNumber 快递单号
     */
    export function delivery(merchantOrderId?: string, orderProductIds?: string, expressCode?: string, expressNumber?: string) {
        return fetch.patch<void>("/api/order/product/delivery", { merchantOrderId, orderProductIds, expressCode, expressNumber });
    }

    /**
     * 商户取消发货
     * 
     * @param orderProductIds 订单商品id（多个逗号分割）
     */
    export function cancelDelivery(merchantOrderId?: string, orderProductIds?: string) {
        return fetch.patch<void>("/api/order/product/delivery/cancel", { merchantOrderId, orderProductIds });
    }

    /**
     * 对用户退回来的商品确认收货
     * @param orderProductId 退货商品id
     */
    export function confirmReceived(orderProductId?: string) {
        return fetch.patch<void>("/api/order/product/receipt", {orderProductRefundId: orderProductId});
    }
}