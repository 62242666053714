

import { QueryModel, QueryState } from "@framework/models";

import { TableView } from "./table-view";
import { PropsType } from "./base-component";
import { EditableTableProps } from "./editable-table";

type PickDataType<T> = T extends QueryModel<infer P> ? (P extends QueryState<infer Q> ? Q : P) : T;

export abstract class PaginationTableView<T extends QueryModel<any>> extends TableView<T> {
    
    #pageSizeList?: number[]

    constructor (props: PropsType<T>) {
        super(props);
    }

    set pageSizeList(pageSizeOptions: number[]) {
        this.#pageSizeList = pageSizeOptions;
    }

    renderTable(props: EditableTableProps<PickDataType<T>>): JSX.Element {
        const { model } = this.props;
        const size = this.state.limit == null ? 10 : this.state.limit;
        const total = this.state.total == null ? 0 : this.state.total;
        const skip = this.state.skip == null ? 0 : this.state.skip;
        const current = Math.floor(skip / size) + 1;
        props.pagination = {
            total: total,
            defaultPageSize: size,
            current: current,
            onChange: (page, pageSize) => {
                this.query(Object.assign({}, model.state.params, { limit: pageSize, skip: (page - 1) * pageSize }));
            }
        }
        return super.renderTable(props);
    }
}