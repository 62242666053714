
import { Converter } from "@framework/utils";
import { AppBase } from "@framework/app-base";

import Delta from "quill-delta";
import React, { CSSProperties } from "react";
import { InboxOutlined } from "@ant-design/icons";
import Quill, { QuillOptionsStatic } from "quill";
import { Button, Input, message, Modal, ModalProps, UploadFile } from "antd";

import { Decorator } from "./types";
import FileUploader from "./file-uploader";
import { DecoratorForm } from "./decorator-form";

class FilesConverter implements Converter<UploadFile<any>, UploadFile<any>> {
    convert(value?: UploadFile<any> | undefined, values?: Record<string, any> | undefined): UploadFile<any> | null {
        if (value == null) {
            return null;
        }
        let result: UploadFile<any> = { status: "done", name: 'temp.jpg', uid: "" }
        if (value.originFileObj instanceof File) {
            result.uid = value.uid;
            result.name = value.name;
            result.originFileObj = value.originFileObj;
            if (value.url == null) {
                result.url = URL.createObjectURL(value.originFileObj);
                result.status = "done";
            } else {
                result.url = value.thumbUrl;
            }
        } else {
            result = value;
        }
        return result;
    }
    convertBack(value?: UploadFile<any> | undefined, values?: Record<string, any> | undefined, target?: Record<string, any> | undefined): UploadFile<any> | null {
        if (value == null) {
            return null;
        }
        return value;
    }
}

const FileConverter = new FilesConverter();

export interface FileUploadResult {
    url: string
    thumbUrl: string
    originUrl: string
}

export interface QuillEditorProps extends QuillOptionsStatic {
    style?: CSSProperties
    className?: string
    value?: string
    onChange?: (value?: string) => void

    upload: (file: File) => Promise<FileUploadResult>

    [key: string]: any
}

const DefaultFonts = [
    "CourierNew",
    "FranklinGothicMedium",
    "GillSans",
    "LucidaSans",
    "SegoeUI",
    "TimesNewRoman",
    "TrebuchetMS",
    "SystemUI",
    "Arial",
    "Cambria",
    "Cursive",
    "Fantasy",
    "Georgia",
    "Impact",
    "Monospace",
    "SansSerif"
];

const DefaultModules = {
    table: true,
    history: {
        delay: 2000,
        maxStack: 500,
        userOnly: true
    },
    toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code", "code-block"],
        [{script: "super"}, {script: "sub"}],
        [{ size: ["small", false, "large", "huge", "12px", "14px", "16px", "18px", "24px", "32px"] }, { header: [1, 2, 3, 4, false, 5, 6] }, {font: DefaultFonts}, { color: [] }, { background: [] }],
        [{align: ""}, {align: "right"}, {align: "center"}, {align: "justify"}, {list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent: "+1"}],
        ["link", "image"],
        ["clean"]
    ],
};

interface QuillEditorState {
    editorExtra?: any
    editorType?: "image" | "link"
    showImageSelector?: boolean
    value?: string
    defaultValue?: string
    editor?: Quill

    [key: string]: any
}

export class QuillEditor extends React.Component<QuillEditorProps, QuillEditorState> {
    editorRef = React.createRef<any>();

    constructor(props: QuillEditorProps) {
        super(props);
        this.state = {};
    }
    
    componentDidMount(): void {
        this.initEditor();
    }

    #getEditorTitle(editorType: "image" | "link"): string {
        switch (editorType) {
            case "image":
                return "插入图片";
            case "link":
                return "输入链接地址";
        }
    }

    static getDerivedStateFromProps(nextProps: QuillEditorProps, prevState: QuillEditorState) {
        if (nextProps.value != prevState.propValue) {
            const { editor } = prevState;
            if (editor == null) {
                return null;
            }
            const delta = editor.clipboard.convert({ html: nextProps.value });
            const selection = editor.getSelection();
            editor.setContents(delta, "silent");
            if (selection != null) {
                editor.setSelection(selection.index, selection.length, "silent");
            }
            return { propValue: nextProps.value };
        }
        return null;
    }

    #getDecorator(editorType: "image" | "link", editorExtra?: string): Decorator[] {
        const labelCol = { span: 4 };
        if (editorType == "image") {
            return [{
                name: "image",
                label: "选择图片",
                labelCol: labelCol,
                element: (
                    <FileUploader
                        accept="image/*"
                        multiple={true}
                        listType="picture-card"
                        converter={FileConverter}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">点击此区域添加图片</p>
                        <p className="ant-upload-hint">建议图片大小不超过500k</p>
                    </FileUploader>
                )
            }]
        } else if (editorType == "link") {
            let placeholder = editorExtra == null ? "请输入链接地址https://" : `请为${editorExtra}设置链接地址`;
            return [{
                name: "link",
                label: "链接地址",
                labelCol: labelCol,
                element: (<Input placeholder={placeholder} />)
            }]
        } else {
            return [];
        }
    }

    private initEditor() {
        /*----自定义字体 ----*/
        const { value, onChange, style, className, defaultValue, ...restProps  } = this.props;
        //1.需先引入需要展示的字体样式  然后加入到字体白名单里
        const Font = Quill.import("formats/font");
        //将字体加入到白名单
        Font.whitelist = DefaultFonts;
        if (restProps.modules == null) {
            restProps.modules = Object.assign({}, DefaultModules);
        }
        Quill.register(Font, true);

        const SizeStyle = Quill.import('attributors/style/size');
        SizeStyle.whitelist = ['12px', '14px', '16px', '18px', '24px', '32px'];
        Quill.register(SizeStyle, true);

        const promise = restProps.modules?.formula ? import("katex") : Promise.resolve(null);
        promise.then((katexModule) => {
            if (katexModule != null) {
                (window as any).katex = katexModule.default
                import("../../../node_modules/katex/dist/katex.css");
            }
            if (restProps.theme == "bubble") {
                import("quill/dist/quill.bubble.css").then(() => import("@framework/styles/quill-editor-zh.global.less"));
            } else {
                import("quill/dist/quill.snow.css").then(() => import("@framework/styles/quill-editor-zh.global.less"));
            }

            const innerEditor = new Quill(this.editorRef.current, restProps);
            if (value != null || defaultValue != null) {
                const delta = innerEditor.clipboard.convert({ html: defaultValue == null ? value : defaultValue});
                innerEditor.setContents(delta, "silent");
            }
            let toolbar = innerEditor.getModule("toolbar");
            this.replaceHandlers(toolbar, innerEditor);
            innerEditor.on("text-change", (delta, oldDelta, source) => {
                const value = innerEditor.root.innerHTML;
                this.setState({ value: value });
                this.props.onChange && this.props.onChange(innerEditor.root.innerHTML);
            });
            this.setState({ editor: innerEditor })
        });
    }

    private replaceHandlers(toolbar: any, editor: Quill) {
        toolbar.handlers.link = (value: boolean) => {

            let range = editor.getSelection();
            if (range == null) {
                  return;
            }
            if (value) {
                if (range.length == 0) {
                    return;
                }
                let preview = editor.getText(range.index, range.length);
                if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf("mailto:") !== 0) {
                        preview = "mailto:" + preview;
                }
                this.#showModal("link", preview);
            } else {
                const format = editor.getFormat(range);
                editor.format("link", false);
            }
        }

        toolbar.handlers.image =(value: any) => this.#showModal("image");
    }

    #onInsertImage = (fileList: UploadFile<any>[]) => {
        const { editor } = this.state;
        if (editor == null || fileList == null || fileList.length == 0) {
            return;
        } 
        const promiseArray: Promise<FileUploadResult>[] = [];
        const selection = editor.getSelection(true);
        let insertPosition = selection == null ? editor.getLength() : selection.index;
        insertPosition = insertPosition >= 1 ? insertPosition - 1 : 0;
        
        let delta: Delta = new Delta().retain(insertPosition);

        for (let image of fileList) {
            delta.insert({ image: image.thumbUrl }, { width: "100%", height: "auto" });
            if (this.props.upload != null) {
                promiseArray.push(this.props.upload(image.originFileObj as File));
            }
        }
        editor.updateContents(delta, "user");

        this.setState({ showImageSelector: false });
        Promise.all(promiseArray).then(urls => {
            let replaceDelta = new Delta().retain(insertPosition).delete(delta.length() - insertPosition);
            for (let url of urls) {
                replaceDelta.insert( { image: url.originUrl }, { width: "100%", height: "auto" });
            }
            editor?.updateContents(replaceDelta, "user");
        }).catch(reason => {
            message.error(reason);
            let removeDelta = new Delta().retain(insertPosition).delete(delta.length() - insertPosition);
            editor?.updateContents(removeDelta);
        });
    }

    #showModal = (editorType: "image" | "link", editorExtra?: string) => {
        const updater: Record<string, any> = {};
        const onSubmit = (values: Record<string, any>) => {
            if (editorType == "image") {
                this.#onInsertImage(values.image as UploadFile<any>[]);
            } else if (editorType == "link") {
                this.state.editor?.format("link", values.link, "user");
            }
            updater.closeModal();
        };
        updater.closeModal = AppBase.Instance?.showModal({
            footer: false,
            closable: true,
            destroyOnClose: true,
            open: true,
            title: this.#getEditorTitle(editorType),
            children: (
                <DecoratorForm
                    onSubmit={onSubmit}
                    className="editor-form"
                    decorators={this.#getDecorator(editorType, editorExtra)}
                >
                    <div className="operation">
                        <Button htmlType="submit" type="primary">确定</Button>
                    </div>
                </DecoratorForm>
            )
        });
    }

    render() {
        const { style, className } = this.props;
        return (
            <div style={style} className={`quill-editor ${className == null ? "" : className}`}>
                <div id="__quill_editor__" style={{border: "none"}} ref={this.editorRef}></div>
            </div>
        );
    }
}