

import { fetch } from "@root/app";
import { PageResult } from "@root/framework/utils";


export namespace ArticleApi {

    export enum ArticleCateStatus {
        Enabled = 1,
        Disabled = 2
    }

    /**
     * 文章分类
     */
    export interface ArticleCate {
        /**
         * 分类编号
         */
        code?: string
        /**
         * 所属上级分类
         */
        parentCode?: string
        /**
         * 分类名称
         */
        name?: string
        /**
         * 分类图标
         */
        icon?: string
        /**
         * 分类状态 1-启用 2-禁用
         */
        status?: ArticleCateStatus
        /**
         * 推荐序号
         */
        sort?: number
        children?: ArticleCate[]
        /**
         * 创建时间
         */
        createTime?: number
        /**
         * 创建人
         */
        createUserName?: string
        /**
         * 最后修改时间
         */
        lastUpdateTime?: number
        /**
         * 最后修改人
         */
        lastUpdateUserName?: string
    }

    export enum ArticleStatus {
        /**
         * 新建
         */
        New = 1,
        /**
         * 审核中
         */
        UnderReview = 2,
        /**
         * 已驳回
         */
        Rejected = 3,
        /**
         * 已发布
         */
        Published = 4,
        /**
         * 已删除
         */
        Deleted = 5
    }

    /**
     * 文章
     */
    export interface Article {
        id?: string
        /**
         * 文章分类编号
         */
        cateCode?: string
        /**
         * 分类名称
         */
        cateName?: string
        /**
         * 
         */
        title?: string
        /**
         * 文章封面图
         */
        cover?: string
        /**
         * 文章轮播图 （至少一张）
         */
        sliderImage?: string
        /**
         * 驳回原因
         */
        rejectReason?: string
        /**
         * 文章内容
         */
        content?: string
        /**
         * 创建时间
         */
        createTime?: number
        /**
         * 创建人
         */
        createUserName?: string
        /**
         * 最后修改时间
         */
        lastUpdateTime?: number
        /**
         * 最后修改人
         */
        lastUpdateUserName?: string
        /**
         * 发布时间
         */
        publishTime?: number
        /**
         * 推荐序号
         */
        sort?: number
        /**
         * 审核人
         */
        auditorName?: string
        /**
         * 状态 1-新建 2-待审核 3-驳回 4-已发布 5-已删除
         */
        status?: number
    }

    /**
     * 新增文章分类。
     * 
     * @param name       分类名称
     * @param icon       分类图标
     * @param status     分类状态
     * @param sort       推荐序号
     * @param parentCode 上级分类编号 
     */
    export function addArticleCate(name?: string, icon?: string, status?: ArticleCateStatus, sort?: number, parentCode?: string) {
        return fetch.post<ArticleCate>("/api/article-cate", { name, status, sort, parentCode, icon });
    }

    /**
     * 更改分类状态
     * 
     * @param code   分类编号
     * @param status 新的分类状态
     */
    export function changeArticleCateStatus(code?: string, status?: ArticleCateStatus) {
        return fetch.patch<void>(`/api/article-cate/status/${code}`, { status });
    }

    /**
     * 查询文章分类列表。
     * 
     * @param name   按分类名称查询
     * @param status 按分类状态查询
     */
    export function listArticleCate(name?: string, status?: ArticleCateStatus) {
        return fetch.get<ArticleCate[]>("/api/article-cate", { name, status });
    }

    /**
     * 修改文章分类。
     * 
     * @param code 分类编号。
     * @param name 新分类名称。
     * @param icon 新分类图标。
     * @param sort 新推荐序号。
     */
    export function updateArticleCate(code?: string, name?: string, icon?: string, sort?: number) {
        return fetch.put<void>(`/api/article-cate/${code}`, { name, icon, sort });
    }
    /**
     * 新增文章。
     * 
     * @param title       文章标题
     * @param cateCode    文章分类
     * @param cover       文章封面图
     * @param sliderImage 轮播图
     * @param content     文章内容
     */
    export function addArticle(title?: string, cateCode?: string, cover?: string, sliderImage?: string, content?: string, sort?: number) {
        return fetch.post<Article>("/api/article", { title, cover, cateCode, sliderImage, content, sort });
    }

    /**
     * 允许发布指定文章。
     * 
     * @param id 文章 id
     */
    export function audit(id?: string) {
        return fetch.patch<void>(`/api/article/published/${id}`);
    }

    /**
     * 标记删除指定文章。
     * 
     * @param id 文章 id
     */
    export function del(id?: string) {
        return fetch.patch<void>(`/api/article/delete/${id}`);
    }

    /**
     * 获取文章详情。
     * 
     * @param id 文章 id
     */
    export function detail(id?: string) {
        return fetch.get<Article>(`/api/article/${id}`);
    }

    /**
     * 查询文章列表。
     * 
     * @param title  按标题查询
     * @param status 按文章状态查询
     */
    export function listArticle(limit?: number, skip?: number, title?: string, status?: ArticleStatus, cateCode?: string) {
        return fetch.get<PageResult<Article>>("/api/article", { limit, skip, title, status, cateCode });
    }

    /**
     * 发布文章。
     * 
     * @param id 文章 id
     */
    export function publish(id?: string) {
        return fetch.patch<void>(`/api/article/submit/${id}`);
    }

    /**
     * 允许发布指定文章。
     * 
     * @param id           文章 id
     * @param rejectReason 驳回原因
     */
    export function reject(id?: string, rejectReason?: string) {
        return fetch.patch(`/api/article/rejection/${id}`, { rejectReason });
    }

    /**
     * 修改文章信息。
     * 
     * @param id          文章id
     * @param title       文章标题
     * @param cateCode    文章分类
     * @param cover       文章封面图
     * @param content     文章内容
     * @param sliderImage 文章轮播图
     */
    export function update(id?: string, title?: string, cateCode?: string, cover?: string, content?: string, sliderImage?: string, sort?: number) {
        return fetch.put<void>(`/api/article/${id}`, { title, cateCode, cover, content, sliderImage, sort });
    }
}