

import { BaseModel, QueryModel, QueryState } from "@framework/models";
import { Converter, DEFAULT_IDS_CONVERTER, PageResult, toTree } from "@framework/utils";
import { WeekDayRangePicker, WeekDayRangePickerProps, TableColumn, CellProps, TableField, Decorator, SelectColumn, TreeQuerySelect, FileUploader, QuillEditor, GeoSelector, PermissionButton } from "@framework/component";

import { EditOutlined, InboxOutlined, MinusOutlined } from "@ant-design/icons";
import { Button, Cascader, DatePicker, Divider, Input, Modal, Select, InputNumber } from "antd";

import areas from "@root/area";
import { FileUploadApi, MerchantApi, MerchantCateApi } from "@api";
import { ImageColumn, LinkColumn, TagColumn, TimestampColumn } from "@root/component";
import { FilesConverter, StringDateRangeConv, StringWeekRangeConv } from "@root/converter";

import { getTagItem, InvoicePartyMap, MerchantStatusMap, MerchantTagMap } from "./tag-map";
import { PermissionCode } from "@root/permission";


import InvoiceParty = MerchantApi.InvoiceParty;

type Merchant = MerchantApi.Merchant;
type OpenTime = MerchantApi.OpenTime;
type Reservation = MerchantApi.Reservation;
type PreferentialPolicy = MerchantApi.PreferentialPolicy;

interface AreaObject {
    code: string
    name: string
    extName?: string
    level: number
    firstLetterSpell: string
    fullSpell: string
    extCode: string
    parentCode?: string
    children?: AreaObject[]
}

const areaOptions = toTree(areas.tianjin as AreaObject[], "code", "parentCode", "children");


const AreaConverter = {

    convertBack(value?: string[] | undefined, values?: Record<string, any> | undefined, target?: Record<string, any> | undefined): null | undefined {
        if (target != null) {
            delete target.areas;
            if (value != null) {
                target.area = value.length > 0 ? value[0] : undefined;
                target.street = value.length > 1 ? value[1] : undefined;
            }
        }
        return undefined;
    }
}

const LngLatConverter = {
    convertBack(value?: AMap.POI, values?: Record<string, any>, target?: Record<string, any>) {
        if (target != null) {
            target.address = value?.adname;
            target.longitude = value?.location.lng;
            target.latitude = value?.location.lat;
        }
    }
}

export interface MerchantState extends QueryState<Merchant> {
    showMap?: boolean
}

const keyOfId = "code";
const keyOfChildren = "children";
const keyOfParentId = "parentCode";
const keyOfValue = "name";

export class MerchantModel extends QueryModel<MerchantState> {

    constructor() {
        super();
    }

    saveOrUpdate = async (merchant: Merchant) => {
        const item = merchant as Required<Merchant>;
        if (this.state.editingItem?.id == null) {
            item.status = 2;
            return MerchantApi.add(item);
        } else {
            return MerchantApi.update(this.state.editingItem.id, item);
        }
    }

    changeStatus = (index: number, status: MerchantApi.Status) => {
        if (this.state.data != null) {
            let array: Merchant[] = this.state.data;
            MerchantApi.changeStatus(array[index].id, status).then(() => {
                array[index].status = status;
                this.updateState({data: array})
            });
        }
    }

    getEditorDecorators(editingItem?: Merchant): Decorator[] {
        const location: any = {};
        if (editingItem?.latitude != null && editingItem.longitude != null) {
            location.location = { lng: editingItem.longitude, lat: editingItem.latitude };
        }
        location.address = editingItem?.address;
        let decorators: Decorator[] = [{
            name: "name",
            label: "商户名称",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: <Input placeholder="输入商户名称" />,
            rules: [{ type: "string", required: true, min: 2, max: 50, message: "请填写商户名称（2-50字）" }],
            initialValue: editingItem?.name
        }, {
            name: "lnglat",
            label: "经纬度坐标",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (<GeoSelector name="选择商户地理位置" />),
            rules: [{ required: true, type: "object", message: "请选择商户地理位置" }],
            converter: LngLatConverter,
            initialValue: location
        }, {
            name: "areas",
            label: "所属街道",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (
                <Cascader
                    options={areaOptions}
                    placeholder="选择商户所属街道"
                    fieldNames={{ label: "extName", value: "extName", children: "children" }}
                />
            ),
            dependencies: ["lnglat"],
            updateProps: (_, values) => {
                const lnglat = values[0];
                if (lnglat?.adname != null) {
                    return { initialValue: [lnglat.adname, lnglat.street] }
                }
            },
            converter: AreaConverter as Converter<string | undefined, string[]>,
            rules: [{ type: "array", required: true, message: "请选择商户所在区(县)/街道" }],
            initialValue: editingItem?.area && editingItem.street ? [editingItem?.area, editingItem?.street] : undefined
        }, {
            name: "address",
            label: "详细地址",
            labelCol: { span: 5 },
            dependencies: ["lnglat"],
            className: "editor-form-item-2",
            element: (<Input placeholder="填写商户详细地址 '楼栋-门牌号'" />),
            rules: [{ message: "请选择商户所在区(县)/街道" }],
            updateProps: (_, values) => {
                const lnglat = values[0];
                if (lnglat?.address != null) {
                    return { initialValue: lnglat.address };
                }
            },
            initialValue: editingItem?.address
        }, {
            name: "cateCode",
            label: "商户分类",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (
                <TreeQuerySelect
                    allowClear={true}
                    keyOfValue={keyOfId}
                    keyOfLabel={keyOfValue}
                    placeholder="选择商户分类"
                    keyOfChildren={keyOfChildren}
                    keyOfParentId={keyOfParentId}
                    fetchItems={(value?: string) => MerchantCateApi.listMerchantCate(value).then((list) => toTree(list, keyOfId, keyOfParentId, keyOfChildren))}
                />
            ),
            rules: [{ required: true, message: "请选择商户分类"}],
            initialValue: editingItem?.cateCode
        }, {
            name: "invoiceParty",
            label: "开票方",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (
                <Select placeholder="请选择开票方">
                    {Object.keys(InvoicePartyMap).map(key => (
                        <Select.Option key={key} value={Number(key)}>{getTagItem(key, InvoicePartyMap).name}</Select.Option>
                    ))}
                </Select>
            ),
            rules: [{ required: true, message: "请选择开票方" }],
            initialValue: editingItem?.invoiceParty == null ? InvoiceParty.Platform : editingItem.invoiceParty
        }, {
            name: "cover",
            label: "封面图片",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (
                <FileUploader
                    maxCount={1}
                    accept="image/*"
                    listType="picture"
                    converter={FilesConverter}
                    customRequest={FileUploadApi.requestForUploader}
                >
                    <p className="ant-upload-drag-icon" style={{display:"inline"}}>
                        <InboxOutlined height="100%" style={{fontSize:32}} />
                        <span className="ant-upload-text" style={{display:"inline-block"}}>点击此区域上传图片</span>
                        <span className="ant-upload-hint" style={{display:"inline-block"}}>建议图片尺寸700*400不超过500k</span>
                    </p>
                </FileUploader>
            ),
            rules: [{required: true, message: "请选择一张图片作为商户封面图片"}],
            initialValue: editingItem?.cover,
            converter: DEFAULT_IDS_CONVERTER
        }, {
            name: "headerImage",
            label: "背景图片",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (
                <FileUploader
                    maxCount={1}
                    accept="image/*"
                    listType="picture"
                    converter={FilesConverter}
                    customRequest={FileUploadApi.requestForUploader}
                >
                    <p className="ant-upload-drag-icon" style={{display:"inline"}}>
                        <InboxOutlined height="100%" style={{fontSize:32}} />
                        <span className="ant-upload-text" style={{display:"inline-block"}}>点击此区域上传图片</span>
                        <span className="ant-upload-hint" style={{display:"inline-block"}}>建议图片尺寸700*400不超过500k</span>
                    </p>
                </FileUploader>
            ),
            rules: [{ required: true, message: "请选择一张图片作为商户背景图"}],
            initialValue: editingItem?.headerImage,
            converter: DEFAULT_IDS_CONVERTER
        }, {
            name: "contact",
            label: "联系人",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (<Input placeholder="输入商户联系人姓名" />),
            rules: [{ required: true, message: "请填写联系人"}],
            initialValue: editingItem?.contact
        }, {
            name: "phone",
            label: "联系电话",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (<Input placeholder="输入商户联系电话" maxLength={15} />),
            rules: [{type: "regexp", required: true, message: "填写的联系电话无效"}],
            initialValue: editingItem?.phone
        }, {
            name: "tags",
            label: "标签",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (
                <Select placeholder="请选择商户标签，可多选" mode="multiple" >
                    {Object.keys(MerchantTagMap).map(key => (
                        <Select.Option key={key} value={key}>{getTagItem(key, MerchantTagMap).name}</Select.Option>
                    ))}
                </Select>
            ),
            converter: DEFAULT_IDS_CONVERTER,
            initialValue: editingItem?.tags
        }, {
            name: "score",
            label: "评分",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (<InputNumber placeholder="输入商户评分1-5" min={1} max={5}/>),
            rules: [{type: "number", message: "填写的评分无效"}],
            initialValue: editingItem?.score
        }, {
            name: "sort",
            label: "排序",
            labelCol: { span: 5 },
            className: "editor-form-item-2",
            element: (<InputNumber placeholder="输入商户排序" min={1}/>),
            initialValue: editingItem?.sort
        }];
        return decorators;
    }

    getQueryDecorators(): Decorator[] {
        return [{
            name: "name",
            label: "商户名称",
            className: "query-form-item",
            element: <Input placeholder="按商户名称查询" />,
            labelCol: { span: 5 },
        }, {
            name: "contact",
            label: "联系人",
            className: "query-form-item",
            element: <Input placeholder="按商户联系人查询" />,
            labelCol: { span: 5 }
        }, {
            name: "phone",
            label: "联系电话",
            className: "query-form-item",
            element: <Input placeholder="按商户联系电话查询" />,
            labelCol: { span: 5 }
        }, {
            name: "cateCode",
            label: "商户分类",
            className: "query-form-item",
            element: (
                <TreeQuerySelect 
                    keyOfValue="code"
                    keyOfLabel="name"
                    allowClear={true}
                    keyOfChildren="children"
                    placeholder="按商户分类查询"
                    keyOfParentId="parentCode"
                    fetchItems={(value? :string) => MerchantCateApi.listMerchantCate(value)}
                />
            ),
            labelCol: { span: 5 }
        }, {
            name: "areas",
            label: "所属街道",
            className: "query-form-item",
            element: (
                <Cascader
                    options={areaOptions}
                    placeholder="按商户所属街道查询"
                    fieldNames={{ label: "extName", value: "extName", children: "children" }}
                />
            ),
            labelCol: { span: 5 },
            converter: AreaConverter as Converter<string | undefined, string[]>
        }, {
            name: "status",
            label: "状态",
            className: "query-form-item",
            element: (
                <Select allowClear={true} placeholder="按商户状态查询">
                    {Object.keys(MerchantStatusMap).map(key => {
                        return <Select.Option key={key} value={key}>{getTagItem(key, MerchantStatusMap).name}</Select.Option>;
                    })}
                </Select>
            ),
            labelCol: { span: 5 }
        }];
    }

    getTableColumns(): TableColumn<Merchant>[] {
        return [
            { dataIndex: "id", title: "ID" },
            new LinkColumn("商户名称", "/app/merchant/%(id)s", "name", { width: 100 }),
            new ImageColumn("封面图片", "cover"),
            { dataIndex: "cateName", title: "商户分类" },
            { title: "商户地址", stringFormat: "%(area)s%(street)s%(address)s" }, 
            new TagColumn(InvoicePartyMap, "开票方", "invoiceParty", { width: 60 }),
            { dataIndex: "sort", title: "商户排序"},
            { dataIndex: "contact", title: "联系人", width: 90 },
            { dataIndex: "phone", title: "联系电话" },
            new TimestampColumn("最后修改时间", "lastUpdateTime", { width: 120 }),
            { dataIndex: "lastUpdateUserName", title: "最后修改人" },
            new TagColumn(MerchantStatusMap, "状态", "status", { width: 60 }),
            {
                key: "operation",
                title: "操作",
                render: (value, record, index) => {
                    let nextStatus: MerchantApi.Status = record.status == MerchantApi.Status.Disabled ? MerchantApi.Status.Closed : MerchantApi.Status.Disabled;
                    let nextName = nextStatus == MerchantApi.Status.Disabled ? "禁用" : "恢复";
                    let key = 1;
                    const operations = [];
                    if (record.status == MerchantApi.Status.Opened) {
                        operations.push(
                            <PermissionButton
                                key={key++}
                                type="link"
                                size="small"
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.MERCHANT_DETAIL_MANAGE}
                                onClick={() => this.changeStatus(index, MerchantApi.Status.Closed)}
                            >
                                休息
                            </PermissionButton>
                        )
                    } else if (record.status == MerchantApi.Status.Closed) {
                        operations.push(
                            <PermissionButton
                                key={key++}
                                type="link"
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.MERCHANT_DETAIL_MANAGE}
                                size="small" onClick={() => this.changeStatus(index, MerchantApi.Status.Opened)}>开始营业</PermissionButton>
                        );
                    }
                    operations.push(
                        <PermissionButton
                            key={key++}
                            type="link"
                            size="small"
                            hideWhenNoPermission={true}
                            permissionCode={PermissionCode.MERCHANT_MANAGE}
                            onClick={() => this.showEditor(record)}>修改</PermissionButton>
                    );
                    operations.push(
                        <PermissionButton
                            key={key++}
                            type="link"
                            size="small"
                            hideWhenNoPermission={true}
                            permissionCode={PermissionCode.MERCHANT_MANAGE}
                            onClick={() => this.changeStatus(index, nextStatus)} >{nextName}</PermissionButton>
                    );
                    return operations;
                }
            }
        ];
    }
    
    public queryOverride(params?: any): Promise<PageResult<MerchantApi.Merchant>> {
        return MerchantApi.listMerchant(
            params?.limit, params?.skip, params?.name, params?.contact,
            params?.phone, params?.area, params?.street, params?.cateCode, params?.status
        );
    }

    protected showEditor = (editingItem?: Merchant) => {
        this.updateState({ showEditor: true, editingItem: editingItem });
    }
}

export interface MerchantDetail {
    /**
     * 商户介绍
     */
    content?: string
    /**
     * 开放时段列表
     */
     openTime?: OpenTime[]
     /**
      * 预约渠道列表
      */
     reservation?: Reservation[]
     /**
      * 优惠政策列表
      */
     preferentialPolicy?: PreferentialPolicy[]
     /**
      * 热线电话
      */
     supportPhone?: string[]
     /**
      * 重要公告
      */
     notice?: string
    /**
     * 商户资质
     */
    qualification?: string
}

interface DetailState extends Merchant {

    editorType?: EditorType

    editingIndex?: number

    showModal?: boolean

    merchantDetail?: MerchantDetail
}

export enum EditorType {
    OpenTime = 1,
    Reservation = 2,
    PreferentialPolicy = 3,
    SupportPhone = 4,
    Notice = 5,
    Info = 6,
    Content = 7,
    Qualification = 8
}

export const TITLE_MAP: Record<EditorType, string> = {
    1: "开放时间",
    2: "预约渠道",
    3: "优待政策",
    4: "热线电话",
    5: "重要公告",
    6: "商户信息",
    7: "商户介绍",
    8: "商户资质"
};

export class MerchantDetailModel extends BaseModel<DetailState> {
    #id?: number;

    constructor() {
        super();
        this.state = { id: -1, cateCode: "", cateName: "", name: "", area: "", street: "", invoiceParty: InvoiceParty.Platform, status: 3, score: 0, createTime: 0, createUserName: ""};
    }

    get id(): number | undefined {
        return this.#id;
    }

    set id(id: number | undefined) {
        this.#id = id;
        this.detail();
    }

    closeModal = () => {
        this.updateState({ showModal: false, editingIndex: undefined, editorType: undefined });
    }

    clear(type: EditorType) {
        let title = TITLE_MAP[type];
        const { merchantDetail } = this.state;
        let array: Array<any> | undefined = merchantDetail?.openTime;
        if (type == EditorType.PreferentialPolicy) {
            array = merchantDetail?.preferentialPolicy;
        } else if (type == EditorType.Reservation) {
            array = merchantDetail?.reservation;
        } else if (type == EditorType.SupportPhone) {
            array = merchantDetail?.supportPhone;
        }
        if (array == null || array.length == 0) {
            return;
        }
        let result = Modal.confirm({
            afterClose: () => result.destroy(),
            maskClosable: true,
            okCancel: true,
            okType: "danger",
            autoFocusButton: "cancel",
            title: `清空${title}`,
            content: `正在清空${title}，是否继续？`,
            onOk: () => {
                if (type == EditorType.OpenTime) {
                    this.updateState({ merchantDetail: { openTime: [] }});
                } else if (type == EditorType.PreferentialPolicy) {
                    this.updateState({ merchantDetail: { preferentialPolicy: [] }});
                } else if (type == EditorType.Reservation) {
                    this.updateState({ merchantDetail: { reservation: [] }});
                }else if (type == EditorType.SupportPhone) {
                    this.updateState({ merchantDetail: { supportPhone: [] }});
                }
                result.destroy();
            }
        })
    }

    detail = () => {
        if (this.#id == null) {
            this.setState({} as Merchant);
        } else {
            MerchantApi.detail(this.#id).then((merchant: Merchant) => {
                const detail: DetailState = merchant as DetailState;
                const merchantDetail: MerchantDetail = {};
                if (detail.merchantDetail != null) {
                    const { openTime, reservation, preferentialPolicy, supportPhone, notice, content, qualification} = detail.merchantDetail;
                    merchantDetail.openTime = openTime == null ? undefined : JSON.parse(openTime as any);
                    merchantDetail.reservation = reservation == null ? undefined : JSON.parse(reservation as any);
                    merchantDetail.preferentialPolicy = preferentialPolicy == null ? undefined : JSON.parse(preferentialPolicy as any);
                    merchantDetail.supportPhone = supportPhone == null ? undefined : JSON.parse(supportPhone as any);
                    merchantDetail.notice = notice;
                    merchantDetail.content = content;
                    merchantDetail.qualification = qualification;
                    detail.merchantDetail = merchantDetail;
                } else {
                    detail.merchantDetail = {};
                }
                this.setState(merchant);
            });
        }
    }

    getEditorDecorators = () : Decorator[] => {
        const { editorType, editingIndex, merchantDetail } = this.state;
        const { openTime, preferentialPolicy, reservation, supportPhone, notice, content, qualification } = merchantDetail == null ? {} as MerchantDetail  : merchantDetail;
        if (editorType == EditorType.OpenTime) {
            const newItem = () => {
                const item: MerchantApi.OpenTimePart = {
                    weekDay: "",
                    timeRange: "全天",
                    description: ""
                }
                return item;
            }
            const item = editingIndex == null || openTime == null ? null : openTime[editingIndex];
            return [{
                name: "desc",
                label: "开放日期",
                className: "editor-form-item",
                element: <DatePicker.RangePicker />,
                converter: StringDateRangeConv,
                rules: [{ required: true, message: "请选择开放日期区间" }],
                initialValue: item?.desc
            }, {
                name: "parts",
                trigger: "onItemsChange",
                valuePropName: "dataSource",
                className: "editor-form-item",
                element: <TableField rowKey={(record, index) => index as number} columns={this.getEditorColumns(editorType) as any} newItem={newItem} />,
                rules: [{ required: true, type: "array", min: 1, message: "工作时段至少包含一条记录", defaultField: {
                    type: "object",
                    fields: { weekDay: { required: true, type: "string", message: "工作日不能为空" } }
                }}],
                initialValue: item?.parts
            }];
        } else if (editorType == EditorType.PreferentialPolicy) {
            const policy = preferentialPolicy == null || editingIndex == null ? null : preferentialPolicy[editingIndex];
            return [
                { name: "fee", label: "收费标准", className: "editor-form-item", element: <Input placeholder="请输入收费标准" />, rules: [{ required: true, message: "请输入收费标准" }], initialValue: policy?.fee },
                { name: "applicable", label: "适用群体", className: "editor-form-item", element: <Input placeholder="请输入适用群体" />, rules: [{ required: true, message: "请输入适用群体" }], initialValue: policy?.applicable },
                { name: "resttict", label: "政策介绍", className: "editor-form-item", element: <Input.TextArea autoSize={{minRows:4, maxRows: 4}} placeholder="请输入收费标准" />, rules: [{ required: true, message: "请输入收费标准" }], initialValue: policy?.resttict },
            ];
        } else if (editorType == EditorType.Reservation) {
            const reserv = reservation == null || editingIndex == null ? null : reservation[editingIndex];
            return [
                { name: "channelName", label: "渠道名称", labelCol:{ span: 5 }, className: "editor-form-item", element: <Input placeholder="请输入渠道名称" />, rules: [{ required: true, message: "请输入渠道名称" }], initialValue: reserv?.channelName },
                { name: "channelValue", label: "详情", labelCol:{ span: 5 }, className: "editor-form-item", element: <Input placeholder="请输入渠道详情" />, rules: [{ required: true, message: "请输入渠道详情" }], initialValue: reserv?.channelValue },
            ];
        } else if (editorType == EditorType.SupportPhone) {
            const phone = supportPhone == null || editingIndex == null ? null : supportPhone[editingIndex];
            return [
                { name: "phone", label: "热线电话", className: "editor-form-item", element: <Input placeholder="请输入电话号码" />, rules: [{ required: true, message: "请输入电话号码" }], initialValue: phone }
            ];
        } else if (editorType == EditorType.Notice || editorType == EditorType.Content || editorType == EditorType.Qualification) {
            return [{
                className: "editor-form-item",
                name: editorType == EditorType.Notice ? "notice" : editorType == EditorType.Qualification ? "qualification" : "content",
                element: (
                    <QuillEditor
                        theme="snow"
                        upload={FileUploadApi.upload}
                        style={{margin: "0 auto", width: 400, height: 600, boxShadow: "none", border: "1px solid silver"}}
                    />
                ),
                rules: [{ required: true, message: editorType == EditorType.Notice ? "公告内容不能为空" : editorType == EditorType.Qualification ? "商户资质不能为空" : "商户介绍不能为空" }],
                initialValue: editorType == EditorType.Notice ? notice : editorType == EditorType.Qualification ? qualification : content
            }]
        } else if (editorType == EditorType.Info) {
            const model = new MerchantModel();
            return model.getEditorDecorators(this.state);
        }
        return [];
    }

    getEditorColumns(type: EditorType): Array<TableColumn<MerchantApi.OpenTimePart> | TableColumn<Reservation> | TableColumn<PreferentialPolicy> | TableColumn<string[]>> {
        
        switch (type) {
            case EditorType.OpenTime:
                return [
                    { title: "工作日", dataIndex:"weekDay" as any, width: 200, readonly: false, editingComponent: WeekdayRangePicker, converter: StringWeekRangeConv, shouldConvert: false, fallback: "" },
                    new SelectColumn<MerchantApi.OpenTimePart>({ title: "营业时段", width: 120, dataIndex: "timeRange"}, {
                        children: [
                            <Select.Option key={"all-time"} value="全天">全天</Select.Option>,
                            <Select.Option key={"a.m"} value="上午">上午</Select.Option>,
                            <Select.Option key={"p.m"} value="下午">下午</Select.Option>,
                        ]
                    }),
                    { title: "详细信息", dataIndex: "description", readonly: false, dataType: "string" },
                ];
            case EditorType.PreferentialPolicy:
                return [
                    { title: "收费标准", dataIndex:"fee", readonly: false, fallback: "" },
                    { title: "适用人群", dataIndex:"applicable", readonly: false, fallback: "" },
                    { title: "政策介绍", dataIndex:"fee", readonly: false, fallback: "" }
                ];
            default:
                return [];
        }
    }

    getEditorTitle() {
        const { editorType, editingIndex } = this.state;
        if (editorType == null) {
            return null;
        }
        const prefix: string = editingIndex == null ? "新增" : "修改";
        switch (editorType) {
            case EditorType.PreferentialPolicy:
                return `${prefix}优待政策`;
            case EditorType.Reservation:
                return `${prefix}预约渠道`;
            case EditorType.SupportPhone:
                return `${prefix}热线电话`;
            case EditorType.Notice:
                return "修改重要公告";
            case EditorType.Content:
                return "修改商户介绍";
            case EditorType.OpenTime:
                return `${prefix}开放时间`;
            case EditorType.Qualification:
                return `修改商户资质`;
            default:
                return "修改商户信息";
        }
    }

    getKeyByType(type: EditorType): null | (keyof MerchantDetail) {
        if (type == EditorType.PreferentialPolicy) {
            return "preferentialPolicy";
        } else if (type == EditorType.Reservation) {
            return "reservation";
        } else if (type == EditorType.SupportPhone) {
            return "supportPhone";
        } else if (type == EditorType.Notice) {
            return "notice";
        } else if (type == EditorType.Content) {
            return "content";
        } else if (type == EditorType.Qualification) {
            return "qualification"
        } else if (type == EditorType.OpenTime) {
            return "openTime";
        } else if (type == EditorType.Info) {
            return null;
        } else {
            throw new Error("未知的编辑类型！");
        }
    }

    getRowKey = (type: EditorType, record: OpenTime | Reservation | PreferentialPolicy | string) => {
        switch(type) {
            case EditorType.Reservation:
                return (record as Reservation).channelValue;
            case EditorType.PreferentialPolicy:
                return (record as PreferentialPolicy).applicable;
            case EditorType.SupportPhone:
                return record as string;
            default:
                const r = record as OpenTime;
                return `${r.desc}-${r.parts[0].weekDay}-${r.parts[0].timeRange}-${r.parts[0].description}`;
        }
    }

    getTableColumns<T>(type: EditorType) : TableColumn<OpenTime>[] | TableColumn<Reservation>[] | TableColumn<PreferentialPolicy>[] | TableColumn<string>[] {
        const operation: TableColumn<any> = { title: "操作", render: (value: any, record: any, index: number) => {
            return (
                <>
                    <PermissionButton
                        type="link"
                        onClick={() => this.showModal(type, index)}
                        permissionCode={PermissionCode.MERCHANT_DETAIL_MANAGE}
                    >
                        <EditOutlined />
                    </PermissionButton>
                    <Divider type="vertical" />
                    <PermissionButton
                        type="link"
                        onClick={() => this.onRemove(type, index)}
                        permissionCode={PermissionCode.MERCHANT_DETAIL_MANAGE}
                    >
                        <MinusOutlined />
                    </PermissionButton>
                </>
            )
        }};
        if (type == EditorType.OpenTime) {
            operation.render = (value: any, record: OpenTime, index: number) => {
                return (
                    <>
                        <PermissionButton
                            type="link"
                            permissionCode={PermissionCode.MERCHANT_DETAIL_MANAGE}
                            onClick={() => this.showModal(type, (record as any).actualIndex)}
                        >
                            <EditOutlined />
                        </PermissionButton>
                        <Divider type="vertical" />
                        <PermissionButton
                            type="link"
                            permissionCode={PermissionCode.MERCHANT_DETAIL_MANAGE}
                            onClick={() => this.onRemove(type, (record as any).actualIndex)}
                        >
                            <MinusOutlined />
                        </PermissionButton>
                    </>
                )
            }
            operation.onCell = (data: OpenTime) => ({rowSpan: (data as any).rowSpan});
            return [
                { dataIndex: "desc", title: "开放日期", onCell: (data: OpenTime) => ({ rowSpan: (data as any).rowSpan }), dataType: "string" },
                { title: "工作日", onCell: (data: OpenTime) => ({ rowSpan: (data as any).weekDayRowSpan }), dataType: "string", stringFormat: "%(parts[0].weekDay)s" },
                { title: "工作时段", dataType: "string", stringFormat: "%(parts[0].timeRange)s" },
                { title: "详细信息", dataType: "string", stringFormat: "%(parts[0].description)s" },
                operation
            ];
        } else if (type == EditorType.PreferentialPolicy) {
            return [
                { dataIndex: "fee", title: "收费标准", dataType: "string" },
                { dataIndex: "applicable", title: "适用人群", dataType: "string" },
                { dataIndex: "resttict", title: "政策介绍", dataType: "string" },
                operation
            ]
        } else if (type == EditorType.Reservation) {
            return [
                { dataIndex: "channelName", title: "渠道名称", dataType: "string" },
                { dataIndex: "channelValue", title: "渠道详情", dataType: "string" },
                operation
            ];
        } else if (type == EditorType.SupportPhone) {
            return [
                { title: "电话号码", dataType: "string" },
                operation
            ];
        }
        return [];
    }

    onRemove = (type: EditorType, index: number) => {
        const { merchantDetail } = this.state;
        let updateParams: Partial<MerchantDetail> = { 
            notice: merchantDetail?.notice, 
            openTime: merchantDetail?.openTime, 
            preferentialPolicy: merchantDetail?.preferentialPolicy,
            reservation: merchantDetail?.reservation,
            supportPhone: merchantDetail?.supportPhone
        };
        const key = this.getKeyByType(type);

        if (key == "notice" || key == "content" || key == null) {
            return;
        }
        if (merchantDetail == null || merchantDetail[key] == null) {
            return;
        }
        updateParams[key] = (merchantDetail[key] as any[]).reduce((prev, current, currentIndex) => {
            if (index !== currentIndex) {
                prev.push(current);
            }
            return prev;
        }, [] as any[]);
        this.updateIntroduce(updateParams);
    }

    onSubmit = (values: any) => {
        const { editingIndex, editorType, merchantDetail } = this.state;
        let updateParams: Partial<MerchantDetail> = {
            content: merchantDetail?.content,
            notice: merchantDetail?.notice, 
            openTime: merchantDetail?.openTime, 
            preferentialPolicy: merchantDetail?.preferentialPolicy,
            reservation: merchantDetail?.reservation,
            supportPhone: merchantDetail?.supportPhone,
            qualification: merchantDetail?.qualification
        };
        const key = this.getKeyByType(editorType as EditorType);
        if (key == null) {
            MerchantApi.update(this.#id as number, values).then(() => {
                this.updateState({ editingIndex: undefined, editorType: undefined, showModal: false, merchantDetail: updateParams, ...values });
            });
        } else if (key == "notice") {
            updateParams.notice = values.notice;
        } else if (key == "content") {
            updateParams.content = values.content;
        } else if (key == 'qualification') {
            updateParams.qualification = values.qualification
        } else {
            if (key == "supportPhone") {
                values = values.phone;
            }
            const array: any = updateParams[key] == null ? [] : [...(updateParams[key] as Array<any>)];
            if (editingIndex == null) {
                array.push(values);
            } else {
                array[editingIndex] = values;
            }
            updateParams[key] = array;
        }
        this.updateIntroduce(updateParams);
    }

    showMerchantEditor = () => {
        this.updateState({ showModal: true, editingIndex: undefined, editorType: EditorType.Info });
    }

    showModal = (type: EditorType, index?: number) => {
        this.updateState({ showModal: true, editingIndex: index, editorType: type });
    }

    updateIntroduce(updateParams: Partial<MerchantDetail>) {
        MerchantApi.updateIntroduce(this.#id as number, updateParams?.openTime,
            updateParams?.notice, updateParams?.content, updateParams?.reservation, updateParams?.preferentialPolicy, updateParams?.supportPhone, updateParams?.qualification
        ).then(() => {
            const newState = { editingIndex: undefined, editorType: undefined, showModal: false, merchantDetail: updateParams }
            this.updateState(newState);
        });
    }
}

type WeekdayRangeProps<RecordType> = WeekDayRangePickerProps & CellProps<RecordType>;

function WeekdayRangePicker<RecordType>({ column, record, value, editing, ...props }: WeekdayRangeProps<RecordType>) {
    return <WeekDayRangePicker value={value} {...props} />
}