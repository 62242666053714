

import { toTree } from "@framework/utils";
import { QueryModel, QueryState } from "@framework/models";
import { Decorator, TreeQuerySelect, SelectionTableField, TableColumn, PermissionButton } from "@framework/component";

import { Button, Divider, Input, Modal } from "antd";

import { RoleApi } from "@api";
import { TimestampColumn } from "@root/component";
import { SelectionTableValueConverter } from "@root/converter";
import { PermissionCode } from "@root/permission";

export interface RoleState extends QueryState<RoleApi.Role> {
     currentStep?: number
     expandedRowKeys?: string[]
} 

export class RoleModel extends QueryModel<RoleState> {

    constructor() {
        super();
    }

    public getEditorDecorators(editingItem?: RoleApi.Role): Decorator<any>[] {
        
        const { currentStep, data } = this.state;
        if (currentStep == null || currentStep < 1) {
            return [{
                name: "name",
                label: "角色名称",
                className: "editor-form-item",
                element: <Input placeholder="请输入角色名称" allowClear={true} />,
                rules: [{ type: "string", required: true, whitespace: true }],
                initialValue: editingItem?.name
            }, {
                name: "description",
                label: "角色介绍",
                className: "editor-form-item",
                hidden: currentStep != null && currentStep > 0,
                element: <Input.TextArea maxLength={200} showCount={true} placeholder="请输入角色介绍，200字以内！" allowClear={true} />,
                rules: [{ type: "string", required: true, message: "请输入用户显示名称！" }],
                initialValue: editingItem?.description
            }, {
                name: "pid",
                label: "上级角色",
                className: "editor-form-item",
                element: (
                    <TreeQuerySelect
                        keyOfValue="id"
                        keyOfLabel="name"
                        allowClear={true}
                        showSearch={true}
                        searchMode="query"
                        treeDefaultExpandAll={true}
                        disabled={editingItem?.id != null}
                        fetchItems={(name?: string) => Promise.resolve(data == null ? [] as RoleApi.Role[] : data)}
                    />
                ),
                initialValue: editingItem?.pid == null ? (data == null ? undefined : data[0].id) : editingItem.pid
            }];
        } else {
            return [{
                name: "permissions",
                className: "editor-form-item",
                hidden: currentStep != null && currentStep <= 0,
                element: (
                    <SelectionTableField
                        rowKey="id"
                        size="small"
                        style={{ height: 500}}
                        getDataSource={() => {
                            return editingItem?.pid == null? Promise.resolve([] as RoleApi.Permission[]) : RoleApi.listAvailablePermissions(editingItem.pid)
                        }}
                        columns={[
                            {title: "权限名称", dataIndex: "name"},
                            {title: "权限介绍", dataIndex: "description"}
                        ]}
                        rowSelection={{
                            getCheckboxProps: (record) => {
                                return {
                                    disabled: !record.allowCheck
                                }
                            }
                        }}
                    />
                ),
                initialValue: editingItem?.permissions,
                converter: new SelectionTableValueConverter()
            }]
        }
    }

    public getTableColumns(): TableColumn<RoleApi.Role>[] {
        return [
            { title: "名 称", dataIndex: "name" },
            { title: "介 绍", dataIndex: "description", fallback: "--" },
            { title: "创建人", dataIndex: "createUserName", fallback: "--" },
            new TimestampColumn<RoleApi.Role>("创建时间", "createTime"),
            new TimestampColumn<RoleApi.Role>("最后修改时间", "lastUpdateTime"),
            { title: "", render: (value, record, index) => {
                let key = 1;
                const result: React.ReactNode[] = [];
                if (record.allowEdit) {
                    result.push(
                        <PermissionButton
                            type="link"
                            key="modify"
                            hideWhenNoPermission={true}
                            onClick={() => this.showEditor(record)}
                            permissionCode={PermissionCode.ROLE_MANAGE}
                        >
                            修改
                        </PermissionButton>,
                        <Divider key="1" type="vertical" />,
                        <PermissionButton
                            type="link"
                            key="delete"
                            hideWhenNoPermission={true}
                            onClick={() => this.#doDelete(record)}
                            permissionCode={PermissionCode.ROLE_MANAGE}
                        >
                            删除
                        </PermissionButton>
                    );
                }
                return result;
            }}
        ];
    }

    goBack = () => {
        const { currentStep } = this.state;
        if (currentStep != null && currentStep >= 1) {
            this.updateState({ currentStep: currentStep - 1 });
        }
    }

    onEditorSubmit = (values: any) => {
        const { currentStep, editingItem } = this.state;
        if (currentStep == 0) {
            this.updateState({ editingItem: Object.assign({}, editingItem, values), currentStep: currentStep + 1});
            return Promise.reject();
        } else if (currentStep == 1) {
            return this.saveOrUpdate(Object.assign({}, editingItem, values));
        }
    }

    public async queryOverride(params?: any): Promise<RoleApi.Role[]> {
        const roles = await RoleApi.list();
        this.updateState({ expandedRowKeys: roles.map(role => role.id as string) });
        roles.sort((x, y) => {
            if (x.level == null || y.level == null) {
                return 0;
            }
            return x.level > y.level ? 1 : (x.level < y.level ? -1 : 0);
        })
        return toTree(roles, "id", "pid", "children", true);
    }

    public async saveOrUpdate(role: RoleApi.Role) {
        const { editingItem } = this.state;
        if (editingItem?.id != null) {
            await RoleApi.update(editingItem.id, role.permissions, role.name, role.description);
            return Object.assign({}, editingItem, role);
        } else {
            return await RoleApi.add(role.name, role.description, role.permissions, role.pid);
        }
    }

    showEditor = (role?: RoleApi.Role) => {
        if (role != null) {
            RoleApi.detail(role.id as string).then(detail => {
                detail.permissions = detail.permissionList?.filter(permission => permission.checked).map(permission => permission.id);
                this.updateState({ editingItem: detail, currentStep: 0, showEditor: true });
            });
        } else {
            this.updateState({ editingItem: undefined, currentStep: 0, showEditor: true });
        }
    }

    #doDelete = (role?: RoleApi.Role) => {
        Modal.confirm({
            title: "确认",
            content: `正在删除角色 ${role?.name}，是否继续？`,
            okButtonProps: { danger: true },
            okText: "删除",
            onOk: async () => {
                await RoleApi.remove(role?.id);
                this.refresh();
            }
        });
    }
}