

import { PageResult } from "@framework/utils";

import { RoleApi } from "./role";
import { fetch } from "@root/app";


export namespace UserApi {

    export declare type HEX = string;

    export interface User {
        id?: string
        loginName?: string
        name?: string
        nickName?: string
        province?: string
        city?: string
        street?: string
        county?: string
        displayName?: string
        merchantName?: string
        email?: string
        gender: number
        phone?: string
        lastLoginIp?: string
        lastLoginTime?: number
        createTime: number
        merchantId?: number,
        enabled: boolean
        permissions?: string,
        roles?: RoleApi.Role[]
    }

    export interface SignInfo extends User {
        signed?: boolean
        token?: string
        autoSignIn: boolean
        permissions?: string
    }

    export interface ImageData {
        data: string;
    }

    /**
     * 修改登录密码。
     * 
     * @param params 新旧密码。
     * @returns void
     */
    export function changePassword(params: { oldPassword: string, newPassword: string }): Promise<void> {
        return fetch.patch<void>(`/api/user/change`, params);
    }

    /**
     * 检查用户输入的登录名是否有效。
     * @param loginName 登录名
     */
    export function check(loginName: string): Promise<any> {
        return fetch.get<void>("/api/user/check", { loginName });
    }

    /**
     * 检查用户输入的验证码是否正确。
     * @param code 验证码
     */
    export async function checkVerifyCode(code: string): Promise<void> {
        return fetch.get<void>("/api/user/code/check/img", { code });
    }

    /**
     * 创建新用户。
     * 
     * @param params 用户信息
     * @returns {@link User}
     */
    export async function create(loginName?: string, password?: string, code?: string, merchantId?: number,
        name?: string, nickName?: string, email?: string, gender?: number, province?: string, city?: string,
        county?: string, street?: string, phone?: string): Promise<User> {

            return fetch.post<User>("/api/user", {
                loginName, password, code, merchantId, name, nickName, email, gender, province, city, county, street, phone
            });
    }

    /**
     * 批量禁用用户。
     * @param ids ','分隔的用户id
     * @returns void
     */
    export async function disable(ids: string): Promise<void> {
        return fetch.patch<void>("/api/user/disable", { ids });
    }

    /**
     * 批量启用用户。
     * @param ids ','分隔的用户id
     * @returns void
     */
    export async function enable(ids: string): Promise<void> {
        return fetch.patch<void>("/api/user/enable", { ids });
    }

    /**
     * 
     * 登录或注册时获取验证码图片。
     * 
     * @returns { data: '十六进制编码的验证码图片'}
     */
    export async function getImgVerifyCode(): Promise<{ data: HEX }> {
        return fetch.get<ImageData>("/api/user/code/get/img");
    }

    /**
     * 分页查询用户列表。
     * @param limit     分页大小
     * @param skip      分页位置
     * @param name      用户名称/昵称
     * @param email     电子邮件地址
     * @param roleId    角色编号
     * @param phone     联系电话
     * @param loginName 登录名
     * @param enabled   用户状态 禁用/启用
     * @param loginTime 登录日期
     * @returns 
     */
    export async function list(limit?: number, skip?: number, name?: string, email?: string,
        roleId?: string, phone?: string, loginName?: string, enabled?: boolean, loginTime?: number)  {
        return fetch.get<PageResult<User>>("/api/user", { limit, skip, name, email, roleId, phone, loginName, enabled, loginTime });
    }

    /**
     * 用户登录。
     * 
     * @param loginName  登录名
     * @param password   登录密码
     * @param code       验证码
     * @param autoSignIn 自动登录
     */
    export async function login(loginName?: string, password?: string, code?: string, autoSignIn?: boolean): Promise<SignInfo> {
        return fetch.post<SignInfo>("/api/user/login", { loginName, password, code, autoSignIn });
    }

    /**
     * 用户退出登录。
     */
    export async function logout(): Promise<void> {
        return fetch.post<void>("/api/user/logout");
    }

    /**
     * 修改用户信息。
     * 
     * @param id        用户编号
     * @param loginName 登录名
     * @param name      用户名称
     * @param nickName  用户昵称
     * @param email     电子邮件地址
     * @param gender    性别
     * @param province    省
     * @param city    市
     * @param county    
     * @param street    性别
     * @param phone     联系电话
     * @param loginName 登录名
     * @param enabled   用户状态 禁用/启用
     * @param loginTime 登录日期
     * @returns {@link User}
     */
    export async function modify(id: string, loginName?: string, name?: string, nickName?: string,
        email?: string, gender?: number, province?: string, city?: string, county?: string, street?: string, phone?: string) {
        return fetch.put<void>(`/api/user/${id}`, { loginName, name, nickName, email, gender, province, city, county, street, phone});
    }

    /**
     * 新用户注册。
     * 
     * @param params 用户信息
     * @returns {@link User}
     */
    export async function registe(params: { loginName: string, password: string, code: string }): Promise<User> {
        return fetch.post<User>("/api/user/register", params);
    }

    /**
     * 重置指定用户的密码（需要权限）。
     * 
     * @param uid 需重置其密码的用户id。
     * @param newPassword 新密码。
     * @returns void
     */
    export async function resetPassword(uid: string, newPassword: string): Promise<void> {
        return fetch.patch<void>(`/api/user/reset/${uid}`, { newPassword });
    }
}