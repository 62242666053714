

import { PageResult } from "@framework/utils";

import { Button, TablePaginationConfig } from "antd";
import { RowSelectMethod } from "antd/es/table/interface";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";

import { Decorator } from "./types";
import { DecoratorForm } from "./decorator-form";
import { EditableTable, EditableTableProps } from "./editable-table";


const toAntdPagination = (result: PageResult<any>) : TablePaginationConfig => {
    const skip = result.skip == null ? 0 : result.skip;
    const limit = result.limit == null || result.limit <= 0 ? 10 : result.limit;
    const total = result.total == null ? 0 : result.total;
    const current = Math.floor((skip == null ? 0 : skip) / limit) + 1;
    return {
        total: total,
        defaultPageSize: limit,
        current: current
    };
}

export interface SelectionTableValue {
    selectedRowKeys?: React.Key[]
}

export interface SelectionTableFieldProps<RecordType> extends Omit<EditableTableProps<RecordType>, "onChange"> {
    keyofParent?: keyof RecordType
    onChange?: (value: SelectionTableValue) => void
    value?: SelectionTableValue
    getDataSource: (limit?: number, skip?: number, params?: any) => Promise<Array<RecordType> | PageResult<RecordType>>
    queryDecorators?: Decorator<any>[]
}

/**
 * 将 Table 作为 Form 表单元素
 * @param props 
 * @returns 
 */
export function SelectionTableField<RecordType extends {}> (props: SelectionTableFieldProps<RecordType>) {

    const [pagination, setPagination] = useState<TablePaginationConfig | undefined>(undefined);
    const [dataSource, setDataSource] = useState<Array<RecordType> | undefined>();

    const ref = React.createRef<DecoratorForm>();

    const resetParams = () => {
        ref.current?.resetFields();
    }

    const onClickQuery = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        ref.current?.submit();
    }
    
    const handleSubmit = (values: any) => {
        doQuery(undefined, undefined, values);
    }

    useEffect(() => {
        doQuery();
    }, []);

    // useEffect(() => {
    //     setSelectedKeyMap(keyArrayToMap());
    // }, [props.value])

    // const getRowKey = (rowKey: keyof RecordType | GetRowKey<RecordType>, record: RecordType, index?: number) => {
    //     if (typeof rowKey === "function") {
    //         return rowKey(record, index);
    //     } else {
    //         return record[rowKey] as React.Key;
    //     }
    // }

    const doQuery = (limit?: number, skip?: number, params?: any) => {
        props.getDataSource(limit, skip, params).then((result) => {
            if (result instanceof Array) {
                setDataSource(result);
                setPagination(undefined);
            } else {
                setDataSource(result.info);
                const newPagination = toAntdPagination(result);
                newPagination.onChange = (page, pageSize) => {
                    props.getDataSource(pageSize, (page - 1) * pageSize, params)
                }
                setPagination(newPagination);
            }
        });
    }

    const View = useMemo(() => {
        const { onChange, value, getDataSource, queryDecorators, rowKey, keyofParent, rowSelection: originRowSelection, ...restProps } = props;

        const originOnChange = originRowSelection?.onChange;

        const fireChange = (selectedRowKeys: React.Key[], selectedRows: RecordType[], info: { type: RowSelectMethod }) => {
            onChange != null && onChange({ selectedRowKeys })
            if (originOnChange != null) {
                try {
                    originOnChange(selectedRowKeys, selectedRows, info);
                } catch (error: any) {
                    console.error(error?.message);
                }
            }
        }

        const rowSelection = Object.assign({}, originRowSelection, {
            preserveSelectedRowKeys: true,
            type: "checkbox",
            selectedRowKeys: value?.selectedRowKeys == null ? [] : value.selectedRowKeys,
            fixed: "left",
            onChange: fireChange
        });

        return (
            <>
                {queryDecorators != null && queryDecorators.length > 0 && (
                    <DecoratorForm
                        ref={ref}
                        className="query-form"
                        onSubmit={handleSubmit}
                        decorators={queryDecorators}
                    >
                        <div className="operation">
                            <Button type="primary" onClick={onClickQuery}>查询</Button>
                            <Button type="default" onClick={resetParams}>重置</Button>
                        </div>
                    </DecoratorForm>
                )}
                <EditableTable dataSource={dataSource} rowSelection={rowSelection} rowKey={rowKey} {...restProps} />
            </>
        )
    }, [props, dataSource, pagination])

    return View;
}