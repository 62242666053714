import { DrawerProps, ModalProps } from "antd";
import { PermissionRequire } from "./permission-require";
import { BitArray } from "./utils";


export abstract class AppBase<TUser> {

    static Instance: AppBase<any>;

    #defaultModal?: ModalProps;

    #modals: Array<ModalProps|null>;

    #drawer?: DrawerProps;

    get modals() {
        return this.#modals;
    }

    get drawer() {
        return this.#drawer;
    }

    abstract get permissions(): BitArray;

    abstract get currentUser() : TUser;

    abstract get mapAppKey(): string;

    abstract get mapSecurity(): string;

    abstract get(name: string) : any;

    hasPermission(PermissionCode?: number) : boolean;
    hasPermission(PermissionCode?: PermissionRequire) : boolean;

    hasPermission(permission?: PermissionRequire | number): boolean {
        if (permission == null) {
            return true;
        } else if (typeof permission === "number") {
            return this.permissions.get(permission);
        }
        
        if (permission.children == null && permission.requirePermissions == null) {
            return true;
        }
    
        let result = true;
        let permissions = permission.children == null ? permission.requirePermissions as number[] : permission.children;
    
        if (permission.requireType == null || permission.requireType == "any") {
            for (let item of permissions) {
                if (item instanceof Object) {
                    result = this.hasPermission(item);
                } else {
                    result = this.permissions.get(item);
                }
                if (result) {
                    break;
                }
            }
        } else {
            result = true;
            for (let item of permissions) {
                if (item instanceof Object) {
                    result = result && this.hasPermission(item);
                } else {
                    result = result && this.permissions.get(item);
                }
            }
        }
        return result;
    }

    onRender(type?: "drawer" | "modal") {};

    public showDrawer(props: DrawerProps) {
        this.#drawer = props;
        const onClose = props.onClose;
        props.onClose = (e) => {
            this.#drawer = undefined;
            if (onClose != null) {
                onClose(e);
            }
            this.onRender("drawer");
        }
        this.onRender("drawer");
    }

    public showModal(props: ModalProps) {
        const onCancel = props.onCancel;
        const closeCallback = () => {
            const index = this.#modals.indexOf(props);
            this.#modals.splice(index, 1);
            this.onRender("modal");
        }
        props.onCancel = (e) => {
            if (onCancel) {
                onCancel(e);
            }
            closeCallback();
        }
        this.#modals.push(props);
        this.onRender("modal");
        return closeCallback;
    }

    public closeModal(props: ModalProps) {
        if (this.#modals.length > 0) {
            if (props == null) {
                this.#modals.splice(this.#modals.length - 1, 1);
            } else {
                const index = this.#modals.indexOf(props);
                if (index >= 0) {
                    this.#modals.splice(index, 1);
                }
            }
            this.onRender("modal");
        }
    }

    protected constructor() {
        if (AppBase.Instance != null) {
            throw new Error("请勿重复创建 AppBase 实例，每个页面应用只应存在一个 AppBase 实例！");
        }
        this.#modals = [];
        AppBase.Instance = this;
    }
}