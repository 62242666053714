

import { BaseModel, QueryModel, QueryState } from "@framework/models";
import { DEFAULT_IDS_CONVERTER, PageResult, TimestampConverter } from "@framework/utils";
import { Decorator, FileUploader, PermissionButton, QuillEditor, SelectionTableField, TableColumn } from "@framework/component";

import { InboxOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Input, InputNumber, Modal, Select } from "antd";


import { FileUploadApi, VoucherApi, ActivityApi } from "@api";
import { FilesConverter, SelectionTableValueConverter } from "@root/converter";
import { ImageColumn, LinkColumn, TagColumn, TimestampColumn } from "@root/component";

import { ActivityStatusMap, VoucherTypeMap } from "./tag-map";
import { PermissionCode } from "@root/permission";


export interface State extends QueryState<ActivityApi.Activity> {

}

export class ActivityModel extends QueryModel<State> {

    constructor() {
        super();
    }

    public getEditorDecorators(activity?: ActivityApi.Activity): Decorator<any>[] {
        const labelCol = { span: 5 };
        return [{
            name: "title",
            label: "活动主题",
            labelCol: labelCol,
            className: "editor-form-item",
            element: <Input placeholder="请输入活动主题（100字以内）" maxLength={100} />,
            rules: [{ required: true, message: "请输入活动主题" }],
            initialValue: activity?.title
        }, {
            name: "cover",
            label: "活动封面",
            labelCol: labelCol,
            className: "editor-form-item",
            element: (
                <FileUploader
                    maxCount={1}
                    accept="image/*"
                    listType="picture"
                    converter={FilesConverter}
                    customRequest={FileUploadApi.requestForUploader}
                >
                    <p className="ant-upload-drag-icon" style={{ display: "inline" }}>
                        <InboxOutlined height="100%" style={{ fontSize: 32 }} />
                        <span className="ant-upload-text" style={{ display: "inline-block" }}>点击此区域上传图片</span>
                        <span className="ant-upload-hint" style={{ display: "inline-block" }}>建议使用正方形图片，大小不超过300k</span>
                    </p>
                </FileUploader>
            ),
            rules: [{ required: true, message: "请上传活动封面图片" }],
            converter: DEFAULT_IDS_CONVERTER,
            initialValue: activity?.cover
        }, {
            name: "startTime",
            label: "开始日期",
            labelCol: labelCol,
            className: "editor-form-item",
            element: <DatePicker style={{ width: "100%" }} placeholder="请选择活动开始日期" />,
            rules: [{ required: true, message: "请选择活动开始日期" }],
            initialValue: activity?.startTime,
            converter: new TimestampConverter()
        }, {
            name: "endTime",
            label: "结束日期",
            labelCol: labelCol,
            className: "editor-form-item",
            element: <DatePicker style={{ width: "100%" }} placeholder="请选择活动结束日期" />,
            rules: [{ required: true, message: "请选择活动结束日期" }],
            initialValue: activity?.endTime,
            converter: new TimestampConverter()
        }, {
            name: "sort",
            label: "排 序",
            labelCol: labelCol,
            className: "editor-form-item",
            element: <InputNumber min={0} step={1} />,
            initialValue: activity?.sort
        }, {
            name: "remark",
            label: "备 注",
            labelCol: labelCol,
            className: "editor-form-item",
            element: <Input.TextArea showCount={true} placeholder="活动备注（200字以内）" />,
            initialValue: activity?.remark
        }];
    }

    public getQueryDecorators(): Decorator<any>[] {
        return [{
            name: "title",
            label: "活动主题",
            className: "query-form-item",
            element: <Input placeholder="按活动主题查询" />
        }, {
            name: "status",
            label: "活动状态",
            className: "query-form-item",
            element: (
                <Select allowClear>
                    <Select.Option value={ActivityApi.Status.New}>新建</Select.Option>
                    <Select.Option value={ActivityApi.Status.Available}>已开始</Select.Option>
                    <Select.Option value={ActivityApi.Status.Paused}>已暂停</Select.Option>
                    <Select.Option value={ActivityApi.Status.Finished}>已结束</Select.Option>
                </Select>
            )
        }];
    }

    public getTableColumns(): TableColumn<ActivityApi.Activity>[] {
        return [
            new LinkColumn("活动主题", "/app/activity/%(id)s", "title"),
            new ImageColumn("封面图片", "cover"),
            new TimestampColumn("开始日期", "startTime", "YYYY/MM/DD", { fallback: "--" }),
            new TimestampColumn("结束日期", "endTime", "YYYY/MM/DD", { fallback: "--" }),
            new TimestampColumn("最后修改时间", "lastUpdateTime"),
            { title: "最后修改人", dataIndex: "lastUpdateUserName", fallback: "--" },
            new TagColumn(ActivityStatusMap, "状态", "status"),
            {
                render: (value, record) => {
                    let key = 1;
                    const operation = [];
                    if (record.status == ActivityApi.Status.New) {
                        operation.push(
                            <PermissionButton
                                type="link"
                                key={key++}
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.ACTIVITY_MANAGE}
                                onClick={this.showEditor.bind(this, record)}
                            >
                                修改
                            </PermissionButton>,
                            <Divider key={key++} type="vertical" />,
                            <PermissionButton
                                type="link"
                                key={key++}
                                hideWhenNoPermission={true}
                                onClick={this.start.bind(this, record)}
                                permissionCode={PermissionCode.ACTIVITY_MANAGE}
                            >
                                立即启用
                            </PermissionButton>
                        );
                    } else if (record.status == ActivityApi.Status.Available) {
                        operation.push(
                            <PermissionButton
                                type="link"
                                key={key++}
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.ACTIVITY_MANAGE}
                                onClick={this.pause.bind(this, record)}
                            >
                                暂停
                            </PermissionButton>,
                            <Divider key={key++} type="vertical" />,
                            <PermissionButton
                                type="link"
                                key={key++}
                                hideWhenNoPermission={true}
                                onClick={this.stop.bind(this, record)}
                                permissionCode={PermissionCode.ACTIVITY_MANAGE}
                            >
                                结束
                            </PermissionButton>
                        );
                    } else if (record.status == ActivityApi.Status.Paused) {
                        operation.push(
                            <PermissionButton
                                type="link"
                                key={key++}
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.ACTIVITY_MANAGE}
                                onClick={this.start.bind(this, record)}
                            >
                                恢复
                            </PermissionButton>,
                            <Divider key={key++} type="vertical" />,
                            <PermissionButton
                                type="link"
                                key={key++}
                                hideWhenNoPermission={true}
                                onClick={this.stop.bind(this, record)}
                                permissionCode={PermissionCode.ACTIVITY_MANAGE}
                            >
                                结束
                            </PermissionButton>
                        );
                    }
                    return operation;
                }
            }
        ];
    }

    public pause = (activity: ActivityApi.Activity) => {
        let onResolve: any = null;
        let onReject: any = null;
        let promise = new Promise<ActivityApi.Activity>((resolve, reject) => {
            onResolve = resolve;
            onReject = reject;
        });
        Modal.confirm({
            title: "确认",
            type: "info",
            content: "正在暂时停用当前活动，是否继续？",
            onOk: async () => {
                await ActivityApi.pause(activity.id);
                const { data } = this.state;
                activity.status = ActivityApi.Status.Paused;
                onResolve(activity);
                this.updateState({ data: data == null ? [] : [...data] });
            },
        })
        return promise;
    }

    public queryOverride(params?: any): Promise<PageResult<ActivityApi.Activity> | ActivityApi.Activity[]> {
        return ActivityApi.list(params?.limit, params?.skip, params?.title, params?.status);
    }

    public showEditor = (activity?: ActivityApi.Activity) => {
        this.updateState({ editingItem: activity, showEditor: true });
    }

    public async saveOrUpdate(activity: ActivityApi.Activity) {
        const { editingItem } = this.state;
        if (editingItem?.id == null) {
            activity = await ActivityApi.add(activity.title, activity.startTime,
                activity.endTime, activity.cover, activity.content, activity.sort, activity.remark);
            return activity;
        } else {
            await ActivityApi.update(editingItem.id, activity.title, activity.startTime,
                activity.endTime, activity.cover, activity.content, activity.sort, activity.remark);
            return Object.assign({}, editingItem, activity);
        }
    }


    public start = (activity: ActivityApi.Activity) => {
        let onResolve: any = null;
        let onReject: any = null;
        let promise = new Promise<ActivityApi.Activity>((resolve, reject) => {
            onResolve = resolve;
            onReject = reject;
        });
        let content = "是否立即启用当前活动？";
        if (activity.status == ActivityApi.Status.Paused) {
            content = "正在恢复当前活动，是否继续？"
        }
        Modal.confirm({
            title: "确认",
            type: "info",
            content: content,
            onOk: async () => {
                await ActivityApi.start(activity.id);
                const { data } = this.state;
                activity.status = ActivityApi.Status.Available;
                onResolve(activity);
                this.updateState({ data: data == null ? [] : [...data] });
            },
        })
        return promise;
    }

    public stop = (activity: ActivityApi.Activity) => {
        let onResolve: any = null;
        let onReject: any = null;
        let promise = new Promise<ActivityApi.Activity>((resolve, reject) => {
            onResolve = resolve;
            onReject = reject;
        });
        Modal.confirm({
            title: "确认",
            type: "info",
            content: "正在停用当前活动，活动停用后将永久失效，是否继续？",
            onOk: async () => {
                await ActivityApi.finish(activity.id);
                const { data } = this.state;
                activity.status = ActivityApi.Status.Finished;
                onResolve(activity);
                this.updateState({ data: data == null ? [] : [...data] });
            },
        })
        return promise;
    }
}


/**
 * 活动详情页的编辑弹窗类型
 */
export enum ModalType {
    /**
     * 活动信息编辑弹窗
     */
    Info = 1,
    /**
     * 关联优惠券选择弹窗。
     */
    Voucher = 2,
    /**
     * 活动内容富文本弹窗。
     */
    Content = 3
}


export interface DetailState {
    activity: ActivityApi.Activity,
    batchList?: VoucherApi.VoucherBatch[]
    modalType?: ModalType
    showPreview?: boolean
    selectedBatchIds?: string[]
}

export class ActivityDetailModel extends BaseModel<DetailState> {
    #activityModel: ActivityModel;
    #selectionValueConverter = new SelectionTableValueConverter();
    constructor() {
        super();
        this.#activityModel = new ActivityModel();
    }

    public async detail(id?: string) {
        const activity = await ActivityApi.detail(id);
        this.updateState({ activity, batchList: activity.couponBatchList });
        delete activity.couponBatchList;
    }


    #getRuleName = (value: any, batch: VoucherApi.VoucherBatch, index: number) => {
        if (batch == null) {
            return "--";
        }
        if (batch.discountRule == VoucherApi.Rule.MXDY) {
            let discountStr = batch.discount == null ? "-" : (batch.discount * 100).toFixed(0);
            if (discountStr.endsWith("0")) {
                discountStr = discountStr.substring(0, discountStr.length - 1);
            }
            return `满${batch.restrictPrice?.toFixed(0)} ${discountStr}折`;
        }
        const prefix = batch.discountRule == VoucherApi.Rule.PMXMY ? "每" : "满";
        return `${prefix}${batch.restrictPrice?.toFixed(0)}-${batch.discount?.toFixed(0)}`;
    }

    public getBatchColumns(): TableColumn<VoucherApi.VoucherBatch>[] {
        return [
            new LinkColumn("名      称", `/app/activity/voucher/%(id)s`, "name", { width: 100 }),
            new TagColumn(VoucherTypeMap, "优惠方式", "type"),
            { title: "满减规则", render: this.#getRuleName },
            new TimestampColumn("开始日期", "startTime", { width: 130, stringFormat: "YYYY/MM/DD" }),
            new TimestampColumn("结束/截止日期", "endTime", { width: 130, stringFormat: "YYYY/MM/DD" })
        ]
    }

    public getEditorDecorators(): Decorator<any>[] {
        const { modalType, activity, batchList } = this.state;
        switch (modalType) {
            case ModalType.Info:
                return this.#activityModel.getEditorDecorators(activity);
            case ModalType.Voucher:
                return [{
                    name: "selectedBatchIds",
                    className: "editor-form-item",
                    element: (
                        <SelectionTableField
                            rowKey="id"
                            queryDecorators={[{
                                name: "name",
                                label: "优惠券名称",
                                className: "query-form-item-60",
                                element: <Input style={{ width: "100%" }} placeholder="按优惠券名称查询" />
                            }]}
                            getDataSource={(limit, skip, params) => {
                                return ActivityApi.listAvailableBatch(activity?.id, limit, skip, params?.name);
                            }}
                            columns={this.getBatchColumns()}
                        />
                    ),
                    initialValue: batchList == null ? [] : batchList.map(batch => batch.id),
                    converter: this.#selectionValueConverter
                }]
            case ModalType.Content:
                return [{
                    name: "content",
                    valuePropName: "defaultValue",
                    className: "editor-form-item",
                    element: (
                        <QuillEditor
                            theme="snow"
                            value={activity?.content}
                            style={{ margin: "0 auto", width: 400, height: 858, borderRadius: 22 }}
                            upload={FileUploadApi.upload}
                        />
                    ),
                    initialValue: activity.content
                }]
        }
        return [];
    }

    public getModalTitle() {
        const { modalType } = this.state;
        switch (modalType) {
            case ModalType.Info: return "修改活动信息";
            case ModalType.Voucher: return "选择活动优惠";
            case ModalType.Content: return "修改活动详情";
        }
    }

    public onFormSubmit = async (values: any) => {
        const { modalType, activity } = this.state;
        switch (modalType) {
            case ModalType.Info:
                await ActivityApi.update(activity?.id, values.title, values.startTime, values.endTime, values.cover, values.content, values.sort, values.remark);
                this.updateState({ activity: Object.assign({}, activity, values) });
                break;
            case ModalType.Voucher:
                await ActivityApi.setBatchList(activity?.id, values.selectedBatchIds?.join(","));
                this.detail(activity?.id);
                this.updateState({ modalType: undefined });
                break;
            case ModalType.Content:
                await ActivityApi.update(activity?.id, activity.title, activity.startTime, activity.endTime, activity.cover, values.content, activity.sort, activity.remark);
                this.updateState({ modalType: undefined, activity: { ...activity, content: values.content } });
                break;
        }
    }

    public pause = () => {
        this.#activityModel.pause(this.state.activity).then((activity) => {
            this.updateState({ activity: { ...activity } });
        });
    }

    public removeSelectedBatch() {
        const { selectedBatchIds, activity } = this.state;
        if (selectedBatchIds == null || selectedBatchIds.length == 0) {
            return;
        }
        Modal.confirm({
            title: "确认",
            content: `正在移除选定的${selectedBatchIds.length}个优惠项，是否继续？`,
            mask: false,
            maskClosable: false,
            onOk: async () => {
                await ActivityApi.setBatchList(activity?.id, selectedBatchIds.join(","));
                this.updateState({ selectedBatchIds: [] });
                this.detail(activity?.id);
            }
        });
    }

    public showEditor = (modalType: ModalType) => {
        this.updateState({ modalType: modalType });
    }

    public start = () => {
        this.#activityModel.start(this.state.activity).then((activity) => {
            this.updateState({ activity: { ...activity } });
        });
    }

    public stop = () => {
        this.#activityModel.stop(this.state.activity).then((activity) => {
            this.updateState({ activity: { ...activity } });
        });
    }
}