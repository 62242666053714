import { Button, ButtonProps } from "antd";
import { useEffect, useState } from "react";
import { AppBase } from "@framework/app-base";

export interface PermissionButtonProps extends ButtonProps {
    permissionCode?: number;
    /**
     * 无权限时隐藏当前按钮
     * @default false
     */
    hideWhenNoPermission?: boolean;
    /**
     * 无权限时自动禁用按钮操作
     * @default true
     */
    disableWhenNoPermission?: boolean;
    onPermissionChecked?: (passed: boolean) => Partial<ButtonProps> | undefined;
}

export function PermissionButton({ permissionCode, hideWhenNoPermission, onPermissionChecked, disableWhenNoPermission, style, disabled, ...props }: PermissionButtonProps) {
    const [visible, setVisible] = useState<boolean>(true);
    const [disable, setDisable] = useState<boolean | undefined>(disabled);
    const [dynamicProps, setDynamicProps] = useState<ButtonProps>();

    useEffect(() => {
        const hasPermission = AppBase.Instance.hasPermission(permissionCode);
        if (onPermissionChecked != null) {
            setDynamicProps(onPermissionChecked(hasPermission));
        }
        setVisible(hasPermission || hideWhenNoPermission != true);
        setDisable(disabled ? true : (!hasPermission && disableWhenNoPermission != false));
    }, [permissionCode, disabled, onPermissionChecked]);
    
    style = dynamicProps?.style == null ? style : Object.assign({}, style, dynamicProps.style);

    const finalStyle = visible ? style : Object.assign({}, style, { display: "none" });
    const finalProps = dynamicProps == null ? props : Object.assign({}, props, dynamicProps, { style: finalStyle});

    return <Button style={finalStyle} disabled={disable} {...finalProps} />
}