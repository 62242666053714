

import { PageResult } from "@framework/utils";

import { fetch } from "@root/app";


export namespace MerchantApi {
    /**
     * 商户信息
     */
    export interface Merchant {
        /**
         * 商户id
         */
        id: number
        /**
         * 商户所属分类
         */
        cateCode: string
        /**
         * 分类名称
         */
        cateName: string
        /**
         * 商户名称
         */
        name: string
        /**
         * 开票方
         */
        invoiceParty: InvoiceParty
        /**
         * 商户所属区/县
         */
        area: string
        /**
         * 商户所属街道
         */
        street: string
        /**
         * 商户详细地址
         */
        address?: string
        /**
         * 商户所在地理经度
         */
        longitude?: number
        /**
         * 商户所在地理维度
         */
        latitude?: number
        /**
         * 封面图
         */
        cover?: string
        /**
         * 背景图
         */
        headerImage?: string
        /**
         * 联系人
         */
        contact?: string
        /**
         * 联系电话
         */
        phone?: string
        /**
         * 1-营业中；2-休息中；3-禁用（不在前端展示，平台操作，商户不能启用）
         */
        status: Status
        /**
         * 商户评分
         */
        score: number
        /**
         * 商户标签 1-城市名片；2-网红打卡；3-当季旅游
         */
        tags?: string
        /**
         * 创建时间
         */
        createTime: number
        /**
         * 创建人
         */
        createUserName: string
        /**
         * 最后修改时间
         */
        lastUpdateTime?: number
        /**
         * 最后修改人
         */
        lastUpdateUserName?: string
        /**
         * 排序
         */
        sort?: number
    }

    /**
     * 商户状态  
     */
    export enum Status {
        /**
         * 营业中 
         */
        Opened = 1,
        /**
         * 休息中
         */
        Closed = 2,
        /**
         * 禁用（禁用后该商户不在前端展示。商户用户不可启用）
         */
        Disabled = 3
    }

    /**
     * 开票方
     */
    export enum InvoiceParty {
        /**
         * 平台开具
         */
        Platform = 1,
        /**
         * 商户自行开具
         */
        Merchant = 2
    }

    export type TimeRange = "上午" | "下午" | "全天"

    export interface Range<T> {
        start: T
        end: T
    }

    export interface OpenTimePart {
        /**
         * 营业日
         */
        weekDay: string
        /**
         * 营业时段
         */
        timeRange: TimeRange,
        /**
         * 对当前开放时间的详细描述，如：9:00-12:00 等
         */
        description: string
    }

    export interface OpenTime {
        /**
         * 开放时间
         */
        desc: string
        /**
         * 
         */
        parts: Array<OpenTimePart>
    }

    /**
     * 预约渠道
     */
    export interface Reservation {
        /**
         * 渠道名称
         */
        channelName: string
        /**
         * 渠道介绍
         */
        channelValue: string
    }

    /**
     * 优待政策
     */
    export interface PreferentialPolicy {
        /**
         * 收费标准
         */
        fee: string
        /**
         * 适用人群
         */
        applicable: string
        /**
         * 政策介绍
         */
        resttict: string
    }

    /**
     * 新增商户
     * 
     * @param merchant 商户信息
     * @returns 新增商户
     */
    export async function add(merchant: Merchant) {
        return fetch.post<Merchant>("/api/merchant", merchant);
    }

    /**
     * 更改商户状态
     * @param id 商户id
     * @param status 商户状态
     */
    export async function changeStatus(id: number, status: Status) {
        return fetch.put<void>(`/api/merchant/status/${id}`, { status });
    }

    /**
     * 查询商户详情。
     * @param id 商户编号
     * @returns 商户详情。
     */
    export async function detail(id: number) {
        return fetch.get<Merchant>(`/api/merchant/${id}`);
    }

    export async function listMerchant(limit?: number, skip?: number, name?: string,
        contact?: string, phone?: string, area?: string, street?: string, cateCode?: string, status?: number) {
        return fetch.get<PageResult<Merchant>>("/api/merchant", { limit, skip, name, contact, phone, area, street, cateCode, status });
    }

    /**
     * 修改商户信息。
     * 
     * @param id 商户id
     * @param merchant 商户信息
     */
    export async function update(id: number, merchant: Partial<Merchant>) {
        return fetch.put<void>(`/api/merchant/${id}`, merchant);
    }

    /**
     * 更新商户简介
     * 
     * @param id 商户id
     * @param openTime 开放时间
     * @param notice 商户公告
     * @param content 商户介绍
     * @param reservation 预约渠道
     * @param preferentialPolicy 优待政策
     * @param supportPhone 联系电话
     */
    export async function updateIntroduce(id: number, openTime?: OpenTime[], notice?: string,
        content?: string, reservation?: Reservation[], preferentialPolicy?: PreferentialPolicy[], supportPhone?: string[], qualification?: string) {
        return fetch.put<void>(`/api/merchant/introduction/${id}`, { 
            openTime: openTime == null ? null : JSON.stringify(openTime),
            reservation: reservation == null ? null : JSON.stringify(reservation), 
            preferentialPolicy: preferentialPolicy == null ? null : JSON.stringify(preferentialPolicy), 
            supportPhone: supportPhone == null ? null : JSON.stringify(supportPhone), 
            content, notice, qualification
        });
    }
}