

import AMapLoader from "@amap/amap-jsapi-loader";
import "@amap/amap-jsapi-types";

import { Component, createRef, RefObject } from "react";


export interface AMapProps extends AMap.MapOptions {
    /**
     * 申请好的Web端开发者Key
     */
    appKey: string
    /**
     * 开发者密钥
     */
    securityJsCode: string
    /**
     * JSAPI 的版本
     * @default 1.4.15
     */
    version?: string
    /**
     * 需要使用的的插件列表，如比例尺'AMap.Scale'等
     */
    plugins?: string[]
    resizeEnable?: boolean
    afterMount?: (map: AMap.Map) => void;
}

export class AMapComponent extends Component<AMapProps> {
    #map?: AMap.Map;
    #rejectLoad?: (reason?: any) => void
    containerRef: RefObject<HTMLDivElement>
    constructor(props: AMapProps) {
        super(props);
        (window as any)._AMapSecurityConfig = {
            securityJsCode: props.securityJsCode,
        }
        this.containerRef = createRef();
    }

    public get map() {
        return this.#map;
    }

    componentDidMount(): void {
        const { plugins, afterMount, appKey, version } = this.props;
        new Promise((resolve, reject) => {
            this.#rejectLoad = reject;
            AMapLoader.load({
                key: appKey,
                version: version == null ? "1.4.15" : version,
                plugins: plugins == null ? [""] : plugins
            }).then(() => {
                if (this.#rejectLoad != null) {
                    const options = Object.assign({}, this.props);
                    this.#map = new AMap.Map(this.containerRef.current as HTMLDivElement, options);
                    this.#rejectLoad = undefined;
                    resolve(this.#map);
                    if (afterMount != null) {
                        afterMount(this.#map);
                    }
                }
            })
        }) ;
    }

    componentWillUnmount(): void {
        if (this.#rejectLoad != null) {
            this.#rejectLoad(new Error("终止加载"));
            this.#rejectLoad = undefined;
        }
        if (this.#map != null) {
            this.#map.destroy();
        }
    }

    componentDidUpdate(prevProps: Readonly<AMapProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.#map == null) {
            return;
        }
        if (this.props.center != null && this.props.center != prevProps.center) {
            this.#map.setCenter(this.props.center);
        }
        if (this.props.layers != null && this.props.layers != prevProps.layers) {
            this.#map.setLayers(this.props.layers);
        }
        if (this.props.zoom != null && this.props.zoom != prevProps.zoom) {
            this.#map.setZoom(this.props.zoom);
        }
        if (this.props.rotation != null && this.props.rotation != prevProps.rotation) {
            this.#map.setRotation(this.props.rotation);
        }
        if (this.props.features != null && this.props.features != prevProps.features) {
            this.#map.setFeatures(this.props.features);
        }
        if (this.props.zooms != null && this.props.zooms != prevProps.zooms) {
            this.#map.setZooms(this.props.zooms);
        }
        if (this.props.mapStyle != null && this.props.mapStyle != prevProps.mapStyle) {
            this.#map.setMapStyle(this.props.mapStyle);
        }
        const status = this.#buildMapStatus(prevProps);
        if (status != null) {
            this.#map.setStatus(status);
        }
        if (this.props.limitBounds != null && this.props.limitBounds != prevProps.limitBounds) {
            this.#map.setLimitBounds(this.props.limitBounds);
        }
        if (this.props.defaultCursor != null && this.props.defaultCursor != prevProps.defaultCursor) {
            this.#map.setDefaultCursor(this.props.defaultCursor);
        }
        if (this.props.labelRejectMask != null && this.props.labelRejectMask != prevProps.labelRejectMask) {
            this.#map.setLabelRejectMask(this.props.labelRejectMask);
        }
        if (this.props.mask != null && this.props.mask != prevProps.mask) {
            this.#map.setMask(this.props.mask);
        }
    }

    render() {
        return <div ref={this.containerRef} style={{height: "100%", width: "100%"}} className="map"></div>
    }

    #buildMapStatus(prevProps: AMapProps) {
        const { 
            showIndoorMap, resizeEnable, dragEnable, keyboardEnable, doubleClickZoom,
            zoomEnable, rotateEnable, scrollWheel, jogEnable, pitchEnable, showLabel, animateEnable
        } = this.props;
        let changed = false;
        const status: Record<string, boolean> = {};
        if (showIndoorMap != null && showIndoorMap != prevProps.showIndoorMap) {
            changed = true;
            status.showIndoorMap = showIndoorMap;
        }
        if (resizeEnable != null && resizeEnable != prevProps.resizeEnable) {
            changed = true;
            status.resizeEnable = resizeEnable;
        }
        if (dragEnable != null && dragEnable != prevProps.dragEnable) {
            changed = true;
            status.dragEnable = dragEnable;
        }
        if (keyboardEnable != null && keyboardEnable != prevProps.keyboardEnable) {
            changed = true;
            status.keyboardEnable = keyboardEnable;
        }
        if (doubleClickZoom != null && doubleClickZoom != prevProps.doubleClickZoom) {
            changed = true;
            status.doubleClickZoom = doubleClickZoom;
        }
        if (zoomEnable != null && zoomEnable != prevProps.zoomEnable) {
            changed = true;
            status.zoomEnable = zoomEnable;
        }
        if (rotateEnable != null && rotateEnable != prevProps.rotateEnable) {
            changed = true;
            status.rotateEnable = rotateEnable;
        }
        if (scrollWheel != null && scrollWheel != prevProps.scrollWheel) {
            changed = true;
            status.scrollWheel = scrollWheel;
        }
        if (jogEnable != null && jogEnable != prevProps.jogEnable) {
            changed = true;
            status.jogEnable = jogEnable;
        }
        if (pitchEnable != null && pitchEnable != prevProps.pitchEnable) {
            changed = true;
            status.pitchEnable = pitchEnable;
        }
        if (showLabel != null && showLabel != prevProps.showLabel) {
            changed = true;
            status.showLabel = showLabel;
        }
        if (animateEnable != null && animateEnable != prevProps.animateEnable) {
            changed = true;
            status.animateEnable = animateEnable;
        }
        return changed ? null : status;
    }
}