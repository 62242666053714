

import { Converter } from "@framework/utils";

import { Moment } from "moment";
import { sprintf } from "sprintf-js";
import type { ColumnType, TableProps } from "antd/lib/table";
import { DatePicker, Image, Input, InputNumber, Select, SelectProps, Table, TimePicker } from "antd";
import React, { ChangeEvent, MouseEvent, TdHTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";

export interface CellProps<RecordType> {
    column: TableColumn<RecordType>

    record: RecordType

    value?: any

    onChange?: (value: any) => void

    onFocus?: () => void;

    onBlur?: () => void;

    [key: string]: any
}

type StringFormater = string | ((value: any) => string)

/**
 * {@link TableColumn["dataType"]} 为 "date" | "time" | "datetime" 时，单元格值或经过转换后的单元格值必须为 {@link moment} 类型。
 */
export interface TableColumn<RecordType> extends Omit<ColumnType<RecordType>, "dataIndex"> {
    dataIndex?: keyof RecordType,
    /**
     * 数据类型
     */
    dataType?: "string" | "image" | "number" | "date" | "time" | "datetime"
    /**
     * 单元格内容的格式化，
     * {@link sprintf}
     */
    stringFormat?: StringFormater
    /**
     * {@link sprintf["stringFormat"]} 格式化前或非编辑状态展示时，是否使用 {@link Converter["convert"]} 对原数据进行处理。
     * @default true
     */
    shouldConvert?: boolean
    /**
     * 当单元格数据为 null 或 undefined 时使用的值。
     */
    fallback?: any
    /**
     * 在显示或编辑时，将数据转换为显示组件或编辑组件特定数据类型。
     */
    converter?: Converter<any, any>
    /**
     * 自定义显示组件，如需指定组件的props，请使用 {@link TableColumn["onComponentWillMount"]}。
     */
    viewComponent?: React.ComponentType<CellProps<RecordType>>
    /**
     * 自定义编辑组件，如需指定组件的props，请使用 {@link TableColumn["onComponentWillMount"]}。
     */
    editingComponent?: React.ComponentType<CellProps<RecordType>>
    /**
     * 此列数据是否为只读（只读列不允许修改）。
     * @default true
     */
    readonly?: boolean | ((value: any, record: RecordType) => boolean)
    /**
     * 组件挂载前通知用户，可在此方法中修改组件的props。
     * @param value 使用 converter.convert 转换后的单元格值。
     * @param props 组件属性
     * @param editing 是否为编辑组件。
     */
    onComponentWillMount?: <T extends CellProps<RecordType>>(value: any, props: T, editing: boolean) => CellProps<RecordType>
}

function getDefaultStringFormat(dataType?: string) {
    if (dataType == undefined) {
        return "%s";
    }
    switch (dataType) {
        case "string": return "%s";
        case "image": return "%s";
        case "number": return "%d";
        case "date": return "yyyy-MM-DD";
        case "time": return "HH:mm:ss";
        case "datetime": return "yyyy-MM-DD HH:mm:ss";
        default: return "%s";
    }
}

export function CellWrapper<RecordType extends {}>({ editing, onChange, onBlur, onFocus, ...props }: CellProps<RecordType>) {
    const { column, record } = props;
    const { converter, shouldConvert, fallback, dataIndex } = column;
    const [isEditing, setEditing] = useState(editing);
    const [changeFlag, setChangeFlag] = useState({});

    const [item] = useState(record);
    const [value, setValue] = useState<any>(() => {
        if (item == null) {
            return undefined;
        } else if (column.dataIndex == null || column.dataIndex == "") {
            return item;
        } else {
            return item[column.dataIndex];
        }
    });
    const [focused, setFocused] = useState(false);


    const fireOnFocus = () => {
        setFocused(true);
        onFocus != null && onFocus();
    }

    const fireOnBlur = () => {
        setFocused(false);
        onBlur != null && onBlur();
    }

    const fireOnChange = (value: any, ...args: any) => {
        const finalValue = column.converter?.convertBack ? column.converter.convertBack(value, record, record) : value;
        if (column.dataIndex) {
            onChange != null && onChange({ record, column, value: finalValue });
            record[column.dataIndex as keyof RecordType] = finalValue;
            setChangeFlag({});
        }
    }

    const getStringFormater = (formater: StringFormater) => {
        if (typeof formater == "string") {
            return (value: any) => {
                if (column.dataType == "date" || column.dataType == "time" || column.dataType == "datetime") {
                    return value == null || typeof value != "object" ? "" : (value as Moment).format(formater);
                } else {
                    try {
                        return value == null ? "" : sprintf(formater, value);
                    } catch (error) {
                        console.error(column, error);
                        return value;
                    }
                }
            };
        } else {
            return formater;
        }
    }

    const renderViewer = (finalValue: any, stringFormat: StringFormater, { column, record, src, ...finalProps }: any) => {
        const format: StringFormater = getStringFormater(stringFormat);

        switch (column.dataType) {
            case "image":
                src = src == null ? finalValue : src;
                return <Image src={src} {...finalProps} />;
            case "number":
                return <span {...finalProps}>{finalValue != null ? format(finalValue) : "N/A"}</span>;
            default:
                return <span {...finalProps}>{format(finalValue)}</span>;
        }
    }

    const renderEditor = (finalValue: any, stringFormat: StringFormater, props: any): JSX.Element => {
        switch (column.dataType) {
            case "image":
                return <Image width={64} height={64} src={finalValue} />;
            case "number":
            case "date":
            case "time":
            case "datetime":
            case "string":
            default:
                return (
                    <CellEditor
                        {...props}
                        record={item}
                        onBlur={fireOnBlur}
                        value={finalValue}
                        onFocus={fireOnFocus}
                        type={column.dataType}
                        onChange={fireOnChange}
                        stringFormat={stringFormat}
                    />
                );
        }
    }

    useEffect(() => {
        const readonly = column.readonly == null ? true : column.readonly;
        const isReadonly = (readonly instanceof Function) ? readonly(value, record) : readonly;
        setEditing((editing || focused) && !isReadonly);
        setValue(!dataIndex || record == null ? record : record[dataIndex as keyof RecordType]);
    }, [changeFlag, record, editing, column, focused])

    const Component = useMemo<JSX.Element>(() => {
        const { dataType } = column;
        const stringFormat = column.stringFormat == null ? getDefaultStringFormat(dataType) : column.stringFormat;

        const convertedValue = (!isEditing && shouldConvert === false) ? value : (converter?.convert ? converter.convert(value, item) : value);
        const finalValue = convertedValue == null ? fallback : convertedValue;
        let finalProps = props;

        let View = isEditing ? column.editingComponent : column.viewComponent;

        if (column.onComponentWillMount != null) {
            finalProps = { ...props };
            finalProps = column.onComponentWillMount(finalValue, finalProps, isEditing);
        }
        if (View != null) {
            return (
                <View
                    value={finalValue}
                    onFocus={fireOnFocus}
                    format={stringFormat}
                    onBlur={fireOnBlur}
                    onChange={fireOnChange}
                    {...finalProps}
                />
            );
        }
        if (isEditing) {
            return renderEditor(finalValue, stringFormat, finalProps);
        } else {
            return renderViewer(finalValue, stringFormat, finalProps);
        }
    }, [isEditing, value, column.converter, column.dataType, column.onComponentWillMount, column.stringFormat, props]);

    return Component;
}

export function CellEditor<RecordType extends {}>(props: CellProps<RecordType>) {

    const { type, value, stringFormat, onFocus, onBlur, onChange } = props;

    let fireOnChange = onChange;
    const otherProps: Record<string, any> = {};
    let Component: React.ComponentType<any>;
    if (type == "number") {
        Component = InputNumber;
    } else if (type == "time") {
        Component = TimePicker;
        otherProps.format = stringFormat;
    } else if (type == "datetime") {
        Component = DatePicker;
        otherProps.format = stringFormat;
        otherProps.showTime = true;
    } else if (type == "date") {
        Component = DatePicker;
        otherProps.format = stringFormat;
        otherProps.showTime = false;
    } else {
        Component = Input;
        fireOnChange = onChange == null ? undefined : (event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        }
    }
    return <Component defaultValue={value} onChange={fireOnChange} onFocus={onFocus} onBlur={onBlur} {...otherProps} />
}

export interface EditableTableProps<RecordType> extends Omit<TableProps<RecordType>, "columns" | "rowKey"> {
    columns: TableColumn<RecordType>[]
    /**
     * 
     */
    alwaysShow?: boolean
    onCellValueChange?: (values: any) => void
    rowKey: keyof RecordType | ((record: RecordType, index?: number) => string | number)
}

const findTdIndex = (target: HTMLElement) => {
    let td: HTMLElement | null = null;
    let parent: HTMLElement | null = target;
    while (parent != null && td == null) {
        if (parent.tagName == "TD" && td == null) {
            td = parent;
        }
        parent = parent.parentElement;
    }
    if (td == null) {
        return -1;
    }
    let attr = td.attributes.getNamedItem("col-index")
    return attr?.value == null ? -1 : Number.parseInt(attr.value);
}

export function EditableTable<RecordType extends {}>({ columns, rowKey, alwaysShow, dataSource, onCellValueChange, onRow, ...props }: EditableTableProps<RecordType>) {
    
    const [editing, setEditing] = useState<Record<string, any>>({ row: undefined, col: undefined });
    const [prevEditingCol, setPrevEditingCol] = useState<number | undefined>(undefined);
    const [items, setItems] = useState<readonly RecordType[]>(dataSource == null ? [] : dataSource);

    const generateColumns = useCallback(() => {
        return columns.map((column, colIndex) => {
            const finalColumn: TableColumn<RecordType> = { ...column };
            if (finalColumn.render == null) {
                finalColumn.render = (value: any, record: RecordType, index: number) => {
                    return (
                        <CellWrapper
                            record={record}
                            column={finalColumn}
                            onChange={onCellValueChange}
                            editing={editing.row == index && editing.col == colIndex}
                        />
                    );
                }
            }
            const onCell = finalColumn.onCell;
            finalColumn.onCell = (record: RecordType, rowIndex?: number) => {
                let tdProps: TdHTMLAttributes<any> | null = null;
                if (onCell != null) {
                    tdProps = onCell(record, rowIndex);
                }
                if (tdProps == null) {
                    tdProps = {};
                }
                (tdProps as any)["col-index"] = colIndex;
                return tdProps;
            }
            return finalColumn;
        });
    }, [columns, editing]);

    const [ finalColumns, setFinalColumns ] = useState(() => generateColumns());

    const onRowProp = (record: RecordType, index?: number) => {
        let rowProps = onRow == null ? {} : onRow(record, index);
        const originOnMouseOver = rowProps.onMouseOver;
        rowProps.onMouseOver = (event: MouseEvent<any>) => {
            if (originOnMouseOver != null) {
                originOnMouseOver(event);
            }
            setPrevEditingCol(editing.col);
            setEditing({ row: index, col: findTdIndex(event.target as HTMLElement)});
        }
        
        const originOnMouseLeave = rowProps.onMouseLeave;
        rowProps.onMouseLeave = (event: MouseEvent<any>) => {
            if (originOnMouseLeave != null) {
                originOnMouseLeave(event);
            }
            setPrevEditingCol(editing.col);
            setEditing({ row: undefined, col: undefined });
        }
        return rowProps;
    };

    useEffect(() => {
        setItems(dataSource == null ? [] : dataSource);
    }, [dataSource]);

    useEffect(() => {
        setFinalColumns(generateColumns());
    }, [columns, editing]);

    return (
        <Table
            onRow={onRowProp}
            dataSource={items}
            rowKey={rowKey as any}
            columns={finalColumns as any}
            key={items == null || items.length == 0 ? "__empty__" : "-"}
            {...props}
        />
    );
}

export interface SelectCellEditorProps<RecordType> extends SelectProps {

    column: TableColumn<RecordType>

    record: RecordType

}

export function SelectCellEditor<RecordType extends {}>({ column, record, value, ...props }: SelectCellEditorProps<RecordType>) {

    return (
        <Select
            defaultValue={value}
            {...props}
        >
        </Select>
    );
}

/**
 * 使用 Select 作为编辑组件的列。
 */
export class SelectColumn<RecordType> implements TableColumn<RecordType> {
    #selectProps?: SelectProps;
    constructor(params: TableColumn<RecordType>, selectProps?: SelectProps) {
        this.#selectProps = selectProps;
        Object.assign(this, params);
    }

    readonly: boolean | ((value: any, record: RecordType) => boolean) = false;

    editingComponent: React.ComponentType<CellProps<RecordType>> = (SelectCellEditor as any);

    onComponentWillMount = <T extends CellProps<RecordType>>(value: any, props: T, editing: boolean) => {
        if (editing && this.#selectProps != null) {
            return Object.assign({}, this.#selectProps, props);
        }
        return props;
    }
}