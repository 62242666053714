import { DEFAULT_IDS_CONVERTER, PageResult } from "@framework/utils";
import { QueryModel, QueryState } from "@framework/models";
import { Decorator, DecoratorForm, FileUploader, TreeQuerySelect, QuillEditor, TableColumn, PermissionButton } from "@framework/component";

import { Button, Divider, Input, InputNumber, Modal, ModalFuncProps, Result, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { FilesConverter } from "@root/converter";
import { ArticleApi, FileUploadApi } from "@api";
import { ArticleCateStatusMap, ArticleStatusMap } from "./tag-map";
import { ImageColumn, SvgColumn, TagColumn, TimestampColumn } from "@root/component";

import ArticleStatus = ArticleApi.ArticleStatus;
import ArticleCateStatus = ArticleApi.ArticleCateStatus;
import { PermissionCode } from "@root/permission";

type Article = ArticleApi.Article;
type ArticleCate = ArticleApi.ArticleCate;

interface CateState extends QueryState<ArticleCate> {

}

export class ArticleCateModel extends QueryModel<CateState> {
    constructor() {
        super();
    }

    public getQueryDecorators(): Decorator<any>[] {
        const labelCol = { span: 5 };
        return [{
            name: "name",
            label: "分类名称",
            labelCol: labelCol,
            className: "query-form-item",
            element: <Input placeholder="按分类名称查询" />
        }, {
            name: "status",
            label: "状 态",
            labelCol: labelCol,
            className: "query-form-item",
            element: (
                <Select allowClear={true}>
                    <Select.Option value={null}>全部</Select.Option>
                    <Select.Option value={ArticleCateStatus.Enabled}>启用</Select.Option>
                    <Select.Option value={ArticleCateStatus.Disabled}>禁用</Select.Option>
                </Select>
            )
        }]
    }

    public getEditorDecorators(editingItem?: ArticleCate): Decorator<any>[] {
        const labelCol = { span: 5 };
        return [{
            name: "name",
            label: "分类名称",
            labelCol: labelCol,
            className: "editor-form-item",
            element: <Input placeholder="请输入分类名称" />,
            rules: [{ required: true, message: "分类名称不能为空" }],
            initialValue: editingItem?.name
        }, {
            name: "icon",
            label: "分类图标",
            labelCol: labelCol,
            className: "editor-form-item",
            element: (
                <FileUploader
                    maxCount={1}
                    listType="text"
                    accept="image/svg+xml"
                    converter={FilesConverter}
                    customRequest={FileUploadApi.requestForIconUploader}
                >
                    <p className="ant-upload-drag-icon" style={{ display: "inline" }}>
                        <InboxOutlined height="100%" style={{ fontSize: 32 }} />
                        <span className="ant-upload-text" style={{ display: "inline-block" }}>点击此区域上传图片</span>
                        <span className="ant-upload-hint" style={{ display: "inline-block" }}>建议使用正方形图标，大小不超过30k</span>
                    </p>
                </FileUploader>
            ),
            converter: DEFAULT_IDS_CONVERTER,
            rules: [{ required: true, message: "分类图标不能为空" }],
            initialValue: editingItem?.icon
        }, {
            name: "sort",
            label: "排序序号",
            labelCol: labelCol,
            className: "editor-form-item",
            element: <InputNumber min={0} max={999999} placeholder="请输入排序序号" />,
            initialValue: editingItem?.sort
        }, {
            label: "上级分类",
            name: "parentCode",
            hidden: true,
            labelCol: labelCol,
            className: "editor-form-item",
            // hidden: editingItem?.code != null,
            element: (
                <TreeQuerySelect
                    keyOfValue="code"
                    keyOfLabel="name"
                    searchMode="filter"
                    keyOfChildren="children"
                    keyOfParentId="parentCode"
                    fetchItems={() => ArticleApi.listArticleCate()}
                />
            ),
            initialValue: editingItem?.parentCode
        }];
    }

    public getTableColumns(): TableColumn<ArticleCate>[] {
        return [
            { title: "分类名称", dataIndex: "name" },
            new SvgColumn("分类图标", "icon", 32),
            { title: "排序序号", dataIndex: "sort", fallback: "--" },
            new TimestampColumn("创建时间", "createTime"),
            { title: "创 建 人", dataIndex: "createUserName", fallback: "--" },
            new TimestampColumn("最后修改时间", "lastUpdateTime"),
            { title: "最后修改人", dataIndex: "lastUpdateUserName", fallback: "--" },
            new TagColumn(ArticleCateStatusMap, "状态", "status"),
            {
                render: (value, record, index) => {
                    const result = [
                        <PermissionButton
                            type="link"
                            key="update"
                            hideWhenNoPermission={true}
                            onClick={() => this.showEditor(record)}
                            permissionCode={PermissionCode.ARTICLE_CATE_MANAGE}
                        >
                            修改
                        </PermissionButton>
                    ];
                    if (record.status == ArticleCateStatus.Enabled) {
                        result.push(
                            <Divider key="1" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="disable"
                                hideWhenNoPermission={true}
                                onClick={() => this.#disable(record)}
                                permissionCode={PermissionCode.ARTICLE_CATE_MANAGE}
                            >
                                禁用
                            </PermissionButton>
                        );
                    } else {
                        result.push(
                            <Divider key="1" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="enable"
                                hideWhenNoPermission={true}
                                onClick={() => this.#enable(record)}
                                permissionCode={PermissionCode.ARTICLE_CATE_MANAGE}
                            >
                                启用
                            </PermissionButton>
                        );
                    }
                    return result;
                }
            }
        ];
    }

    #enable = (cate?: ArticleCate) => {
        ArticleApi.changeArticleCateStatus(cate?.code, ArticleCateStatus.Enabled).then(this.refresh);
    }

    #disable = (cate?: ArticleCate) => {
        ArticleApi.changeArticleCateStatus(cate?.code, ArticleCateStatus.Disabled).then(this.refresh);
    }

    public showEditor = (cate?: ArticleCate) => {
        this.setState({ showEditor: true, editingItem: cate });
    }

    public saveOrUpdate(cate?: ArticleCate) {
        const { editingItem } = this.state;
        if (editingItem?.code == null) {
            return ArticleApi.addArticleCate(cate?.name, cate?.icon, ArticleCateStatus.Enabled, cate?.sort, cate?.parentCode);
        } else {
            return ArticleApi.updateArticleCate(editingItem.code, cate?.name, cate?.icon, cate?.sort);
        }
    }

    public queryOverride(params?: any): Promise<ArticleCate[]> {
        return ArticleApi.listArticleCate(params?.name, params?.status);
    }
}

interface ArticleState extends QueryState<Article> {
    currentStep: number
}

export class ArticleModel extends QueryModel<ArticleState> {

    constructor() {
        super();
    }

    public getQueryDecorators(): Decorator<any>[] {
        const labelCol = { span: 5 };
        return [{
            name: "title",
            label: "标题",
            labelCol: labelCol,
            className: "query-form-item",
            element: <Input placeholder="按分类名称查询" />
        }, {
            name: "cateCode",
            label: "分类",
            labelCol: labelCol,
            className: "query-form-item",
            element:
                <TreeQuerySelect
                    keyOfValue="code"
                    keyOfLabel="name"
                    keyOfChildren="children"
                    placeholder="按分类查询"
                    keyOfParentId="parentCode"
                    showSearch={true}
                    searchMode="filter"
                    treeDefaultExpandAll={true}
                    fetchItems={ArticleApi.listArticleCate}
                    allowSelect={(item) => item.parentCode != null && item.parentCode.length == 8}
                />
        }, {
            name: "status",
            label: "状 态",
            labelCol: labelCol,
            className: "query-form-item",
            element: (
                <Select allowClear={true}>
                    <Select.Option value={ArticleStatus.New}>待发布</Select.Option>
                    <Select.Option value={ArticleStatus.UnderReview}>审核中</Select.Option>
                    <Select.Option value={ArticleStatus.Rejected}>已驳回</Select.Option>
                    <Select.Option value={ArticleStatus.Published}>已发布</Select.Option>
                    <Select.Option value={ArticleStatus.Deleted}>已删除</Select.Option>
                </Select>
            )
        }]
    }

    public getEditorDecorators(editingItem?: Article): Decorator<any>[] {
        const labelCol = { span: 5 };
        const { currentStep } = this.state;
        if (currentStep == 0) {
            return [{
                name: "title",
                label: "标 题",
                labelCol: labelCol,
                className: "editor-form-item-2",
                element: <Input placeholder="请输入文章标题" />,
                rules: [{ required: true, message: "文章标题不能为空" }],
                initialValue: editingItem?.title
            }, {
                name: "cateCode",
                label: "文章分类",
                labelCol: labelCol,
                className: "editor-form-item-2",
                element: (
                    <TreeQuerySelect
                        keyOfValue="code"
                        keyOfLabel="name"
                        searchMode="filter"
                        keyOfChildren="children"
                        keyOfParentId="parentCode"
                        treeDefaultExpandAll={true}
                        fetchItems={() => ArticleApi.listArticleCate()}
                    />
                ),
                rules: [{ required: true, message: "请选择文章分类" }],
                initialValue: editingItem?.cateCode
            }, {
                name: "cover",
                label: "封 面 图",
                labelCol: labelCol,
                className: "editor-form-item-2",
                element: (
                    <FileUploader
                        maxCount={1}
                        accept="image/*"
                        listType="picture"
                        converter={FilesConverter}
                        customRequest={FileUploadApi.requestForUploader}
                    >
                        <p className="ant-upload-drag-icon" style={{ display: "inline" }}>
                            <InboxOutlined height="100%" style={{ fontSize: 32 }} />
                            <span className="ant-upload-text" style={{ display: "inline-block" }}>点击此区域上传图片</span>
                            <span className="ant-upload-hint" style={{ display: "inline-block" }}>建议使用方形图片，大小不超过300k</span>
                        </p>
                    </FileUploader>
                ),
                converter: DEFAULT_IDS_CONVERTER,
                rules: [{ required: true, message: "封面图片不能为空" }],
                initialValue: editingItem?.cover
            }, {
                name: "sliderImage",
                label: "轮 播 图",
                labelCol: labelCol,
                className: "editor-form-item-2",
                element: (
                    <FileUploader
                        maxCount={5}
                        accept="image/*"
                        listType="picture-card"
                        converter={FilesConverter}
                        customRequest={FileUploadApi.requestForUploader}
                    >
                        <p className="ant-upload-drag-icon" style={{ display: "inline" }}>
                            <InboxOutlined height="100%" style={{ fontSize: 32 }} />
                            <span className="ant-upload-text" style={{ display: "inline-block" }}>点击此区域上传图片</span>
                            <span className="ant-upload-hint" style={{ display: "inline-block" }}>建议图片宽高比3:4，大小不超过600k</span>
                        </p>
                    </FileUploader>
                ),
                converter: DEFAULT_IDS_CONVERTER,
                rules: [{ required: true, message: "至少上传一张" }],
                initialValue: editingItem?.sliderImage
            }, {
                name: "sort",
                label: "推荐序号",
                labelCol: labelCol,
                className: "editor-form-item-2",
                element: <InputNumber min={0} max={999999} />,
                initialValue: editingItem?.sort
            }]
        } else {
            return [{
                name: "content",
                element: (
                    <QuillEditor
                        theme="snow"
                        upload={FileUploadApi.upload}
                        style={{ margin: "0 auto", width: 400, height: 600, border: "1px solid silver", boxShadow: "none" }}
                    />
                ),
                initialValue: editingItem?.content
            }]
        }
    }

    public getTableColumns(): TableColumn<Article>[] {
        return [
            { title: "标题", dataIndex: "title" },
            new ImageColumn("封 面 图", "cover", 64),
            { title: "推荐序号", dataIndex: "sort", fallback: "--" },
            { title: "文章分类", dataIndex: "cateName", fallback: "--" },
            new TimestampColumn("最后修改时间", "lastUpdateTime"),
            { title: "最后修改人", dataIndex: "lastUpdateUserName", fallback: "--" },
            new TimestampColumn("发布时间", "publishTime", { fallback: "--" }),
            { title: "审 核 人", dataIndex: "auditorName", fallback: "--" },
            new TagColumn(ArticleStatusMap, "状态", "status"),
            {
                render: (value, record, index) => {
                    const result = [
                        <PermissionButton
                            type="link"
                            key="update"
                            hideWhenNoPermission={true}
                            onClick={() => this.showEditor(record)}
                            permissionCode={PermissionCode.ARTICLE_MANAGE}
                        >
                            修改
                        </PermissionButton>
                    ];
                    if (record.status == ArticleStatus.New) {
                        result.push(
                            <Divider key="1" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="publish"
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.ARTICLE_MANAGE}
                                onClick={() => this.#changeStatus(record, ArticleStatus.UnderReview)}
                            >
                                申请发布
                            </PermissionButton>
                        );
                    } else if (record.status == ArticleStatus.UnderReview) {
                        result.push(
                            <Divider key="2" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="audit"
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.ARTICLE_MANAGE}
                                onClick={() => this.#changeStatus(record, ArticleStatus.Published)}
                            >
                                确认发布
                            </PermissionButton>,
                            <Divider key="3" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="reject"
                                hideWhenNoPermission={true}
                                onClick={() => this.#reject(record)}
                                permissionCode={PermissionCode.ARTICLE_MANAGE}
                            >
                                驳回
                            </PermissionButton>
                        );
                    }
                    result.push(
                        <Divider key="4" type="vertical" />,
                        <PermissionButton
                            key="del"
                            type="link"
                            style={{ color: "crimson" }}
                            hideWhenNoPermission={true}
                            permissionCode={PermissionCode.ARTICLE_MANAGE}
                            onClick={() => this.#changeStatus(record, ArticleStatus.Deleted)}
                        >
                            删除
                        </PermissionButton>
                    );
                    return result;
                }
            }
        ];
    }

    public goBack = () => {
        const { currentStep } = this.state;
        if (currentStep != null && currentStep >= 1) {
            this.updateState({ currentStep: currentStep - 1 });
        }
    }

    public nextStep(article: Article) {
        const { currentStep, editingItem } = this.state;
        this.setState({ currentStep: currentStep + 1, editingItem: Object.assign({}, editingItem, article) });
    }

    public saveOrUpdate(article?: Article) {
        const { editingItem } = this.state;
        article = Object.assign({}, editingItem, article);
        if (editingItem?.id == null) {
            return ArticleApi.addArticle(article.title, article.cateCode, article.cover, article.sliderImage, article.content, article.sort);
        } else {
            return ArticleApi.update(editingItem.id, article.title, article.cateCode, article.cover, article.content, article.sliderImage, article.sort);
        }
    }

    public showEditor(editingItem?: ArticleApi.Article | undefined) {
        if (editingItem?.id != null) {
            ArticleApi.detail(editingItem.id).then(article => {
                this.updateState({ showEditor: true, currentStep: 0, editingItem: article });
            });
        } else {
            this.updateState({ showEditor: true, currentStep: 0, editingItem: editingItem });
        }
    }

    #changeStatus = async (article: Article, status: ArticleStatus) => {
        switch (status) {
            case ArticleStatus.Deleted:
                await this.#showConfirmModal({
                    title: "删除确认",
                    type: "warn",
                    content: `正在删除文章《${article.title}》，是否继续？`,
                    okText: "删除",
                    okButtonProps: { danger: true }
                });
                await ArticleApi.del(article.id);
                break;
            case ArticleStatus.UnderReview:
                await this.#showConfirmModal({
                    title: "申请发布",
                    type: "confirm",
                    content: `文章《${article.title}》将在管理员审核后正式发布，且在此期间无法修改文章，是否继续？`
                });
                await ArticleApi.publish(article.id);
                break;
            case ArticleStatus.Published:
                await this.#showConfirmModal({ title: "确认发布", type: "confirm", content: `确认通过审核后，文章将立即发布，是否继续？` });
                await ArticleApi.audit(article.id);
                break;
        }
        this.refresh();
    }

    #reject = (article: Article) => {
        const updater = Modal.confirm({
            width: 500,
            icon: false,
            title: "驳回发布申请",
            okButtonProps: { style: { display: "none" } },
            cancelButtonProps: { style: { display: "none" } },
            content: (
                <DecoratorForm
                    className="editor-form"
                    decorators={[{
                        name: "reason",
                        label: "驳回原因",
                        labelCol: { span: 5 },
                        className: "editor-form-item",
                        element: <Input placeholder="请输入驳回原因" />,
                        rules: [{ required: true, message: "请填写驳回原因" }]
                    }]}
                    onSubmit={async (values) => {
                        await ArticleApi.reject(article.id, values.reason);
                        updater.destroy();
                    }}
                >
                    <div className="operation">
                        <Button type="primary" htmlType="submit">确认</Button>
                    </div>
                </DecoratorForm>
            )
        });
    }

    #showConfirmModal = (props: ModalFuncProps) => {
        const { onOk, onCancel } = props;

        return new Promise<void>((resolve, reject) => {
            props.onOk = (...args) => {
                onOk != null && onOk(...args);
                resolve();
            };
            props.onCancel = (...args) => {
                onCancel != null && onCancel(...args);
                reject();
            }
            Modal.confirm(props);
        })
    }

    public queryOverride(params?: any): Promise<PageResult<Article>> {
        return ArticleApi.listArticle(params?.limit, params?.skip, params?.title, params?.status,params?.cateCode);
    }

}