

export const PUBLIC_KEY = 
`-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0IFgGUpwipWFy9isfYtP
yrArl8msAs/9srHPpc+GGUgoauRxrrj8QFhC/IOczj2P5yHymMMz+0Tb6M3D+eB0
NeVhrOkVWqybkrFkqO5Vklbgm8YNwCYyKyIZOt8y4b5eYQNTB1xL4AjSAe1oRmm7
WWiGrATEhaLspZZwL3ndMBUS+cwe58pfIoLu+Wxr5bNCO1KkY1JkgI3W3exEeWfV
v5XeKNAkTMHS7b1gfB7Ww2aPcUh+rUaUAbYtl9q0Ja/65qhekjkjMih7JJ4+0Eej
Yc9GgWhFl8Pv9COvAEY3yHbwq2rYeREp1MjjeisxwiQFYicwCFg4CjCQShkP0W2n
eQIDAQAB
-----END PUBLIC KEY-----`;




export const HD = `/hd`;
export const CACHE = `/cache`;