

import { Delayer, PageResult } from "@framework/utils";

import { Pagination, PaginationProps, Select, SelectProps } from "antd";
import React, { ReactNode, useCallback, useEffect, useState } from "react";


export interface QuerySelectProps<T> extends Omit<SelectProps, "showSearch" | "options"> {
    current?: T
    keyOfLabel: keyof T
    keyOfValue: keyof T
    options?: T[]
    /**
     * 向 {@link SelectProps["onChange"]} 事件传递的参数类型；
     * * value: 传递 {@link keyOfValue} 属性值
     * * object: 传递当前选中项的所有数据
     * @default "value"
     */
    triggerValue?: "value" | "object"
    fetchItems?: (value?: string, limit?: number, skip?: number) => Promise<T[] | PageResult<T>>;
    renderOptions?: (value: T, index: number, selected: boolean) => ReactNode
}

export function QuerySelect<T>({ keyOfLabel, keyOfValue, current, fetchItems, triggerValue, dropdownRender, onSearch, searchValue, renderOptions, options, onChange, ...props }: QuerySelectProps<T>) {

    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<PaginationProps>();
    const [items, setItems] = useState<T[]>(options == null ? [] : options);

    const doFetch = async (value?: string, limit?: number, skip?: number) => {
        try {
            let result;
            setLoading(true);
            if (fetchItems == null) {
                setPagination(undefined);
                return;
            }
            const values = await fetchItems(value, limit, skip);
            if (values instanceof Array) {
                setPagination(undefined);
                result = values;
            } else {
                const pagination: PaginationProps = {
                    pageSize: values.limit,
                    total: values.total,
                    current: Math.ceil(values.skip / values.limit) + 1,
                    onChange(page, pageSize) {
                        doFetch(value, pageSize, (page - 1) * pageSize);
                    }
                }
                setPagination(pagination);
                result = values.info;
            }

            if (current != null && current[keyOfValue] != null) {
                let found = false;
                for (let item of result) {
                    if (item[keyOfValue] == current[keyOfValue]) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    result.unshift(current);
                }
            }
            setItems(result);
        } finally {
            setLoading(false);
        }
    }

    const dropDownRender = (menu: React.ReactElement) => {
        return (
            <div>
                {dropdownRender == null ? menu : dropdownRender(menu)}
                <Pagination size="small" {...pagination} />
            </div>
        )
    };

    const fireSearch = useCallback(onSearch != null ? onSearch : (fetchItems != null ? Delayer.debounce(doFetch, 500) : () => {}), [fetchItems, onSearch, current, keyOfValue])

    useEffect(() => {
        if (fireSearch != null) {
            fireSearch(searchValue as string);
        }
    }, [fireSearch, searchValue]);

    const finalOptions = items.map((item, index) => ({
        label: renderOptions == null ? item[keyOfLabel] as string : renderOptions(item, index, item[keyOfValue] == props.value),
        value: item[keyOfValue] as string,
        origin: item,
        name: item[keyOfLabel]
    }));

    const fireOnChange: SelectProps["onChange"] = (value, option) => {
        if (onChange != null) {
            if (triggerValue == null || triggerValue == "value") {
                onChange(value, option);
            } else {
                onChange((option as any).origin, option);
            }
        }
    }

    return (
        <Select
            loading={loading}
            onSearch={fireSearch}
            options={finalOptions}
            onChange={fireOnChange}
            showSearch={fetchItems != null}
            dropdownRender={dropDownRender}
            filterOption={fetchItems == null}
            {...props}
        />
    )
}