import { fetch } from "@root/app";
import { PageResult } from "@root/framework/utils";

export namespace ReportApi {
    /**
     * 支付方式
     */
    export enum PayType {
        /**
         * 微信小程序支付
         */
        WECHAT = 1,
        /**
         * 支付宝支付
         */
        ALI = 2,
        /**
         * 被扫支付
         */
        SCAN = 127
    }

    /**
     * 交易状态
     */
    export enum PayStatus {
        /**
         * 支付成功
         */
        SUCCESS = 0,
        /**
         * 支付中
         */
        PAYING = 1,
        /**
         * 支付失败
         */
        FAILED = 2,
        /**
         * 待撤销
         */
        WAIT_REVOKE = 3,
        /**
         * 待退款
         */
        WAIT_REFUND = 4,
        /**
         * 已撤销
         */
        REVOKED = 5,
        /**
         * 退款中
         */
        REFUNDING = 6,
        /**
         * 已退款
         */
        REFUNDED = 7
    }
    /**
     * 交易记录明细
     */
    export interface PayRecord {
        /**
         * id
         */
        id: string;
        /**
         * 收款平台 id
         */
        platformId: string;
        /**
         * 收款平台
         */
        platformName: string;
        /**
         * 交易号
         */
        payId: string;
        /**
         * 三方交易号
         */
        transactionId: string;
        /**
         * 商户订单号
         */
        orderId: string;
        /**
         * 平台订单号
         */
        platformOrderId: string;
        /**
         * 退订单号
         */
        refundOrderId?: string;
        /**
         * 支付方式
         */
        payType: string;
        /**
         * 交易金额
         */
        amount: number;
        /**
         * 已退金额
         */
        refundedAmount: number;
        /**
         * 交易说明
         */
        description?: string;
        /**
         * 交易时间
         */
        payTime: number;
        /**
         * 建立时间
         */
        createTime: number;
        /**
         * 收款人
         */
        payeeName: string;
        /**
         * 付款人
         */
        payerName?: string;
        /**
         * 交易状态
         */
        status: number;
        outId?: string;
    }

    /**
     * 缴款汇总表
     */
    export interface PaySummary {
        /**
         * 收款平台编号
         */
        platformId: string;
        /**
         * 收款平台名称
         */
        platformName: string;
        /**
         * 商户编号
         */
        merchantId: number;
        /**
         * 商户名称
         */
        merchantName: string;
        /**
         * 支付方式
         */
        payType: number;
        /**
         * 收款人id
         */
        payeeId: string;
        /**
         * 收款人名称
         */
        payeeName: string;
        /**
         * 0-收款  7-退款
         */
        status: number;
        /**
         * 总金额
         */
        amount: number;
    }

    export interface IncomeViewBase {
        /**
         * 商户编号
         */
        merchantId: number;
        /**
         * 商户名称
         */
        merchantName: string;
        /**
         * 商品分类编号
         */
        productCate: string;
        /**
         * 商品分类名称
         */
        productCateName: string;
        /**
         * 收入类型
         */
        incomeType: string;
        /**
         * 结算类型
         */
        settleType: number;
        /**
         * 商户折扣额
         */
        merchantDiscount: number;
        /**
         * 平台折扣额
         */
        platformDiscount: number;
        /**
         * 数量
         */
        qty: number;
        /**
         * 总金额
         */
        amount: number;
        /**
         * 结算收入
         */
        settleAmount: number;
        /**
         * 平台收入
         */
        actualAmount: number;

        totalAmount: number
    }

    /**
     * 营业收入
     */
    export interface BusinessIncome extends IncomeViewBase {
        /**
         * 成本
         */
        cost: number;
        /**
         * 开票金额
         */
        invoiceAmount: number;
    }

    /**
     * 预收款
     */
    export interface PrepaidIncome extends BusinessIncome {
    }

    /**
     * 营业收入/预收款明细
     */
    export interface IncomeDetail extends BusinessIncome {
        /**
         * id
         */
        id: number;
        /**
         * 商品编号
         */
        productId: string;
        /**
         * 商品名称
         */
        productName: string;
    }

    export interface MerchantIncome extends IncomeViewBase {
    }

    export interface MerchantIncomeDetail extends MerchantIncome {
        /**
         * id
         */
        id: number;
        /**
         * 商品编号
         */
        productId: string;
        /**
         * 商品名称
         */
        productName: string;
    }
    
    export interface Platform {
        id: string
        name: string
        effectTime: number
        expireTime?: number
        createTime: number
        status: number
    }

    export function listPlatform(name?: string): Promise<Platform[]> {
        return fetch.get<Platform[]>("/api/platform", { name });
    }


    /**
     * 生成财务报表
     * 
     * @param date 报表日期
     */
    export function generate(date: number): Promise<void> {
        return fetch.post("/api/report/generate", { date });
    }

    /**
     * 查询收退款记录。
     * 
     * @param startDate 开始日期
     * @param endDate 结束日期
     * @param limit 分页大小
     * @param skip  分页位置
     * @param merchantName 商户名称
     * @param merchantId 商户编号
     * @param orderId 商户订单号
     * @param payTypes 支付方式
     * @param status 交易状态
     */
    export function listPayment(
        startDate: number, endDate: number, limit?: number, skip?: number,
        merchantName?: string, merchantId?: number, orderId?: string,
        payTypes?: string, status?: number, platformIds?: string
    ): Promise<PayRecord[]> {
        return fetch.get<PayRecord[]>("/api/report/payment/detail", { startDate, endDate, limit, skip, merchantId, merchantName, orderId, payTypes, status, platformIds });
    }

    /**
     * 查询缴款汇总表。
     * 
     * @param startDate 开始日期
     * @param endDate 结束日期
     * @param merchantName 商户名称
     * @param payTypes 支付方式
     * @param status 交易状态
     */
    export function listPaymentSummary(startDate: number, endDate: number, merchantName?: string, payTypes?: string, status?: number, platformIds?: string): Promise<PaySummary[]> {
        return fetch.get<PaySummary[]>("/api/report/payment/summary", { startDate, endDate, merchantName, payTypes, status, platformIds });
    }

    /**
     * 查询营业收入报表。
     * 
     * @param startDate 开始日期
     * @param endDate 结束日期
     * @param merchantName 商户名称
     * @param cateName 商品分类
     * @param incomeTypes 收入类型
     */
    export function listBusiness(startDate: number, endDate: number, merchantName?: string, cateName?: string, incomeTypes?: string, settleType?: number): Promise<BusinessIncome[]> {
        return fetch.get<BusinessIncome[]>("/api/report/business/income", { startDate, endDate, merchantName, cateName, incomeTypes, settleType });
    }

    /**
     * 查询营业收入明细。
     * 
     * @param startDate 开始日期
     * @param endDate 结束日期
     * @param limit 分页大小
     * @param skip 分页位置
     * @param merchantId 商户编号
     * @param cateCode 商品分类编号
     * @param incomeTypes 收入类型
     */
    export function listBusinessDetail(startDate: number, endDate: number, limit?: number, skip?: number, merchantId?: number, cateCode?: string, incomeTypes?: string, settleType?: number): Promise<PageResult<IncomeDetail>> {
        return fetch.get<PageResult<IncomeDetail>>("/api/report/business/detail", { limit, skip, startDate, endDate, merchantId, cateCode, incomeTypes, settleType });
    }

    /**
     * 查询预收款报表。
     * 
     * @param startDate 开始日期
     * @param endDate 结束日期
     * @param merchantName 商户名称
     * @param cateName 商品分类
     * @param incomeTypes 收入类型
     */
    export function listPrepaid(startDate: number, endDate: number, merchantName?: string, cateName?: string, incomeTypes?: string, settleType?: number): Promise<PrepaidIncome[]> {
        return fetch.get<PrepaidIncome[]>("/api/report/prepaid/income", { startDate, endDate, merchantName, cateName, incomeTypes, settleType });
    }

    /**
     * 查询预收款明细。
     * 
     * @param startDate 开始日期
     * @param endDate 结束日期
     * @param limit 分页大小
     * @param skip 分页位置
     * @param merchantId 商户编号
     * @param cateCode 商品分类编号
     * @param incomeTypes 收入类型
     */
    export function listPrepaidDetail(startDate: number, endDate: number, limit?: number, skip?: number, merchantId?: number, cateCode?: string, incomeTypes?: string, settleType?: number): Promise<PageResult<IncomeDetail>> {
        return fetch.get<PageResult<IncomeDetail>>("/api/report/prepaid/detail", { limit, skip, startDate, endDate, merchantId, cateCode, incomeTypes, settleType });
    }

    export function listMerchantBusiness(startDate: number, endDate: number, cateName?: string, incomeTypes?: string, settleType?: number): Promise<MerchantIncome[]> {
        return fetch.get<MerchantIncome[]>("/api/report/business/merchant/income", { startDate, endDate, cateName, incomeTypes, settleType });
    }

    /**
     * 查询商户对账明细。
     * 
     * @param startDate 开始日期
     * @param endDate 结束日期
     * @param limit 分页大小
     * @param skip 分页位置
     * @param cateCode 商品分类编号
     * @param incomeTypes 收入类型
     */
    export function listMerchantBusinessDetail(startDate: number, endDate: number, limit?: number, skip?: number, merchantId?: number, cateCode?: string, incomeTypes?: string, settleType?: number): Promise<PageResult<MerchantIncomeDetail>> {
        return fetch.get<PageResult<MerchantIncomeDetail>>("/api/report/business/merchant/detail", { limit, skip, startDate, endDate, merchantId, cateCode, incomeTypes, settleType });
    }
}