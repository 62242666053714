import { Modal, ModalProps, PageHeader, StepProps, Steps, StepsProps } from "antd";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";


export interface StepsModalProps extends ModalProps {
    /**
     * 当前步骤
     */
    current?: number
    /**
     * 步骤变更时触发。
     * 
     * @param step     当前步骤
     * @param prevStep 前一步骤
     * @returns 若可跳转到指定页面返回 true，否则返回 false。
     */
    onStepChanged?: (step: number, prevStep?: number) => Promise<boolean>
    /**
     * 配置步骤列表
     */
    steps?: StepProps[]
}

export function StepsModal({ title, steps, children, okText, cancelText, onStepChanged, onOk, onCancel, ...props }: StepsModalProps) {

    const [current, setCurrent] = useState<number | undefined>(undefined);

    function updateCurrentStep(next: number, current?: number) {
        if (onStepChanged != null) {
            onStepChanged(next, current).then(() => setCurrent(next));
        } else {
            setCurrent(next);
        }
    }

    useEffect(() => {
        const step = props.current == null || steps == null ? 0 : (props.current >= steps.length ? steps.length : props.current);
        if (steps != null) {
            updateCurrentStep(step, current);
        }
    }, [steps, props.current]);


    const TitleNode = useMemo(() => {
        if (steps == null) {
            return title;
        } else {
            const currentStep = current == null ? 0 : current;
            const step = steps[currentStep];
            return <PageHeader title={step.title} subTitle={step.subTitle} extra={title} backIcon={currentStep > 0 ? undefined : false} onBack={() => updateCurrentStep(currentStep - 1, current)} />;
        }
    }, [steps, current, title]);

    const StepsView = useMemo(() => {
        if (steps == null) {
            return undefined;
        } else {
            return (<Steps size="small" labelPlacement="vertical" current={current} items={steps} />)
        }
    }, [steps, current]);

    const hasPrev = steps != null && steps.length > 1 && current != null && current > 0;
    const hasNext = steps != null && steps.length > 1 && (current == null || current < steps.length - 1);

    const fireOnOk = (e: React.MouseEvent<HTMLElement>) => {
        if (hasNext) {
            const prev = current == null ? 0 : current;
            const next = prev >= steps.length ? steps.length - 1 : prev + 1;
            if (onStepChanged) {
                onStepChanged(next, prev).then(() => setCurrent(next));
            } else {
                setCurrent(next);
            }
        } else if (onOk != null) {
            onOk(e);
        }
    }

    const fireOnCancel = (e: React.MouseEvent<HTMLElement>) => {
        if (hasPrev) {
            const prev = current == null ? 0 : current;
            const next = prev == 0 ? 0 : prev - 1;
            if (onStepChanged) {
                onStepChanged(next, prev).then(() => setCurrent(next));
            } else {
                setCurrent(next);
            }
        } else if (onCancel != null) {
            onCancel(e);
        }
    }

    return (
        <Modal
            {...props}
            onOk={fireOnOk}
            title={TitleNode}
            onCancel={fireOnCancel}
            okText={hasNext ? "下一步" : okText}
            cancelText={hasPrev ? "上一步" : cancelText}
        >
            {StepsView}
            {children}
        </Modal>
    )
}