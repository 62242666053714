

import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { RangePickerProps } from "antd/lib/date-picker";

import styles from "@framework/styles/week-day-picker.less";

export type WeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7

interface ExtraProps{
    onChange?: (value?: Range<WeekDay>) => void;

    value?: Range<WeekDay>

    weekDayMap?: Record<WeekDay, string>
}


export interface Range<T> {
    start: T
    end: T
}

export type WeekDayRangePickerProps = ExtraProps & Omit<RangePickerProps, "format" | "defaultValue" | "value" | "dateRender" | "disabledDate" | "picker" | "ranges"> 

const START = moment({year: 2000, month: 4, date: 1}).valueOf(); // 2000-5-1
const END = moment({year: 2000, month: 4, date: 7, hour: 23, minute: 59, second: 59, millisecond: 999}).valueOf();   // 2000-5-7

const rangeToMoment = (range?: Range<WeekDay>) => {
    if (range == null) {
        return null;
    } else if (range instanceof Array) {
        return range as unknown as [Moment, Moment];
    }
    return [moment({year: 2000, month: 4, date: range.start }), moment({year: 2000, month: 4, date: range.end})]  as unknown as [Moment, Moment];
}

const DEFAULT_WEEK_DAY_MAP: Record<WeekDay, string> = {
    1: "周一",
    2: "周二",
    3: "周三",
    4: "周四",
    5: "周五",
    6: "周六",
    7: "周日"
}

export function WeekDayRangePicker({ onChange, value, weekDayMap, dropdownClassName, ...props }: WeekDayRangePickerProps) {
    
    const weekdayMap = weekDayMap == null ? DEFAULT_WEEK_DAY_MAP : weekDayMap;

    const onValueChange = (dates: any) => {
        if (onChange != null) {
            if (dates == null || !(dates instanceof Array)) {
                onChange();
            } else {
                onChange( { start: (dates[0] as Moment).isoWeekday() as WeekDay, end: (dates[1] as Moment).isoWeekday() as WeekDay })
            }
        }
    }

    const defaultDisabledDate = (date: Moment) => {
        let timestamp = date.valueOf();
        return timestamp < START || timestamp > END;
    }

    const weekDayRender = (currentDate: Moment, today: Moment, info: any) => {
        let timestamp = currentDate.valueOf();
        if (timestamp < START || timestamp > END) {
            return <span className="no-display"></span>;
        } else {
            return <div className="ant-picker-cell-inner">{weekdayMap[currentDate.isoWeekday() as WeekDay]}</div>;
        }
    }
    
    return (
        <DatePicker.RangePicker 
            {...props}
            picker="date"
            dropdownClassName={`${styles.hideYearSelector}${dropdownClassName == null ? "" : " " + dropdownClassName}`}
            onChange={onValueChange} 
            dateRender={weekDayRender}
            format={(date) => weekdayMap[date.isoWeekday() as WeekDay]}
            defaultValue={rangeToMoment(value)}
            defaultPickerValue={[moment(START), moment(END)]}
            disabledDate={defaultDisabledDate}
        />
    )
}