

import { PageResult } from "@framework/utils";

import { ProductApi } from ".";
import { fetch } from "@root/app"
import { MerchantApi } from "./merchant";


export namespace VoucherApi {

    export enum BatchStatus {
        New = 1,
        /**
         * 待审核
         */
        Pending = 2,
        /**
         * 已审核
         */
        Effected = 3,
        /**
         * 已停用
         */
        Stoped = 4,
        /**
         * 已驳回
         */
        Rejected = 5
    }

    export enum VoucherType {
        /**
         * 立减
         */
         Instant = 1,
         /**
          * 用券
          */
         Coupon = 2
    }

    export enum MakeMode {
        /**
         * 自动生成
         */
        Auto = 0,
        /**
         * 手动导入
         */
        Import = 1
    }

    export enum ExpireType {
        /**
         * 
         */
        Fixed = 1,
        /**
         * 顺延
         */
        Delay = 2
    }

    export enum VoucherStatus {
        /**
         * 不可用
         */
        UnAvailable = 0,
        /**
         * 可用
         */
        Available = 1,
        /**
         * 已使用
         */
        Used = 2,
        /**
         * 已删除
         */
        Deleted = 3,
        /**
         * 已过期
         */
        Expired = 4
    }

    export enum Rule {
        /**
         * 满减
         */
        MXMY = 1,
        /**
         * 阶梯满减
         */
        PMXMY = 2,
        /**
         * 折扣
         */
        MXDY = 3
    }

    export interface VoucherBatch {
        /**
         * 标识
         */
        id?: string;
        /**
         * 制券方式
         */
        makeCouponMode?: MakeMode;
        /**
         * 优惠券名称。
         */
        name?: string;
        /**
         * 类型，立减-1       领取-2
         */
        type?: VoucherType;
        /**
         * 活动id
         */
        activityId?: string;
        /**
         * 活动标题
         */
        activityTitle?: string;
        /**
         * 优惠金额
         */
        discount?: number;
        /**
         * 优惠规则，满减-1   重复满减-2    折扣-3
         */
        discountRule?: Rule;
        /**
         * 生效时间
         */
        startTime?: number;
        /**
         * 结束时间，立减券：使用结束时间；领取券：领券结束时间
         */
        endTime?: number;
        /**
         * 结束时间类型，固定时间-1    顺延-2
         */
        expireType?: ExpireType;
        /**
         * 过期时间，顺延存期限（天），固定期限存时间戳
         */
        expireTime?: number;
        /**
         * 最大发放数量
         */
        maxGrantQty?: number;
        /**
         * 商户id
         */
        merchantId?: number;
        /**
         * 商户名称
         */
        merchantName?: string;
        /**
         * 限制金额
         */
        restrictPrice?: number;
        /**
         * 限制数量，立减限制使用数量，领券限制领取数量
         */
        restrictQty?: number;
        /**
         * 创建时间，时间戳
         */
        createTime?: number;
        /**
         * 创建人
         */
        createUserName?: string;
        /**
         * 最后修改时间，时间戳
         */
        lastUpdateTime?: number;
        /**
         * 优惠劵规则
         */
        rule?: string
        /**
         * 最后修改人
         */
        lastUpdateUserName?: string;
        /**
         * 状态，待审核-1   可发放（优惠券）/可使用（立减券）-2     禁用-3      已过期-4
         */
        status?:  BatchStatus;
        restrictMerchantIds?: string;

        restrictProductIds?: string;

        restrictCateCodes?: string;

        restrictMerchantList?: MerchantApi.Merchant[]

        restrictCateList?: ProductApi.ProductCate[]
        restrictProductSet?: string[]
    }

    /**
     * 优惠券信息
     */
    export interface Voucher {
        /**
         * 标识
         */
        no?: string;
        /**
         * 外部券号
         */
        externalNo?: string;
        /**
         * 批次编号
         */
        couponId: string;
        /**
         * 生效时间
         */
        takeEffectTime?: number;
        /**
         * 过期时间
         */
        expireTime?: number;
        /**
         * 发放时间
         */
        grantTime?: number;
        /**
         * 发放人
         */
        grantUserName?: string;
        /**
         * 使用时间
         */
        usedTime?: number;
        /**
         * 关联用户
         */
        userName?: string
        /**
         * 状态
         */
        status?: VoucherStatus;
    }

    /**
     * 建立用券批次
     * 
     * @param name                优惠券名称
     * @param activityId          关联活动
     * @param type                券类型
     * @param discountRule        用券规则
     * @param discount            优惠金额/折扣率
     * @param startTime           开始日期
     * @param endTime             立减-结束日期 用券-发放截止日期
     * @param expireType          过期方式 1-顺延 2-固定期限
     * @param expireTime          过期时间/顺延天数
     * @param restrictPrice       最低消费
     * @param restrictQty         立减限制使用数量，领券限制领取数量
     * @param maxGrantQty         最大发放数
     * @param restrictMerchantIds 限制商户（多个逗号分割）
     * @param restrictCateCodes   限制品类（多个逗号分割）
     * @param restrictProductIds  限制商品（多个逗号分割）
     * 
     * @returns 
     */
    export async function addBatch(name?: string, activityId?: string, type?: VoucherType, makeCouponMode?: MakeMode,
        discountRule?: Rule, discount?: number, startTime?: number, endTime?: number, expireType?: number,
        expireTime?: number, restrictPrice?: number, restrictQty?: number, maxGrantQty?: number,
        restrictMerchantIds?: string, restrictCateCodes?: string, restrictProductIds?: string) {
        return fetch.post<VoucherBatch>("/api/coupon/batch", { 
            name, makeCouponMode, activityId, type, discountRule, discount, startTime, endTime, expireType, expireTime,
            restrictPrice, restrictQty, maxGrantQty, restrictMerchantIds, restrictCateCodes, restrictProductIds
         });
    }

    export async function detail(id?: string) {
        return fetch.get<VoucherBatch>(`/api/coupon/batch/${id}`);
    }

    /**
     * 导入外部优惠券
     * 
     * @param id   批次编号
     * @param file 存放外部优惠券的 excel 文件
     */
    export async function importVoucher(id: string, file: File) {
        const data = new FormData();
        data.append("file", file);
        return fetch.postFormData<void>(`/api/coupon/import/${id}`, data);
    }

    export async function listBatch(limit?: number, skip?: number, discountRule?: Rule, type?: VoucherType, status?: BatchStatus,name?:string) {
        return fetch.get<PageResult<VoucherBatch>>("/api/coupon/batch", { limit, skip, discountRule, type, status,name });
    }

    /**
     * 建立用券批次
     * 
     * @param id                  批次编号
     * @param name                优惠券名称
     * @param activityId          关联活动
     * @param type                券类型
     * @param discountRule        用券规则
     * @param discount            优惠金额/折扣率
     * @param startTime           开始日期
     * @param endTime             立减-结束日期 用券-发放截止日期
     * @param expireType          过期方式 1-顺延 2-固定期限
     * @param expireTime          过期时间/顺延天数
     * @param restrictPrice       最低消费
     * @param restrictQty         立减限制使用数量，领券限制领取数量
     * @param maxGrantQty         最大发放数
     * @param restrictMerchantIds 限制商户（多个逗号分割）
     * @param restrictCateCodes   限制品类（多个逗号分割）
     * @param restrictProductIds  限制商品（多个逗号分割）
     * 
     * @returns 
     */
    export async function updateBatch(id?: string, name?: string, activityId?: string, type?: VoucherType, makeCouponMode?: MakeMode,
                                    discountRule?: Rule, discount?: number, startTime?: number, endTime?: number, expireType?: number,
                                    expireTime?: number, restrictPrice?: number, restrictQty?: number, maxGrantQty?: number) {
        return fetch.put<void>(`/api/coupon/batch/${id}`, { 
            name, activityId, type, discountRule, discount, startTime, endTime, expireType, expireTime,
            restrictPrice, restrictQty, maxGrantQty, makeCouponMode
        });
    }

    export async function updateRestrict(id?: string, merchantIds?: string, cateCodes?: string, productIds?: string) {
        return fetch.put<void>(`/api/coupon/batch/restrict/${id}`, { merchantIds, cateCodes, productIds });
    }

    /**
     * 查询优惠券列表。
     * 
     * @param limit 分页大小
     * @param skip 分页位置
     * @param discountRule 活动规则
     * @param type 优惠券类型
     * @param status 优惠券状态
     * @param couponBatchId 批次编号
     * @returns 
     */
    export async function listVoucher(couponBatchId?: string, limit?: number, skip?: number, discountRule?: Rule, type?: VoucherType, status?: BatchStatus) {
        return fetch.get<PageResult<Voucher>>("/api/coupon", { limit, skip, discountRule, type, status, couponBatchId });
    }

    /**
     * 查询指定优惠券批次当前设定的商品列表。
     * 
     * @param couponBatchId 优惠券批次编号
     * @param limit 分页大小
     * @param skip 分页位置
     * @returns 
     */
    export async function listLimitedProducts(couponBatchId?: string, limit?: number, skip?: number) {
        return fetch.get<PageResult<ProductApi.Product>>("/api/coupon/batch/restrict/product", {limit, skip, couponBatchId});
    }

    /**
     * 审核指定优惠券活动申请
     * 
     * @param batchId 优惠券批次
     * @returns 
     */
    export async function audit(batchId?: string) {
        return fetch.patch(`/api/coupon/batch/audit/adopt/${batchId}`);
    }

    /**
     * 给指定用户发放优惠券
     * @param batchId 全批次
     * @param userIds 用户
     * @returns 
     */
    export async function grant(batchId?: string, userIds?: string) {
        return fetch.post(`/api/coupon/batch/grant/${batchId}`, { userIds });
    }

    /**
     * 驳回指定优惠券活动申请。
     * 
     * @param batchId 优惠券批次
     * @returns 
     */
     export async function reject(batchId?: string, reason?: string) {
        return fetch.patch(`/api/coupon/batch/audit/reject/${batchId}`, { reason });
    }

    /**
     * 停用指定批次，停用后不允许领取及发放对应优惠券。
     * @param id 批次编号
     */
    export async function stop(id?: string) {
        return fetch.patch<void>(`/api/coupon/batch/disabled/${id}`);
    }
    
    /**
     * 提交审核。
     * @param id 批次编号
     */
     export async function submit(id?: string) {
        return fetch.patch<void>(`/api/coupon/batch/submit/${id}`);
    }

    /**
     * 优惠券活动延期。
     * 
     * @param batchId 优惠券批次
     * @returns 
     */
    export async function extension(batchId?: string, time?: number) {
        return fetch.post(`/api/coupon/batch/extension/${batchId}`, { time });
    }

    /**
     * 保存优惠劵批次规则
     * @param id 批次id
     * @param ruleContent 规则内容
     * @returns 
     */
    export async function setDiscountRule(id?: string, ruleContent?: string) {
        return fetch.post(`/api/coupon/batch/rule/${id}`, {ruleContent})
    }
}