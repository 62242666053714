

import { ReactNode } from "react";
import { LiteralUnion } from "antd/es/_util/type";
import { PresetColorType } from "antd/es/_util/colors";
import { EditOutlined, LockOutlined } from "@ant-design/icons";

import { MerchantApi, MerchantCateApi, OrderApi, ProductApi, VoucherApi, ActivityApi, ArticleApi, AdvertisingApi, MarketingApi, ReportApi } from "@api";

const DEFAULT: TagItem = { name: "", color: "" }

export interface TagItem {
    name: string | ReactNode
    secName?: string
    color: LiteralUnion<PresetColorType, string>
    fontColor?: LiteralUnion<PresetColorType, string>
}

export const UserStatusMap: Record<string, TagItem> = {
    true: { name: "启用", color: "blue" },
    false: { name: "禁用", color: "#999999" }
}

import MerchantCateStatus = MerchantCateApi.CateStatus;
export const MerchantCateStatusMap: Record<number, TagItem> = {
    [MerchantCateStatus.Show]: { name: "显示", color: "blue" },
    [MerchantCateStatus.Hidden]: { name: "隐藏", color: "magenta" }
}

import MerchantStatus = MerchantApi.Status;
export const MerchantStatusMap: Record<number, TagItem> = {
    [MerchantStatus.Opened]: { name: "营业中", color: "blue" },
    [MerchantStatus.Closed]: { name: "休息中", color: "magenta" },
    [MerchantStatus.Disabled]: { name: "禁用", color: "#999999" }
};

import InvoiceParty = MerchantApi.InvoiceParty;
export const InvoicePartyMap: Record<number, TagItem> = {
    [InvoiceParty.Platform]: { name: "平台", color: "blue" },
    [InvoiceParty.Merchant]: { name: "商户", color: "magenta" }
};


export const MerchantTagMap: Record<string, TagItem> = {
    "1": { name: "城市名片", color: "orange" },
    "2": { name: "网红打卡", color: "volcano" },
    "3": { name: "当季旅游", color: "cyan" }
}


import ProductCateStatus = ProductApi.ProductCateStatus;
export const ProductCateStatusMap: Record<number, TagItem> = {
    [ProductCateStatus.enabled]: { name: "启用", color: "blue" },
    [ProductCateStatus.disabled]: { name: "禁用", color: "magenta" }
}

import ProductStatus = ProductApi.ProductStatus;
//1- 待审核 2- 审核通过 （仓库） 3- 驳回 4- 上架 (出售中) 5- 下架 （仓库） 6- 删除 7- 禁用
export const ProductStatusMap: Record<number, TagItem> = {
    [ProductStatus.New]: { name: "待提交", secName: "待提交", color: "purple" },
    [ProductStatus.UnderReview]: { name: "待审核", secName: "审核中", color: "lime" },
    [ProductStatus.Passed]: { name: "已审核", secName: "待上架", color: "orange" },
    [ProductStatus.Rejected]: { name: "已驳回", secName: "驳回", color: "magenta" },
    [ProductStatus.Available]: { name: "已上架", secName: "出售中", color: "blue" },
    [ProductStatus.Removed]: { name: "已下架", secName: "待上架", color: "yellow" },
    [ProductStatus.Deleted]: { name: "已删除", secName: "已删除", color: "red" },
    [ProductStatus.Disabled]: { name: "已上架(商户被禁用)", secName: "已上架(商户被禁用)", color: "" },
}

export const BrandStatusMap: Record<number, TagItem> = {
    1: { name: "启用", color: "blue" },
    2: { name: "禁用", color: "magenta" }
}

import SpecStatus = ProductApi.SpecStatus;
export const SpecStatusMap: Record<number, TagItem> = {
    [SpecStatus.Editable]: { name: <EditOutlined />, color: "blue" },
    [SpecStatus.Sealed]: { name: <LockOutlined />, color: "" }
}


import SkuStatus = ProductApi.SkuStatus;
export const SkuStatusMap: Record<number, TagItem> = {
    [SkuStatus.Enabled]: { name: "启用", color: "blue" },
    [SkuStatus.Disabled]: { name: "禁用", color: "magenta" }
}

import BatchStatus = VoucherApi.BatchStatus;
/**
 * 批次状态
 */
export const BatchStatusMap: Record<number, TagItem> = {
    [BatchStatus.New]: { name: "新建", secName: "待提交", color: "purple" },
    [BatchStatus.Pending]: { name: "待审核", secName: "审核中", color: "lime" },
    [BatchStatus.Effected]: { name: "已审核", secName: "已生效", color: "blue" },
    [BatchStatus.Stoped]: { name: "已停用", secName: "已停用", color: "magenta" },
    [BatchStatus.Rejected]: { name: "已驳回", secName: "驳回", color: "yellow" }
}

import VoucherType = VoucherApi.VoucherType;
/**
 * 优惠方式
 */
export const VoucherTypeMap: Record<number, TagItem> = {
    [VoucherType.Instant]: { name: "立减", color: "blue" },
    [VoucherType.Coupon]: { name: "用券", color: "orange" }
}

import VoucherRule = VoucherApi.Rule;
/**
 * 优惠规则
 */
export const VoucherRuleMap: Record<number, TagItem> = {
    [VoucherRule.MXMY]: { name: "满减", color: "purple" },
    [VoucherRule.PMXMY]: { name: "阶梯满减", color: "orange" },
    [VoucherRule.MXDY]: { name: "满额折扣", color: "yellow" }
}

import VoucherStatus = VoucherApi.VoucherStatus;
/**
 * 优惠券状态
 */
export const VoucherStatusMap: Record<number, TagItem> = {
    [VoucherStatus.UnAvailable]: { name: "未发放", color: "" },
    [VoucherStatus.Available]: { name: "可用", color: "purple" },
    [VoucherStatus.Used]: { name: "已使用", color: "lime" },
    [VoucherStatus.Deleted]: { name: "已删除", color: "red" },
    [VoucherStatus.Expired]: { name: "已过期", color: "yellow" }
}

import ActivityStatus = ActivityApi.Status;
export const ActivityStatusMap: Record<number, TagItem> = {
    [ActivityStatus.New]: { name: "新建", color: "purple" },
    [ActivityStatus.Available]: { name: "已开始", color: "blue" },
    [ActivityStatus.Paused]: { name: "暂停", color: "orange" },
    [ActivityStatus.Finished]: { name: "已停止", color: "#999999" },
}

export const OrderTypeMap: Record<string, TagItem> = {
    "Platform": { name: "平台订单", color: "gold" },
    "Merchant": { name: "商户订单", color: "orange" },
    "Refund": { name: "退款订单", color: "magenta" }
}

import OrderStatus = OrderApi.OrderStatus;
export const OrderStatusMap: Record<number, TagItem> = {
    [OrderStatus.New]: { name: "新建", color: "purple" },
    [OrderStatus.Paying]: { name: "正在支付", color: "orange" },
    [OrderStatus.Paid]: { name: "已支付", color: "blue" },
    [OrderStatus.Finished]: { name: "交易完成", color: "gold" },
    [OrderStatus.Closed]: { name: "已关闭", color: "#999999" }
}

import RefundOrderStatus = OrderApi.RefundOrderStatus;
export const RefundOrderStatusMap: Record<number, TagItem> = {
    [RefundOrderStatus.Pending]: { name: "等待商家确认", color: "purple" },
    [RefundOrderStatus.Refunding]: { name: "正在退款", color: "orange" },
    [RefundOrderStatus.Refunded]: { name: "已退款", color: "blue" },
    [RefundOrderStatus.Rejected]: { name: "已拒绝", color: "magenta" },
    [RefundOrderStatus.Closed]: { name: "已关闭", color: "#999999" }
}

import OrderProductStatus = OrderApi.ProductStatus;
export const OrderProductStatusMap: Record<number, TagItem> = {
    [OrderProductStatus.New]: { name: "待付款", secName: "用户未支付", color: "purple" },
    [OrderProductStatus.Paid]: { name: "待发货", secName: "用户已付款", color: "blue" },
    [OrderProductStatus.Shipped]: { name: "已发货", secName: "商户已发货", color: "magenta" },
    [OrderProductStatus.Complete]: { name: "交易完成", secName: "用户已收货", color: "gold" },
    [OrderProductStatus.Revoked]: { name: "已撤销", secName: "已关闭", color: "" }
}

export const RefundProductStatusMap: Record<number, TagItem> = {
    [OrderProductStatus.New]: { name: "待确认", secName: "待商户确认", color: "purple" },
    [OrderProductStatus.Paid]: { name: "等待用户发货", secName: "等待用户发货", color: "blue" },
    [OrderProductStatus.Shipped]: { name: "用户已发货", secName: "用户已发货", color: "magenta" },
    [OrderProductStatus.Complete]: { name: "已收货", secName: "商户已收货", color: "gold" },
    [OrderProductStatus.Revoked]: { name: "已撤销", secName: "已关闭", color: "" }
}

import PaymentStatus = OrderApi.PaymentStatus;

export const PaymentStatusMap: Record<number, TagItem> = {
    [PaymentStatus.Success]: { name: "支付成功", color: "blue" },
    [PaymentStatus.Paying]: { name: "正在支付", color: "purple" },
    [PaymentStatus.Failed]: { name: "支付失败", color: "red" },
    [PaymentStatus.WaitRevoke]: { name: "等待撤销", color: "" },
    [PaymentStatus.WaitRefund]: { name: "等待退款", color: "magenta" },
    [PaymentStatus.Revoked]: { name: "已撤销", color: "#999999" },
    [PaymentStatus.Refunding]: { name: "退款中", color: "orange" },
    [PaymentStatus.Refunded]: { name: "已退款", color: "orange" }
}

import ArticleCateStatus = ArticleApi.ArticleCateStatus;
export const ArticleCateStatusMap: Record<number, TagItem> = {
    [ArticleCateStatus.Enabled]: { name: "启用", color: "blue" },
    [ArticleCateStatus.Disabled]: { name: "禁用", color: "magenta" }
}

import ArticleStatus = ArticleApi.ArticleStatus;
export const ArticleStatusMap: Record<number, TagItem> = {
    [ArticleStatus.New]: { name: "待发布", color: "purple" },
    [ArticleStatus.UnderReview]: { name: "审核中", color: "gold" },
    [ArticleStatus.Rejected]: { name: "已驳回", secName: "审核未通过", color: "#999999" },
    [ArticleStatus.Published]: { name: "已发布", color: "blue" },
    [ArticleStatus.Deleted]: { name: "已删除", color: "red" },
}

import AdvertisingStatus = AdvertisingApi.AdvertisingStatus;
export const AdvertisingStatusMap: Record<number, TagItem> = {
    [AdvertisingStatus.Enabled]: { name: "启用", color: "blue" },
    [AdvertisingStatus.Disabled]: { name: "禁用", color: "magenta" }
}

import MarketingStatus = MarketingApi.marketingStatus;
export const MarketingStatusMap: Record<number, TagItem> = {
    [MarketingStatus.Enabled]: { name: "启用", color: "blue" },
    [MarketingStatus.Disabled]: { name: "禁用", color: "magenta" }
}

import PickUpType = ProductApi.PickUpType;
export const PickUpTypeMap: Record<number, TagItem> = {
    [PickUpType.SelfPickUp]: { name: "自取", color: "" },
    [PickUpType.SendByPost]: { name: "邮寄", color: "blue" },
    [PickUpType.Free]: { name: "用户下单时选择", color: "magenta" }
}

export const repeatNoticeStatusMap: Record<string, TagItem> = {
    true: { name: "重复", color: "blue" },
    false: { name: "每天一次", color: "magenta" }
}

import PayType = ReportApi.PayType;
export const PayTypeMap: Record<string, TagItem> = {
    [PayType.ALI]: { name: "支付宝", color: " magenta" },
    [PayType.WECHAT]: { name: "微信小程序", color: "blue" },
    [PayType.SCAN]: { name: "付款码支付", color: "gold" }
}

export const CalendarStockStatusMap: Record<number, TagItem> = {
    1: { name: "启用", color: "blue" },
    2: { name: "禁用", color: "magenta" }
}

export const CalendarPriceStatusMap: Record<number, TagItem> = {
    0: { name: "新建", color: "blue" },
    1: { name: "生效", color: "green" },
    2: { name: "失效", color: "yellow" },
    3: { name: "停止", color: "magenta" }
}

export function getTagItem(key: string | number | undefined, map: Record<string | number, TagItem>) {
    return key == null ? DEFAULT : (map[key] ? map[key] : DEFAULT);
}