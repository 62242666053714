

import { Button } from "antd";
import { MouseEvent, useEffect, useState } from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { EditableTable, EditableTableProps, TableColumn } from "./editable-table";

export interface TableFieldProps<RecordType> extends Omit<EditableTableProps<RecordType>, "pagination" | "rowKey"> {
    onItemsChange?: (value: readonly RecordType[]) => void

    newItem?: () => RecordType

    rowKey?: keyof RecordType | ((record: RecordType, index?: number) => (string | number))

    allowRemove?: (record: RecordType) => boolean
}

export function TableField<RecordType extends object>({ onItemsChange, newItem, columns, dataSource, allowRemove, rowKey, ...props}: TableFieldProps<RecordType>) {
    const [ itemsSource, setItemsSource ] = useState<RecordType[]>([]);
    const [ nextRowKey, setNextRowKey ] = useState(1);
    const [ keyMap ] = useState(() => {
        const map: Record<string, RecordType> = {};
        if (rowKey == null) {
            let nextKey = nextRowKey;
            for (let item of itemsSource) {
                map[nextKey] = item;
                nextKey++;
            }
            setNextRowKey(nextKey);
        }
        return map;
    });

    useEffect(() => {
        setItemsSource((dataSource == null ? [] : dataSource) as any);
    }, [dataSource])

    const fireItemsChange = (items: readonly RecordType[]) => {
        if (onItemsChange != null) {
            onItemsChange(items);
        }
    }

    const actualRowKey = rowKey != null ? rowKey : (data: RecordType) => {
        return Object.keys(keyMap).reduce((prev, key) => {
            if (prev !== "") {
                return prev;
            } else if (keyMap[key] == data) {
                return key;
            } else {
                return "";
            }
        }, "");
    };

    const onAddItem = (event: MouseEvent<HTMLSpanElement>) => {
        if (newItem == null) {
            return;
        }
        const item = newItem();
        if (rowKey == null) {
            keyMap[nextRowKey] = item;
            setNextRowKey(nextRowKey + 1);
        }
        let newItems = [...itemsSource, item];
        setItemsSource(newItems);
        fireItemsChange(newItems);
    }

    const onRemoveItem = (index: number, event: MouseEvent<HTMLSpanElement>) => {
        if (rowKey == null && actualRowKey instanceof Function) {
            let key = actualRowKey(itemsSource[index]);
            delete keyMap[key];
        }
        let newItems: RecordType[] = itemsSource.reduce((prev, current, currentIndex) => {
            if (index !== currentIndex) {
                prev.push(current);
            }
            return prev;
        }, [] as RecordType[]);

        setItemsSource(newItems);
        fireItemsChange(newItems);
    }
    const finalColumns = [...columns];

    let rowHeader: TableColumn<RecordType> = {
        width: "40px",
        title: <Button type="link" size="small" onClick={onAddItem}><PlusCircleOutlined /></Button> ,
        render: (value, record, index) => index + 1
    }
    finalColumns.unshift(rowHeader);
    finalColumns.push( {
        width: "40px",
        render: (value, record, index) => (
            <Button
                type="link"
                size="small"
                onClick={onRemoveItem.bind(null, index)} 
                disabled={allowRemove == null ? false : !allowRemove(record)}
            >
                <MinusCircleOutlined />
            </Button>
        )
    })

    return (
        <EditableTable
            alwaysShow={true}
            pagination={false}
            tableLayout="fixed"
            rowKey={actualRowKey}
            dataSource={itemsSource}
            columns={finalColumns as any}
            {...props}
        />
    );
}