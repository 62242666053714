

import { Converter } from "@framework/utils";
import { Range, WeekDay } from "@framework/component";

import moment, { Moment } from "moment";


class StringDateRangeConverter implements Converter<string, [Moment, Moment]> {
    seperator: string;

    constructor(seperator?: string) {
        this.seperator = seperator == null ? "~" : seperator;
    }

    convert(value?: string | undefined, values?: Record<string, any> | undefined): [Moment, Moment] | null {
        if (value == null) {
            return null;
        }
        const parts: string[] = value.split(this.seperator);
        if (parts.length == 0) {
            return null;
        } else if (parts.length == 1) {
            let date = moment(parts[0].trim());
            return [date, date];
        }
        return [ moment(parts[0].trim()), moment(parts[1].trim()) ];
    }

    convertBack(value?: [Moment, Moment] | undefined, values?: Record<string, any> | undefined, target?: Record<string, any> | undefined): string | null {
        if (value == null) {
            return null;
        }
        return `${value[0].format("yyyy-MM-DD")} ${this.seperator} ${value[1].format("yyyy-MM-DD")}`
    }
}

const DEFAULT_WEEK_DAY_MAP: Record<WeekDay, string> = {
    1: "周一",
    2: "周二",
    3: "周三",
    4: "周四",
    5: "周五",
    6: "周六",
    7: "周日"
}

class StringWeekRangeConverter implements Converter<string, Range<WeekDay>> {
    weekDayMap: Record<WeekDay, string>
    reverseMap: Record<string, WeekDay>

    seperator: string;

    constructor(seperator?: string, weekDayMap?: Record<WeekDay, string>) {
        this.seperator = seperator == null ? "~" : seperator;
        this.weekDayMap = weekDayMap == null ? DEFAULT_WEEK_DAY_MAP : weekDayMap;
        this.reverseMap = Object.keys(this.weekDayMap).reduce((prev: any, current: string) => {
            let key = Number.parseInt(current) as WeekDay;
            prev[this.weekDayMap[key]] = key;
            return prev;
        }, {});
        
    }

    convert(value?: string | undefined, values?: Record<string, any> | undefined): Range<WeekDay> | null {
        if (value == null) {
            return null;
        }
        const parts: string[] = value.split(this.seperator);
        if (parts.length == 0) {
            return null;
        } else if (parts.length == 1) {
            return {start: this.reverseMap[parts[0].trim()], end: this.reverseMap[parts[0].trim()]};
        }
        return { start: this.reverseMap[parts[0].trim()], end: this.reverseMap[parts[1].trim()]}
    }

    convertBack(value?: Range<WeekDay> | undefined, values?: Record<string, any> | undefined, target?: Record<string, any> | undefined): string | null {
        if (value == null) {
            return "";
        }
        return `${this.weekDayMap[value.start]} ${this.seperator} ${this.weekDayMap[value.end]}`
    }

}

export const MinuteConverter = {
    convert: (value?: number) => {
        if (value != null) {
            return moment().startOf('day').add(value, 'minutes')
        } else {
            return undefined;
        }
    },
    convertBack: (time?: Moment) => {
        if (time == null) {
            return undefined;
        } else {
            return moment(time).diff(time.startOf('day'), 'minute');
        }
    }
}


export const StringDateRangeConv = new StringDateRangeConverter();
export const StringWeekRangeConv = new StringWeekRangeConverter();
