

import { Avatar, Breadcrumb, Drawer, Dropdown, Layout, MenuProps } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { matchRoutes, Outlet, useLocation, useNavigate } from "react-router-dom";

import { StepsModal } from "@framework/component";
import { RouteWithPermission } from "@framework/route-with-permission";

import app from "@root/app";
import { routers } from "@root/router";
import { SiderBarModel } from "@root/models";

import { SiderBarComponent } from "./sider-bar";

import styles from "@style/layout.less";

import IconLogout from "@svg/icon-logout.svg";
import IconChangePwd from "@svg/icon-change-pwd.svg";
import Icon, { HomeOutlined, UserOutlined } from "@ant-design/icons";

const { Header, Content, Footer } = Layout;

type PickParamsType<T> = T extends (arg: infer P) => any ? P : never;

type MenuInfo = PickParamsType<MenuProps["onClick"]>;

export default function (props: any) {
    const location = useLocation();
    const navigate = useNavigate();

    const [ modalChanged, setModalChanged ] = useState({});
    const [ drawerChange, setDrawerChanged ] = useState({});
    const [ contentKey, setContentKey ] = useState(new Date().getTime());

    const [ breadcrumbItems, setBreadcrumbItems ] = useState<React.ReactNode[]>([]);

    const [ menu ] = useState(() => {
        return [
            { key: "1", label: "修改密码", icon: <Icon component={IconChangePwd} /> },
            // { key: "2", label: "系统设置", icon: <Icon component={IconSystemSettings} /> },
            { key: "3", label: "退出登录", icon: <Icon component={IconLogout} /> }
        ]
    });

    const [ siderBarModel ] = useState(() => {
        return new SiderBarModel(Object.assign({ routers: routers, openKeys: [], selectedKeys: [], collapsed: false }, app.currentUser));
    });

    const onMenuClick = (info: MenuInfo) => {
        if (info.key == "1") {
            app.doChangePassword();
        } else if (info.key == "3") {
            app.doLogout().then(() => navigate("/login", { replace: false }));
        } else if (info.key == "2") {

        }
    }

    useEffect(() => {
        app.onRender = (type) => {
            if (type == "modal") {
                setModalChanged({});
            } else {
                setDrawerChanged({});
            }
        }
    }, [])


    useEffect(() => {
        const selectedKeys: string[] = [];
        const openKeys: string[] = [];
        const breadcrumbList: React.ReactNode[] = [];
        const routes = matchRoutes(routers as any, location.pathname);
        setContentKey(new Date().getTime());
        routes != null && routes.forEach((item, index) => {
            const route = item.route as RouteWithPermission;
            if (route.showInSider == null || route.showInSider) {
                if (route.children == null || route.children.length <= 0) {
                    selectedKeys.push(route.key.toString());
                }
            }
            if (route.children != null && route.children.length > 0) {
                openKeys.push(route.key.toString());
            }
            breadcrumbList.push(
                <Breadcrumb.Item key={route.key} href={index == routers.length - 1 ? undefined : item.pathname}>
                    {item.pathname == "/app" ? <HomeOutlined /> : route.icon}
                    <span>{route.title}</span>
                </Breadcrumb.Item>
            );
        });
        setBreadcrumbItems(breadcrumbList);
        if (siderBarModel.state.openKeys == null || siderBarModel.state.openKeys.length == 0) {
            siderBarModel.updateState({ selectedKeys, openKeys });
        } else {
            siderBarModel.updateState({ selectedKeys });
        }
        if (!siderBarModel.state.signed) {
            const urlWithoutDomain = window.location.href.substring(window.location.origin.length);
            const params = new URLSearchParams();
            params.set("refer", urlWithoutDomain);
            navigate(`/login?${params.toString()}`, { replace: false });
        }
    }, [location.pathname]);

    const ModalsView = useMemo(() => {
        return app.modals.map((modalProps, index) => <StepsModal key={index} open={true} {...modalProps} />);
    }, [modalChanged]);

    const DrawerView = useMemo(() => app.drawer == null ? undefined : (<Drawer open={true} {...app.drawer} /> ), [drawerChange]);

    return (
        <Layout>
            <SiderBarComponent model={siderBarModel} />
            <Layout>
                <Header>
                    <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
                    <Dropdown menu={{items:menu, onClick: onMenuClick}} className={styles.userAction}>
                        <span>
                            <Avatar icon={<UserOutlined />} style={{marginRight: 10}} />
                            {app.currentUser.displayName}
                        </span>
                    </Dropdown>
                </Header>
                <Content key={contentKey} className={styles.mainContent}>
                    <Outlet />
                </Content>
                <Footer>Footer</Footer>
            </Layout>
            {ModalsView}
            {DrawerView}
        </Layout>
    )
}