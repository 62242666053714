

import { Delayer, toTree, PageResult } from "@framework/utils";

import { TreeSelect, TreeSelectProps } from "antd";
import { useEffect, useMemo, useState } from "react";

export interface TreeQuerySelectProps<T> extends TreeSelectProps {
    /**
     *  给定的数据源是否为有序数据。
     */
    dataSorted?: boolean
    /**
     * 渲染 Select.Option 时，用作 value。
     */
    keyOfValue?: keyof T
    /**
     * 同时指定此属性及 {@link TreeQuerySelectProps["keyOfParentId"]} 后，将自动将加载的数据转换为树形数据。
     */
    keyOfChildren?: keyof T
    /**
     * 同时指定此属性及 {@link TreeQuerySelectProps["keyOfChildren"]} 后，将自动将加载的数据转换为树形数据。
     */
    keyOfParentId?: keyof T
    /**
     * 渲染 Select.Option 时，用作 label。传入 {@link TreeQuerySelectProps["renderOption"]} 后不必传入此属性。
     */
    keyOfLabel: keyof T

    allowSelect?: (data: T) => boolean
    /**
     * onSearch 的查询方式，filter - 对 value 进行过滤， query - 使用 {@link TreeQuerySelectProps["filterItems"]} 方法重新查询。
     */
    searchMode?: "filter" | "query"
    /**
     * 指定如何根据用户输入自动刷新显示的数据列表。
     */
    fetchItems?: (value?: string) => Promise<PageResult<T> | Array<T>>
}

export function TreeQuerySelect<T>(props: TreeQuerySelectProps<T>) {
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([] as T[]);
    const [dataSource, setDataSource] = useState([] as T[]);
    const [fireSearch, setFireSearch] = useState<((value: string) => void) | undefined>(undefined);

    const { keyOfChildren, allowSelect, keyOfParentId, keyOfValue: keyOfId, keyOfLabel: keyOfValue, fetchItems, onSearch, searchMode, ...restProps} = props;

    useEffect(() => {
        if (!props.showSearch) {
            return;
        }
        if (onSearch != null) {
            setFireSearch(onSearch);
        } else if (fetchItems != null) {

            const filterFunc = searchMode == "query" ? doFetch : doFilter;

            const fetchFunc = Delayer.debounce(filterFunc, 500);
            setFireSearch(() => {
                return (value: string) => {
                    setLoading(true);
                    fetchFunc(value).then(processResult);
                }
            });
        } else { 
            setFireSearch(undefined);
        }
    }, [fetchItems, onSearch, keyOfValue, keyOfChildren, searchMode, props.showSearch, dataSource])

    const doFetch = async (value?: string) => {
        const { fetchItems, keyOfValue, keyOfParentId, keyOfChildren, allowSelect, dataSorted } = props;
        if (fetchItems == null) {
            return [] as T[];
        }
        const values = await fetchItems(value);
        const list = (values instanceof Array) ? values : values.info;
        if (keyOfChildren != null && keyOfValue != null && keyOfParentId != null) {
            return toTree(list, keyOfValue, keyOfParentId, keyOfChildren, dataSorted);
        }
        if (allowSelect != null) {
            setAllowSelect(list, allowSelect);
        }
        return list;
    }

    const filter = (filterValue?: string, list?: T[]) => {
        const { keyOfLabel: keyOfValue, keyOfChildren } = props;
        if (list == null || !(list instanceof Array)) {
            return [];
        } else if (filterValue == null || filterValue == "") {
            return list;
        } else {
            let result: T[] = [];
            for (const item of list) {
                const value = item[keyOfValue];
                if (typeof value == "string" && value.includes(filterValue)) {
                    result.push({...item});
                } else if (keyOfChildren != null) {
                    const children = item[keyOfChildren];
                    result = result.concat(filter(filterValue, children as T[])) ;
                }
            }
            return result;
        }
    }

    const doFilter = (filterValue?: string) => {
        return filter(filterValue, dataSource);
    }

    const processResult = (values: T[]) => {
        setLoading(false);
        const { allowSelect } = props;
        if (allowSelect != null) {
            setAllowSelect(values, allowSelect);
        }
        
        setFilteredData(values);
    }

    const setAllowSelect = (list: T[] | undefined, allowSelect: (data: T) => boolean) => {
        const { keyOfChildren } = props;
        if (list == null || !(list instanceof Array) || keyOfChildren == null) {
            return;
        }
        for (const item of list) {
            const current = item as any;
            const children = item[keyOfChildren] as any;
            current.selectable = allowSelect(item);
            if (children != null) {
                setAllowSelect(children, allowSelect);
            }
        }
    }

    useEffect(() => {
        doFetch().then(list => {
            setFilteredData(list);
            setDataSource(list);
        });
    }, []);

    const View = useMemo(() => {
        return (
            <TreeSelect
                loading={loading}
                onSearch={fireSearch}
                filterTreeNode={false}
                treeData={filteredData as any}
                key={filteredData == null || filteredData.length == 0 ? "__empty__" : "-"}
                {...restProps}
                fieldNames={{ label: keyOfValue as string, value: keyOfId as string, children: keyOfChildren as string }}
            />
        );
    }, [props, loading, fireSearch, filteredData, keyOfValue, keyOfId, keyOfChildren]);

    return View;
}