import { Converter } from "@root/framework/utils";


export class PercentConverter implements Converter<number, number> {
    convert(value?: number | undefined, values?: Record<string, any> | undefined): number | null {
        return value == null ? null : (value > 1 ? 100 : value * 100);
    }
    convertBack(value?: number | undefined, values?: Record<string, any> | undefined, target?: Record<string, any> | undefined): number | null {
        return value == null ? null : value / 100;
    }
}

const DefaultPercentConvert = new PercentConverter();

export { DefaultPercentConvert };