

import { Converter } from "@framework/utils";

import { useCallback, useEffect, useState } from "react";
import { Modal, ModalProps, Upload, UploadProps } from "antd";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload";

type ImageModalProps = ModalProps & { src: string }

export function ImgPreviewModal({ onCancel, open, src, ...resetProps }: ImageModalProps) {
    return (
        <Modal
            open={open}
            footer={null}
            onCancel={onCancel}
            {...resetProps}>
            <img crossOrigin="anonymous" style={{ width: '100%' }} src={src} />
        </Modal>
    )
}

type ProUploadProps<T extends Array<string | UploadFile<any>>> = Omit<UploadProps<any>, "onChange"> & {

    value?: T
    /**
     * 图片大小限制
     */
    maxFileSize?: number,

    converter?: Converter<string | UploadFile<any>, UploadFile<any>>

    onChange?: (value?: T) => void
};

export function FileUploader({ children, value, maxFileSize, onChange, converter, action, ...props}: ProUploadProps<any>) {
    const [fileList, setFileList] = useState([] as UploadFile<any>[]);
    const [visibleFile, setVisibleFile] = useState<UploadFile<any> | null>(null);

    const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
        const values: Array<string | UploadFile<any>> = [];
        let changed: boolean = false;
        const finalList = info.fileList.map(file => {
            let finalFile: UploadFile<any> | null = file;
            if (file.uid === info.file.uid) {
                if (converter != null && converter.convert != null) {
                    finalFile = converter.convert(file.response == null ? file : file.response);
                    changed = finalFile != null && finalFile.status != "uploading";
                }
            }
            const value = finalFile == null ? undefined : converter?.convertBack(finalFile);
            if (value != null) {
                values.push(value);
            }
            return finalFile;
        });
        if (changed && onChange != null) {
            onChange(values);
        }
        setFileList(finalList as any);
    }

    const shouldUpload = (file: RcFile, fileList: RcFile[]) => {
        if (maxFileSize != null && file.size > maxFileSize) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        const fileList: Array<UploadFile<any>> = (value != null && value instanceof Array) ? value.map(item => {
            const parsedFile = converter?.convert(item);
            if (parsedFile == null) {
                throw new Error("converter.convert 的结果不能为空！");
            }
            return parsedFile as UploadFile<any>;
        }) : [];
        setFileList(fileList);
    }, [value])


    const showModal = useCallback(() => {
        if (visibleFile != null) {
            const url = visibleFile.preview == null ? visibleFile.thumbUrl : visibleFile.preview;
            return (
                <ImgPreviewModal
                    open={true}
                    src={url as string}
                    onCancel={() => setVisibleFile(null)}
                />
            )
        }
    }, [visibleFile])

    action = action == null ? (file) => Promise.resolve(URL.createObjectURL(file)) : action;
    
    return (
        <>
            <Upload.Dragger
                action={action}
                fileList={fileList}
                onChange={handleChange}
                className="avatar-uploader"
                beforeUpload={shouldUpload}
                onPreview={(file) => setVisibleFile(file)}
                {...props}
            >
                {children != null && children}
            </Upload.Dragger>
            {showModal()}
        </>
    )
}

export default FileUploader;