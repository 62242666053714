

import { BaseModel } from "@framework/models";
import { PageResult, TimestampConverter, toTree, getAllChildren, mergeProps, treeToMap } from "@framework/utils";
import { Decorator, DecoratorForm, SelectionTableField, SelectionTableFieldProps, TableColumn } from "@framework/component";


import { Button, DatePicker, Input, Modal, ModalProps } from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";

import { VoucherBatchModel } from "./voucher-batch-model";
import { MerchantApi, ProductApi, VoucherApi } from "@api";
import { SelectionTableValueConverter } from "@root/converter";
import { ImageColumn, TagColumn, TimestampColumn } from "@root/component";
import { ProductCateStatusMap, ProductStatusMap, VoucherStatusMap } from "./tag-map";
import app from "@root/app";
const { TextArea } = Input

export enum ModalType {
    Product = 0,
    Merchant = 1,
    ProductCate = 2,
    Info = 3,
    DiscountRule = 4,
}

interface DetailState {
    allowEdit: boolean
    batch: VoucherApi.VoucherBatch
    merchants?: MerchantApi.Merchant[]
    vouchers?: VoucherApi.Voucher[]
    productPagination: TablePaginationConfig
    voucherPagination: TablePaginationConfig
    products?: ProductApi.Product[]
    productCates?: ProductApi.ProductCate[]
    selectedProductIds?: string[],
    selectedMerchantIds?: string[],
    selectedCateCodes?: string[],
    allProductCates?: ProductApi.ProductCate[]
    modalType?: ModalType
    rule?: RuleType[],
    discountRule?: RuleType
}

export interface RuleType {
    label: string,
    value: string,
    key?: Number
}

const toAntdPagination = (result: PageResult<any>): TablePaginationConfig => {
    const skip = result.skip == null ? 0 : result.skip;
    const limit = result.limit == null || result.limit <= 0 ? 10 : result.limit;
    const total = result.total == null ? 0 : result.total;
    const current = Math.floor((skip == null ? 0 : skip) / limit) + 1;
    return {
        total: total,
        defaultPageSize: limit,
        current: current
    };
}

export class VoucherBatchDetailModel extends BaseModel<DetailState> {
    #key: number;
    #batchModel = new VoucherBatchModel();
    #selectionValueConverter = new SelectionTableValueConverter();
    constructor() {
        super();
        this.#key = 1;
        this.state = {
            allowEdit: true,
            batch: {},
            productPagination: {},
            voucherPagination: {}
        }
    }

    /**
     * 查询优惠批次详情。
     * @param id 
     * @returns 
     */
    public async detail(id?: string) {
        return Promise.all([VoucherApi.detail(id), VoucherApi.listLimitedProducts(id), ProductApi.listCate(), VoucherApi.listVoucher(id)]).then(values => {
            const [batch, limitedProducts, allProductCates, vouchers] = values;
            let discountRuleList = batch.rule ? JSON.parse(batch.rule) : []
            discountRuleList.forEach((discount: RuleType) => {
                discount.key = this.#key++;
            })
            const params: Partial<DetailState> = {
                batch: batch,
                products: limitedProducts.info,
                vouchers: vouchers.info,
                merchants: batch.restrictMerchantList,
                allProductCates: toTree(allProductCates, "code", "parentCode", "children"),
                productCates: batch.restrictCateList == null ? [] : toTree(batch.restrictCateList, "code", "parentCode", "children"),
                rule: discountRuleList
            };
            params.productPagination = toAntdPagination(limitedProducts);
            params.voucherPagination = toAntdPagination(vouchers);

            params.productPagination.onChange = (page, pageSize) => {
                VoucherApi.listLimitedProducts(id, pageSize, (page - 1) * pageSize).then(result => {
                    this.updateState({
                        products: result.info,
                        productPagination: Object.assign({}, this.state.productPagination, toAntdPagination(result))
                    });
                });
            }
            params.voucherPagination.onChange = (page, pageSize) => {
                VoucherApi.listVoucher(id, pageSize, (page - 1) * pageSize).then(result => {
                    this.updateState({
                        vouchers: result.info,
                        voucherPagination: Object.assign({}, this.state.voucherPagination, toAntdPagination(result))
                    });
                });
            }
            this.updateState(params);
        });
    }

    /**
     * 获取用于展示已选商户列表的列定义。
     */
    getMerchantColumns(): TableColumn<MerchantApi.Merchant>[] {
        return [
            { title: "商户名称", dataIndex: "name", width: 100 },
            new ImageColumn("封面图片", "cover", 32),
            { dataIndex: "cateName", title: "商户分类" },
            { title: "商户地址", stringFormat: "%(area)s%(street)s%(address)s" },
            { dataIndex: "score", title: "商户评分", width: 80, stringFormat: "%.1f" },
            { dataIndex: "contact", title: "联系人", width: 90 },
            { dataIndex: "phone", title: "联系电话" }
        ];
    }

    getProductColumns(): TableColumn<ProductApi.Product>[] {
        return [
            { title: "商品名称", dataIndex: "storeName" },
            new ImageColumn("封面图片", "cover", 32),
            { title: "商品分类", dataIndex: "cateName", dataType: "string", fallback: "--" },
            { title: "货    号", dataIndex: "itemNo", dataType: "string", fallback: "--" },
            { title: "所属商户", dataIndex: "merchantName", dataType: "string", fallback: "--" },
            { dataIndex: "sort", title: "排序序号", fallback: "--" },
            new TagColumn(ProductStatusMap, "状态", "status")
        ];
    }

    getProductCateColumns(): TableColumn<ProductApi.ProductCate>[] {
        return [
            { dataIndex: "name", title: "分类名称", dataType: "string" },
            new ImageColumn("封面图片", "image", 32),
            new TimestampColumn("创建时间", "createTime"),
            { dataIndex: "createUserName", title: "创建人", dataType: "string", fallback: "--" },
            new TimestampColumn("最后修改时间", "lastUpdateTime"),
            { dataIndex: "lastUpdateUserName", title: "最后修改人", dataType: "string", fallback: "--" },
            new TagColumn(ProductCateStatusMap, "状态", "status")
        ];
    }

    getVoucherColumns(): TableColumn<VoucherApi.Voucher>[] {
        return [
            { dataIndex: "no", title: "券    号", dataType: "string" },
            { dataIndex: "externalNo", title: "外部券号", fallback: "--" },
            { dataIndex: "userName", title: "关联用户", fallback: "--" },
            new TimestampColumn("生效日期", "takeEffectTime", { stringFormat: "YYYY/MM/DD" }),
            new TimestampColumn("过期时间", "expireTime", { stringFormat: "YYYY/MM/DD" }),
            new TimestampColumn("发放时间", "grantTime"),
            { dataIndex: "grantUserName", title: "发 放 人" },
            new TimestampColumn("使用时间", "usedTime"),
            new TagColumn(VoucherStatusMap, "状态", "status")
        ];
    }

    /**
     * 获取选择限定商户时，form 表单的视图属性
     * @returns 
     */
    #getMerchantFiledProps = () => {
        return {
            queryDecorators: [{
                name: "name",
                label: "商户名称",
                className: "query-form-item-40",
                element: <Input placeholder="按商户名称查询" />
            }],
            getDataSource: (limit?: number, skip?: number, params?: any) => {
                return MerchantApi.listMerchant(limit, skip, params?.name,
                    undefined, undefined, undefined, undefined, undefined, 1);
            },
            columns: [
                { title: "商户名称", dataIndex: "name", width: 100 },
                new ImageColumn("封面图片", "cover", 24),
                { title: "商户地址", stringFormat: "%(area)s%(street)s%(address)s" },
                { dataIndex: "score", title: "商户评分", width: 80, stringFormat: "%.1f" },
                { dataIndex: "contact", title: "联系人", width: 90 },
                { dataIndex: "phone", title: "联系电话" }
            ]
        }
    }

    #getProductFieldProps = () => {
        const { merchants, productCates } = this.state;
        const merchantIds = merchants?.map(merchant => merchant.id);
        const cateCodes = this.#getSelectedCateCodes(productCates);
        return {
            queryDecorators: [{
                name: "name",
                label: "商品名称",
                className: "query-form-item-40",
                element: <Input placeholder="按商品名称查询" />
            }, {
                name: "itemNo",
                label: "货    号",
                className: "query-form-item-40",
                element: <Input placeholder="按货号查询" />
            }],
            getDataSource: (limit?: number, skip?: number, params?: any) => {
                const merchantIdsStr = merchantIds == null ? undefined : merchantIds.join(",");
                const cateCodesStr = cateCodes == null ? undefined : cateCodes.join(",");
                return ProductApi.listAvailableForBatch(limit, skip, params?.name, merchantIdsStr, cateCodesStr);
            },
            columns: [
                { title: "商品名称", dataIndex: "storeName", width: 100 },
                new ImageColumn("封面图片", "cover", 24),
                { title: "货    号", dataIndex: "itemNo", dataType: "string", fallback: "--" },
                new TagColumn(ProductStatusMap, "状态", "status")
            ]
        }
    }

    #getProductCateFieldProps = (): Omit<SelectionTableFieldProps<ProductApi.ProductCate>, "rowKey"> => {
        return {
            rowSelection: {
                checkStrictly: false,
            },
            expandable: {
                defaultExpandAllRows: true
            },
            queryDecorators: [{
                name: "name",
                label: "分类名称",
                className: "query-form-item-40",
                element: <Input placeholder="按分类名称查询" />
            }],
            getDataSource: async (limit?: number, skip?: number, params?: any) => {
                const result = await ProductApi.listCate(params?.name, ProductApi.ProductCateStatus.enabled);
                return toTree(result, "code", "parentCode", "children");
            },
            columns: [
                { dataIndex: "name", title: "分类名称", dataType: "string" },
                new ImageColumn("图片", "image", 24),
                { dataIndex: "sort", title: "排序序号", fallback: "--" },
                new TimestampColumn("最后修改时间", "lastUpdateTime"),
                { dataIndex: "lastUpdateUserName", title: "最后修改人", dataType: "string", fallback: "--" },
                new TagColumn(ProductCateStatusMap, "状态", "status")
            ]
        }
    }

    #getSelectedCateCodes = (productCates?: ProductApi.ProductCate[]): string[] => {
        const { allProductCates } = this.state;
        if (allProductCates == null || productCates == null) {
            return [];
        } else {
            const map = treeToMap(allProductCates, "code", "children");
            const result: Array<ProductApi.ProductCate> = [];
            for (const cate of productCates) {
                if (result.findIndex(item => item.code === cate.code) == -1) {
                    const node = map[cate.code as string];
                    getAllChildren(node, "children", result);
                }
            }
            return result.map(item => item.code as string);
        }
    }

    async #doUpdate(params: Partial<VoucherApi.VoucherBatch>) {
        const { batch, productCates, merchants } = this.state;
        const updating = mergeProps(batch, params);
        if (updating == null) {
            throw new Error("修改批次时发生错误！");
        }
        await VoucherApi.updateBatch(
            updating?.id, updating?.name, updating?.activityId, updating?.type, updating.makeCouponMode,
            updating?.discountRule, updating?.discount, updating?.startTime, updating?.endTime, updating?.expireType,
            updating?.expireTime, updating?.restrictPrice, updating?.restrictQty, updating?.maxGrantQty
        );
        return updating;
    }

    async #doUpdateRestrict(params: Pick<VoucherApi.VoucherBatch, "restrictCateCodes" | "restrictMerchantIds" | "restrictProductIds">) {
        const { batch, productCates, merchants } = this.state;
        const origin = {
            restrictCateCodes: this.#getSelectedCateCodes(productCates).join(","),
            restrictMerchantIds: merchants?.map(merchant => merchant.id).join(","),
            restrictProductIds: batch.restrictProductSet == null ? undefined : batch.restrictProductSet.join(",")
        }
        const updating = mergeProps(origin, params);
        await VoucherApi.updateRestrict(batch.id, updating?.restrictMerchantIds, updating?.restrictCateCodes, updating?.restrictProductIds);
    }

    getModalTitle() {
        const { modalType } = this.state;
        switch (modalType) {
            case ModalType.Info:
                return "修改批次信息";
            case ModalType.Merchant:
                return "修改适用商户列表";
            case ModalType.ProductCate:
                return "修改适用品类列表";
            case ModalType.Product:
                return "修改适用商品列表";
            case ModalType.DiscountRule:
                return "设置优惠劵批次规则"
        }
    }

    getDecorators(): Decorator<any>[] {
        const { modalType, batch } = this.state;
        this.#batchModel.state.editingItem = this.state.batch;
        if (modalType == ModalType.Info) {
            return this.#batchModel.getEditorDecorators();
        } else if (modalType == ModalType.Merchant) {
            const merchantProps = this.#getMerchantFiledProps();
            const merchantIds = this.state.merchants?.map(merchant => merchant.id);
            return [{
                name: "restrictMerchantIds",
                className: "editor-form-item",
                converter: this.#selectionValueConverter,
                element: <SelectionTableField rowKey="id" {...merchantProps} />,
                initialValue: merchantIds
            }];
        } else if (modalType == ModalType.Product) {
            const productProps = this.#getProductFieldProps();
            return [{
                name: "restrictProductIds",
                className: "editor-form-item",
                converter: this.#selectionValueConverter,
                element: <SelectionTableField rowKey="id" {...productProps} />,
                initialValue: batch.restrictProductSet
            }];
        } else if (modalType == ModalType.ProductCate) {
            const cateCodes = this.#getSelectedCateCodes(this.state.productCates);
            const productCateProps = this.#getProductCateFieldProps();

            return [{
                name: "restrictCateCodes",
                className: "editor-form-item",
                converter: this.#selectionValueConverter,
                element: <SelectionTableField rowKey="code" keyofParent="parentCode" {...productCateProps} />,
                initialValue: cateCodes
            }]
        } else if (modalType == ModalType.DiscountRule) {
            const { discountRule } = this.state
            return [{
                name: "label",
                label: "规则名称",
                className: "editor-form-item",
                element: <Input placeholder="请填写规则名称" />,
                rules: [{ required: true, message: "请填写规则名称" }],
                initialValue: discountRule?.label
            }, {
                name: "value",
                label: "规则详情",
                className: "editor-form-item",
                element: <TextArea placeholder="请填写规则详情" autoSize={{minRows: 10, maxRows: 10}} />,
                rules: [{ required: true, message: "请填写规则详情" }],
                initialValue: discountRule?.value
            }]
        }
        return [];
    }

    removeSeletedRestrict(type: ModalType) {
        const { selectedCateCodes, selectedMerchantIds, selectedProductIds, batch } = this.state;
        let typeName = null;

        let updateKey: string | null = null;
        let removeKeys: Array<string> | undefined = undefined;
        let originMap: Record<string, any> | undefined = undefined;
        switch (type) {
            case ModalType.Merchant:
                typeName = "商户";
                updateKey = "restrictMerchantIds";
                removeKeys = selectedMerchantIds;
                originMap = batch.restrictMerchantList?.reduce((prev, item) => {
                    prev[item.id] = item;
                    return prev;
                }, {} as Record<string, MerchantApi.Merchant>);
                break;
            case ModalType.Product:
                typeName = "商品";
                removeKeys = selectedProductIds;
                updateKey = "restrictProductIds";
                originMap = batch.restrictProductSet?.reduce((prev, item) => {
                    prev[item] = item;
                    return prev;
                }, {} as Record<string, string>);
                break;
            case ModalType.ProductCate:
                typeName = "品类";
                removeKeys = selectedCateCodes;
                updateKey = "restrictCateCodes";
                originMap = treeToMap(this.state.productCates as Array<ProductApi.ProductCate>, "code", "children");
                const selected = (selectedCateCodes == null || originMap == null) ? [] : selectedCateCodes.map(code => (originMap as Record<string, ProductApi.ProductCate>)[code]);
                removeKeys = this.#getSelectedCateCodes(selected);
                break;
        }
        if (originMap == null || removeKeys == null || updateKey == null || Object.keys(originMap).length == 0 || removeKeys.length == 0) {
            return;
        }

        Modal.confirm({
            title: "确认",
            content: `正在移除选定的${removeKeys.length}个适用${typeName}，是否继续？`,
            mask: false,
            maskClosable: false,
            onOk: async () => {
                const params: Record<string, any> = {};
                const map = originMap as Record<string, any>;
                for (const key of (removeKeys as Array<string>)) {
                    delete map[key];
                }
                params[updateKey as string] = Object.keys(map).join(",");
                await this.#doUpdateRestrict(params);
                this.updateState({ selectedCateCodes: [], selectedMerchantIds: [], selectedProductIds: [] });
                this.detail(batch.id);
            }
        });
    }

    showInfoEditor = () => {
        this.updateState({ modalType: ModalType.Info });
    }

    importVoucher = async (closeModal: () => void, values: any) => {
        const { batch, voucherPagination } = this.state;
        await VoucherApi.importVoucher(batch.id as string, values.file[0].originFileObj);
        closeModal();
        const limit = voucherPagination?.defaultPageSize == null ? 10 : voucherPagination.defaultPageSize;
        const skip = voucherPagination?.current == null || voucherPagination.current < 1 ? 0 : (voucherPagination.current - 1) * limit;
        VoucherApi.listVoucher(batch.id, limit, skip).then(result => {
            this.updateState({
                vouchers: result.info,
                voucherPagination: toAntdPagination(result)
            });
        });
    }

    audit = () => {
        const { batch } = this.state;
        Modal.confirm({
            type: "confirm",
            title: "审核确认",
            maskClosable: true,
            onOk: async () => {
                await VoucherApi.audit(batch?.id)
                this.updateState({ batch: { ...batch, status: VoucherApi.BatchStatus.Effected } })
            },
            content: "是否继续将此优惠券批次更改为已审核？"
        });
    }

    delay = () => {
        const { batch } = this.state;
        const updater = Modal.confirm({
            width: 500,
            title: "延期",
            maskClosable: true,
            okButtonProps: { style: { display: "none" } },
            cancelButtonProps: { style: { display: "none" } },
            content: (
                <DecoratorForm
                    className="editor-form"
                    decorators={[{
                        name: "expireTime",
                        label: "结束日期",
                        element: <DatePicker style={{ width: "100%" }} placeholder="请输入结束日期" />,
                        rules: [{ required: true, message: "结束日期不能为空！" }],
                        converter: new TimestampConverter()
                    }]}
                    onSubmit={async (values) => {
                        await VoucherApi.extension(batch.id, values.expireTime);
                        this.updateState({ batch: { ...batch, expireTime: values.expireTime } });
                        updater.destroy();
                    }}
                >
                    <div className="operation">
                        <Button type="primary" style={{ width: "30%" }} htmlType="submit">提交</Button>
                    </div>
                </DecoratorForm>
            )
        });
    }

    reject = () => {
        const { batch } = this.state;
        const updater = Modal.confirm({
            title: "驳回",
            icon: undefined,
            maskClosable: true,
            okButtonProps: { style: { display: "none" } },
            cancelButtonProps: { style: { display: "none" } },
            content: (
                <DecoratorForm
                    className="editor-form"
                    decorators={[{
                        name: "reason",
                        label: "驳回原因",
                        element: <Input placeholder="请输入驳回原因" />,
                        rules: [{ required: true, message: "驳回原因不能为空！" }]
                    }]}
                    onSubmit={async (values) => {
                        await VoucherApi.reject(batch.id, values.reason);
                        updater.destroy();
                        this.updateState({ batch: { ...batch, status: VoucherApi.BatchStatus.Rejected } });
                    }}
                >
                    <div className="operation">
                        <Button style={{width: "30%"}} type="primary" htmlType="submit">提交</Button>
                    </div>
                </DecoratorForm>
            )
        });
    }

    stop = () => {
        const { batch } = this.state;
        Modal.confirm({
            mask: false,
            title: "确认",
            type: "confirm",
            maskClosable: false,
            content: "正在停用此优惠券，是否继续？",
            onOk: async () => {
                await VoucherApi.stop(batch?.id);
                this.updateState({ batch: { ...batch, status: VoucherApi.BatchStatus.Stoped } });
            }
        });
    }

    submit = () => {
        const { batch } = this.state;
        Modal.confirm({
            mask: false,
            title: "确认",
            type: "confirm",
            maskClosable: false,
            content: "提交审核后无法再次修改，是否继续？",
            onOk: async () => {
                await VoucherApi.submit(batch?.id)
                this.updateState({ batch: { ...batch, status: VoucherApi.BatchStatus.Pending } });
            }
        });
    }

    getRuleName() {
        return this.#batchModel.getRuleName(null, this.state.batch, 0);
    }

    getExpireSetting() {
        return this.#batchModel.getExpireTime(null, this.state.batch, 0);
    }

    getGrantQtyDesc() {
        const { batch } = this.state;
        if (batch?.type == VoucherApi.VoucherType.Instant) {
            return `前${batch?.maxGrantQty}笔订单可使用此优惠`
        } else {
            return `最大发放${batch?.maxGrantQty}张，管理员发放操作不受此限制影响`
        }
    }


    onFormSubmit = async (values: any) => {
        const params: Record<string, string> = { ...values };
        if (values.restrictMerchantIds != null) {
            params.restrictMerchantIds = values.restrictMerchantIds.join(",");
        }
        if (values.restrictCateCodes != null) {
            params.restrictCateCodes = values.restrictCateCodes.join(",");
        }
        if (values.restrictProductIds != null) {
            params.restrictProductIds = values.restrictProductIds.join(",");
        }

        const { modalType, batch } = this.state;

        if (modalType == ModalType.Info) {
            const updated = await this.#doUpdate(params);
            this.updateState({ modalType: undefined, batch: updated });
        } else if (modalType == ModalType.DiscountRule) {
            const { discountRule, rule } = this.state
            if(discountRule){
                rule?.forEach((discount: RuleType) => {
                    if(discount.key == discountRule.key) {
                        discount.label = values.label;
                        discount.value = values.value;
                    }
                })
            }else{
                values.key = this.#key++;
                rule?.push(values)
            }
            VoucherApi.setDiscountRule(batch.id, JSON.stringify(rule)).then(() => {
                this.updateState({discountRule: undefined, rule: [...(rule as RuleType[])], modalType: undefined})
            })
        } else {
            await this.#doUpdateRestrict(params);
            this.detail(batch.id);
            this.updateState({ modalType: undefined });
        }
    }

    removeDiscountRule = (key: number) => {
        Modal.confirm({
            title: "确认删除",
            content: "是否删除当前规则？",
            onOk: () => new Promise<void>((resolve, reject) => {
                try {
                    const { rule, batch } = this.state;
                    if (rule != null) {
                        let newRule = rule.filter((discount: RuleType) => discount.key != key);
                        VoucherApi.setDiscountRule(batch.id, JSON.stringify(newRule)).then(() => {
                            this.updateState({rule: newRule })
                        })
                    }
                    resolve();
                } catch (error) {
                    reject(error);
                }
            })
        })
    }

    updateDiscount = (value: RuleType) => {
        this.updateState({ discountRule: value, modalType: ModalType.DiscountRule })
    }
}