

import { BaseModel, QueryModel, QueryState } from "@framework/models";

import { ReactNode } from "react";
import { Button, Modal, ModalProps } from "antd";

import { DecoratorForm } from "./decorator-form";
import { Decorator, DecoratorFormProps } from "./types";
import { BaseComponent, UseRouter, PropsType } from "./base-component";
import { EditableTable, EditableTableProps, TableColumn } from "./editable-table";

type PickDataType<T> = T extends QueryModel<infer P> ? (P extends QueryState<infer Q> ? Q : P) : T;

/**
 * 从url获取查询参数、根据查询参数请求接口数据并更新当前组件以及查询和刷新页面等功能。
 */
export abstract class TableView<T extends QueryModel<any>> extends BaseComponent<PropsType<T>> {
    #rowKey?: keyof PickDataType<T>;
    #queryDecorators: Decorator[];
    constructor(props: PropsType<T>) {
        super(props);
        this.queryParams = new URLSearchParams();
        this.#queryDecorators = this.getQueryDecorators();
    }

    set rowKey(rowKey: keyof PickDataType<T>) {
        this.#rowKey = rowKey;
    }

    protected get queryDecorators() {
        return this.#queryDecorators;
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.attached) {
            let params: Record<string, any> = {};
            if (this.queryParams != null) {
                for (let key of this.queryParams.keys()) {
                    params[key] = this.queryParams.get(key);
                }
            }
            this.props.model.queryParams = Object.assign(params, this.pathParams);
        }
        this.props.model.query(this.attached ? this.props.model.queryParams : undefined);
        this.#queryDecorators = this.#queryDecorators.map(item => {
            if (item.initialValue == null) {
                const value =this.queryParams.get(item.name);
                item.initialValue = value == null ? undefined : value;
            }
            return item;
        });
    }

    /**
     * 获取传递给编辑表单 {@link DecoratorForm} 的字段列表。
     * 
     * @param editingItem 当前正在编辑的数据。
     * @returns 新增/修改表单中包含的字段列表。
     */
    abstract getEditorDecorators(editingItem?: PickDataType<T>): Decorator[];

    /**
     * 获取传递给查询表单 {@link DecoratorForm} 的字段列表。
     * 
     * @param model 传递给查询表单 {@link DecoratorForm} 的 model 属性。
     * @returns 查询表单中包含的字段列表。
     */
    abstract getQueryDecorators(): Decorator[];

    abstract getTableColumns(): TableColumn<PickDataType<T>>[];

    onModelStateChanged(source: BaseModel<any>, params: any, originState: any): void {
        if (source == this.props.model) {
            this.setState(this.props.model.state);
        }
    }

    /**
     * 
     * @param values 
     */
    abstract onEditorSubmit(values: PickDataType<T>): Promise<any> | any;

    query(params?: any) {
        const queryParams = new URLSearchParams();
        if (params != null) {
            Object.keys(params).forEach(key => {
                if (params[key] != null) {
                    queryParams.set(key, params[key]);
                }
            });
        }
        this.navigateTo(`${this.location.pathname}?${queryParams.toString()}`, true);
        this.props.model.query(params);
    }

    render() {
        const { showEditor, editingItem } = this.state;
        return (
            <>
                {showEditor && this.#renderEditorModal(editingItem)}
                <UseRouter attach={this} />
                {this.#renderQueryForm()}
                {this.renderOther()}
                {this.#renderTable()}
            </>
        )
    }

    renderOther(): ReactNode {
        return undefined;
    }

    renderEditorForm(props: DecoratorFormProps, editingItem?: PickDataType<T>) {
        return (
            <DecoratorForm {...props}>
                <div className="operation">
                    <Button htmlType="submit" type="primary">确定</Button>
                </div>
            </DecoratorForm>
        );
    }

    /**
     * 组件渲染时使用此方法渲染编辑表单，重写此方法自定义编辑表单。
     * 
     * @param props 弹框样式
     * @param editingItem 当前正在编辑的数据。
     */
    renderEditorModal(props: ModalProps, editingItem?: PickDataType<T>) {
        return (<Modal {...props} />);
    }

    /**
     * 组件渲染时使用此方法渲染查询表单，重写此方法自定义查询表单。
     * 
     * @param props 查询表单的 props
     * @returns 查询表单
     */
    renderQueryForm(props: DecoratorFormProps) {
        return (
            <DecoratorForm {...props}>
                <div className="operation">
                    <Button htmlType="submit" type="primary">查询</Button>
                    <Button htmlType="reset" onClick={() => this.query(null)} type="default">重置</Button>
                </div>
            </DecoratorForm>
        )
    }

    renderTable(props: EditableTableProps<PickDataType<T>>) {
        props.loading = this.state.loading;
        return <EditableTable {...props} />
    }

    /**
     * 显示编辑模态框。
     * 
     * @param editingItem 正在编辑的数据。
     */
    protected showEditor = (editingItem?: PickDataType<T>) => {
        this.props.model.updateState({ showEditor: true, editingItem: editingItem });
    }

    #getEditorDecorators = (): Decorator[] => {
        return this.getEditorDecorators(this.props.model.state.editingItem);
    }

    #onEditorSubmit = (values: PickDataType<T>) => {
        Promise.resolve(this.onEditorSubmit(values))
            .then((value: any) => {
                this.props.model.refresh();
                this.props.model.updateState({ editingItem: undefined, showEditor: false });
            });
    }

    #query = (params: any) => {
        this.query(params);
    }

    #renderEditorForm = (editingItem?: PickDataType<T>): ReactNode => {
        const formProps: DecoratorFormProps = {
            className: "editor-form",
            onSubmit: this.#onEditorSubmit,
            getDecorators: this.#getEditorDecorators
        };
        return this.renderEditorForm(formProps, editingItem);
    }

    #renderEditorModal = (editingItem?: PickDataType<T>) => {
        const modalProps: ModalProps = {
            closable: true,
            mask: false,
            maskClosable: true,
            onCancel: () => {
                this.props.model.updateState({ showEditor: false, editingItem: undefined })
            },
            open: this.state.showEditor,
            centered: true,
            children: this.#renderEditorForm(editingItem),
            okType: "primary",
            destroyOnClose: true,
            footer: null
        };
        return this.renderEditorModal(modalProps, editingItem);
    }

    #renderQueryForm = () => {
        let props: DecoratorFormProps = {
            onSubmit: this.#query,
            className: "query-form",
            decorators: this.#queryDecorators
        }
        return this.renderQueryForm(props);
    }

    #renderTable = () => {
        const { data } = this.state;
        const props: EditableTableProps<PickDataType<T>> = {
            rowKey: this.#rowKey as string,
            columns: this.getTableColumns(),
            dataSource: data,
            pagination: false
        };
        
        return this.renderTable(props);
    }
}
