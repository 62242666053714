

import { PageResult } from "@framework/utils";

import { MerchantApi, PickRequire } from ".";
import { fetch } from "@root/app";


import InvoiceParty = MerchantApi.InvoiceParty;

export namespace ProductApi {

    export enum ProductCateStatus {
        enabled = 1,
        disabled = 2
    }

    export interface ProductCate {
        /**
         * 分类编号
         */
        code?: string
        /**
         * 分类名称
         */
        name?: string
        /**
         * 下一编号
         */
        nextCode?: number
        /**
         * 分类图片
         */
        image?: string
        /**
         * 税收分类编号
         */
        taxCode?: string
        /**
         * 税率
         */
        taxRate?: number
        /**
         * 状态 1-启用 2-禁用
         */
        status?: ProductCateStatus
        /**
         * 排序序号
         */
        sort?: number
        /**
         * 父级分类编号
         */
        parentCode?: string
        /**
         * 创建时间
         */
        createTime?: number
        /**
         * 创建人
         */
        createUserName?: string
        /**
         * 最后修改时间
         */
        lastUpdateTime?: number
        /**
         * 最后修改人
         */
        lastUpdateUserName?: string
        children?: ProductCate[]
    }

    /**
     * 商品状态
     */
    export enum ProductStatus {
        /**
         * 待提交
         */
        New = 0,
        /**
         * 审核中/待审核
         */
        UnderReview = 1,
        /**
         * 审核通过/仓库中
         */
        Passed = 2,
        /**
         * 驳回
         */
        Rejected = 3,
        /**
         * 上架/出售中
         */
        Available = 4,
        /**
         * 下架（仓库）
         */
        Removed = 5,
        /**
         * 已删除
         */
        Deleted = 6,
        /**
         * 已禁用
         */
        Disabled = 7
    }

    /**
     * 规格状态
     */
    export enum SpecStatus {
        /**
         * 允许修改
         */
        Editable = 1,
        /**
         * 已锁定
         */
        Sealed = 2
    }

    /**
     * sku 状态
     */
    export enum SkuStatus {
        /**
         * 启用
         */
        Enabled = 1,
        /**
         * 禁用
         */
        Disabled = 2
    }

    export enum PickUpType {
        /**
         * 自取
         */
        SelfPickUp = 0,
        /**
         * 邮递
         */
        SendByPost = 1,
        /**
         * 自选
         */
        Free = 2
    }

    export interface SpecValue {
        key?: number
        index?: number
        name?: string
        nameId?: string
        no?: string
        /**
         * 本地生成sku编号时使用
         */
        localNo?: string
        status?: SpecStatus
    }

    /**
     * 商品规格
     */
    interface BaseProductSpec<T extends SpecValue | Array<SpecValue>> {
        rowSpan?: number
        originIndex?: number
        name?: string
        value?: T
    }

    export interface ProductSpec extends BaseProductSpec<Array<SpecValue>> {
        editable?: boolean
    }

    export interface SkuSpec extends BaseProductSpec<SpecValue> {}

    export interface ProductSku {
        no?: string
        name?: string
        skuNo?: string
        productId?: string
        /**
         * sku 规格 （提交时传入规格在商品规格列表中的索引），服务器端返回格式为 {@link Array<ProductSpec<string>>}。
         */
        specs?: Array<SkuSpec>
        /**
         * 成本
         */
        cost?: number
        stock?: number
        price?: number
        image?: string
        status?: SkuStatus
        tagPrice?: number
        /**
         * sku规格序号（数组索引等价于商品规格列表中的索引{@link Product["specStr"]}，该位置的数值标识规格值为对应商品规格值列表的索引{@link ProductSpec["value"]}）
         */
        index?: Array<number>
        /**
         * 当前 sku 是否允许编辑
         */
        editable?: boolean
    }

    export interface Product {
        /**
         * 商品id
         */
        id?: string
        /**
         * 货号
         */
        itemNo?: string
        /**
         * 商户id
         */
        merchantId?: number
        /**
         * 商户封面图片(logo)
         */
        merchantCover?: string
        /**
         * 商户名称
         */
        merchantName?: string
        /**
         * 展示图
         */
        cover?: string
        /**
         * 轮播图
         */
        sliderImage?: string
        /**
         * 详情图
         */
        storeDetail?: string
        /**
         * 商品名称
         */
        storeName?: string
        /**
         * 商品分类id
         */
        cateCode?: string
        /**
         * 商品分类名称
         */
        cateName?: string
        /**
         * 收货方式
         */
        pickUpType?: PickUpType
        /**
         * 价格
         */
        price: number
        /**
         * 单位
         */
        unitName: string
        /**
         * 排序(正序)
         */
        sort: number
        /**
         * 销量
         */
        sales: number
        /**
         * 状态 1-待审核 2-审核通过（仓库） 3-驳回 4-上架(出售中) 5-下架（仓库） 6-删除 7-禁用
         */
        status: ProductStatus
        /**
         * 浏览次数
         */
        browse: number
        /**
         * 是否可从远程服务器同步库存
         */
        syncStock?: boolean
        /**
         * 品牌id
         */
        brandId?: number
        /**
         * 推荐排序
         */
        promoteSort?: number
        /**
         * 价格类型
         */
        priceType?: number
        /**
         * 日期选择方式 0-单日 1-区间
         */
        buyModel?: number
        /**
         * 是否需要实名
         */
        verified?: boolean
        /**
         * 驳回原因
         */
        remarks?: string
        /**
         * 库存控制：0- 按SKU（单规格库存）、1- 按商品（所有规格共用库存）
         */
        stockType?: number
        /**
         * 是否为组合商品
         */
        combinationGood?: boolean
        /**
         * 核销控制：true-单独核销 false-订单核销
         */
        singleWriteOff?: boolean
        /**
         * 规格列表 {@link Array}<{@link ProductSpec}> 的 json 字符串。
         */
        specs?: string
        /**
         * 规格列表 {@link Array}<{@link ProductSpec}>。
         */
        specArray?: Array<ProductSpec>
        /**
         * sku 列表 {@link ProductSku}。
         */
        skuList?: Array<ProductSku>
        /**
         * 添加时间
         */
        createTime?: number
        /**
         * 添加人
         */
        createUserName?: string
        /**
         * 最后修改时间
         */
        lastUpdateTime?: number
        /**
         * 最后修改人
         */
        lastUpdateUserName?: string

        invoiceParty?: InvoiceParty
        /**
         * 购买方式 0-小程序内展示 1-跳转到其他小程序
         */
        type?: number
        /**
         * 小程序 appId
         * 购买方式为1时有效。
         */
        wechatAppId?: string
        /**
         * 小程序页面路径
         * 购买方式为1时有效。
         */
        wechatPageLocation?: string
        allowEdit: boolean
    }

    export interface SkuInfo {
        skuNo: string
        specValue: string
        storeName: string
        productId: string
        merchantName: string
        startMinute: number
        endMinute: number
        cost: number
        sellPrice: number
    }

    export interface CalendarItem {
        id?: string,
        skuNo?: string
        /**
         * 使用日期（开始）
         */
        startTime?: number
        /**
         * 使用日期（结束）
         */
        endTime?: number
    }

    /**
     * 游船航线
     */
    export interface shipRoute {
        dicId?: string,
        dicName?: string,
        id?: string,
        merchantId?: number,
        type?: number
    }

    /**
     * 日历价格
     */
    export interface CalendarPrice extends CalendarItem {
        /**
         * 售价
         */
        sellPrice?: number
        /**
         * 吊牌价
         */
        tagPrice?: number
        /**
         * 成本价
         */
        costPrice?: number
        /**
         * 购买日期开始（分钟数）
         */
        takeEffectTime?: number
        /**
         * 购买日期结束（分钟数）
         */
        invalidTime?: number
        /**
         * 可用时段开始（分钟数）
         */
        startMinute?: number
        /**
         * 可用时段结束（分钟数）
         */
        endMinute?: number
        status?: number
        timeId?: number
        childProductInfo: SkuInfo[]
    }

    /**
    * 日历库存
    */
    export interface CalendarStock extends CalendarItem {
        status?: number
        stockNum?: number
        soldNum?: number
        lockNum?: number
        surplusNum?: number
        useTime?: number
    }

    /**
     * 价格类型
     */
    export enum PriceType {
        /**
         * 统一价格
         */
        Flat = 0,
        /**
         * 日历价格
         */
        Calendar = 1,
        /**
         * 分时预约
         */
        TimeSharing = 2
    }

    /**
    * 日历库存状态
    */
    export enum CalendarStockStatus {
        /**
         * 启用
         */
        Enabled = 1,
        /**
         * 禁用
         */
        Disabled = 2
    }

    /**
   * 日历价格状态
   */
    export enum CalendarPriceStatus {
        /**
         * 新建
         */
        NEW = 0,
        /**
        * 生效
        */
        TAKE_EFFECT = 1,
        /**
         * 失效
         */
        INVALID = 2,
        /**
         * 停止
         */
        STOP = 3
    }

    export interface PriceInfo {
        /**
         * 是否允许修改关联子商品
         */
        allowUpdate: boolean,
        childList: SkuInfo[]
    }

    export async function addCate(name?: string, sort?: number, image?: string, parentCode?: string, taxCode?: string, taxRate?: number) {
        return fetch.post<ProductCate>("/api/product-cate", { name, sort, image, parentCode, taxCode, taxRate });
    }

    export async function updateCate(code?: string, name?: string, image?: string, sort?: number, taxCode?: string, taxRate?: number) {
        return fetch.put<void>(`/api/product-cate/${code}`, { name, image, sort, taxCode, taxRate });
    }

    export async function changeCateStatus(code: string, status: ProductCateStatus) {
        return fetch.put<void>(`/api/product-cate/status/${code}`, { status: status });
    }

    export async function listCate(name?: string, status?: ProductCateStatus) {
        return fetch.get<Array<ProductCate>>("/api/product-cate", { name, status });
    }

    export type AddParams = PickRequire<Product, "merchantId" | "cover" | "sliderImage" | "cateCode" | "unitName", "sort" | "brandId" | "promoteSort" | "itemNo">;
    export async function add(
        merchantId: number, storeName: string, cover: string, sliderImage: string, cateCode: string,
        unitName: string, itemNo?: string, sort?: number, brandId?: number, promoteSort?: number,
        type?: number, wechatAppId?: string, pagePath?: string, pickUpType?: PickUpType,
        invoiceParty?: InvoiceParty, priceType?: number, verified?: boolean, buyModel?: number,
        stockType?: number, combinationGood?: boolean, singleWriteOff?: boolean
    ) {
        return fetch.post<Product>("/api/product", {
            merchantId, storeName, cover, sliderImage, cateCode, unitName, itemNo, sort, brandId, promoteSort,
            type, wechatAppId, wechatPageLocation: pagePath, pickUpType, invoiceParty, priceType, verified, buyModel,
            stockType, combinationGood, singleWriteOff
        });
    }

    export async function changeSkuStatus(ids: string, status: SkuStatus) {
        return fetch.put<void>("/api/product/sku/status", { skuNos: ids, status });
    }

    /**
     * 通过审核
     * @param ids ','分隔的商品编号
     */
    export async function audit(ids?: string) {
        return fetch.put<void>("/api/product/status/audite", { ids });
    }
    /**
     * 下架
     * @param ids ','分隔的商品编号
     */
    export async function offShelves(ids?: string) {
        return fetch.put<void>("/api/product/status/off-shelves", { ids });
    }
    /**
     * 标记删除指定商品。
     * @param ids ','分隔的商品编号
     */
    export async function markAsDelete(ids?: string) {
        return fetch.put<void>("/api/product/status/delete", { ids });
    }
    /**
     * 上架
     * @param ids ','分隔的商品编号
     */
    export async function onSale(ids?: string) {
        return fetch.put<void>("/api/product/status/on-sale", { ids });
    }
    /**
     * 驳回。
     * @param ids ','分隔的商品编号
     * @param reason 驳回原因
     */
    export async function reject(ids?: string, reason?: string) {
        return fetch.put<void>("/api/product/status/reject", { ids, reason });
    }

    /**
     * 提交审核。
     * @param ids ','分隔的商品编号
     */
    export async function submit(ids?: string) {
        return fetch.put<void>("/api/product/status/submit", { ids });
    }

    export async function detail(id: string) {
        return fetch.get<Product>(`/api/product/${id}`);
    }

    export async function saveDetail(id: string, content?: string) {
        return fetch.post<void>("/api/product/detail", { id, content });
    }

    export async function saveSpecAndSku(id: string, spec: string, sku: string) {
        return fetch.post<void>("/api/product/spec", { id, spec, sku });
    }

    export async function list(limit?: number, skip?: number, name?: string, status?: ProductStatus, itemNo?: string, merchantName?: string, cateCode?: string) {
        return fetch.get<PageResult<Product>>("/api/product", { limit, skip, name, status, itemNo, merchantName, cateCode });
    }

    export async function listSku(limit?: number, skip?: number, search?: string) {
        return fetch.get<PageResult<SkuInfo>>("/api/product/sku", { limit, skip, search });
    }

    export async function update(
        id: string, merchantId: number, storeName: string, cover: string, sliderImage: string,
        cateCode: string, unitName: string, itemNo?: string, sort?: number, brandId?: number,
        promoteSort?: number, type?: number, wechatAppId?: string, pagePath?: string, pickUpType?: PickUpType,
        invoiceParty?: InvoiceParty, priceType?: number, verified?: boolean, buyModel?: number,
        stockType?: number, combinationGood?: boolean, singleWriteOff?: boolean
    ) {
        return fetch.put<void>(`/api/product/${id}`, {
            merchantId, storeName, cover, sliderImage, cateCode, unitName, itemNo, sort, brandId, promoteSort,
            type, wechatAppId, wechatPageLocation: pagePath, pickUpType, invoiceParty, priceType, verified, buyModel,
            stockType, combinationGood, singleWriteOff
        });
    }

    export async function listAvailableForBatch(limit?: number, skip?: number, name?: string, merchantIds?: string, cateCodes?: string) {
        return fetch.get<PageResult<Product>>("/api/product/coupon/available", { limit, skip, name, merchantIds, cateCodes });
    }

    /*价格日历 */
    export async function addCalendarPrice(
        skuNo: string, startTime: number, endTime: number, takeEffectTime: number, invalidTime: number,
        startMinute: number, endMinute: number, sellPrice: number, costPrice: number, tagPrice: number,
        timeId?: string, childProductInfo?: Array<SkuInfo>
    ) {
        return fetch.post<void>("/api/product/calendar/price", {
            skuNo, startTime, endTime, startMinute, endMinute, takeEffectTime, invalidTime, sellPrice, costPrice, tagPrice, timeId,
            childProductInfo: childProductInfo == null ? null : JSON.stringify(childProductInfo)
        });
    }

    export async function updateCalendarPrice(
        id: string, startTime: number, endTime: number, takeEffectTime: number, invalidTime: number,
        startMinute: number, endMinute: number, sellPrice: number, costPrice: number, tagPrice: number,
        timeId?: string, childProductInfo?: Array<SkuInfo>
    ) {
        return fetch.put<void>(`/api/product/calendar/price/${id}`, {
            startTime, endTime, takeEffectTime, invalidTime, startMinute, endMinute, sellPrice, costPrice, tagPrice, timeId,
            childProductInfo: childProductInfo == null ? null : JSON.stringify(childProductInfo)
        });
    }

    export async function stopCalendarPrice(id: string) {
        return fetch.patch<void>(`/api/product/calendar/price/invalid/${id}`);
    }

    export async function deleteCalendarPrice(id: string) {
        return fetch.delete<void>(`/api/product/calendar/price/${id}`);
    }

    export async function calendarPriceList(limit?: number, skip?: number, skuNo?: string, status?: number, startTime?: number, endTime?: number, takeEffectTime?: number, invalidTime?: number) {
        return fetch.get<PageResult<CalendarPrice>>("/api/product/calendar/price", {
            limit, skip, skuNo, status, startTime, endTime, takeEffectTime, invalidTime
        });
    }

    export async function getPriceDetail(skuNo: string, priceId?: string) {
        return fetch.get<{ allowUpdate: boolean, childList: SkuInfo[] }>("/api/product/child", { skuNo, priceId });
    }

    /**
     * 添加商品库存。
     * 
     * @param stockType 库存控制：0- 按SKU（单规格库存）、1- 按商品（所有规格共用库存）
     * @param startTime 开始日期
     * @param endTime   结止日期（null：长期生效）
     * @param useTime   截止时间（当日0点开始到截止时间的分钟数）
     * @param stock     库存数
     * @param itemId    stockType为1时，传入商品id；否则，传入 sku 编号。
     */
    export async function addCalendarStock(stockType: number, startTime: number, endTime: number, useTime: number, stock: number, itemId?: string) {
        if (stockType == 1) {
            return fetch.post<void>("/api/product/calendar/stock/by-product", { productId: itemId, startTime, endTime, useTime, stock });
        } else {
            return fetch.post<void>("/api/product/calendar/stock/by-sku", { skuNo: itemId, startTime, endTime, useTime, stock });
        }
    }

    export async function updateCalendarStock(id: string, stock: number) {
        return fetch.put<void>(`/api/product/calendar/stock/${id}`, {
            stock
        });
    }

    export async function updateCalendarStockStatus(id: string, status: ProductApi.CalendarStockStatus) {
        return fetch.patch<void>(`/api/product/calendar/stock/status/${id}`, {
            status
        });
    }

    export async function calendarStockList(limit?: number, skip?: number, itemId?: string, status?: number, startTime?: number, endTime?: number) {
        return fetch.get<PageResult<CalendarPrice>>("/api/product/calendar/stock", {
            limit, skip, itemId, status, startTime, endTime
        });
    }

    /**
     * 同步远程服务器库存
     * @param productId 商品 id
     */
    export async function syncRemoteStock(productId: string) {
        return fetch.post<void>("/api/product/calendar/stock/sync", { productId });
    }

    export async function getMerchantDic(merchantId?: string, type: number = 1) {
        return fetch.get<shipRoute[]>("/api/merchant/dic", { merchantId, type });
    }
}