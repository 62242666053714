

import { BaseModel } from "@framework/models";

import { RouteWithPermission } from "@framework/route-with-permission";

import app from "@root/app";
import { UserApi } from "@api";

type SignInfo = UserApi.SignInfo;

export interface SiderBarState extends SignInfo {
    routers: RouteWithPermission[]
    openKeys: string[]
    selectedKeys: string[]
    collapsed: boolean
}

export class SiderBarModel extends BaseModel<SiderBarState> {
    private routers: RouteWithPermission[];
    constructor(state: SiderBarState) {
        super();
        this.routers = state.routers;
        this.setState({...state, routers: this.getFilteredRouter()});
    }

    public getFilteredRouter(): RouteWithPermission[] {
        if (this.routers[1].children != null) {
            return this.filterRouter(this.routers[1].children);
        }
        return [];
    }

    private filterRouter(routers: RouteWithPermission[]): RouteWithPermission[] {
        let result: RouteWithPermission[] = [];
        for (let router of routers) {
            if (app.hasPermission(router.permission)) {
                let item = {...router};
                if (item.children != null) {
                    item.children = this.filterRouter(item.children);
                    if (item.children.length > 0) {
                        result.push(item);
                    }
                } else {
                    result.push(item);
                }
            }
        }
        return result;
    }

}