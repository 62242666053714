

import Icon from "@ant-design/icons";
import { CSSProperties, useState } from "react";
import { DrawerProps, SelectProps } from "antd";
import { AMapComponent } from "./a-map";

import { QuerySelect } from ".";
import { AppBase } from "@framework/app-base";
import "@framework/styles/geo-selector.global.less";

import IconLocation from "@framework/icon/icon-location.svg";
import { PageResult } from "../utils";

type ValueType = Pick<AMap.POI, "location" | "address" | "adname"> & { street?: string };

export interface GeoSelectorProps {
    name?: string
    value?: ValueType
    center?: [number, number]
    className?: string
    style?: CSSProperties
    onChange?: (value: ValueType | undefined) => void
}

export function POIRender(poi: AMap.POI & { index?: number, selected?: boolean }) {
    return (
        <div className="poi-container">
            <div className={`icon ${poi.selected ? "selected" : ""}`}>{poi.index}</div>
            <div className="poi-desc">
                <div className="poi-title">{poi.name}</div>
                <div className="poi-info"><p>{`地址：${poi.address}`}</p></div>
                {poi.tel != null && poi.tel != "" && <div className="poi-info"><p>{`电话：${poi.tel}`}</p></div>}
            </div>
            {poi.photos != null && poi.photos.length > 0 && <div className="poi-image" style={{background: `url(${poi.photos[0].url}) no-repeat`}}></div>}
        </div>
    )
}


export interface MapSearchProps {
    value?: ValueType
    center?: [number, number]
    onChange?: (value: ValueType | undefined) => void
}

export function MapSearch({ value, center, onChange } : MapSearchProps) {

    const [ map, setMap ] = useState<AMap.Map>();
    const [ fireSearch, setFireSearch ] = useState<(value?: string, limit?: number, skip?: number) => Promise<PageResult<AMap.POI>>>();
    const [ store ] = useState<{ search?: (value: string) => any, geocoder?: AMap.Geocoder }>({});

    const antiGeocode = (value: ValueType, map: AMap.Map) => {
        new Promise<ValueType>((resolve, reject) => {
            if (store.geocoder != null) {
                store.geocoder.getAddress(value.location, (status, result) => {
                    if (status == "complete") {
                        const { province, city, district, township } = result.regeocode.addressComponent;
                        const prefix = `${province}${city}${district}${township}`;
                        value.address = result.regeocode.formattedAddress.substring(prefix.length);
                        value.adname = district;
                        value.street = township;
                    }
                    resolve(value);
                });
            } else {
                resolve(value);
            }
        }).then((value) => {
            onChange && onChange(value);
        }).finally(() => {
            map?.clearMap();
            const marker = new AMap.Marker({
                icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png",
                position: value.location 
            });
            map?.add(marker);
            map?.setFitView([marker]);
        })
    }

    const addSelectedMarker = (amap: AMap.Map) => {
        if (value?.location != null) {
            const marker = new AMap.Marker({
                icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png",
                position: [value.location.lng, value.location.lat] 
            });
            amap.add(marker);
            amap.setFitView([marker]);
        }
    }

    const onMapMounted = (map: AMap.Map) => {
        setMap(map);
        const placeSearch = new AMap.PlaceSearch({
            pageSize: 5,
            pageIndex: 1,
            map: map,
            city: "天津市",
            autoFitView: true,
            extensions: "base"
        });
        map?.plugin(["AMap.Geocoder"], () => {
            store.geocoder = new AMap.Geocoder({ radius: 100 });
        });
        setFireSearch(() => (keyword?: string, limit?: number, skip?: number) => {
            placeSearch.setPageSize(limit == null ? 10 : limit);
            placeSearch.setPageIndex(skip == null || limit == null ? 0 : Math.floor(skip / limit) + 1);
            return new Promise<PageResult<AMap.POI>>((resolve, reject) => {
                if (keyword != null && keyword != "") {
                    placeSearch.search(keyword, (status, result) => {
                        if (result.poiList == null) {
                            resolve({ limit: limit as number, skip: skip as number, total: skip as number, info: [] });
                        } else {
                            resolve({
                                limit: result.poiList.pageSize,
                                skip: (result.poiList.pageIndex - 1) * result.poiList.pageSize,
                                total: result.poiList.count,
                                info: result.poiList.pois
                            });
                        }
                    });
                } else {
                    reject("");
                }
            });
        });

        addSelectedMarker(map);
        map.on("click", (e) => {
            antiGeocode({ location: e.lnglat, address: "", adname: "" }, map);
        });
        AMap.event.addListener(placeSearch, "selectChanged", onPoiSelect);
    }
    
    const onPoiSelect: SelectProps["onChange"] = (value) => {
        antiGeocode({ location: value.location, address: value.address, adname: value.adname }, map as AMap.Map);
    }

    return (
        <div className="map-container">
            <AMapComponent
                center={center}
                afterMount={onMapMounted}
                plugins={["AMap.PlaceSearch"]}
                appKey={AppBase.Instance.mapAppKey}
                securityJsCode={AppBase.Instance.mapSecurity}
            />
            <div className="search-container" onClick={e => e.stopPropagation()}>
                <QuerySelect
                    keyOfValue="id"
                    keyOfLabel="name"
                    triggerValue="object"
                    onChange={onPoiSelect}
                    placeholder="按地名查询"
                    fetchItems={fireSearch}
                    optionLabelProp="name"
                    renderOptions={(value, index, selected) => <POIRender {...value} index={index} selected={selected} />}
                />
            </div>
        </div>
    )
}

export function GeoSelector({ name, value, center, className, style, onChange }: GeoSelectorProps) {

    const showMapSelector = () => {
        const drawerProps: DrawerProps = {
            width: "auto",
            title: name,
            open: true,
            placement: "right",
            maskClosable: true,
            destroyOnClose: true,
            bodyStyle: {padding: 10},
            contentWrapperStyle: {left: 300}
        };
        drawerProps.children = (<MapSearch value={value} onChange={onChange} center={center} />);
        AppBase.Instance.showDrawer(drawerProps);
    };

    return (
        <div className={className} style={style}>
            <div onClick={showMapSelector}>
                <Icon component={IconLocation} />
                <span>{value?.location == null ? "未设置" : `[${value.location.lng}, ${value?.location.lat}]`}</span>
            </div>
        </div>
    )

}