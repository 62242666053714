import { DEFAULT_IDS_CONVERTER, PageResult, TimestampConverter } from "@framework/utils";
import { QueryModel, QueryState } from "@framework/models";
import { Decorator, DecoratorForm, FileUploader, TreeQuerySelect, QuillEditor, TableColumn, PermissionButton, QuerySelect } from "@framework/component";

import { Button, DatePicker, Divider, Input, InputNumber, Modal, ModalFuncProps, Result, Select, TimePicker, Radio } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { FilesConverter, MinuteConverter } from "@root/converter";
import { AdvertisingApi, FileUploadApi, ProductApi } from "@api";
import { ArticleCateStatusMap, AdvertisingStatusMap, repeatNoticeStatusMap } from "./tag-map";
import { ImageColumn, SvgColumn, TagColumn, TimestampColumn } from "@root/component";

import AdvertisingStatus = AdvertisingApi.AdvertisingStatus;
import AdvertisingType = AdvertisingApi.AdvertisingType;
import { PermissionCode } from "@root/permission";
import { MinutesColumn } from "@root/component/column";

type Advertising = AdvertisingApi.Advertising;


interface AdvertisingState extends QueryState<Advertising> {
    currentStep: number
}

export class AdvertisingModel extends QueryModel<AdvertisingState> {

    constructor() {
        super();
    }

    public getQueryDecorators(): Decorator<any>[] {
        const labelCol = { span: 5 };
        return [{
            name: "title",
            label: "标 题",
            labelCol: labelCol,
            className: "query-form-item",
            element: <Input placeholder="按标题查询" />
        }, {
            name: "status",
            label: "状 态",
            labelCol: labelCol,
            className: "query-form-item",
            element: (
                <Select allowClear={true}>
                    <Select.Option value={AdvertisingStatus.Enabled}>启用</Select.Option>
                    <Select.Option value={AdvertisingStatus.Disabled}>禁用</Select.Option>
                </Select>
            )
        }]
    }

    public getEditorDecorators(editingItem?: Advertising): Decorator<any>[] {
        const labelCol = { span: 5 };
        const { currentStep } = this.state;
        console.log(this.state);
        return [{
            name: "title",
            label: "标 题",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: <Input placeholder="请输入文章标题" />,
            rules: [{ required: true, message: "文章标题不能为空" }],
            initialValue: editingItem?.title
        }, {
            name: "sort",
            label: "推荐序号",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: <InputNumber min={0} max={999999} />,
            initialValue: editingItem?.sort
        }, {
            name: "type",
            label: "类型",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: (
                <Select allowClear={true}>
                    <Select.Option value={null}>无</Select.Option>
                    <Select.Option value={AdvertisingType.product}>商品</Select.Option>
                </Select>),
            initialValue: editingItem?.type
        }, {
            name: "relevancyId",
            label: "关联商品",
            labelCol: labelCol,
            dependencies: ["type", "sort"],
            className: "editor-form-item-2",
            element: (
                <QuerySelect
                    allowClear
                    keyOfValue="id"
                    keyOfLabel="storeName"
                    placeholder="搜索想要绑定的商品"
                    fetchItems={(value, limit, skip) => ProductApi.list(limit, skip, value)}
                />
            ),
            updateProps: (_, [type, sort]) => {
                if (type == AdvertisingType.product) {
                    return {
                        element: <QuerySelect
                            allowClear
                            keyOfValue="id"
                            keyOfLabel="storeName"
                            placeholder="搜索想要绑定的商品"
                            fetchItems={(value, limit, skip) => ProductApi.list(limit, skip, value)}
                        />
                    }
                }
            },
            initialValue: editingItem?.relevancyId
        }, {
            name: "startDate",
            label: "开始日期",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: <DatePicker style={{ width: "100%" }} placeholder="请选择活动开始日期" />,
            rules: [{ required: true, message: "请选择开始日期" }],
            initialValue: editingItem?.startDate,
            converter: new TimestampConverter()
        }, {
            name: "endDate",
            label: "结束日期",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: <DatePicker style={{ width: "100%" }} placeholder="请选择活动结束日期" />,
            rules: [{ required: true, message: "请选择结束日期" }],
            initialValue: editingItem?.endDate,
            converter: new TimestampConverter()
        }, {
            name: "startTime",
            label: "每日开始时间",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: <TimePicker style={{ width: "100%" }} placeholder="请选择开始时间" format="HH:mm" />,
            rules: [{ required: true, message: "请选择开始时间" }],
            initialValue: editingItem?.startTime,
            converter: MinuteConverter
        }, {
            name: "endTime",
            label: "每日结束时间",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: <TimePicker style={{ width: "100%" }} placeholder="请选择结束时间" format="HH:mm"/>,
            rules: [{ required: true, message: "请选择结束时间" }],
            initialValue: editingItem?.endTime,
            converter: MinuteConverter
        }, {
            name: "repeatNotice",
            label: "重复显示",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: (
                <Radio.Group value={true}>
                    <Radio value={true}>重复</Radio>
                    <Radio value={false}>每天一次</Radio>
                </Radio.Group>),
            rules: [{ required: true, message: "重复显示不能为空" }],
            initialValue: editingItem?.repeatNotice
        }, {
            name: "image",
            label: "广 告 图",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: (
                <FileUploader
                    maxCount={1}
                    accept="image/*"
                    listType="picture"
                    converter={FilesConverter}
                    customRequest={FileUploadApi.requestForUploader}
                >
                    <p className="ant-upload-drag-icon" style={{ display: "inline" }}>
                        <InboxOutlined height="100%" style={{ fontSize: 32 }} />
                        <span className="ant-upload-text" style={{ display: "inline-block" }}>点击此区域上传图片</span>
                        <span className="ant-upload-hint" style={{ display: "inline-block" }}>建议使用长形图片，大小不超过300k</span>
                    </p>
                </FileUploader>
            ),
            converter: DEFAULT_IDS_CONVERTER,
            rules: [{ required: true, message: "广告图片不能为空" }],
            initialValue: editingItem?.image
        }]
    }

    public getTableColumns(): TableColumn<Advertising>[] {
        return [
            { title: "标题", dataIndex: "title" },
            new ImageColumn("广 告 图", "image", 64),
            new TimestampColumn("投放开始日期", "startDate", { fallback: "--" }),
            new TimestampColumn("投放结束日期", "endDate", { fallback: "--" }),
            new MinutesColumn("投放开始时间", "startTime", { fallback: "--" }),
            new MinutesColumn("投放结束时间", "endTime", { fallback: "--" }),
            { title: "推荐序号", dataIndex: "sort", fallback: "--" },
            new TagColumn(repeatNoticeStatusMap, "重复通知", "repeatNotice"),
            new TagColumn(AdvertisingStatusMap, "状态", "status"),
            new TimestampColumn("创建时间", "createTime", { fallback: "--" }),
            { title: "创建人", dataIndex: "createUserName", fallback: "--" },
            new TimestampColumn("最后修改时间", "lastUpdateTime"),
            { title: "最后修改人", dataIndex: "lastUpdateUserName", fallback: "--" },
            {
                render: (value, record, index) => {
                    const result = [
                        <PermissionButton
                            type="link"
                            key="update"
                            hideWhenNoPermission={true}
                            onClick={() => this.showEditor(record)}
                            permissionCode={PermissionCode.ARTICLE_MANAGE}
                        >
                            修改
                        </PermissionButton>,
                        <Divider key="4" type="vertical" />,
                        <PermissionButton
                            key="del"
                            type="link"
                            style={{ color: "crimson" }}
                            hideWhenNoPermission={true}
                            permissionCode={PermissionCode.ARTICLE_MANAGE}
                            onClick={() => this.#deleteAdvertising(record)}
                        >
                            删除
                        </PermissionButton>
                    ];
                    if (record.status == AdvertisingStatus.Enabled) {
                        result.push(
                            <Divider key="1" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="disable"
                                hideWhenNoPermission={true}
                                onClick={() => this.#disable(record)}
                                permissionCode={PermissionCode.ARTICLE_CATE_MANAGE}
                            >
                                禁用
                            </PermissionButton>
                        );
                    } else {
                        result.push(
                            <Divider key="1" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="enable"
                                hideWhenNoPermission={true}
                                onClick={() => this.#enable(record)}
                                permissionCode={PermissionCode.ARTICLE_CATE_MANAGE}
                            >
                                启用
                            </PermissionButton>
                        );
                    }
                    return result;
                }
            }
        ];
    }

    #enable = (item?: Advertising) => {
        AdvertisingApi.changeAdvertisingStatus(item?.id, AdvertisingStatus.Enabled).then(this.refresh);
    }

    #disable = (item?: Advertising) => {
        AdvertisingApi.changeAdvertisingStatus(item?.id, AdvertisingStatus.Disabled).then(this.refresh);
    }

    #deleteAdvertising = async (Advertising: Advertising) => {
        await this.#showConfirmModal({
            title: "删除确认",
            type: "warn",
            content: `正在删除《${Advertising.title}》，是否继续？`,
            okText: "删除",
            okButtonProps: { danger: true }
        });
        await AdvertisingApi.deleteAdvertising(Advertising.id);
        this.refresh();
    }

    #showConfirmModal = (props: ModalFuncProps) => {
        const { onOk, onCancel } = props;

        return new Promise<void>((resolve, reject) => {
            props.onOk = (...args) => {
                onOk != null && onOk(...args);
                resolve();
            };
            props.onCancel = (...args) => {
                onCancel != null && onCancel(...args);
                reject();
            }
            Modal.confirm(props);
        })
    }

    public showEditor(editingItem?: Advertising | undefined) {
        this.updateState({ showEditor: true, currentStep: 0, editingItem: editingItem });
    }

    public saveOrUpdate(Advertising?: Advertising) {
        const { editingItem } = this.state;
        if (editingItem?.id == null) {
            return AdvertisingApi.addAdvertising(Advertising?.title, Advertising?.image, Advertising?.type, Advertising?.relevancyId, Advertising?.sort, Advertising?.status, Advertising?.startDate, Advertising?.endDate, Advertising?.repeatNotice, Advertising?.startTime, Advertising?.endTime);
        } else {
            return AdvertisingApi.changeAdvertising(editingItem.id, Advertising?.title, Advertising?.image, Advertising?.type, Advertising?.relevancyId, Advertising?.sort, Advertising?.status, Advertising?.startDate, Advertising?.endDate, Advertising?.repeatNotice, Advertising?.startTime, Advertising?.endTime);
        }
    }
    
    public queryOverride(params?: any): Promise<PageResult<Advertising>> {
        return AdvertisingApi.getAdvertisingList(params?.title, params?.status, params?.type);
    }

}