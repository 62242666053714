
import React, { lazy, ReactNode, Suspense } from "react";

import { SignInModel, UserModel } from "./models/user-model";

// 切换页面会出现闪屏现象
// 解决思路：公共页面不采用懒加载的方式 并在App.tsx去除Suspense的包裹
import AppLayout from "./layout";
import { RouteWithPermission } from "@framework/route-with-permission";
import Icon, { OrderedListOutlined, UserOutlined } from "@ant-design/icons";
import { ActivityDetailModel, ActivityModel, ArticleCateModel, ArticleModel, BrandModel, MerchantCateModel, MerchantDetailModel, MerchantModel, OrderDetailModel, OrderModel, ProductCateModel, ProductModel, RoleModel, VoucherBatchDetailModel, VoucherBatchModel, BannerModel, AdvertisingModel, MerchantAddressModel, PayRecordModel, PaySummaryModel, BusinessIncomeModel, PrepaidIncomeModel, MerchantIncomeModel } from "./models";
import { PermissionCode } from "./permission";
// import ScenicSpot from "./svg/scenic-spot.svg";


const Home = lazy(() => import("@pages/home"));
const UserSignIn = lazy(() => import("@pages/sign"));
const UserManager = lazy(() => import("@pages/user/user-manager"));
const RoleManager = lazy(() => import("@pages/user/role-manager"));
const MerchantCateManager = lazy(() => import("@pages/merchant/merchant-cate"));
const MerchantManager = lazy(() => import("@pages/merchant/merchant-manager"));
const MerchantAddressManager = lazy(() => import("@pages/merchant/merchant-address"));
const MerchantDetail = lazy(() => import("@pages/merchant/merchant-detail"));
const ProductCateManager = lazy(() => import("@pages/product/product-cate-manager"));
const ProductManager = lazy(() => import("@pages/product/product-manager"));
const ProductDetail = lazy(() => import("@pages/product/product-detail"));
const BrandManager = lazy(() => import("@pages/product/brand-manager"));
const VoucherBatchManager = lazy(() => import("@pages/market/voucher-batch-manager"));
const VoucherBatchDetail = lazy(() => import("@pages/market/voucher-batch-detail"));
const ActivityManager = lazy(() => import("@pages/market/activity-manager"));
const ActivityDetail = lazy(() => import("@pages/market/activity-detail"));
const BannerPages = lazy(() => import("@pages/marketing/banner-pages"));
const Advertising = lazy(() => import("@pages/marketing/advertising"));
const OrderManger = lazy(() => import("@pages/order/order-list"));
const OrderDetail = lazy(() => import("@pages/order/order-detail"));
const ArticleManger = lazy(() => import("@pages/article/article-manager"));
const ArticleCateManager = lazy(() => import("@pages/article/article-cate-manager"));
const PayRecordView = lazy(() => import("@pages/report/payment-record"));
const PaySummaryView = lazy(() => import("@pages/report/payment-summary"));
const BusinessIncomeView = lazy(() => import("@pages/report/buisiness-report"));
const PrepaidIncomeView = lazy(() => import("@pages/report/prepaid-report"));
const MerchantIncomeView = lazy(() => import("@pages/report/merchant-reconciliation-report"));

import MerchantSvg from "@svg/merchant.svg";
import MerchantCateSvg from "@svg/merchant-cate.svg";
import MerchantAddressSvg from "@svg/icon-merchant-address.svg";
import ScenicSpotSvg from "@svg/scenic-spot.svg";
import ProductMenuSvg from "@svg/icon-product-menu.svg";
import ProductCateSvg from "@svg/icon-product-cate.svg";
import BrandSvg from "@svg/icon-brand.svg";
import ActivityCenterSvg from "@svg/icon-activity-center.svg";
import VoucherGroupSvg from "@svg/icon-voucher-group.svg";
import RoleManageSvg from "@svg/icon-role-manage.svg";
import ActivityManagerSvg from "@svg/icon-activity-manager.svg";
import OrderManagerSvg from "@svg/icon-order-manager.svg";
import PlatformOrderSvg from "@svg/icon-platform-order.svg";
import MerchantOrderSvg from "@svg/icon-merchant-order.svg";
import RefundOrderSvg from "@svg/icon-refund-order.svg";
import ArticleMangerSvg from "@svg/icon-article-manager.svg";
import ArticleCateSvg from "@svg/icon-article-cate.svg";
import ArticleSvg from "@svg/icon-article.svg";

import { Navigate } from "react-router-dom";

// const User = lazy(() => import('../User'));
// 实现懒加载的用Suspense包裹 定义函数
const lazyLoad = (children: ReactNode): ReactNode => {
    return <Suspense fallback={<h1>Loading...</h1>}>
        {children}
    </Suspense>
}

let index = 1;
export const routers: RouteWithPermission[] = [{
    key: index++,
    index: true,
    element: (<Navigate to="/app" />),
    showInSider: false
}, {
    key: index++,
    path: "app",
    element: (<AppLayout />),
    children: [{
        key: index++,
        index: true,
        title: "首页",
        showInSider: false,
        element: lazyLoad(<Home model={new SignInModel()} />)
    }, {
        key: index++,
        title: "订单管理",
        icon: <Icon component={OrderManagerSvg} />,
        path: "order",
        children: [{
            key: index++,
            path: "platform",
            title: "平台订单",
            icon: <Icon component={PlatformOrderSvg} />,
            element: lazyLoad(<OrderManger key="Platform" model={new OrderModel("Platform")} />),
            permission: { requirePermissions: [PermissionCode.ORDER_PLATFORM_VIEW], requireType: "any" }
        }, {
            key: index++,
            path: "merchant",
            title: "商户订单",
            icon: <Icon component={MerchantOrderSvg} />,
            element: lazyLoad(<OrderManger key="Merchant" model={new OrderModel("Merchant")} />),
            permission: { requirePermissions: [PermissionCode.ORDER_MERCHANT_VIEW], requireType: "any" }
        }, {
            key: index++,
            path: "refund",
            title: "退款订单",
            icon: <Icon component={RefundOrderSvg} />,
            element: lazyLoad(<OrderManger key="Refund" model={new OrderModel("Refund")} />),
            permission: { requirePermissions: [PermissionCode.ORDER_REFUND_VIEW, PermissionCode.ORDER_REFUND_PRICE], requireType: "any" }
        }, {
            key: index++,
            path: "platform/:id",
            title: "平台订单详情",
            element: lazyLoad(<OrderDetail model={new OrderDetailModel("Platform")} />),
            showInSider: false
        }, {
            key: index++,
            path: "merchant/:id",
            title: "商户订单详情",
            element: lazyLoad(<OrderDetail model={new OrderDetailModel("Merchant")} />),
            showInSider: false
        }, {
            key: index++,
            path: "refund/:id",
            title: "退款订单详情",
            element: lazyLoad(<OrderDetail model={new OrderDetailModel("Refund")} />),
            showInSider: false
        }]
    }, {
        key: index++,
        title: "商户信息",
        icon: <Icon component={ScenicSpotSvg} />,
        path: "merchant",
        children: [{
            key: index++,
            title: "商户分类",
            icon: <Icon component={MerchantCateSvg} />,
            path: "cate",
            element: lazyLoad(<MerchantCateManager model={new MerchantCateModel()} />),
            permission: { requirePermissions: [PermissionCode.MERCHANT_CATE_VIEW, PermissionCode.MERCHANT_CATE_MANAGE], requireType: "any" }
        }, {
            key: index++,
            path: "address",
            icon: <Icon component={MerchantAddressSvg} />,
            title: "收货地址管理",
            element: lazyLoad(<MerchantAddressManager model={new MerchantAddressModel()} />),
            permission: { requirePermissions: [PermissionCode.ADDRESS_MANAGE, PermissionCode.LIST_MERCHANT_ADDRESS], requireType: "any" }
        }, {
            key: index++,
            index: true,
            icon: <Icon component={MerchantSvg} />,
            title: "商户",
            element: lazyLoad(<MerchantManager model={new MerchantModel()} />),
            permission: { requirePermissions: [PermissionCode.MERCHANT_VIEW, PermissionCode.MERCHANT_MANAGE], requireType: "any" }
        }, {
            key: index++,
            path: ":id",
            title: "商户详情",
            showInSider: false,
            element: lazyLoad(<MerchantDetail model={new MerchantDetailModel()} />)
        }]
    }, {
        key: index++,
        title: "商品信息",
        icon: <Icon component={ProductMenuSvg} />,
        path: "product",
        children: [{
            key: index++,
            title: "商品分类",
            icon: <Icon component={ProductCateSvg} />,
            path: "cate",
            element: lazyLoad(<ProductCateManager model={new ProductCateModel()} />),
            permission: { requirePermissions: [PermissionCode.PRODUCT_CATE_VIEW, PermissionCode.PRODUCT_CATE_MANAGE], requireType: "any" }
        }, {
            key: index++,
            title: "品牌列表",
            icon: <Icon component={BrandSvg} />,
            path: "brand",
            element: lazyLoad(<BrandManager model={new BrandModel()} />),
            permission: { requirePermissions: [PermissionCode.BRAND_MANAGE, PermissionCode.BRAND_VIEW], requireType: "any" }
        }, {
            key: index++,
            index: true,
            icon: <Icon component={MerchantSvg} />,
            title: "商品",
            element: lazyLoad(<ProductManager model={new ProductModel()} />),
            permission: {
                requirePermissions: [
                    PermissionCode.PRODUCT_VIEW, PermissionCode.PRODUCT_SUBMIT, PermissionCode.PRODUCT_EDIT,
                    PermissionCode.PRODUCT_ON_SALE, PermissionCode.PRODUCT_COMMEND, PermissionCode.PRODUCT_AUDIT
                ], requireType: "any"
            }
        }, {
            key: index++,
            path: ":id",
            title: "商品详情",
            showInSider: false,
            element: lazyLoad(<ProductDetail />)
        }]
    }, {
        key: index++,
        title: "活动信息",
        icon: <Icon component={ActivityCenterSvg} />,
        path: "activity",
        children: [{
            key: index++,
            path: "voucher",
            title: "批次管理",
            icon: <Icon component={VoucherGroupSvg} />,
            element: lazyLoad(<VoucherBatchManager model={new VoucherBatchModel()} />),
            permission: { requirePermissions: [PermissionCode.COUPON_BATCH_VIEW, PermissionCode.COUPON_BATCH_MANAGE, PermissionCode.COUPON_BATCH_AUDIT], requireType: "any" }
        }, {
            index: true,
            key: index++,
            title: "活动管理",
            icon: <Icon component={ActivityManagerSvg} />,
            element: lazyLoad(<ActivityManager model={new ActivityModel()} />),
            permission: { requirePermissions: [PermissionCode.ACTIVITY_MANAGE, PermissionCode.ACTIVITY_VIEW], requireType: "any" }
        }, {
            key: index++,
            path: "voucher/:id",
            title: "批次详情",
            element: lazyLoad(<VoucherBatchDetail model={new VoucherBatchDetailModel()} />),
            showInSider: false
        }, {
            key: index++,
            path: ":id",
            title: "活动详情",
            element: lazyLoad(<ActivityDetail model={new ActivityDetailModel()} />),
            showInSider: false
        }]
    }, {
        key: index++,
        title: "文章管理",
        icon: <Icon component={ArticleMangerSvg} />,
        path: "article",
        children: [{
            key: index++,
            path: "cate",
            title: "文章分类",
            icon: <Icon component={ArticleCateSvg} />,
            element: lazyLoad(<ArticleCateManager model={new ArticleCateModel()} />),
            permission: { requirePermissions: [PermissionCode.ARTICLE_CATE_MANAGE, PermissionCode.ARTICLE_CATE_VIEW], requireType: "any" }
        }, {
            index: true,
            key: index++,
            title: "文章列表",
            icon: <Icon component={ArticleSvg} />,
            element: lazyLoad(<ArticleManger model={new ArticleModel()} />),
            permission: { requirePermissions: [PermissionCode.ARTICLE_VIEW, PermissionCode.ARTICLE_MANAGE], requireType: "any" }
        }]
    }, {
        key: index++,
        title: "营销管理",
        icon: <Icon component={ArticleMangerSvg} />,
        path: "marketing",
        children: [{
            key: index++,
            path: "banner",
            title: "轮播图管理",
            icon: <Icon component={ArticleCateSvg} />,
            element: lazyLoad(<BannerPages model={new BannerModel()} />),
            permission: { requirePermissions: [PermissionCode.ARTICLE_CATE_MANAGE, PermissionCode.ARTICLE_CATE_VIEW], requireType: "any" }
        }, {
            key: index++,
            path: "advertising",
            title: "广告管理",
            icon: <Icon component={ArticleCateSvg} />,
            element: lazyLoad(<Advertising model={new AdvertisingModel()} />),
            permission: { requirePermissions: [PermissionCode.ARTICLE_CATE_MANAGE, PermissionCode.ARTICLE_CATE_VIEW], requireType: "any" }
        }]
    }, {
        key: index++,
        title: "财务报表",
        icon: <Icon component={ArticleMangerSvg} />,
        path: "report",
        children: [{
            key: index++,
            path: "pay/list",
            title: "收退款记录",
            icon: <Icon component={ArticleCateSvg} />,
            element: lazyLoad(<PayRecordView model={new PayRecordModel()} />),
            permission: { requirePermissions: [PermissionCode.REPORT_MANAGE], requireType: "any" }
        }, {
            key: index++,
            path: "pay/summary",
            title: "缴款单",
            icon: <Icon component={ArticleCateSvg} />,
            element: lazyLoad(<PaySummaryView model={new PaySummaryModel()} />),
            permission: { requirePermissions: [PermissionCode.REPORT_MANAGE], requireType: "any" }
        }, {
            key: index++,
            path: "business",
            title: "营业收入",
            icon: <Icon component={ArticleCateSvg} />,
            element: lazyLoad(<BusinessIncomeView model={new BusinessIncomeModel()} />),
            permission: { requirePermissions: [PermissionCode.REPORT_MANAGE], requireType: "any" }
        }, {
            key: index++,
            path: "prepaid",
            title: "预收款",
            icon: <Icon component={ArticleCateSvg} />,
            element: lazyLoad(<PrepaidIncomeView model={new PrepaidIncomeModel()} />),
            permission: { requirePermissions: [PermissionCode.REPORT_MANAGE], requireType: "any" }
        }, {
            key: index++,
            path: "merchant",
            title: "商户对账单",
            icon: <Icon component={ArticleCateSvg} />,
            element: lazyLoad(<MerchantIncomeView model={new MerchantIncomeModel()} />),
            permission: { requirePermissions: [PermissionCode.MERCHANT_REPORT_VIEW], requireType: "any" }
        }]
    }, {
        key: index++,
        title: "用户管理",
        icon: <UserOutlined />,
        path: "user",
        children: [{
            key: index++,
            index: true,
            title: "用户列表",
            icon: <OrderedListOutlined />,
            element: lazyLoad(<UserManager model={new UserModel()} />),
            permission: { requirePermissions: [PermissionCode.USER_MANAGE, PermissionCode.CREATE_USER, PermissionCode.USER_ROLE_MANAGE], requireType: "any" }
        }, {
            key: index++,
            path: "role",
            title: "角色管理",
            icon: <Icon component={RoleManageSvg} />,
            element: lazyLoad(<RoleManager model={new RoleModel()} />),
            permission: { requirePermissions: [PermissionCode.ROLE_MANAGE], requireType: "any" }
        }]
    }]
}, {
    key: index++,
    path: "/login",
    title: "登录页",
    element: lazyLoad(<UserSignIn model={new SignInModel()} />),
    showInSider: false
}]

