

import { Buffer } from "buffer";

export class BitArray {
    #bitmap: Uint8Array;
    #size: number = 8;
    constructor(bitmap?: string, encoding?: BufferEncoding) {
        if (bitmap != null) {
            this.#bitmap = Buffer.from(bitmap, encoding);
            this.#size = this.#bitmap.length << 3;
        } else {
            this.#bitmap = new Uint8Array(this.#size);
        }
    }

    set (position: number, value: boolean) {
        if (position > this.#size || position < 0) {
            throw new Error("指定位置无效！");
        }
        let offset = position & 7;
        let index = position >> 3;
        if (value) {
            this.#bitmap[index] = this.#bitmap[index] | (0x80 >> offset);
        } else {
            this.#bitmap[index] = this.#bitmap[index] & ~(0x80 >> offset);
        }
    }

    get (position: number) {
        if (position > this.#size || position < 0) {
            throw new Error("指定位置无效！");
        }
        let offset = position & 7;
        let index = position >> 3;
        return !!(this.#bitmap[index] & (0x80 >> offset));
    }
}