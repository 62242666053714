

import { AppBase } from "@framework/app-base";
import { FetchContextImpl } from "@framework/fetch-context";
import { Decorator, DecoratorForm } from "@framework/component";
import { BitArray, ExceptionInterceptor, ResponseResult, storage } from "@framework/utils";

import { Button, Input, message, Modal } from "antd";

import { UserApi } from "@api";
import { PUBLIC_KEY } from "./constants";
import Icon from "@ant-design/icons";

import IconChangePwd from "@svg/icon-change-pwd.svg";

const FetchContext: FetchContextImpl = new FetchContextImpl(PUBLIC_KEY);

export const ResetPwdDecorators: Decorator[] = [{
    name: "password",
    className: "editor-form-item",
    element: <Input.Password placeholder="请输入新密码" />,
    rules: [{ type: "string", required: true, min: 8, max: 24, message: "请输入8-24位密码，允许包含大小写字母、数字、下划线以及特殊字符" }],
}, {
    name: "confirmPassword",
    dependencies: ["password"],
    element: <Input.Password placeholder="请输入确认密码" allowClear={true} />,
    updateProps: (decorator, values) => {
        const password = values[0];
        return {
            rules: decorator.hidden ? undefined : [{
                type: "string",
                required: true,
                validator: (_rule, value) => {
                    if (value !== password) {
                        return Promise.reject("两次输入的密码不一致！");
                    }
                    return Promise.resolve();
                }
            }]
        }
    }
}];

const ChangePwdDecorators: Decorator[] = [{
    name: "oldPassword",
    className: "editor-form-item",
    element: <Input.Password placeholder="请输入旧密码" />,
    rules: [{ required: true, min: 6, max: 24, message: "为确保本人操作，请输入旧登录密码" }],
}, {
    ...ResetPwdDecorators[0],
    dependencies: ["oldPassword"],
    updateProps: (_, values) => {
        const oldPassword = values[0];
        return {
            rules: [{
                type: "string",
                required: true,
                validator: (_rule, value) => {
                    if (value == null || value.length < 8 || value.length > 24) {
                        return Promise.reject("请输入8-24位密码，允许包含大小写字母、数字、下划线以及特殊字符");
                    }
                    if (oldPassword === value) {
                        return Promise.reject("新密码不能与原密码相同！");
                    }
                    return Promise.resolve();
                }
            }]
        }
    }
}, ResetPwdDecorators[1]]

class ExceptionHandleInterceptor extends ExceptionInterceptor {

    constructor() {
        super(0);
    }


    public process<T>(result: ResponseResult<T>, reason: Error): void | ResponseResult<T> {
        message.error(reason.message);
        if (result.response.status === 401) {
            const urlWithoutDomain = window.location.href.substring(window.location.origin.length);
            const params = new URLSearchParams();
            params.set("refer", urlWithoutDomain);
            window.location.replace(`/login?${params.toString()}`);
        }
    }
}

const SIGNED_KEY = "@@Signed";

FetchContext.fetch.encrypt = true;

export const fetch = FetchContext.fetch;

class App extends AppBase<UserApi.SignInfo> {
    #permissions: BitArray;
    #currentUser: UserApi.SignInfo;
    constructor() {
        super();
        this.#currentUser = storage.getObjectFromLocal<UserApi.SignInfo>(SIGNED_KEY, { 
            signed: false,
            autoSignIn: false,
            gender: 1,
            enabled: true,
            createTime: new Date().getTime()
        });
        FetchContext.fetch.addInterceptor(new ExceptionHandleInterceptor());
        const base64Str = this.#currentUser.permissions;
        this.#permissions = new BitArray(base64Str == null || base64Str == "" ? "AAAAAAAAAAA=" : base64Str , "base64");
    }

    get fetch() {
        return FetchContext.fetch;
    }

    get mapAppKey(): string {
        return "f9ff99e8788c2178abc235d5248f7b0d"
    }

    get mapSecurity(): string {
        return "78e39b66810a1f4098dbcc07ddb1aaab";
    }

    get(name: string) {
    }

    get currentUser() {
        return this.#currentUser;
    }

    get permissions(): BitArray {
        return this.#permissions;
    }

    async login(loginName?: string, password?: string, verifyCode?: string) {
        const data = await UserApi.login(loginName, password, verifyCode);
        if (data != null) {
            data.signed = true;
        }
        this.#permissions = new BitArray(data.permissions, "base64");
        storage.saveInLocal(SIGNED_KEY, data);
        this.#currentUser = data;
    }

    public doChangePassword = () => {
        const updater = Modal.success({
            title: "修改密码",
            width: 500,
            icon: <Icon component={IconChangePwd} />,
            okButtonProps: { style: { display: "none" } },
            cancelButtonProps: { style: { display: "none" } },
            content: (
                <DecoratorForm
                    className="editor-form"
                    style={{ marginTop: 20 }}
                    onSubmit={async (values) => {
                        await UserApi.changePassword({ oldPassword: values.oldPassword, newPassword: values.password });
                        updater.destroy();
                    }}
                    decorators={ChangePwdDecorators}
                >
                    <div className="operation">
                        <Button type="primary" htmlType="submit">确定</Button>
                        <Button type="default" onClick={() => updater.destroy()}>取消</Button>
                    </div>
                </DecoratorForm>
            )
        })
    }

    public doLogout = () => {
        return new Promise<void>((resolve, reject) => {
            Modal.confirm({
                title: "警告：",
                content: "您确定要退出登录吗？",
                okText: "确定",
                cancelText: "取消",
                onOk: async () => {
                    await UserApi.logout();
                    localStorage.removeItem(SIGNED_KEY);
                    resolve();
                }
            });
        });
    }
}

new App();

export default App.Instance as App;
