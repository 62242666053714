

import { fetch } from "@root/app";

export namespace MerchantCateApi {
    /**
     * 商户分类
     */
    export interface MerchantCate {
        /**
         * 分类编号
         */
        code?: string
        /**
         * 分类名称
         */
        name?: string
        /**
         * 分类状态
         */
        status?: number
        /**
         * 序号
         */
        sort?: number
        /**
         * 上级分类编号
         */
        parentCode?: string
        /**
         * 创建时间
         */
        createTime?: number
        /**
         * 创建人
         */
        createUserName?: string
        /**
         * 最后修改时间
         */
        lastUpdateTime?: number
        /**
         * 最后修改人
         */
        lastUpdateUserName?: string,
        children?: MerchantCate[]
    }

    /**
     * 商户分类状态
     */
    export enum CateStatus {
        /**
         * 显示
         */
        Show = 1,
        /**
         * 隐藏
         */
        Hidden = 2
    }

    /**
     * 新增商户分类
     * @param name 分类名称
     * @param sort 排序序号
     * @param status 分类状态 1-显示 2-隐藏
     * @param parentCode 上级分类编号
     * @returns 
     */
    export async function addMerchantCate(name?: string, sort?: number, status?: CateStatus, parentCode?: string): Promise<void> {
        return fetch.post("/api/merchant-cate", { name, status, sort, parentCode });
    }

    /**
     * 商户分类列表
     * @param name 分类名称
     * @param status 分类状态 1-显示 2-隐藏
     * @returns 
     */
    export async function listMerchantCate(name?: string, status?: CateStatus): Promise<MerchantCate[]> {
        return fetch.get("/api/merchant-cate", { name, status });
    }

    /**
     * 修改商户分类
     * @param code 需修改的商户分类编号
     * @param name 分类名称
     * @param sort 排序序号
     * @returns 
     */
    export async function updateMerchantCate(code?: string, name?: string, sort?: number): Promise<void> {
        return fetch.put(`/api/merchant-cate/${code}`, { name, sort });
    }

    /**
     * 修改商户分类状态
     * @param code 需修改的商户分类编号
     * @param status 分类状态 1-显示 2-隐藏
     * @returns 
     */
    export async function updateMerchantCateStatus(code: string, status: number): Promise<void> {
        return fetch.put(`/api/merchant-cate/status/${code}`, { status });
    }
}