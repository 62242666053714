

import { Decorator, TableColumn } from "@framework/component";
import { PageResult, TimestampConverter } from "@framework/utils";
import { BaseModel, QueryModel, QueryState } from "@framework/models";

import { DatePicker, Input, Select } from "antd";

import { OrderApi } from "@api";
import { LinkColumn, TagColumn, TimestampColumn } from "@root/component";

import { OrderStatusMap, RefundOrderStatusMap } from "./tag-map";

import OrderStatus = OrderApi.OrderStatus;
import RefundOrderStatus = OrderApi.RefundOrderStatus;


type OrderBase = OrderApi.OrderBase;
type PlatformOrder = OrderApi.PlatformOrder;
type MerchantOrder = OrderApi.MerchantOrder;
type RefundOrder = OrderApi.RefundOrder;

type OrderType = "Platform" | "Merchant" | "Refund";

export interface OrderQueryState<T extends OrderBase> extends QueryState<T> {
    orderType: OrderType
}

export interface PlatformOrderQueryState extends OrderQueryState<PlatformOrder> {
    orderType: "Platform"
}

export interface MerchantOrderQueryState extends OrderQueryState<MerchantOrder> {
    orderType: "Merchant"
}

export interface RefundOrderQueryState extends OrderQueryState<RefundOrder> {
    orderType: "Refund"
}

export class OrderModel extends QueryModel<PlatformOrderQueryState | MerchantOrderQueryState | RefundOrderQueryState> {
    #timestampConverter: TimestampConverter;
    constructor(orderType: OrderType) {
        super();
        this.state = { orderType: orderType };
        this.#timestampConverter = new TimestampConverter();
    }

    public getQueryDecorators(): Decorator<any>[] {
        const { orderType } = this.state;
        const labelCol = { span: 6 };
        const result: Decorator<any>[] = [{
            name: "orderId",
            label: "订 单 号",
            labelCol: labelCol,
            className: "query-form-item",
            element: <Input placeholder="按订单号查询" />
        }];
        if (orderType == "Platform") {
            result.push({
                name: "userPhone",
                label: "联系电话",
                labelCol: labelCol,
                className: "query-form-item",
                element: <Input placeholder="按联系电话查询" />
            }, {
                name: "userName",
                label: "购 买 人",
                labelCol: labelCol,
                className: "query-form-item",
                element: <Input placeholder="按购买人姓名查询" />
            });
        } else if (orderType == "Merchant") {
            result.push({
                name: "platformOrderId",
                label: "平台订单号",
                labelCol: labelCol,
                className: "query-form-item",
                element: <Input placeholder="按平台订单号查询" />
            })
        } else if (orderType == "Refund") {
            result[0].label = "退订单号";
            result.push({
                name: "platformOrderId",
                label: "原平台订单号",
                labelCol: labelCol,
                className: "query-form-item",
                element: <Input placeholder="按原平台订单号查询" />,
            }, {
                name: "merchantOrderId",
                label: "原商户订单号",
                labelCol: labelCol,
                className: "query-form-item",
                element: <Input placeholder="按原商户订单号查询" />,
            }, {
                name: "refundDate",
                label: "退款日期",
                labelCol: labelCol,
                className: "query-form-item",
                element: <DatePicker allowClear showTime={false} placeholder="按退款日期查询" />,
                converter: this.#timestampConverter
            }, {
                name: "status",
                label: "订单状态",
                labelCol: labelCol,
                className: "query-form-item",
                element: (
                    <Select placeholder="按退订单状态查询" allowClear>
                        <Select.Option value={RefundOrderStatus.Pending}>等待商家确认</Select.Option>
                        <Select.Option value={RefundOrderStatus.Refunding}>正在退款</Select.Option>
                        <Select.Option value={RefundOrderStatus.Refunded}>已退款</Select.Option>
                        <Select.Option value={RefundOrderStatus.Closed}>已关闭</Select.Option>
                        <Select.Option value={RefundOrderStatus.Rejected}>已拒绝</Select.Option>
                    </Select>
                )
            });
        }
        if (orderType != "Refund") {
            result.push({
                name: "createTime",
                label: "下单日期",
                labelCol: labelCol,
                className: "query-form-item",
                element: <DatePicker allowClear showTime={false} placeholder="按下单日期查询" />,
                converter: this.#timestampConverter
            }, {
                name: "status",
                label: "订单状态",
                labelCol: labelCol,
                className: "query-form-item",
                element: (
                    <Select placeholder="按订单状态查询" allowClear>
                        <Select.Option value={OrderStatus.New}>新建</Select.Option>
                        <Select.Option value={OrderStatus.Paying}>正在支付</Select.Option>
                        <Select.Option value={OrderStatus.Paid}>已付款</Select.Option>
                        <Select.Option value={OrderStatus.Finished}>交易完成</Select.Option>
                        <Select.Option value={OrderStatus.Closed}>已关闭</Select.Option>
                    </Select>
                )
            })
        }
        return result;
    }

    public getMerchantColumns():  TableColumn<MerchantOrder>[] {
        return [
            new LinkColumn("订单号", "%(id)s", "id"),
            new LinkColumn("商户", "/app/merchant/%(merchantId)s", { stringFormat: "%(merchant.name)s" }),
            new LinkColumn("平台订单", "/app/order/platform/%(platformOrderId)s", "platformOrderId"),
            { title: "总金额", dataIndex: "orderPrice", stringFormat: "￥%.2f", fallback: "--"},
            { title: "支付金额", dataIndex: "paidPrice", stringFormat: "￥%.2f", fallback: "--"},
            new LinkColumn("优惠券", "/app/activity/voucher/%(couponBatchId)s", "couponBatchName", { fallback: "--" }),
            new TimestampColumn("下单时间", "createTime"),
            new TagColumn(OrderStatusMap, "状态", "status")
        ];
    }

    public getPlatformColumns():  TableColumn<PlatformOrder>[] {
        return [
            new LinkColumn("订单号", "%(id)s", "id"),
            { title: "总金额", dataIndex: "orderPrice", stringFormat: "￥%.2f", fallback: "--"},
            { title: "支付金额", dataIndex: "paidPrice", stringFormat: "￥%.2f", fallback: "--"},
            new LinkColumn("优惠券", "/app/activity/voucher/%(couponBatchId)s", "couponBatchName", { fallback: "--" }),
            new TimestampColumn("下单时间", "createTime"),
            new TimestampColumn("过期时间", "expireTime", { fallback: "--" }),
            new TimestampColumn("关闭时间", "closeOrderTime", { fallback: "--" }),
            new TagColumn(OrderStatusMap, "状态", "status")
        ];
    }

    public getRefundColumns(): TableColumn<RefundOrder>[] {
        return [
            new LinkColumn("订单号", "%(id)s", "id"),
            new LinkColumn("平台订单号", "/app/order/platform/%(platformOrderId)s", "platformOrderId"),
            new LinkColumn("商户订单号", "/app/order/merchant/%(merchantOrderId)s", "merchantOrderId"),
            {title: "商品数量", dataIndex: "qty", fallback: "--" },
            { title: "金额", dataIndex: "refundPrice", stringFormat: "￥%.2f", fallback: "--"},
            { title: "实退金额", dataIndex: "actualRefundPrice", stringFormat: "￥%.2f", fallback: "--"},
            new TimestampColumn("发起时间", "refundTime"),
            new TagColumn(RefundOrderStatusMap, "状态", "status")
        ];
    }

    public getTableColumns(): TableColumn<PlatformOrder>[] | TableColumn<MerchantOrder>[] | TableColumn<RefundOrder>[] {
        const { orderType } = this.state;
        if (orderType == "Platform") {
            return this.getPlatformColumns();
        } else if (orderType == "Merchant") {
            return this.getMerchantColumns();
        } else {
            return this.getRefundColumns();
        }
    }

    public async queryOverride(params?: any): Promise<(PlatformOrder | MerchantOrder | RefundOrder)[] | PageResult<PlatformOrder | MerchantOrder | RefundOrder>> {
        const { orderType } = this.state;
        if (orderType == "Platform") {
            return OrderApi.listPlatformOrder(params?.limit, params?.skip,
                params?.orderId, params?.userPhone, params?.userName, params?.createTime, params?.status);
        } else if (orderType == "Merchant") {
            return OrderApi.listMerchantOrder(params?.limit, params?.skip,
                params?.orderId, params?.platformOrderId, params?.createTime, params?.status);
        } else {
            return OrderApi.listRefundOrder(params?.limit, params?.skip, params?.orderId,
                params?.platformOrderId, params?.merchantOrderId, params?.refundDate, params?.status);
        }
    }
}

export interface DetailState<T extends OrderBase> {
    orderType: OrderType
    order?: T
    loading: boolean
}

export interface PlatformDetailState extends DetailState<PlatformOrder> {
    orderType: "Platform"
}


export interface MerchantDetailState extends DetailState<MerchantOrder> {
    orderType: "Merchant"
}


export interface RefundDetailState extends DetailState<RefundOrder> {
    orderType: "Refund"
}

export class OrderDetailModel extends BaseModel<PlatformDetailState | MerchantDetailState | RefundDetailState> {
    constructor(orderType: OrderType) {
        super();
        this.state = { orderType: orderType, loading: false };
    }

    async detail (orderId?: string) {
        const { orderType } = this.state;
        if (orderType == "Platform") {
            let order = await OrderApi.getPlatformOrderDetail(orderId);
            console.log(order);
            this.updateState({ order });
        } else if (orderType == "Refund") {
            let order = await OrderApi.getRefundOrderDetail(orderId);
            console.log(order);
            this.updateState({ order: order });
        } else if (orderType == "Merchant") {
            let order = await OrderApi.getMerchantOrderDetail(orderId);
            console.log(order);
            this.updateState({ order });
        }
    }
}