import { DEFAULT_IDS_CONVERTER, PageResult } from "@framework/utils";
import { QueryModel, QueryState } from "@framework/models";
import { Decorator, DecoratorForm, FileUploader, TreeQuerySelect, QuillEditor, TableColumn, PermissionButton, QuerySelect } from "@framework/component";

import { Button, Divider, Input, InputNumber, Modal, ModalFuncProps, Result, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { FilesConverter } from "@root/converter";
import { MarketingApi, FileUploadApi, ProductApi } from "@api";
import { MarketingStatusMap, ArticleStatusMap } from "./tag-map";
import { ImageColumn, SvgColumn, TagColumn, TimestampColumn } from "@root/component";

import MarketingStatus = MarketingApi.marketingStatus;
import MarketingType = MarketingApi.marketingType;
import { PermissionCode } from "@root/permission";

type Marketing = MarketingApi.Marketing;

interface MarketingState extends QueryState<Marketing> {

}

export class BannerModel extends QueryModel<MarketingState> {
    constructor() {
        super();
    }

    public getQueryDecorators(): Decorator<any>[] {
        const labelCol = { span: 5 };
        return [{
            name: "title",
            label: "标 题",
            labelCol: labelCol,
            className: "query-form-item",
            element: <Input placeholder="按标题名称查询" />
        }, {
            name: "status",
            label: "状 态",
            labelCol: labelCol,
            className: "query-form-item",
            element: (
                <Select allowClear={true}>
                    <Select.Option value={MarketingStatus.Enabled}>启用</Select.Option>
                    <Select.Option value={MarketingStatus.Disabled}>禁用</Select.Option>
                </Select>
            )
        }]
    }

    public getEditorDecorators(editingItem?: Marketing): Decorator<any>[] {
        const { Search } = Input;
        const labelCol = { span: 6 };
        return [{
            name: "title",
            label: "标题名称",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: <Input placeholder="请输入标题名称" />,
            rules: [{ required: true, message: "标题名称不能为空" }],
            initialValue: editingItem?.title
        }, {
            name: "status",
            label: "状态",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: (
                <Select allowClear={true}>
                    <Select.Option value={MarketingStatus.Enabled}>启用</Select.Option>
                    <Select.Option value={MarketingStatus.Disabled}>禁用</Select.Option>
                </Select>),
            initialValue: editingItem?.status
        }, {
            name: "sort",
            label: "排序序号",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: <InputNumber min={0} max={999999} placeholder="请输入排序序号" />,
            initialValue: editingItem?.sort
        }, {
            name: "type",
            label: "类型",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: (
                <Select allowClear={true}>
                    <Select.Option value={null}>无</Select.Option>
                    <Select.Option value={MarketingType.marketingType}>商品</Select.Option>
                </Select>),
            rules: [{ required: true, message: "类型不能为空" }],
            initialValue: editingItem?.type
        }, {
            name: "relevancyId",
            label: "关联商品",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: (
                <QuerySelect
                    allowClear
                    keyOfValue="id"
                    keyOfLabel="storeName"
                    placeholder="搜索想要绑定的商品"
                    fetchItems={(value, limit, skip) => ProductApi.list(limit, skip, value)}
                />
            ),
            rules: [{ required: true, message: "关联ID不能为空" }],
            initialValue: editingItem?.relevancyId
        }, {
            name: "image",
            label: "轮 播 图",
            labelCol: labelCol,
            className: "editor-form-item-2",
            element: (
                <FileUploader
                    maxCount={5}
                    accept="image/*"
                    listType="picture-card"
                    converter={FilesConverter}
                    customRequest={FileUploadApi.requestForUploader}
                >
                    <p className="ant-upload-drag-icon" style={{ display: "inline" }}>
                        <InboxOutlined height="100%" style={{ fontSize: 32 }} />
                        <span className="ant-upload-text" style={{ display: "inline-block" }}>点击此区域上传图片</span>
                        <span className="ant-upload-hint" style={{ display: "inline-block" }}>建议图片宽高比3:4，大小不超过600k</span>
                    </p>
                </FileUploader>
            ),
            converter: DEFAULT_IDS_CONVERTER,
            rules: [{ required: true, message: "至少上传一张" }],
            initialValue: editingItem?.image
        }];
    }

    public getTableColumns(): TableColumn<Marketing>[] {
        return [
            { title: "标题", dataIndex: "title" },
            new ImageColumn("图片", "image", 64),
            { title: "排序序号", dataIndex: "sort", fallback: "--" },
            new TimestampColumn("创建时间", "createTime"),
            { title: "创 建 人", dataIndex: "createUserName", fallback: "--" },
            new TimestampColumn("最后修改时间", "lastUpdateTime"),
            { title: "最后修改人", dataIndex: "lastUpdateUserName", fallback: "--" },
            new TagColumn(MarketingStatusMap, "状态", "status"),
            {
                render: (value, record, index) => {
                    const result = [
                        <PermissionButton
                            type="link"
                            key="update"
                            hideWhenNoPermission={true}
                            onClick={() => this.showEditor(record)}
                            permissionCode={PermissionCode.ARTICLE_CATE_MANAGE}
                        >
                            修改
                        </PermissionButton>
                    ];
                    result.push(
                        <Divider key="4" type="vertical" />,
                        <PermissionButton
                            key="del"
                            type="link"
                            style={{ color: "crimson" }}
                            hideWhenNoPermission={true}
                            permissionCode={PermissionCode.ARTICLE_MANAGE}
                            onClick={() => this.#deleteBanner(record)}
                        >
                            删除
                        </PermissionButton>
                    );
                    if (record.status == MarketingStatus.Enabled) {
                        result.push(
                            <Divider key="1" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="disable"
                                hideWhenNoPermission={true}
                                onClick={() => this.#disable(record)}
                                permissionCode={PermissionCode.ARTICLE_CATE_MANAGE}
                            >
                                禁用
                            </PermissionButton>
                        );
                    } else {
                        result.push(
                            <Divider key="1" type="vertical" />,
                            <PermissionButton
                                type="link"
                                key="enable"
                                hideWhenNoPermission={true}
                                onClick={() => this.#enable(record)}
                                permissionCode={PermissionCode.ARTICLE_CATE_MANAGE}
                            >
                                启用
                            </PermissionButton>
                        );
                    }
                    return result;
                }
            }
        ];
    }

    #enable = (item?: Marketing) => {
        MarketingApi.changeBannerStatus(item?.id, MarketingStatus.Enabled).then(this.refresh);
    }

    #disable = (item?: Marketing) => {
        MarketingApi.changeBannerStatus(item?.id, MarketingStatus.Disabled).then(this.refresh);
    }

    #deleteBanner = async (item?: Marketing) => {
        await this.#showConfirmModal({
            title: "删除确认",
            type: "warn",
            content: `正在删除图片《${item?.title}》，是否继续？`,
            okText: "删除",
            okButtonProps: { danger: true }
        })
        MarketingApi.deleteBanner(item?.id).then(this.refresh)
    }

    #showConfirmModal = (props: ModalFuncProps) => {
        const { onOk, onCancel } = props;

        return new Promise<void>((resolve, reject) => {
            props.onOk = (...args) => {
                onOk != null && onOk(...args);
                resolve();
            };
            props.onCancel = (...args) => {
                onCancel != null && onCancel(...args);
                reject();
            }
            Modal.confirm(props);
        })
    }

    public showEditor = (item?: Marketing) => {
        
        this.setState({ showEditor: true, editingItem: item });
    }

    public saveOrUpdate(item?: Marketing) {
        const { editingItem } = this.state;
        if (editingItem?.id == null) {
            return MarketingApi.addBanner(item?.title, item?.status, item?.sort, item?.image, item?.relevancyId, item?.type);
        } else {
            return MarketingApi.changeBanner(editingItem.id, item?.title, item?.sort, item?.image, item?.status, item?.relevancyId, item?.type);
        }
    }

    public queryOverride(params?: any): Promise<PageResult<Marketing>> {
        return MarketingApi.getBannerList(params?.title, params?.status, params?.type);
    }
}