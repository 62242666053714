import { AddressApi } from "@api";
import app from "@root/app";
import { Decorator, PermissionButton, TableColumn } from "@root/framework/component";
import { QueryModel, QueryState } from "@root/framework/models";
import { PageResult } from "@root/framework/utils";
import { PermissionCode } from "@root/permission";
import { Divider, Input, Select, Switch } from "antd";


export interface State extends QueryState<AddressApi.Address> {

}

export class MerchantAddressModel extends QueryModel<State> {

    constructor() {
        super();
    }

    public getEditorDecorators(address?: AddressApi.Address): Decorator<any>[] {
        return [{
            colon: false,
            name: "default",
            label: "设为默认",
            valuePropName: "checked",
            className: "editor-form-item",
            element: <Switch checkedChildren="是" unCheckedChildren="否" />,
            initialValue: address?.default == null ? false : address.default
        }, {
            name: "name",
            label: "收货人",
            className: "editor-form-item",
            element: <Input placeholder="请填写收货人姓名" maxLength={100} />,
            rules: [{ required: true, message: "请填写收货人姓名" }],
            initialValue: address?.name
        }, {
            name: "phone",
            label: "联系电话",
            className: "editor-form-item",
            element: <Input placeholder="请填写收货人联系电话" maxLength={100} />,
            rules: [{ required: true, message: "请填写收货人联系电话", pattern: /(1\d{10}|0\d{10}(-?\d{3,4})?)/ }],
            initialValue: address?.phone
        }, {
            name: "location",
            label: "收货地址",
            className: "editor-form-item",
            element: <Input placeholder="请填写收货人联系电话" maxLength={100} />,
            rules: [{ required: true, message: "请填写收货人姓名" }],
            initialValue: address?.location
        }];
    }

    public getQueryDecorators(): Decorator<any>[] {
        const decorators = [{
            name: "location",
            label: "收货地址",
            className: "query-form-item",
            element: (<Input placeholder="按收货地址查询" />)
        }, {
            name: "name",
            label: "收货人",
            className: "query-form-item",
            element: (<Input placeholder="按收货人姓名查询" />)
        }, {
            name: "phone",
            label: "联系电话",
            className: "query-form-item",
            element: (<Input placeholder="按收货人联系电话查询" />)
        }];
        if (app.currentUser.merchantId == -1) {
            decorators.unshift({
                name: "merchantName",
                label: "商户名称",
                className: "query-form-item",
                element: (<Input placeholder="按商户名称查询" />)
            });
        }
        return decorators;
    }

    public getTableColumns(): TableColumn<AddressApi.Address>[] {
        return [
            { title: "收货人", dataIndex: "name", fallback: "--" },
            { title: "联系电话", dataIndex: "phone", fallback: "--" },
            { title: "收货地址", dataIndex: "location", fallback: "--" },
            { title: "创建人", dataIndex: "createUserName", fallback: "--" },
            { title: "最后修改人", dataIndex: "lastUpdateUserName", fallback: "--" },
            {
                render: (value, record) => {
                    return (
                        <div>
                            <PermissionButton
                                type="link"
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.ADDRESS_MANAGE}
                                onClick={this.showEditor.bind(this, record)}
                            >
                                修改
                            </PermissionButton>
                            <Divider type="vertical" />
                            <PermissionButton
                                type="link"
                                danger={true}
                                hideWhenNoPermission={true}
                                permissionCode={PermissionCode.ADDRESS_MANAGE}
                                onClick={this.showEditor.bind(this, record)}
                            >
                                删除
                            </PermissionButton>
                        </div>
                    )
                }
            }
        ];
    }

    public queryOverride(params?: any): Promise<PageResult<AddressApi.Address> | AddressApi.Address[]> {
        return AddressApi.list(params?.limit, params?.skip, params?.merchantName, params?.location);
    }

    public showEditor = (activity?: AddressApi.Address) => {
        this.updateState({ editingItem: activity, showEditor: true });
    }

    public async saveOrUpdate(address: AddressApi.Address) {
        const { editingItem } = this.state;
        if (editingItem?.id == null) {
            return await AddressApi.add(address.default, address.name, address.location, address.phone);
        } else {
            await AddressApi.modify(editingItem.id, address.default, address.name, address.location, address.phone);
            return Object.assign({}, editingItem, address);
        }
    }
}