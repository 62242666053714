

export type AddFunc<T> = (a: Partial<T>, b: T) => T;

export interface SummaryGroup<T extends {}> {
    keyProp: keyof T;
    valueProp: keyof T;
}

interface SummaryProp<T> {
    colSpan: number;
    rowSpan: number;
    key?: T[keyof T];
    value: T[keyof T];
};

export interface Summary<T> {
    id?: number;
    isTotal: boolean;
    count: number;
    summaryProps: SummaryProp<T>[];
}

export declare type SummaryItem<T extends {}> = T & Summary<T>;

type SummaryRow<T extends {}> = T & { count: number, rowCount: number, key?: T[keyof T], topIndex?: number };

/**
 * 分类汇总。
 */
export class Subtotal<T extends {}> {
    items: T[];
    add: AddFunc<T>;

    constructor(items: T[], add: AddFunc<T>) {
        this.add = add;
        this.items = items;
    }

    private doSum(a: SummaryRow<T>, b: SummaryItem<T>) {
        a.count += 1;
        if (b.isTotal) {
            a.rowCount += (b as unknown as SummaryRow<T>).rowCount;
        } else {
            a.rowCount += 1;
        }
        this.add(a, b);
    }

    public sort(compare?: (a: T, b: T) => number) {
        if (this.items != null) {
            this.items.sort(compare);
        }
    }

    /**
     * 对当前数据列表执行分类汇总操作。
     * 
     * @param groups 分类汇总的分组信息。
     * @returns 分类汇总结果。
     */
    collect(groups: SummaryGroup<T>[]): Array<SummaryItem<T>> {
        let changedIndex = -1;

        let first = true;
        const result = [] as SummaryItem<T>[];
        const summaryRows = this.getSummaryRows(groups);

        for (const item of this.items) {
            const summaryItem: SummaryItem<T> = { ...item, count: 0, isTotal: false, summaryProps: [] };
            this.initPropMap(summaryItem, groups);
            changedIndex = this.findChangedGroupIndex(groups, summaryRows, item);
            if (changedIndex > 0) {
                if (!first) {
                    this.addSummaryRows(changedIndex, summaryRows, groups, result);
                }
                first = false;
                this.updateSummaryRows(changedIndex, summaryItem, summaryRows, groups, result.length);
            }
            this.doSum(summaryRows[summaryRows.length - 1], summaryItem);
            result.push(summaryItem);
        }
        this.addSummaryRows(0, summaryRows, groups, result);
        return result;
    }

    private getSummaryRows(groups?: SummaryGroup<T>[] | null | undefined): SummaryRow<T>[] {
        const total = Object.assign({} as T, { count: 0, rowCount: 0 });
        if (groups == null || groups.length == 0) {
            return [total];
        }
        const result: SummaryRow<T>[] = [total];
        
        groups.forEach((group, index) => {
            if (index >= groups.length - 1) {
                return;
            }
            result.push(Object.assign({} as T, {count: 0, rowCount: 0}));
        });
        return result;
    }

    private initPropMap(data: SummaryItem<T>, groups: SummaryGroup<T>[]) {
        data.summaryProps = groups.map((group, index) => ({
            colSpan: 1,
            rowSpan: 1,
            key: data[group.keyProp],
            value: data[group.valueProp]
        }));
    }

    /**
     * 当 startIndex 位置处的分组数据发生变动时，将该分组之后所有分组的汇总数据倒序添加到结果列表（当该分组汇总的行数大于1时）。
     * 
     * @param startIndex 数据发生变动的分组的索引位置。
     * @param groups 分组
     * @param result 结果列表。
     */
    private addSummaryRows(startIndex: number, summaryRows: SummaryRow<T>[], groups: SummaryGroup<T>[], result: SummaryItem<T>[]) {
        let addedRows = 0;
        for (let i = summaryRows.length - 1; i >= startIndex; i--) {
            const summaryRow = summaryRows[i];
            if (summaryRow != null) {
                const summary = this.toSummaryItem(summaryRow, i, groups);
                if (summaryRow.count > 1 || i == 0) {
                    result.push(summary);
                    addedRows++;
                }
                summaryRow.rowCount += addedRows;
                this.processCollectedItems(summaryRow, i, result);
                if (i > 0) {
                    this.doSum(summaryRows[i - 1], summary);
                }
            }
        }
        if (startIndex > 0) {
            summaryRows[startIndex - 1].rowCount += addedRows;
        }
    }

    /**
     * 当 startIndex 位置处的分组数据发生变动时，重新设置该分组之后所有分组的汇总数据。
     * 
     * @param startIndex 
     * @param data 分组数据发生变动的原始数据
     * @param groups 分组
     */
    private updateSummaryRows(startIndex: number, data: SummaryItem<T>, summaryRows: SummaryRow<T>[], groups: SummaryGroup<T>[], index: number) {
        for (let i = startIndex; i < summaryRows.length; i++) {
            const summaryRow: SummaryRow<T> = Object.assign({} as T, { count: 0, rowCount: 0 });
            summaryRow.key = data[groups[i - 1].keyProp];
            summaryRow.topIndex = index;
            summaryRows[i] = summaryRow;
        }
    }

    private toSummaryItem(summaryRow: SummaryRow<T>, index: number, groups: SummaryGroup<T>[]) : SummaryRow<T> & SummaryItem<T> {
        const topRow = summaryRow.topIndex == null ? null : this.items[summaryRow.topIndex];
        const result = Object.assign({ ...summaryRow }, { isTotal: true, count: summaryRow.count, summaryProps: [] as SummaryProp<T>[] });
        groups.forEach((group, idx) => {
            const prop: SummaryProp<T> = { colSpan: 1, rowSpan: 1, value: null as any};
            prop.key = topRow == null ? null : topRow[group.keyProp] as any;
            prop.value = topRow == null ? null : topRow[group.valueProp] as any;
            if (idx < index) {
                prop.rowSpan = 0;
            } else if (idx == index) {
                prop.key = summaryRow.key;
                prop.value = (index == 0 ? "总计" : "合计") as any;
                prop.colSpan = groups.length - index;
            } else {
                prop.colSpan = 0;
            }
            result.summaryProps.push(prop);
        });
        return result;
    }

    private processCollectedItems(summaryRow: SummaryRow<T>, index: number, list: SummaryItem<T>[]) {
        if (summaryRow.topIndex == null) {
            return;
        }
        const { topIndex } = summaryRow;
        const item = list[topIndex];
        item.summaryProps[index - 1].rowSpan = summaryRow.rowCount;
        for (let i = 1; i < summaryRow.rowCount; i++) {
            list[topIndex + i].summaryProps[index - 1].rowSpan = 0;
        }
    }

    /**
     * 查找数据的分组与当前分组列表中的数据相比，发生变动的分组所在索引位置。
     * 
     * @param groups 分组列表。
     * @param data 当前数据。
     * @returns 若当前数据的分组相比当前分组列表的分组有变动，则返回变动的索引位置；否则，返回 -1。
     */
    private findChangedGroupIndex(groups: SummaryGroup<T>[], summaryRows: SummaryRow<T>[], data: T) {
        if (groups.length == 0) {
            return 0;
        }
        for (let i = 0; i < groups.length - 1; i++) {
            if (data[groups[i].keyProp] != summaryRows[i + 1].key) {
                return i + 1;
            }
        }
        return -1;
    }
}