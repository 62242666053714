
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Collapse } from "antd";
import { Component } from "react";


export interface ListFieldProps<T> {
    items?: T[]
    disabled?: boolean
    newItem?: () => T
    getExtraProps?: (item: T) => any
    ItemComponent: React.ComponentType<{ item: T, onChange?: (value: any) => void, [key: string]: any }>
    onChange?: (items: T[]) => void
}

interface ListFieldState<T> {
    items: T[]
    keyMap: {
        nextKey: number
        keys: number[]
    }
    activeKey?: React.Key
    itemsFromProps?: T[]
}

export class ListField<T> extends Component<ListFieldProps<T>, ListFieldState<T>> {

    constructor(props: ListFieldProps<T>) {
        super(props);
        const keyMap = {
            nextKey: 1,
            keys: [] as number[]
        };
        props.items?.forEach(() => {
            keyMap.keys.push(keyMap.nextKey);
            keyMap.nextKey += 1;
        });

        this.state = { items: [], itemsFromProps: [], keyMap: keyMap };
    }

    static getDerivedStateFromProps(nextProps: ListFieldProps<any>, prevState: ListFieldState<any>) {
        if (nextProps.hasOwnProperty("items")) {
            if (nextProps.items != prevState.itemsFromProps) {
                const keyMap = { ...prevState.keyMap };
                nextProps.items?.forEach(() => {
                    keyMap.keys.push(keyMap.nextKey);
                    keyMap.nextKey += 1;
                });
                return { itemsFromProps: nextProps.items, items: nextProps.items == null ? [] : nextProps.items, keyMap: keyMap };
            }
        }
        return null;
    }

    addItem = () => {
        const { items, keyMap } = this.state;
        const { onChange, newItem } = this.props;

        const item = newItem == null ? {} as T : newItem();
        const result = [...items, item];
        if (onChange != null) {
            onChange(result);
        }
        keyMap.keys.push(keyMap.nextKey);
        keyMap.nextKey += 1;
        this.setState({ items: result, activeKey: keyMap.keys[result.length - 1] });
    }

    removeItem = (removedIndex: number) => {
        const { onChange } = this.props;
        const { items, keyMap, activeKey } = this.state;

        const removedKey = keyMap.keys[removedIndex];
        keyMap.keys.splice(removedIndex, 1);
        const result = items.filter((_, index) => index != removedIndex);
        if (onChange != null) {
            onChange(result);
        }
        let key = activeKey;
        if (removedKey == activeKey) {
            key = removedIndex >= result.length ? keyMap.keys[removedIndex - 1] : keyMap.keys[removedIndex];
        }
        this.setState({ items: result, keyMap: keyMap, activeKey: key });
    }

    fireOnItemChange = (index: number, value: any) => {
        const { items } = this.state;
        const { onChange } = this.props;
        const result = items.slice(0, index);
        result.push(value);
        result.push(...items.slice(index + 1));
        this.setState({ items: result });
        if (onChange != null) {
            onChange(result);
        }
    }

    render() {
        const { ItemComponent, getExtraProps, disabled } = this.props;
        const { items, keyMap, activeKey } = this.state;
        return (
            <div style={{ display: "flex", flexFlow: "column nowrap", maxHeight: 600, overflow: "auto" }}>
                <Collapse accordion={true} activeKey={activeKey} bordered={true} collapsible="header" onChange={(key) => this.setState({ activeKey: key as string })} expandIconPosition="end">
                    {items.map((item, index) => {
                        const extraProps = getExtraProps == null ? null : getExtraProps(item);
                        const finalProps = extraProps == null ? {} : extraProps;
                        return (
                            <Collapse.Panel
                                key={keyMap.keys[index]}
                                header={`第${index + 1}项`}
                                extra={<Button disabled={disabled} type="link" size="small"><MinusCircleOutlined onClick={() => this.removeItem(index)} /></Button>}
                            >
                                <ItemComponent {...finalProps} item={item} onChange={(value) => this.fireOnItemChange(index, value)} />
                            </Collapse.Panel>
                        )
                    })}
                </Collapse>
                <Button style={{ margin: 10 }} disabled={disabled} onClick={this.addItem} type="dashed"><PlusOutlined />添加</Button>
            </div>
        )
    }
}