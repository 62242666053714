

import moment from "moment";

export function dateRender (value?: number) {
    if (value == null) {
        return "--";
    } else {
        return moment(value).format("yyyy-MM-DD");
    }
}


export function dateTimeRender (value?: number) {
    if (value == null) {
        return "--";
    } else {
        return moment(value).format("yyyy-MM-DD HH:mm");
    }
}