

import { BaseModel } from "@framework/models";
import { BaseComponent, PropsType } from "@framework/component";
import { RouteWithPermission } from "@framework/route-with-permission";


import { Layout, Menu } from "antd";
import { NavLink } from "react-router-dom";
import { SiderBarModel } from "@root/models";
import { ItemType } from "antd/lib/menu/hooks/useItems";


import styles from "@root/styles/sidebar.css";

const { Sider } = Layout;
const { SubMenu } = Menu;

export class SiderBarComponent extends BaseComponent<PropsType<SiderBarModel>> {

    constructor(props: PropsType<SiderBarModel>) {
        super(props)
    }

    onModelStateChanged(source: BaseModel<any>, params: any, originState: any): void {
        if (source == this.props.model) {
            this.setState(source.state);
        }
    }

    getExactPath = (router: RouteWithPermission, basePath: string) => {
        if (router.index || router.path == null || router.path == "") {
            return basePath.endsWith("/") ? basePath : `${basePath}/`;
        } else {
            return `${basePath}/${router.path}`
        }
    }

    onItemSelected = (info: any) => {
        this.props.model.updateState({selectedKeys: [info.key]});
    }

    renderMenuItems(routers?: RouteWithPermission[], basePath: string = ""): ItemType[] {
        let result: Array<ItemType> = [];
        if (routers == null) {
            return result;
        }
        for (let router of routers) {
            let path = this.getExactPath(router, basePath);
            let children = router.children == null ? null : this.renderMenuItems(router.children, path);

            if (children != null && children.length > 0) {
                result.push({ 
                    key: router.key.toString(),
                    label: router.title,
                    icon: router.icon,
                    children: children
                });
            } else if (router.showInSider == null || router.showInSider) {
                result.push({
                    key: router.key.toString(),
                    label: <NavLink to={path}>{router.title}</NavLink>,
                    icon: router.icon
                });
            }
        }

        return result;
    }

    onMenuOpen = (openKeys: string[]) => {
        this.props.model.updateState({ openKeys })
    }

    render() {
        const { collapsed, openKeys, selectedKeys, routers } = this.state;
        return (
            <Sider
                collapsed={collapsed}
                className={`${styles.sider} no-print`}
                collapsible={true}
                trigger={null}
            >
                <NavLink to="/app" className={styles.logo} reloadDocument={false} replace={true} />
                <Menu
                    theme="dark"
                    mode="inline"
                    openKeys={openKeys}
                    selectedKeys={selectedKeys}
                    onOpenChange={this.onMenuOpen}
                    onSelect={this.onItemSelected}
                    items={this.renderMenuItems(routers, "/app")}
                />
            </Sider>
        )
   }
}