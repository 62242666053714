

import { PageResult } from "@framework/utils";

import { fetch } from "@root/app";
import { PickRequire } from "./types";

export namespace BrandApi {

    export enum BrandStatus {
        enabled = 1,
        disabled = 2
    }

    export interface Brand {
        id?: number
        /**
         * 品牌名称
         */
        name?: string
        /**
         * 排序序号
         */
        sort?: number
        /**
         * 状态 1-启用 2-禁用
         */
        status?: BrandStatus
        /**
         * 品牌展示图
         */
        image?: string
        /**
         * 品牌logo
         */
        logo?: string
        /**
         * 在推荐品牌中的排序序号
         */
        promoteSort?: number
        /**
         * 描述
         */
        info?: string
        /**
         * 创建时间
         */
        createTime?: number
        /**
         * 创建人
         */
        createUserName?: string
        /**
         * 最后修改时间
         */
        lastUpdateTime?: number
        /**
         * 最后修改人
         */
        lastUpdateUserName: string
    }

    export async function add(params: PickRequire<Brand, "name" | "sort" | "image" | "logo" | "info" | "status", "promoteSort">) {
        return fetch.post<Brand>("/api/brand", params);
    }

    export async function changeStatus(id: number, status: BrandStatus) {
        return fetch.put<void>(`/api/brand/status/${id}`, { status });
    }

    export async function update(id: number, params: PickRequire<Brand, "name" | "sort" | "image" | "logo" | "info", "promoteSort">) {
        return fetch.put<void>(`/api/brand/${id}`, params);
    }

    export async function list(limit?: number, skip?: number, params?: Pick<Brand, "name" | "status">) {
        return fetch.get<PageResult<Brand>>("/api/brand", { limit, skip, ...params });
    }
}