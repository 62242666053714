

import { fetch } from "@root/app";
import { PageResult } from "@root/framework/utils";


export namespace MarketingApi {

    export enum marketingStatus {
        Enabled = 1,
        Disabled = 2
    }

    export enum marketingType {
        marketingType = 1
    }

    /**
     * banner
     */
    export interface Marketing  {
        id?: string
        /** 
         * 标题
         */
        title?: string
        /**
         * 搜索输入框
         */
        value?: string
        /**
         * 类型
         */
        type?: number
        /**
         * 轮播图
         */
        image?: string
        /**
         * 状态
         */
        status?: number
         /**
         * 排序
         */
        sort?: number
          /**
         * 关联id
         */
        relevancyId?: string
        /**
         * 创建时间
         */
        createTime?: number
        /**
         * 创建人
         */
        createUserName?: string
        /**
         * 最后修改时间
         */
        lastUpdateTime?: number
        /**
         * 最后修改人
         */
        lastUpdateUserName?: string
    }

    /**
     * 查询banner列表
     * @param title 按标题名称查询
     * @param status 按状态查询
     * @param type 按类型查询
     * @returns banner列表
     */
    export function getBannerList(title?: string, status?: marketingStatus, type?: string){
        return fetch.get<PageResult<Marketing>>("/api/banner", {title, status, type})
    }

    export function addBanner(title?: string, status?: marketingStatus, sort?: number, image?: string, relevancyId?: string, type?: number){
        return fetch.post("/api/banner", {title, status, sort, image, relevancyId, type})
    }

    export function changeBanner(id?: string, title?: string, sort?: number, image?: string, status?: marketingStatus, relevancyId?: string, type?: number){
        return fetch.put(`/api/banner/${id}`, {title, sort, image, status, relevancyId, type})
    }

    export function changeBannerStatus(id?: string, status?: marketingStatus){
        return fetch.put(`/api/banner/status/${id}`, {status})
    }

    export function deleteBanner(id?: string){
        return fetch.delete(`/api/banner/${id}`)
    }

    export function getProductList(name?: string){
        return fetch.get("/api/product", {name})
    }
}